// START modules
import React, { useState, useEffect, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import connect from 'react-redux/es/connect/connect';
// END modules

// START components
import TabPanel from '../../../components/TabPanel';
import Upload from './Upload';
import Manage from './Manage';
import Settings from './Settings';
// END components

// START material
import {Button, Box, Modal, Tabs, Tab, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
// END material

// START actions
import {
  contentLoadKeywords,
  contentLoadCreateKeywords,
  loadModifyContentFolder,
  loadDeleteContentFolder,
  loadCreateContentAssets,
  loadReadContentAssets,
  loadDeleteContentAsset,
  setModifyContentAssets,
  loadUpdateContentAsset,
  loadUpdateContentItem,
  setResetContentAssets,
  loadDeleteContentFolderAsset,
  loadDeleteContentItem,
  contentRemoveItems,
  loadCheckLinkedItem,
  loadUpdateLinkedContentItem,
  loadCheckGiphyChannel,
  addGiphyChannel,
  contentRemoveGiphyChannels,
  resetGiphyChannel,
  resetContentItem,
  loadDeleteGiphyChannel,
  addCompoundItem,
  contentUploadItems,
  uploadLinkedItem,
  loadReplaceAdditionalItems,
  contentSetResetCreateKeywords,
  setDeleteContentItemByIndex,
  setToggleContentAssetReplaceForm, loadUpdateContentAssetVersion,
} from '../store/actions';
// END actions

// START selectors
import {
  contentTypes,
  contentKeywords,
  contentCreateKeywords,
  contentItems,
  contentAssets,
  contentItem,
} from '../store/selectors';
import { userChannels } from '../../user/store/selectors';
import GiphyChannelsTab from "./GiphyChannelsTab";
import AlertDialog from "../../../components/AlertDialogue";
// END selectors

// START styles
const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    height: 30,
  },
  buttonSpacing: {
    marginRight: "10px",
  },
  subTitlePaper: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  subTitle: {
    width: "100%",
    height: 49,
    fontSize: 16,
    color: "gray",
    padding: 10,
    paddingLeft: 20,
  },
}));
// END styles

const actionsToProps = (dispatch) => ({
  contentLoadKeywords: (data) => dispatch(contentLoadKeywords(data)),
  contentLoadCreateKeywords: (data) => dispatch(contentLoadCreateKeywords(data)),
  loadModifyContentFolder: (data) => dispatch(loadModifyContentFolder(data)),
  loadDeleteContentFolder: (data) => dispatch(loadDeleteContentFolder(data)),
  loadCreateContentAssets: (data) => dispatch(loadCreateContentAssets(data)),
  loadReadContentAssets: (data) => dispatch(loadReadContentAssets(data)),
  loadDeleteContentAsset: (data) => dispatch(loadDeleteContentAsset(data)),
  setModifyContentAssets: (data) => dispatch(setModifyContentAssets(data)),
  setToggleContentAssetReplaceForm: (data) => dispatch(setToggleContentAssetReplaceForm(data)),
  loadUpdateContentAsset: (data) => dispatch(loadUpdateContentAsset(data)),
  loadUpdateContentAssetVersion: data => dispatch(loadUpdateContentAssetVersion(data)),
  loadUpdateContentItem: (data) => dispatch(loadUpdateContentItem(data)),
  loadUpdateLinkedContentItem: (data) => dispatch(loadUpdateLinkedContentItem(data)),
  setResetContentAssets: (data) => dispatch(setResetContentAssets(data)),
  setResetCreateContentKeywords: (data) => dispatch(contentSetResetCreateKeywords(data)),
  loadDeleteContentFolderAsset: (data) => dispatch(loadDeleteContentFolderAsset(data)),
  loadDeleteContentItem: (data) => dispatch(loadDeleteContentItem(data)),
  setDeleteContentItemByIndex: (data) => dispatch(setDeleteContentItemByIndex(data)),
  loadDeleteGiphyChannel: (data) => dispatch(loadDeleteGiphyChannel(data)),
  contentRemoveItems: (data) => dispatch(contentRemoveItems(data)),
  resetContentItem: (data) => dispatch(resetContentItem(data)),
  checkLinkedItem: (data) => dispatch(loadCheckLinkedItem(data)),
  checkGiphyChannel: (data) => dispatch(loadCheckGiphyChannel(data)),
  addGiphyChannel: (data) => dispatch(addGiphyChannel(data)),
  addCompoundItem: (data) => dispatch(addCompoundItem(data)),
  contentRemoveGiphyChannels: (data) => dispatch(contentRemoveGiphyChannels(data)),
  resetGiphyChannel: (data) => dispatch(resetGiphyChannel(data)),
  contentUploadItems: (data) => dispatch(contentUploadItems(data)),
  uploadLinkedItem: (data) => dispatch(uploadLinkedItem(data)),
  replaceAdditionalItems: (data) => dispatch(loadReplaceAdditionalItems(data)),
});

const stateToProps = state => ({
  contentTypes: contentTypes(state),
  contentKeywords: contentKeywords(state),
  contentCreateKeywords: contentCreateKeywords(state),
  userChannels: userChannels(state),
  contentItems: contentItems(state),
  contentAssets: contentAssets(state),
  contentItem: contentItem(state),
});

const ControlModal = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [currentContentType, setCurrentContentType] = useState({});
  const modalContentRef = useRef(null);
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] = useState(false);

  useEffect(() => {
    if(props.contentTypes.data?.length > 0) {
      const nameMatchesContentType = props.contentTypes.data.filter(content => content.name === props.packName);
      if(nameMatchesContentType.length > 0) {
        setCurrentContentType(nameMatchesContentType[0]);
      } else {
        console.log("MISSMATCH contentType name and packName");
      }
    }
  }, [props.packName, props.contentTypes]);

  useEffect(() => {
    if (props.contentItem.isLoading === false && props.contentItem.message === '' && props.contentItem.data.uid !== '') {
      handleLoadReadContentAssets();
    }
  }, [props.contentItem]);

  const handleTabChange = (e, value) => {
    setValue(value);
  };

  const handleContentLoadKeywords = params => {
    props.contentLoadKeywords(params);
  }

  const handleContentLoadCreateKeywords = params => {
    props.contentLoadCreateKeywords(params);
  }

  const handleControlModalOpen = () => {
    props.handleControlModalOpen();
  }

  const handleModifyContentFolder = values => {
    props.loadModifyContentFolder({data: values, message: ''});
  }

  const handleLoadDeleteContentFolder = uid => {
    props.loadDeleteContentFolder({
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
      folderUid: uid,
    });
    handleControlModalOpen();
  }

  const updateContentAsset = (contentFolderUid, contentAssetUid, params) => {
    props.loadUpdateContentAsset({
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
      contentFolderUid: contentFolderUid,
      contentAssetUid: btoa(contentAssetUid),
      params: params
    });
  }

  const updateContentAssetVersion = (contentFolderUid, contentAssetUid, version) => {
    props.loadUpdateContentAssetVersion({
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
      contentFolderUid: contentFolderUid,
      contentAssetUid: btoa(contentAssetUid),
      version: version
    });
  }

  const handleSaveAndClose = () => {
    const contentFolder = props.contentFolder.data;
    const contentAssets = props.contentAssets.data;

    props.loadUpdateContentFolder({
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
      folderUid: contentFolder.uid,
      params: props.toContentFolderDTO(contentFolder),
    });

    for (let i = 0; i < contentAssets.length; i++) {
      if( !contentAssets[i].isDirty ) {
        continue;
      }

      delete contentAssets[i].showAllKeywords;
      delete contentAssets[i].showReplaceAsset;

      contentAssets[i].items = contentAssets[i].items.map((item) => {
        delete item.preview_url;
        delete item.url;

        return item;
      });

      updateContentAsset(contentFolder.uid, contentAssets[i].uid, {
        ...contentAssets[i],
        folders: [
          {
            uid: contentFolder.uid,
            featured: typeof contentAssets[i].featured !== 'undefined' ? contentAssets[i].featured : false,
            enabled: typeof contentAssets[i].enabled !== 'undefined' ? contentAssets[i].enabled : false,
            rank: typeof contentAssets[i].rank !== 'undefined' ? contentAssets[i].rank : 0,
          },
        ],
        keywords: contentAssets[i].keywords.map(kw => kw.slug)
      });
    }

    handleOnClose();
  }

  const handleLoadCreateContentAssets = () => {
    const contentFolder = {
      uid: props.contentFolder.data.uid,
      rank: 0,
      featured: false,
      enabled: true,
    };

    for (let i = 0; i < props.contentItems.length; i++) {
      let additionalItems = [];
      for (const [key, item] of Object.entries(props.contentItems[i].data)) {
        if (key !== 'preview' && key !== 'zip' && key !== 'index') {
          additionalItems.push(item);
        }
      }

      props.loadCreateContentAssets({
        tenantId: props.tenantId,
        contentTypeUid: props.contentType.uid,
        params: {
          name: props.contentItems[i].data.zip != null ? props.contentItems[i].data.zip.name : `Asset #${i+1}`,
          giphy_channel_id: props.contentItems[i].data.giphy_channel_id,
          items: props.contentType.identifier === 'theme' ? [
            { uid: props.contentItems[i].data.zip.uid, placeholder: 'mainResource' },
            { uid: props.contentItems[i].data.preview.uid, placeholder: 'previewResource' },
            ...additionalItems.map(item => {return {uid: item.uid, placeholder: 'additionalResource'};})
          ] : [{ uid: props.contentItems[i].data.uid, placeholder: 'mainResource' }],
          folders: [{
            ...contentFolder,
            rank: props.contentAssets.data.length + 1 + i
          }],
          version: props.contentItems[i].data.zip != null ? props.contentItems[i].data.zip.version : null,
        },
      });
    }
  }

  const handleLoadReadContentAssets = () => {
    props.loadReadContentAssets({
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
      folderUid: props.contentFolder.data.uid,
    });
  }

  const handleLoadDeleteContentFolderAsset = (contentAssetId, giphyChannelId) => {
    props.loadDeleteContentFolderAsset({
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
      folderUid: props.contentFolder.data.uid,
      contentAssetUid: btoa(contentAssetId),
      giphyChannelUid: giphyChannelId,
    });
  }

  const handleSetModifyContentAssets = (assets) => {
    props.setModifyContentAssets({assets});
  }

  const handleSetToggleContentAssetReplaceForm = (assetUid) => {
    props.setToggleContentAssetReplaceForm({assetUid});
  }

  const handleLoadUpdateContentItem = params => {
    props.loadUpdateContentItem({
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
      contentItemUid: params.contentItemUid,
      form: params.form,
    });
  }

  const handleLoadUpdateLinkedContentItem = params => {
    props.loadUpdateLinkedContentItem({
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
      contentFolderUid: params.contentFolderUid,
      contentAssetUid: params.contentAssetUid,
      contentItemUid: params.contentItemUid,
      form: params.form,
    });
  }

  const handleOnClose = () => {
    if( props.contentItems.length > 0 ) {
      setUnsavedChangesDialogOpen(true);
    } else {
      handleControlModalOpen();
      refreshControlModal();
    }
  }

  const handleUnsavedChangesAgree = () => {
    setUnsavedChangesDialogOpen(false);
    handleControlModalOpen();
    refreshControlModal();
  }

  const handleUnsavedChangesDisagree = () => {
    setUnsavedChangesDialogOpen(false);
  }

  const refreshControlModal = () => {
    setValue(0);

    props.contentRemoveItems();
    props.setResetContentAssets();
    props.setResetCreateContentKeywords();

    if(props.contentType.identifier === 'gif') {
      props.contentRemoveGiphyChannels();
      props.resetGiphyChannel();
      props.resetContentItem();
    }
  }

  const handleLoadDeleteContentItem = (index, itemUid) => {
    props.loadDeleteContentItem({
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
      contentItemUid: itemUid,
    });
  }

  const handleSetDeleteContentItemByIndex = (index) => {
    props.setDeleteContentItemByIndex({
      index: index,
    });
  }

  const handleLoadDeleteGiphyChannel = (index, giphyChannelUid) => {
    props.loadDeleteGiphyChannel({
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
      contentFolderUid: props.contentFolder.data.uid,
      giphyChannelUid: giphyChannelUid
    });
  };

  const addFiles = (files, storageProvider = "cloudinary") => {
    files.forEach((file, index) => {
      const extension = file.type.split("/");
      const fileObject = {
        name: file.name,
        preview: file.preview,
        file_size_bytes: file.size,
        file_type: extension[1],
        index: props.contentItems.length + index,
      };
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      formData.append("url", file.preview);
      formData.append("storageProviderIdentifier", storageProvider);
      props.contentUploadItems({
        form: formData,
        localFile: fileObject,
        contentTypeUid: props.contentType.uid,
      });
    });
  };

  const replaceAdditionalItems = (assetIndex, asset, additionalItems, mainItem, previewItem) => {
    const assetWithDTOCompliantFolders = {
      ...asset,
      folders: asset.folders.map(folder => {
        return {
          uid: folder.uid,
          enabled: folder.pivot.enabled,
          featured: folder.pivot.featured,
          rank: folder.pivot.rank,
        };
      }),
    };

    props.replaceAdditionalItems({
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
      contentFolderUid: props.contentFolder.data.uid,
      contentAsset: assetWithDTOCompliantFolders,
      assetIndex: assetIndex,
      originalItems: asset.items.filter(item => item.placeholder !== 'mainResource' && item.placeholder !== 'previewResource'),
      mainItem: mainItem,
      previewItem: previewItem,
      newItems: additionalItems,
    });
  };

  const deleteGiphyChannel = (giphyChannelUid) => {
    props.loadDeleteGiphyChannel({
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
      contentFolderUid: props.contentFolder.data.uid,
      giphyChannelUid: giphyChannelUid,
    });
  };

  const checkLinkedFile = (input) => {
    props.checkLinkedItem({
      url: input
    });
  };

  const addCompoundItem = (input) => {
    props.addCompoundItem({
      tenantId: props.tenantId,
      ...input
    });
  };

  const checkGiphyChannel = (params) => {
    props.checkGiphyChannel({
      ...params,
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
    });
  };

  const addGiphyChannel = (params) => {
    props.addGiphyChannel({
      ...params,
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
    });
  }

  return (
    <Modal open={props.open} aria-labelledby="modal-sticker-modal" onClose={() => handleOnClose()}>
      <Box sx={{ display: "flex",
                 flexDirection: "column",
                 position: "absolute",
                 top: "12%",
                 width: "76%",
                 left: 0,
                 right: 0,
                 mx: 'auto',
                 maxWidth: 1600,
                 height: "78vh",
                 py: 8,
                 borderBottom: "1px solid #f3f3f3",
                 bgcolor: 'background.paper',
                 borderRadius: 1,
                 overflow: "hidden" }}>
        <Box className={classes.modalHeader} sx={{ px: 12 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ mr: 10, textTransform: 'uppercase', fontSize: '0.75rem', letterSpacing: 1.5, fontWeight: 'regular' }}>
              { props.contentFolder.data.name }
            </Typography>

            <Button id="control_modal_button_cancel" variant="outlined"
                    onClick={() => handleOnClose()}
                    sx={{ color: 'text.secondary',
                          borderColor: 'text.secondary',
                          mr: 2,
                          py: 2.5,
                          px: 9,
                          textTransform: 'capitalize',
                          '&:hover': { color: 'text.primary',
                                       borderColor: 'text.primary' } }}>
              Cancel
            </Button>

            <Button id="control_modal_button_save" variant="contained" color="primary"
                    disabled={
                      (
                        props.contentFolder.data.categoryValidationPassed !== undefined ||
                        props.contentFolder.data.nameValidationPassed !== undefined
                      )
                        &&
                      (
                        !props.contentFolder.data.categoryValidationPassed ||
                        !props.contentFolder.data.nameValidationPassed)
                    }
                    onClick={() => handleSaveAndClose()}
                    sx={{ py: 2.5, px: 9 }}>
              Save & Close
            </Button>
          </Box>
        </Box>

        <Tabs selectionFollowsFocus onChange={handleTabChange} value={value}
              style={{
                borderBottom: "1px solid #f3f3f3",
                minHeight: 49,
              }}
              sx={{ px: 12 }}>
          <Tab id="control_modal_tab_upload" label="Upload" sx={{ px: 10, color: 'text.primary', textTransform: 'capitalize' }} />
          <Tab id="control_modal_tab_manage" label="Manage" sx={{ px: 12, color: 'text.primary', textTransform: 'capitalize' }} />
          {props.contentType.identifier === 'gif' && (
            <Tab id="control_modal_tab_giphy_channels" label="Giphy channels" sx={{ px: 12, color: 'text.primary', textTransform: 'capitalize' }} />
          )}
          <Tab id="control_modal_tab_settings" label="Settings" sx={{ px: 12, color: 'text.primary', textTransform: 'capitalize' }} />
        </Tabs>

        <Box ref={modalContentRef} sx={{ display: 'flex', flexDirection: 'column', my: 0, p: 0, height: 1 }}>
          <TabPanel value={value} index={0}>
            <Upload packName={props.packName}
                    contentType={props.contentType}
                    contentFolder={props.contentFolder}
                    handleLoadCreateContentAssets={() => handleLoadCreateContentAssets()}
                    handleChangeValue={() => setValue(1)}
                    handleLoadDeleteContentItem={(index, itemUid) => handleLoadDeleteContentItem(index, itemUid)}
                    handleSetDeleteContentItemByIndex={(index) => handleSetDeleteContentItemByIndex(index)}
                    handleLoadDeleteGiphyChannel={(index, giphyChannelUid) => handleLoadDeleteGiphyChannel(index, giphyChannelUid)}
                    resetContentItem={props.resetContentItem}
                    checkLinkedFile={checkLinkedFile}
                    addCompoundItem={addCompoundItem}
                    checkGiphyChannel={checkGiphyChannel}
                    addGiphyChannel={addGiphyChannel}
                    contentRemoveGiphyChannels={props.contentRemoveGiphyChannels}
                    resetGiphyChannel={props.resetGiphyChannel}
                    contentUploadItems={props.contentUploadItems}
                    contentRemoveItems={props.contentRemoveItems}
                    uploadLinkedItem={props.uploadLinkedItem}
                    addFiles={addFiles}
                    offsetWidth={modalContentRef.current !== null ? modalContentRef.current.offsetWidth : 0} />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Manage parentClasses={classes}
                    contentType={props.contentType}
                    contentFolder={props.contentFolder}
                    contentAssets={props.contentAssets}
                    handleLoadReadContentAssets={() => handleLoadReadContentAssets()}
                    handleLoadDeleteContentFolderAsset={(contentAssetId, giphyChannelId) => handleLoadDeleteContentFolderAsset(contentAssetId, giphyChannelId)}
                    contentKeywords={props.contentKeywords}
                    contentCreateKeywords={props.contentCreateKeywords}
                    handleContentLoadKeywords={params => handleContentLoadKeywords(params)}
                    handleContentLoadCreateKeywords={(params) => handleContentLoadCreateKeywords(params)}
                    handleSetModifyContentAssets={(assets) => handleSetModifyContentAssets(assets)}
                    handleSetToggleContentAssetReplaceForm={(assetUid) => handleSetToggleContentAssetReplaceForm(assetUid)}
                    handleLoadUpdateContentItem={params => handleLoadUpdateContentItem(params)}
                    handleLoadUpdateLinkedContentItem={params => handleLoadUpdateLinkedContentItem(params)}
                    handleLoadDeleteContentItem={(index, itemUid) => handleLoadDeleteContentItem(index, itemUid)}
                    contentUploadItems={props.contentUploadItems}
                    resetContentItem={props.resetContentItem}
                    updateContentAsset={updateContentAsset}
                    updateContentAssetVersion={updateContentAssetVersion}
                    checkLinkedFile={checkLinkedFile}
                    replaceAdditionalItems={replaceAdditionalItems}
                    items={props.contentItems}
                    offsetWidth={modalContentRef.current !== null ? modalContentRef.current.offsetWidth : 0} />
          </TabPanel>

          {props.contentType.identifier === 'gif' && (
            <TabPanel value={value} index={2}>
              <GiphyChannelsTab giphyChannels={props.contentFolder.data.giphyChannels}
                                deleteGiphyChannel={giphyChannelUid => deleteGiphyChannel(giphyChannelUid)}/>
            </TabPanel>
          )}

          <TabPanel value={value} index={props.contentType.identifier === 'gif' ? 3 : 2}>
            <Settings parentClasses={classes}
                      contentType={props.contentType}
                      createPackFormRef={props.createPackFormRef}
                      handleControlModalOpen={() => handleControlModalOpen()}
                      currentContentType={currentContentType}
                      handleContentLoadKeywords={params => handleContentLoadKeywords(params)}
                      handleContentLoadCreateKeywords={params => handleContentLoadCreateKeywords(params)}
                      contentKeywords={props.contentKeywords}
                      contentCreateKeywords={props.contentCreateKeywords}
                      userChannels={props.userChannels}
                      contentFolder={props.contentFolder}
                      handleModifyContentFolder={values => handleModifyContentFolder(values)}
                      handleLoadDeleteContentFolder={id => handleLoadDeleteContentFolder(id)}
                      offsetWidth={modalContentRef.current !== null ? modalContentRef.current.offsetWidth : 0}
                      rankIsUniqueOnChannels={(rank, contentFolderUid, revenueChannelIds) => props.rankIsUniqueOnChannels(rank, contentFolderUid, revenueChannelIds)} />
          </TabPanel>
        </Box>

        <AlertDialog id={`dialog_close_unsaved_changes`} open={unsavedChangesDialogOpen}
                     handleAgree={() => handleUnsavedChangesAgree()}
                     handleDisagree={() => handleUnsavedChangesDisagree()}
                     title="There are unsaved changes"
                     body='You have not clicked the "Add to pack" button so the assets you have added were not saved. Continuing will cause you to lose them.' />
      </Box>
    </Modal>
  );
};

export default connect(stateToProps, actionsToProps)(ControlModal);
