import connect from "react-redux/es/connect/connect";
import {Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel} from "@mui/material";
import {capitalizeSnakeCase} from "../../../../utils/string";
import {Clear} from "@mui/icons-material";
import {useState} from "react";

const stateToProps = (state) => ({});

const actionToProps = (dispatch) => ({});

const MultipleSelectionFilter = ({filterName, handleValueChange, options, ...props}) => {
  const [values, setValues] = useState([]);

  const handleChange = (option, checked) => {
    let newValues = values;

    if( checked ) {
      newValues.push(option);
    } else {
      const index = newValues.indexOf(option);
      if (index > -1) {
        newValues.splice(index, 1);
      }
    }

    setValues(newValues);

    if( newValues.length === 0 ) {
      handleValueChange(undefined);
    } else {
      handleValueChange(newValues);
    }
  };

  const clearSelected = () => {
    setValues([]);
    handleValueChange(undefined);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', left: -40 }}>
        <Clear onClick={() => clearSelected()} sx={{ cursor: 'pointer', color: 'text.secondary', '&:hover': { color: 'text.primary' } }} />
      </Box>

      <Box>
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend">{capitalizeSnakeCase(filterName)}</FormLabel>
          <FormGroup>
            {options.map(option => (
              <FormControlLabel sx={{ color: 'text.primary' }}
                                key={option}
                                control={<Checkbox />}
                                label={capitalizeSnakeCase(option)}
                                checked={values.includes(option)}
                                onChange={(event) => handleChange(option, event.target.checked)} />
            ))}
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default connect(stateToProps, actionToProps)(MultipleSelectionFilter);

