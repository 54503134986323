import {Group} from "@visx/group";
import {Pie as VisxPie} from '@visx/shape';
import React, {useEffect, useState} from "react";
import {animated, interpolate, useTransition} from "@react-spring/web";
import {scaleOrdinal} from "@visx/scale";
import {Box, IconButton, Paper, Tooltip, Typography, useTheme} from "@mui/material";
import {toHumanPercentage} from "../../utils/numbers";
import {defaultStyles, useTooltip, useTooltipInPortal} from "@visx/tooltip";
import {convertJSONtoCSV, downloadCSV} from "../../utils/csvDownload";
import {CloudDownload} from "@mui/icons-material";

const defaultMargin = {top: 20, right: 20, bottom: 20, left: 20};

export default function DoublePie({
                                    title,
                                    donutData,
                                    pieData,
                                    width,
                                    height,
                                    margin = defaultMargin,
                                    filters,
                                  }) {
  const theme = useTheme();
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const [pieFormattedData, setPieFormattedData] = useState([]);
  const [donutFormattedData, setDonutFormattedData] = useState([]);
  const donutThickness = 50;

  useEffect(() => {
    const top = pieData.slice(0, 5);
    const others = pieData.slice(5);
    const otherSum = others.reduce((acc, item) => acc + item.value, 0);

    if (otherSum > 0) {
      top.push({ label: 'Others', value: otherSum });
    }

    setPieFormattedData(top);
  }, [pieData]);

  useEffect(() => {
    const top = donutData.slice(0, 5);
    const others = donutData.slice(5);
    const otherSum = others.reduce((acc, item) => acc + item.value, 0);

    if (otherSum > 0) {
      top.push({ label: 'Others', value: otherSum });
    }

    setDonutFormattedData(top);
  }, [donutData]);

  if (width < 10) return null;

  const download = () => {
    // Transform the data into the desired structure
    const transformedDonutData = donutData.reduce((acc, x) => {
      acc['"' + x.label + '"'] = toHumanPercentage(x.value);
      return acc;
    }, {});

    const transformedPieData = pieData.reduce((acc, x) => {
      acc['"' + x.label + '"'] = toHumanPercentage(x.value);
      return acc;
    }, {});

    // Combine the transformed data with metadata
    const downloadableData = {
      ...transformedDonutData,
      "_________________________": "_________________________",
      ...transformedPieData,
      ...filters,
      time: (new Date()).toDateString()
    };

    const csvContent = convertJSONtoCSV(downloadableData);

    if (csvContent) {
      downloadCSV(downloadableData, title);
    }
  };

  const colors = [
    theme.palette.vis.pie.one,
    theme.palette.vis.pie.two,
    theme.palette.vis.pie.three,
    theme.palette.vis.pie.four,
    theme.palette.vis.pie.five,
    theme.palette.vis.pie.six,
  ];

  const getColorPie = scaleOrdinal({
    domain: pieFormattedData.map((d) => d.label),
    range: Object.values(colors),
  });

  const getColorDonut = scaleOrdinal({
    domain: donutFormattedData.map((d) => d.label),
    range: Object.values(colors),
  });

  return (
    <Paper sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", p: 4, position: 'relative', '&:hover .download-icon': { visibility: 'visible' } }}>
      {title && <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>}

      <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <svg width={width} height={height}>
          <Group top={centerY + margin.top} left={centerX + margin.left}>
            <VisxPie
              data={pieFormattedData}
              pieValue={(d) => d.value}
              outerRadius={radius}
              innerRadius={radius - donutThickness}
              cornerRadius={3}
              padAngle={0.005}
              pieSortValues={() => 0}
            >
              {(pie) => (
                <AnimatedPie
                  {...pie}
                  animate={true}
                  getKey={(arc) => arc.data.label}
                  getColor={(arc) => getColorPie(arc.data.label)}
                />
              )}
            </VisxPie>

            <VisxPie
              data={donutFormattedData}
              pieValue={(d) => d.value}
              outerRadius={radius - donutThickness * 1.3}
              pieSortValues={() => 0}
            >
              {(pie) => (
                <AnimatedPie
                  {...pie}
                  animate={true}
                  getKey={(arc) => arc.data.label}
                  getColor={(arc) => getColorDonut(arc.data.label)}
                />
              )}
            </VisxPie>
          </Group>
        </svg>
      </Box>

      <Tooltip title="Download">
        <IconButton aria-label="download" className="download-icon" sx={{ position: 'absolute', top: '-16px', right: '-16px', visibility: 'hidden' }}
                    onClick={download}>
          <CloudDownload />
        </IconButton>
      </Tooltip>
    </Paper>
  );
}

const fromLeaveTransition = ({endAngle}) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = ({startAngle, endAngle}) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

const AnimatedPie = ({
                       animate,
                       arcs,
                       path,
                       getKey,
                       getColor,
                       data
                     }) => {
  const theme = useTheme();

  const sliceToTextColorMap = {
    [theme.palette.vis.pie.one]: 'white',
    [theme.palette.vis.pie.two]: 'black',
    [theme.palette.vis.pie.three]: 'black',
    [theme.palette.vis.pie.four]: 'black',
    [theme.palette.vis.pie.five]: 'white',
    [theme.palette.vis.pie.six]: 'black',
  };

  const getTextColor = (backgroundColor) => {
    return sliceToTextColorMap[backgroundColor];
  };

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip();
  const { TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
  };

  const transitions = useTransition(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });
  return transitions((props, arc, {key}) => {
    const [centroidX, centroidY] = path.centroid(arc);
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
    const backgroundColor = getColor(arc);
    const textColor = getTextColor(backgroundColor);

    return (
      <>
        <g key={key}
           onMouseLeave={() => {
             hideTooltip();
           }}
           onMouseMove={(event) => {
             showTooltip({
               tooltipData: {
                 "label": arc.data.label,
                 "value": arc.data.value,
               },
               tooltipTop: event.clientY,
               tooltipLeft: event.clientX
             });
           }} >
          <animated.path
            // compute interpolated path d attribute from intermediate angle values
            d={interpolate([props.startAngle, props.endAngle], (startAngle, endAngle) =>
              path({
                ...arc,
                startAngle,
                endAngle,
              }),
            )}
            fill={getColor(arc)}
          />
          {hasSpaceForLabel && (
            <animated.g style={{opacity: props.opacity}}>
              <text
                fill={textColor}
                x={centroidX}
                y={centroidY}
                dy=".33em"
                fontSize={12}
                textAnchor="middle"
                pointerEvents="none"
                fontFamily={"Roboto"}
              >
                {getKey(arc)}
              </text>
            </animated.g>
          )}
        </g>

        {tooltipOpen && tooltipData && (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <Typography
              variant="body2">
              <b>{tooltipData.label}</b> - {toHumanPercentage(tooltipData.value)}
            </Typography>
          </TooltipInPortal>
        )}
      </>
    );
  });
}