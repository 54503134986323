import {Box} from "@mui/material";
import SingleTextVisSkeleton from "../../../../components/vis/SingleTextVisSkeleton";
import NumberStat from "../../../../components/vis/NumberStat";

const VisGenericNumberStat = ({ isLoading, isLoadingForALongTime, data, sx, filters, statKey, formatter }) => {
  const hasData = Boolean(data);

  return (
    <Box sx={{ display: "flex", ...sx }}>
      {isLoading && (
        <SingleTextVisSkeleton sx={{  bgcolor: "background.paper", width: '100%' }}
                               animation="wave"
                               title={isLoadingForALongTime ? `${statKey} - working` : statKey} />
      )}

      {!isLoading && !hasData && (
        <SingleTextVisSkeleton sx={{  bgcolor: "background.paper", width: '100%' }}
                               animation={false}
                               title={`${statKey} - no data`} />
      )}

      {!isLoading && hasData && (
        <NumberStat
          value={parseFloat(data)}
          label={statKey}
          filters={filters}
          formatter={formatter}
        />
      )}
    </Box>
  );
}

export default VisGenericNumberStat;