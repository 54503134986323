import { createAction } from "redux-actions";

import * as CONTENT from "./types";

// Content items
const contentUploadItems = createAction(CONTENT.UPLOAD_CONTENT_ITEMS, (data) => data);
const contentRemoveItems = createAction(CONTENT.REMOVE_CONTENT_ITEMS, (data) => data);
const loadDeleteContentItem = createAction(CONTENT.LOAD_DELETE_CONTENT_ITEM, (data) => data);
const setDeleteContentItemByIndex = createAction(CONTENT.SET_DELETE_CONTENT_ITEM_BY_INDEX, (data) => data);
const loadUpdateContentItem = createAction(CONTENT.LOAD_UPDATE_CONTENT_ITEM, (data) => data);
const loadUpdateLinkedContentItem = createAction(CONTENT.LOAD_UPDATE_LINKED_CONTENT_ITEM, (data) => data);
const loadCheckLinkedItem = createAction(CONTENT.LOAD_CHECK_LINKED_ITEM, (data) => data);
const setCheckLinkedItem = createAction(CONTENT.SET_CHECK_LINKED_ITEM, (data) => data);
const setLinkedItemUrl = createAction(CONTENT.SET_LINKED_ITEM_URL, (data) => data);
const uploadLinkedItem = createAction(CONTENT.UPLOAD_LINKED_ITEM, (data) => data);
const loadUploadLinkedItem = createAction(CONTENT.LOAD_UPLOAD_LINKED_ITEM, (data) => data);
const setUploadLinkedItem = createAction(CONTENT.SET_UPLOAD_LINKED_ITEM, (data) => data);
const resetContentItem = createAction(CONTENT.RESET_CONTENT_ITEM, (data) => data);
const loadReplaceAdditionalItems = createAction(CONTENT.LOAD_REPLACE_ADDITIONAL_ITEMS, (data) => data);

// Compound Items (themes)
const loadCheckCompoundItem = createAction(CONTENT.LOAD_CHECK_COMPOUND_ITEM, (data) => data);
const setCheckCompoundItem = createAction(CONTENT.SET_CHECK_COMPOUND_ITEM, (data) => data);
const addCompoundItem = createAction(CONTENT.ADD_COMPOUND_ITEM, (data) => data);
const loadAddCompoundItem = createAction(CONTENT.LOAD_ADD_COMPOUND_ITEM, (data) => data);
const setAddCompoundItem = createAction(CONTENT.SET_ADD_COMPOUND_ITEM, (data) => data);

// Giphy channels
const setGiphyChannelName = createAction(CONTENT.SET_GIPHY_CHANNEL_NAME, (data) => data);
const loadCheckGiphyChannel = createAction(CONTENT.LOAD_CHECK_GIPHY_CHANNEL, (data) => data);
const setCheckGiphyChannel = createAction(CONTENT.SET_CHECK_GIPHY_CHANNEL, (data) => data);
const addGiphyChannel = createAction(CONTENT.ADD_GIPHY_CHANNEL, (data) => data);
const loadAddGiphyChannel = createAction(CONTENT.LOAD_ADD_GIPHY_CHANNEL,(data) => data);
const setAddGiphyChannel = createAction(CONTENT.SET_ADD_GIPHY_CHANNEL, (data) => data);
const contentRemoveGiphyChannels = createAction(CONTENT.REMOVE_GIPHY_CHANNELS, (data) => data);
const resetGiphyChannel = createAction(CONTENT.RESET_GIPHY_CHANNEL, (data) => data);
const loadDeleteGiphyChannel = createAction(CONTENT.LOAD_DELETE_GIPHY_CHANNEL, (data) => data);
const setDeleteGiphyChannel = createAction(CONTENT.SET_DELETE_GIPHY_CHANNEL, (data) => data);

// Content types
const contentLoadTypes = createAction(CONTENT.LOAD_CONTENT_TYPES, (data) => data);
const loadResetContentTypes = createAction(CONTENT.LOAD_RESET_CONTENT_TYPES, (data) => data);

// Keywords
const contentLoadKeywords = createAction(CONTENT.LOAD_CONTENT_KEYWORDS, (data) => data);
const contentLoadCreateKeywords = createAction(CONTENT.LOAD_CONTENT_CREATE_KEYWORDS, (data) => data);
const contentSetResetCreateKeywords = createAction(CONTENT.SET_CONTENT_RESET_CREATE_KEYWORDS, (data) => data);

// Packs
const contentLoadCreatePack = createAction(CONTENT.LOAD_CONTENT_CREATE_PACK, (data) => data);

// Asset List
const contentLoadCreateAssetList = createAction(CONTENT.LOAD_CONTENT_CREATE_ASSET_LIST, (data) => data);

// Content folders
const loadContentFolders = createAction(CONTENT.LOAD_CONTENT_FOLDERS, (data) => data);
const loadCreateContentFolder = createAction(CONTENT.LOAD_CREATE_CONTENT_FOLDER, (data) => data);
const loadReadContentFolder = createAction(CONTENT.LOAD_READ_CONTENT_FOLDER, (data) => data);
const loadUpdateContentFolder = createAction(CONTENT.LOAD_UPDATE_CONTENT_FOLDER, (data) => data);
const loadDeleteContentFolder = createAction(CONTENT.LOAD_DELETE_CONTENT_FOLDER, (data) => data);
const setDeleteContentFolder = createAction(CONTENT.SET_DELETE_CONTENT_FOLDER, (data) => data);
const loadModifyContentFolder = createAction(CONTENT.LOAD_MODIFY_CONTENT_FOLDER, (data) => data);
const loadDeleteContentFolderAsset = createAction(CONTENT.LOAD_DELETE_CONTENT_FOLDER_ASSET, (data) => data);
const loadResetContentFolders = createAction(CONTENT.LOAD_RESET_CONTENT_FOLDERS,(data) => data);
const toggleSelectedFolder = createAction(CONTENT.TOGGLE_SELECTED_FOLDER, (data) => data);
const loadPublishContent = createAction(CONTENT.LOAD_PUBLISH_CONTENT, (data) => data);
const publishToMocha = createAction(CONTENT.PUBLISH_TO_MOCHA, (data) => data);

// Content assets
const loadContentAssets = createAction(CONTENT.LOAD_CONTENT_ASSETS, (data) => data);
const loadCreateContentAssets = createAction(CONTENT.LOAD_CREATE_CONTENT_ASSETS, (data) => data);
const loadReadContentAssets = createAction(CONTENT.LOAD_READ_CONTENT_ASSETS, (data) => data);
const loadUpdateContentAsset = createAction(CONTENT.LOAD_UPDATE_CONTENT_ASSET, (data) => data);
const loadUpdateContentAssetVersion = createAction(CONTENT.LOAD_UPDATE_CONTENT_ASSET_VERSION, (data) => data);
const loadDeleteContentAsset = createAction(CONTENT.LOAD_DELETE_CONTENT_ASSET, (data) => data);
const setModifyContentAssets = createAction(CONTENT.SET_MODIFY_CONTENT_ASSETS, (data) => data);
const setToggleContentAssetReplaceForm = createAction(CONTENT.SET_TOGGLE_CONTENT_ASSET_REPLACE_FORM, (data) => data);
const setResetContentAssets = createAction(CONTENT.SET_RESET_CONTENT_ASSETS, (data) => data);

export {
  contentLoadCreateAssetList,
  contentUploadItems,
  contentRemoveItems,
  loadDeleteContentItem,
  setDeleteContentItemByIndex,
  loadCheckCompoundItem,
  setCheckCompoundItem,
  addCompoundItem,
  loadAddCompoundItem,
  setAddCompoundItem,
  loadCheckLinkedItem,
  setCheckLinkedItem,
  setLinkedItemUrl,
  uploadLinkedItem,
  loadUploadLinkedItem,
  setUploadLinkedItem,
  resetContentItem,
  loadReplaceAdditionalItems,
  loadCheckGiphyChannel,
  setCheckGiphyChannel,
  setGiphyChannelName,
  addGiphyChannel,
  loadAddGiphyChannel,
  setAddGiphyChannel,
  contentRemoveGiphyChannels,
  resetGiphyChannel,
  loadDeleteGiphyChannel,
  setDeleteGiphyChannel,
  contentLoadTypes,
  loadResetContentTypes,
  contentLoadKeywords,
  contentLoadCreateKeywords,
  contentSetResetCreateKeywords,
  contentLoadCreatePack,
  loadUpdateContentItem,
  loadUpdateLinkedContentItem,
  loadDeleteContentFolderAsset,
  loadContentFolders,
  loadCreateContentFolder,
  loadReadContentFolder,
  loadUpdateContentFolder,
  loadDeleteContentFolder,
  setDeleteContentFolder,
  loadModifyContentFolder,
  loadPublishContent,
  publishToMocha,
  loadResetContentFolders,
  loadContentAssets,
  loadCreateContentAssets,
  loadReadContentAssets,
  loadUpdateContentAsset,
  loadUpdateContentAssetVersion,
  loadDeleteContentAsset,
  setModifyContentAssets,
  setToggleContentAssetReplaceForm,
  setResetContentAssets,
  toggleSelectedFolder,
};
