import connect from "react-redux/es/connect/connect";
import {Box, Breadcrumbs, Button, Grid, Link} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import React, {useEffect} from "react";
import Page from "../../../components/Page";
import Filters from "../subcomponents/filters/Filters";
import {loadInsights} from "../store/actions";
import {getFilters, getSimpleDailyDashboard, getTenantId} from "../store/selectors";
import VisGenericNumberStat from "../subcomponents/vis/VisGenericNumberStat";
import {FORMAT_AMOUNT, FORMAT_LONG, FORMAT_PERC} from "../../../components/vis/NumberStat";
import VisOperatingSystems from "../subcomponents/vis/VisOperatingSystems";
import VisTopGeos from "../subcomponents/vis/VisTopGeos";
import VisInstallsKaKoBlend from "../subcomponents/vis/VisInstallsKaKoBlend";

const stateToProps = (state) => ({
  tenantId: getTenantId(state),
  filters: getFilters(state),
  simpleDailyDashboard: getSimpleDailyDashboard(state),
});
const actionToProps = (dispatch) => ({
  loadInsights: payload => dispatch(loadInsights(payload)),
});

const SimpleDailyDashboard = ({
  simpleDailyDashboard,
  loadInsights,
  tenantId,
  filters
}) => {
  useEffect(() => {
    if (tenantId) {
      loadInsights({ dashboard: 'simple-daily-dashboard' });
    }
  }, [loadInsights, tenantId]);

  const osData = simpleDailyDashboard.data?.os?.map(os => {
    return {
      label: os.operating_system,
      value: os.count,
    }
  });

  const geosData = simpleDailyDashboard.data?.topGeos;

  const blendedVisData = simpleDailyDashboard.data.daily;

  const callToAction = (
    <Link to="daily-dashboard/advanced" component={RouterLink}>
      <Button color="primary" variant="contained">
        Show advanced
      </Button>
    </Link>
  );

  const breadCrumbs = (
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to="#" component={RouterLink}>
        Reporting
      </Link>

      <Link variant="body1" to="daily-dashboard" component={RouterLink}>
        Daily Dashboard
      </Link>
    </Breadcrumbs>
  );

  return (
    <Page title="Daily Dashboard" breadCrumbs={breadCrumbs} callToAction={callToAction}>
      <Box mb={8}>
        <Filters showUserSpecificFilters={false} dashboard="simple-daily-dashboard" />

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={3}>
            <VisGenericNumberStat data={simpleDailyDashboard.data?.sums?.keyboard_activations}
                                  statKey="Keyboard Activations"
                                  isLoading={simpleDailyDashboard.isLoading}
                                  isLoadingForALongTime={simpleDailyDashboard.isLoadingForALongTime}
                                  filters={filters}
                                  sx={{ width: '100%', mb: 4 }} />

            <VisGenericNumberStat data={simpleDailyDashboard.data?.sums?.app_installs}
                                  statKey="App Installs"
                                  isLoading={simpleDailyDashboard.isLoading}
                                  isLoadingForALongTime={simpleDailyDashboard.isLoadingForALongTime}
                                  filters={filters}
                                  sx={{ width: '100%', mb: 4 }} />

            <VisGenericNumberStat data={simpleDailyDashboard.data?.sums?.activation_rate}
                                  statKey="Activation Rate"
                                  isLoading={simpleDailyDashboard.isLoading}
                                  isLoadingForALongTime={simpleDailyDashboard.isLoadingForALongTime}
                                  filters={filters}
                                  formatter={FORMAT_PERC}
                                  sx={{ width: '100%', mb: 4 }}  />

            <VisGenericNumberStat data={simpleDailyDashboard.data?.sums?.total_revenue}
                                  statKey="Total revenue"
                                  isLoading={simpleDailyDashboard.isLoading}
                                  isLoadingForALongTime={simpleDailyDashboard.isLoadingForALongTime}
                                  filters={filters}
                                  formatter={FORMAT_AMOUNT}
                                  sx={{ width: '100%' }} />
          </Grid>

          <Grid item xs={3}>
            <VisOperatingSystems isLoading={simpleDailyDashboard.isLoading}
                                 isLoadingForALongTime={simpleDailyDashboard.isLoadingForALongTime}
                                 data={osData}
                                 filters={filters}
                                 sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={6}>
            <VisTopGeos isLoading={simpleDailyDashboard.isLoading}
                        isLoadingForALongTime={simpleDailyDashboard.isLoadingForALongTime}
                        data={geosData}
                        filters={filters}
                        sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={12}>
            <VisInstallsKaKoBlend isLoading={simpleDailyDashboard.isLoading}
                                  isLoadingForALongTime={simpleDailyDashboard.isLoadingForALongTime}
                                  data={blendedVisData}
                                  filters={filters}
                                  sx={{ width: "100%", height: "100%" }}/>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
}

export default connect(stateToProps, actionToProps)(SimpleDailyDashboard);