export const formatAmount = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = this < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e);
  }
};

export const abvNumber = (number) => {
  try {
    const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
    const tier = Math.log10(number) / 3 | 0;

    if (tier == 0) return number;

    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    const scaled = number / scale;

    return scaled.toFixed(1) + suffix;
  } catch (e) {
    console.log(e);
  }
};

export function toHuman(num) {
  const units = [
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
  ];

  for (let unit of units) {
    if (num >= unit.value) {
      let value = num / unit.value;
      if (Math.floor(value) === value) {
        return value.toFixed(0) + unit.symbol;
      } else if (Math.floor(value * 10) === value * 10) {
        return value.toFixed(1) + unit.symbol;
      } else {
        return value.toFixed(2) + unit.symbol;
      }
    }
  }

  if (num < 1) {
    return Number(num).toFixed(2);
  }

  return num.toString();
}

export function toHumanPercentage(num) {
  return Number(num / 100).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
  }).replace(',', '.');
}

