import {Box, Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import {useState} from "react";

const stateToProps = (state) => ({});

const actionToProps = (dispatch) => ({});

const ReportGroupings = ({availableGroupings, setReportGroupings}) => {
  const [selectedGroupings, setSelectedGroupings] = useState([]);

  const toggleGrouping = (availableGrouping, checked) => {
    let currentSelectedGroupings = selectedGroupings;

    if( checked ) {
      currentSelectedGroupings.push(availableGrouping);
    } else {
      currentSelectedGroupings = currentSelectedGroupings.filter(grouping => grouping !== availableGrouping);
    }

    setSelectedGroupings(currentSelectedGroupings);
    setReportGroupings(currentSelectedGroupings);
  };

  return (
    <Box>
      {availableGroupings.map((availableGrouping) => (
        <FormGroup key={`grouping_${availableGrouping.name}`}>
          <FormControlLabel control={<Checkbox />}
                            onChange={(event) => toggleGrouping(availableGrouping.name, event.target.checked)}
                            label={availableGrouping.name}
                            sx={{ color: 'text.secondary' }} />
        </FormGroup>
      ))}
    </Box>
  );
};

export default connect(stateToProps, actionToProps)(ReportGroupings);

