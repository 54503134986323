//modules
import React, { useState, useEffect } from "react";
import connect from "react-redux/es/connect/connect";
import UploadBox from "./UploadBox.js";
// state & actions
import {contentItems, giphyChannels} from "../store/selectors";
// material
import {
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import UploadItem from "./UploadItem";

// import actions & state
import LinkedUploadBox from "./LinkedUploadBox";
import LinkedUploadItem from "./LinkedUploadItem";
import GiphyChannelUploadBox from "./GiphyChannelUploadBox";
import GiphyChannel from "./GiphyChannel";
import ThemeUploadBox from "./ThemeUploadBox";
import Theme from "./Theme";

// actions & state
const stateToProps = (state) => ({
  items: contentItems(state),
  giphyChannels: giphyChannels(state),
});

const actionsToProps = (dispatch) => ({
});

const Upload = ({
  contentUploadItems,
  contentRemoveItems,
  resetContentItem,
  uploadLinkedItem,
  contentRemoveGiphyChannels,
  resetGiphyChannel,
  addFiles,
  items,
  ...rest
}) => {
  const [assets, setAssets] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [typeOfUpload, setTypeOfUpload] = useState(['connect_to_channels']);
  const [allAssetsLoading, setAllAssetsLoading] = useState(false);

  const addLinkedFile = (input) => {
    uploadLinkedItem({
      contentTypeUid: rest.contentType.uid,
      localItem: input,
    });
  };

  const addCompoundItem = (input) => {
    rest.addCompoundItem({
      contentTypeUid: rest.contentType.uid,
      index: items.length,
      ...input
    });
  };

  const addGiphyChannel = (input) => {
    rest.addGiphyChannel({
      localGiphyChannel: input,
      contentFolderUid: rest.contentFolder.data.uid,
    })
  }

  const checkGiphyChannel = (input) => {
    rest.checkGiphyChannel({
      name: input,
      contentFolderUid: rest.contentFolder.data.uid,
    });
  }

  useEffect(() => {
    const accepted = items.filter((item) => item.msg === "");
    const rejected = items.filter((item) => item.msg !== "");
    setAssets(accepted);
    setRejected(rejected);
  }, [items]);

  useEffect(() => {
    setAllAssetsLoading(true);

    for (let i = 0; i < assets.length; i++) {
      if (assets.length === (i+1) && assets[i].isLoading === false) {
        setAllAssetsLoading(false);
      }
    }
  }, [assets]);

  const handleTypeOfUploadChange = (event) => {
    contentRemoveItems();
    resetContentItem();
    contentRemoveGiphyChannels();
    resetGiphyChannel();

    setTypeOfUpload(event.target.value);
  };

  const handleDeleteItem = (index, itemUid) => {
    let filteredAssets = assets.filter((item) => assets.indexOf(item) !== index);
    setAssets(filteredAssets);
    rest.handleLoadDeleteContentItem(index, itemUid);
  };

  const handleDeleteRejectedItem = (index) => {
    let filteredAssets = rejected.filter((item) => rejected.indexOf(item) !== index);
    setRejected(filteredAssets);

    rest.handleSetDeleteContentItemByIndex(index);
  };

  const handleDeleteGiphyChannel = (index, giphyChannelUid) => {
    rest.handleLoadDeleteGiphyChannel(index, giphyChannelUid);
  };

  const handleDeleteTheme = (index, zipUid, previewUid) => {
    let filteredAssets = assets.filter((item) => assets.indexOf(item) !== index);
    setAssets(filteredAssets);
    rest.handleLoadDeleteContentItem(index, zipUid);
    rest.handleLoadDeleteContentItem(index, previewUid);
  };

  const handleOnClick = () => {
    rest.handleLoadCreateContentAssets();

    setTimeout(() => {
      rest.handleChangeValue();
      contentRemoveItems();

      if( rest.contentType.identifier === 'gif' ) {
        contentRemoveGiphyChannels();
        resetGiphyChannel();
      }
    }, 500);
  }

  const typeOfUploadSelect = (
    <Box sx={{ mt: 10 }}>
      <FormControl sx={{ width: 256 }}>
        <InputLabel id="label_type_of_upload">Type of Upload</InputLabel>
        <Select labelId="label_type_of_upload"
                id="select_type_of_upload"
                label="Type of Upload"
                value={typeOfUpload}
                onChange={handleTypeOfUploadChange}>

          <MenuItem id="select_option_connect_to_channels" value="connect_to_channels">Connect to channels</MenuItem>
          <MenuItem id="select_option_manually_upload" value="manually_upload">Manually upload</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );

  if (items.length === 0) {
    return (
      <Box sx={{ height: 1, overflowY: 'auto', px: 12 }}>
        {rest.contentType.identifier === 'gif' &&
          <Box>
            {typeOfUploadSelect}

            <Grid container={true} spacing={1} sx={{ mt: 12 }}>
              {typeOfUpload == 'manually_upload' &&
              <Grid item sm={12} md={6}>
                <Box sx={{ p: 0, bgcolor: 'background.paper', position: 'relative', borderRadius: 1 }}>
                  <Box container="true" sx={{ bgcolor: 'background.dark', px: 4, py: 3 }}>
                    <LinkedUploadBox id="card_linked_upload_box" checkLinkedFile={rest.checkLinkedFile} addLinkedFile={addLinkedFile} />
                  </Box>
                </Box>
              </Grid>
              }

              {typeOfUpload == 'connect_to_channels' &&
              <Grid item sm={12}>
                <Box sx={{ p: 0, bgcolor: 'background.paper', position: 'relative', borderRadius: 1 }}>
                  <Box container="true" sx={{ bgcolor: 'background.dark', px: 8, py: 6 }}>
                    <GiphyChannelUploadBox id="card_channel_upload_box" checkGiphyChannel={checkGiphyChannel} addGiphyChannel={addGiphyChannel} />
                  </Box>
                </Box>
              </Grid>
              }
            </Grid>
          </Box>
        }

        {(rest.contentType.identifier === 'sticker' || rest.contentType.identifier === 'wallpaper') &&
          <UploadBox id="card_file_upload_box" addFiles={addFiles} small={false}/>
        }

        {rest.contentType.identifier === 'theme' &&
          <Grid container={true} spacing={4} sx={{mt: 8}}>
            <Grid item sm={12} key="theme-upload-box">
              <Box sx={{ p: 0, bgcolor: 'background.paper', position: 'relative', borderRadius: 1 }}>
                <Box container="true" sx={{ bgcolor: 'background.dark', px: 8, py: 6 }}>
                  <ThemeUploadBox id="card_theme_upload_box" addCompoundItem={addCompoundItem} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        }
      </Box>
    );
  }

  if (items.length > 0) {
    return (
      <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ height: 1, overflowY: 'auto', px: 12 }}>
          {rest.contentType.identifier === 'gif' &&
          <Box>
            {typeOfUploadSelect}

            {typeOfUpload == 'manually_upload' &&
            <Grid container={true} spacing={1} sx={{mt: 12}}>
              {assets.map((item, index) => {
                return (
                  <Grid item sm={12} md={6} key={'card_linked_item_' + index}>
                    <LinkedUploadItem id={'card_linked_item_' + index} index={index} item={item} handleDeleteItem={handleDeleteItem}/>
                  </Grid>
                );
              })}

              <Grid item sm={12} md={6} key="card_linked_upload">
                <Box sx={{p: 0, bgcolor: 'background.paper', position: 'relative', borderRadius: 1}}>
                  <Box container="true" sx={{bgcolor: 'background.dark', px: 4, py: 3}}>
                    <LinkedUploadBox id="card_linked_upload" checkLinkedFile={rest.checkLinkedFile} addLinkedFile={addLinkedFile}/>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            }

            {typeOfUpload == 'connect_to_channels' &&
            <Grid container={true} spacing={4} sx={{mt: 8}}>
              {rest.giphyChannels.map((giphyChannel, index) => {
                return (
                  <Grid item sm={12} key={'card_giphy_channel_' + index}>
                    <Box sx={{ bgcolor: 'background.paper', position: 'relative', borderRadius: 1 }}>
                      <Box container="true" sx={{ bgcolor: 'background.dark', px: 8, py: 6 }}>
                        <GiphyChannel id={'card_giphy_channel_' + index} index={index} giphyChannel={giphyChannel} handleDeleteGiphyChannel={handleDeleteGiphyChannel} />
                      </Box>
                    </Box>
                  </Grid>
                );
              })}

              <Grid item sm={12} key="card_channel_upload">
                <Box sx={{ p: 0, bgcolor: 'background.paper', position: 'relative', borderRadius: 1 }}>
                  <Box container="true" sx={{ bgcolor: 'background.dark', px: 8, py: 6 }}>
                    <GiphyChannelUploadBox id="card_channel_upload" checkGiphyChannel={checkGiphyChannel} addGiphyChannel={addGiphyChannel} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            }
          </Box>
          }

          {(rest.contentType.identifier === 'sticker' || rest.contentType.identifier === 'wallpaper')  &&
          <Box>
            <UploadBox id="card_file_upload" addFiles={addFiles} small={true} />

            <Grid container={true} spacing={1}>
              {assets.map((item, index) => {
                return (
                  <Grid item sm={12} md={6} key={`card_upload_item_${index}`}>
                    <UploadItem id={`card_upload_item_${index}`} index={index} item={item} handleDeleteItem={handleDeleteItem} contentType={rest.contentType} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          }

          {rest.contentType.identifier === 'theme' &&
          <Grid container={true} spacing={4} sx={{mt: 8}}>
            {assets.map((asset, index) => {
              return (
                <Grid item sm={12} key={`card_theme_${index}`}>
                  <Box sx={{ bgcolor: 'background.paper', position: 'relative', borderRadius: 1 }}>
                    <Box container="true" sx={{ bgcolor: 'background.dark', px: 8, py: 6 }}>
                      <Theme id={`card_theme_${index}`} index={index} theme={asset} handleDelete={handleDeleteTheme} />
                    </Box>
                  </Box>
                </Grid>
              );
            })}

            <Grid item sm={12} key="card_theme_upload">
              <Box sx={{ p: 0, bgcolor: 'background.paper', position: 'relative', borderRadius: 1 }}>
                <Box container="true" sx={{ bgcolor: 'background.dark', px: 8, py: 6 }}>
                  <ThemeUploadBox id="card_theme_upload" addCompoundItem={addCompoundItem} />
                </Box>
              </Box>
            </Grid>
          </Grid>
          }

          {rejected.length > 0 &&
          <Box sx={{ pt: 12, pb: 16 }}>
            <Typography variant="h2" sx={{ fontWeight: 'light', color: 'text.secondary' }}>Something went wrong with these:</Typography>

            <Grid id="card_rejected_items" container="true" spacing={1}>
              {rejected.map((item, index) => {
                return (
                  <Grid item sm={12} md={6} key={`card_rejected_upload_item_${index}`}>
                    <UploadItem id={`card_rejected_upload_item_${index}`} index={index} item={item} handleDeleteItem={handleDeleteRejectedItem} contentType={rest.contentType} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          }
        </Box>

        {rejected.length === 0 && assets.length > 0 &&
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', pt: 6, pb: 18, px: 12 }}>
            <Typography id="message_looks_good" variant="h2" sx={{ fontWeight: 'light', mr: 8, color: 'text.secondary' }}>
              Your {' '}
              <span style={{ color: "primary" }}>{`${assets.length}`}</span> {' '}
              { assets.length === 1 ? rest.packName.toLowerCase().substring(0, rest.packName.length) + ' looks '
                : rest.packName.toLowerCase() + 's look ' }
              good!
            </Typography>

            <Button id="button_add_to_pack" disabled={allAssetsLoading === true} variant={"contained"} color="primary" sx={{ py: 2.5, px: 9 }} onClick={() => handleOnClick()}>
              Add to pack
            </Button>
          </Box>
        }
      </Box>
    );
  }
};

export default connect(stateToProps, actionsToProps)(Upload);
