import * as ORCHESTRATION from './types';

const defaultState = {
  section: 'orechestration',
  clientCreation: {
    formSectionUid: 'clientCreation',
    formSectionIsActive: true,
    data: {
      name: ''
    },
  },
  campaignSetup: {
    formSectionUid: 'campaignSetup',
    formSectionIsActive: false,
    data: {
      iosCampaign: {
        checked: false,
      },
      androidCampaign: {
        checked: false,
      },
      locales: [],
    },
  },
  socialSetup: {
    formSectionUid: 'socialSetup',
    formSectionIsActive: false,
    data: {
      twitterId: '',
      youtubeId: '',
      facebookId: '',
    },
  },
  review: {
    formSectionUid: 'review',
    formSectionIsActive: false,
    isLoading: false,
    statusCode: null,
    msg: '',
    errors: [],
  },
  quicklinks: {
    data: [],
    isLoading: false,
    statusCode: null,
    msg: '',
  },
  orchestration: {
    isLoading: false,
    statusCode: null,
    errors: [],
    msg: '',
  },
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ORCHESTRATION.SET_PREVIOUS_SECTION:
      return {
        ...state,
        clientCreation: {
          ...state.clientCreation,
          formSectionIsActive: state.campaignSetup.formSectionIsActive,
        },
        campaignSetup: {
          ...state.campaignSetup,
          formSectionIsActive: state.socialSetup.formSectionIsActive,
        },
        socialSetup: {
          ...state.socialSetup,
          formSectionIsActive: state.review.formSectionIsActive,
        },
        review: {
          ...state.review,
          formSectionIsActive: false,
        }
      };

    case ORCHESTRATION.SET_NEXT_SECTION:
      return {
        ...state,
        review: {
          ...state.review,
          formSectionIsActive: state.socialSetup.formSectionIsActive,
        },
        socialSetup: {
          ...state.socialSetup,
          formSectionIsActive: state.campaignSetup.formSectionIsActive,
        },
        campaignSetup: {
          ...state.campaignSetup,
          formSectionIsActive: state.clientCreation.formSectionIsActive,
        },
        clientCreation: {
          ...state.clientCreation,
          formSectionIsActive: false,
        },
      };

    case ORCHESTRATION.SET_CLIENT_CREATION:
      return {
        ...state,
        clientCreation: {
          ...state.clientCreation,
          data: {
            ...state.clientCreation.data,
            ...action.payload,
          },
        },
      };

    case ORCHESTRATION.SET_CAMPAIGN_SETUP:
      return {
        ...state,
        campaignSetup: {
          ...state.campaignSetup,
          data: {
            ...state.campaignSetup.data,
            ...action.payload,
          },
        },
      };

    case ORCHESTRATION.SET_SOCIAL_SETUP:
      return {
        ...state,
        socialSetup: {
          ...state.socialSetup,
          data: {
            twitterId: action.payload.twitterId,
            youtubeId: action.payload.youtubeId,
            facebookId: action.payload.facebookId,
          },
        },
      };

    case ORCHESTRATION.LOAD_QUICKLINKS:
      return {
        ...state,
        quicklinks: {
          ...state.quicklinks,
          isLoading: true,
        },
      };

    case ORCHESTRATION.SET_QUICKLINKS:
      return {
        ...state,
        quicklinks: {
          ...state.quicklinks,
          data: action.payload.data,
          isLoading: false,
          msg: action.payload.msg,
          statusCode: action.payload.statusCode,
        },
      };

    case ORCHESTRATION.LOAD_ORCHESTRATION:
      return {
        ...state,
        orchestration: {
          ...state.orchestration,
          isLoading: true,
        },
      };

    case ORCHESTRATION.SET_ORCHESTRATION:
      return {
        ...state,
        orchestration: {
          ...state.orchestration,
          isLoading: false,
          errors: action.payload.errors,
          statusCode: action.payload.statusCode,
          msg: action.payload.msg,
        },
      };

    default:
      return state;
  }
}
