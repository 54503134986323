import {
  LOAD_REPORT,
  LOAD_REPORT_DESCRIPTORS, LOAD_REPORT_EXCEL_DOWNLOAD, SET_REPORT,
  SET_REPORT_DESCRIPTORS, SET_REPORT_EXCEL_DOWNLOAD,
  SET_REPORT_FILTERS,
  SET_REPORT_GROUPINGS, SET_REPORT_ORDERINGS, SET_REPORT_ROWS_LIMIT
} from "./types";

const defaultState = {
  section: "reporting",
  reportDescriptors: {
    data: [],
    isLoading: false,
    success: null,
    msg: "",
  },
  reportFilters: [],
  reportGroupings: [],
  reportOrderings: [],
  reportRowsLimit: [],
  report: {
    data: [],
    isLoading: false,
    success: null,
    msg: ""
  },
  reportExcel: {
    isLoading: false,
    success: null,
    msg: "",
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_REPORT_DESCRIPTORS:
      return {
        ...state,
        reportDescriptors: {
          ...state.reportDescriptors,
          isLoading: true,
        },
      };

    case SET_REPORT_DESCRIPTORS:
      return {
        ...state,
        reportDescriptors: {
          ...state.reportDescriptors,
          data: action.payload.data,
          isLoading: false
        }
      };

    case SET_REPORT_FILTERS:
      return {
        ...state,
        reportFilters: action.payload.reportFilters,
      };

    case SET_REPORT_GROUPINGS:
      return {
        ...state,
        reportGroupings: action.payload.reportGroupings,
        report: {
          ...state.report,
          isLoading: false,
          data: [],
        }
      };

    case SET_REPORT_ORDERINGS:
      return {
        ...state,
        reportOrderings: action.payload.reportOrderings,
        report: {
          ...state.report,
          isLoading: false,
          data: [],
        }
      }

    case SET_REPORT_ROWS_LIMIT:
      return {
        ...state,
        reportRowsLimit: action.payload.reportRowsLimit,
      }

    case LOAD_REPORT:
      return {
        ...state,
        report: {
          ...state.report,
          isLoading: true,
        },
      };

    case SET_REPORT:
      return {
        ...state,
        report: {
          ...state.report,
          isLoading: false,
          data: action.payload.data,
          msg: action.payload.message,
          success: action.payload.statusCode === 200,
        },
      }

    case LOAD_REPORT_EXCEL_DOWNLOAD:
      return {
        ...state,
        reportExcel: {
          ...state.report,
          isLoading: true,
        },
      };

    case SET_REPORT_EXCEL_DOWNLOAD:
      return {
        ...state,
        reportExcel: {
          ...state.report,
          isLoading: false,
          msg: action.payload.message,
          success: action.payload.statusCode === 200,
        },
      };

    default:
      return state;
  }
};