const SET_PREVIOUS_SECTION = 'SET_PREVIOUS_SECTION';
const SET_NEXT_SECTION = 'SET_NEXT_SECTION';
const SET_CLIENT_CREATION = 'SET_CLIENT_CREATION';
const SET_CAMPAIGN_SETUP = 'SET_CAMPAIGN_SETUP';
const SET_SOCIAL_SETUP = 'SET_SOCIAL_SETUP';

const LOAD_QUICKLINKS = 'LOAD_QUICKLINKS';
const SET_QUICKLINKS = 'SET_QUICKLINKS';

const LOAD_ORCHESTRATION = 'LOAD_ORCHESTRATION';
const SET_ORCHESTRATION = 'SET_ORCHESTRATION';

export {
  SET_PREVIOUS_SECTION,
  SET_NEXT_SECTION,
  SET_CLIENT_CREATION,
  SET_CAMPAIGN_SETUP,
  SET_SOCIAL_SETUP,
  LOAD_QUICKLINKS,
  SET_QUICKLINKS,
  LOAD_ORCHESTRATION,
  SET_ORCHESTRATION,
};
