import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Box, Button, Card, CardContent, Grid, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const ClientAddForm = ({
                         clientsList,
                         kee2List,
                         clientsLoadCreateOrganizations,
                         setIsSubmittingState,
                         isSubmittingState,
                         errorsServer,
                         setErrorsServer,
                       }) => {
  return (
    <Formik initialValues={{
      kee2_identifier: '',
      name: '',
      filter_alias: '',
      email: '',
      submit: null
    }}
    validationSchema={Yup.object().shape({
      kee2_identifier: Yup.string().max(255).required('Kee2_identifier is required'),
      name: Yup.string().min(5).max(255).required('Name is required'),
      filter_alias: Yup.string().min(5).max(255),
    })}
    onSubmit={(values, {
      resetForm,
      setErrors,
      setStatus,
      setSubmitting
    }) => {
      clientsLoadCreateOrganizations({
        name: values.name,
        kee2_identifier: values.kee2_identifier.value,
        filter_alias: values.filter_alias
      });
      setIsSubmittingState(true);
    }}>
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid container={true} spacing={6}>
                <Grid item md={12} xs={12}>
                  <Autocomplete getOptionLabel={(option) => option.text}
                                options={kee2List}
                                onChange={(e, value) => {
                                  if (value !== null) {
                                    const clientDetails = clientsList.filter((item) => item.kee2_client_identifier === value.value);
                                    setFieldValue("name", clientDetails[0].name)
                                  }
                                  setFieldValue("kee2_identifier", value || '')
                                  setErrorsServer({
                                    ...errorsServer,
                                    kee2_identifier: false
                                  })
                                }}
                                renderInput={(params) => (
                                  <TextField error={Boolean(touched.kee2_identifier && errors.kee2_identifier) || errorsServer.kee2_identifier}
                                             fullWidth
                                             helperText={(touched.kee2_identifier && errors.kee2_identifier) || errorsServer.kee2_identifier}
                                             label="Kee2_identifier"
                                             name="kee2_identifier"
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             variant="outlined"
                                             value={values.kee2_identifier}
                                             required
                                             {...params} />
                                )} />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField error={Boolean(touched.name && errors.name)}
                             fullWidth
                             helperText={touched.name && errors.name}
                             label="Full name"
                             name="name"
                             onBlur={handleBlur}
                             onChange={handleChange}
                             required
                             value={values.name}
                             variant="outlined" />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField error={Boolean(touched.filter_alias && errors.filter_alias)}
                             fullWidth
                             helperText={touched.filter_alias && errors.filter_alias}
                             label="Filter Alias"
                             name="filter_alias"
                             onBlur={handleBlur}
                             onChange={handleChange}
                             value={values.filter_alias}
                             variant="outlined" />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Box sx={{ width: 1, display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" size="large" type="submit" disabled={isSubmittingState}>
                      New Client
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ClientAddForm.propTypes = {
  className: PropTypes.string,
};

export default ClientAddForm;
