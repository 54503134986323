import connect from "react-redux/es/connect/connect";
import {Box, IconButton, InputBase} from "@mui/material";
import {Delete} from "@mui/icons-material";
import React from "react";

const stateToProps = (state) => ({});

const LinkedUploadItem = (props) => {
  return (
    <Box sx={{ p: 0, bgcolor: 'background.paper', position: 'relative', borderRadius: 1 }}>
      <Box container="true" sx={{ bgcolor: 'background.dark', px: 4, py: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <img id={`img_preview_linked_item_${props.index}`}
                 src={props.item.data.preview_url}
                 style={{ width: 56, height: 56, objectFit: 'cover' }} />
          </Box>

          <InputBase id={`input_linked_item_${props.index}`}
                     type="text"
                     variant="outlined"
                     size={"small"}
                     disabled
                     value={props.item.data.url}
                     sx={{ bgcolor: 'background.default',
                           border: 1,
                           borderRadius: 0.5,
                           borderColor: 'secondary.dark',
                           color: 'text.secondary',
                           px: 4,
                           py: 1.5,
                           width: 1,
                           ml: 3,
                           '&:focus': {
                             borderColor: 'secondary.main',
                           }}}/>

          <Box sx={{ width: 68  , ml: 8 }}>
              <IconButton id={`button_delete_linked_item_${props.index}`}
                          aria-label="store"
                          onClick={() => props.handleDeleteItem(props.index, props.item.data.uid)}>
                <Delete sx={{ fontSize: 28 }} />
              </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default connect(stateToProps)(LinkedUploadItem)