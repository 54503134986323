import React, {useEffect} from 'react';
import NProgress from 'nprogress';
import {Box, LinearProgress, useTheme} from '@mui/material';
import {THEMES} from "../constants";

const LoadingScreen = (props) => {
  const theme = useTheme();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box sx={{ alignItems: 'center',
               display: 'flex',
               flexDirection: 'column',
               height: '100vh',
               justifyContent: 'center',
               minHeight: '100vh',
               p: 3 }}
         className={`${props.className}`}>
      <Box sx={{mb: 8}}>
        {theme.name === THEMES.LIGHT && (
          <img title="Tappa logo" style={{width: '100px'}} src="/static/images/logo_blue.png"/>
        )}

        {theme.name === THEMES.DARK && (
          <img title="Tappa logo" style={{width: '100px'}} src="/static/images/logo_yellow.png"/>
        )}
      </Box>

      <Box width={400}>
        <LinearProgress sx={{borderRadius: 5, height: '7px' }} />
      </Box>
    </Box>
  );
};

export default LoadingScreen;
