import React, {useEffect, useState} from 'react';
import connect from 'react-redux/es/connect/connect';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {AppBar, Box, Button, IconButton, SvgIcon, Toolbar, Tooltip, Typography, useTheme} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../components/vectors/Logo';
import Account from './Account';
import Notifications from './Notifications';
import Settings from './Settings';
import {userInfo} from '../../containers/user/store/selectors';
import {clientsOrganization} from "../../containers/clients/store/selectors";
import {uiRedirect} from "../../store/actions";
import {ArrowForward} from "@mui/icons-material";
import {loadPublishContent} from "../../containers/keyboard-content/store/actions";
import PublishModal from "../../containers/keyboard-content/subcomponents/PublishModal";
import {THEMES} from "../../constants";

const stateToProps = state => ({
  userInfo: userInfo(state),
  clientsOrganization: clientsOrganization(state),
});

const actionsToProps = dispatch => ({
  uiRedirect: (url) => dispatch(uiRedirect(url)),
  loadPublishContent: (data) => dispatch(loadPublishContent(data)),
});

const TopBar = ({
                  onMobileNavOpen,
                  userInfo,
                  uiRedirect,
                  clientId,
                  clientsOrganization,
                  ...rest
                }) => {
  const theme = useTheme();

  const [organization, setOrganization] = useState(clientsOrganization);
  const [selectedClient, setSelectedClient] = useState('');
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const url = new URL(window.location);

  useEffect(() => {
    if (clientsOrganization.data) {
      setOrganization(clientsOrganization.data);
      setSelectedClient(clientsOrganization.data.id);
    }
  }, [clientsOrganization.data]);

  let organizations = [];

  if(userInfo.organizations.length > 0) {
    userInfo.organizations.map((item, i) => {
      organizations.push({
        text: item.name,
        value: item.id
      })
    })
  }

  const handlePublishModalIsOpened = (opened) => {
    setPublishModalOpen(!publishModalOpen);

    if (opened === true) {
      rest.loadPublishContent({
        tenantId: clientId
      });
    }
  }

  return (
    <Box>
      <AppBar sx={{ bgcolor: 'background.default', px: 4, boxShadow: 'none', borderBottom: 1, borderColor: 'background.darkest' }}>
        <Toolbar sx={{ minHeight: 64 }}>
          <IconButton sx={{ display: {lg: 'none'} }} color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon sx={{ color: 'text.secondary' }}>
              <MenuIcon/>
            </SvgIcon>
          </IconButton>

          <Box sx={{ display: {xs: 'none', md: 'block'}}}>
            <RouterLink to={!!clientId ? `/clients/${clientId}/keyboard-content` : '/clients'}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {theme.name === THEMES.LIGHT && (
                  <img title="Tappa logo" style={{width: '100px'}} src="/static/images/logo_blue.png"/>
                )}

                {theme.name === THEMES.DARK && (
                  <img title="Tappa logo" style={{width: '100px'}} src="/static/images/logo_yellow.png"/>
                )}

                {clientId &&
                  <Box sx={{ ml: 4 }}>
                    <Typography id="label_client_name" fontSize="medium" fontWeight="700" sx={{ color: 'text.primary' }}>{organization.name ? organization.name : ''}</Typography>
                  </Box>
                }
              </Box>
            </RouterLink>
          </Box>

          <Box ml={2} flexGrow={1} />

          {(clientId && url.href.includes('keyboard-content')) &&
            <Box sx={{mr: 4}}>
              <Button onClick={() => {handlePublishModalIsOpened(true);}}
                      variant="contained" size="small" color={'tertiary'} sx={{borderRadius: 3, pl: 6, pr: 4}}>
                <span>Review and Publish</span>
                <ArrowForward/>
              </Button>
            </Box>
          }

          <Notifications/>

          <Settings/>

          <Box sx={{ bgcolor: 'background.darkest', width: '1px', height: 32, mx: 4   }} />

          <Box ml={2}>
            <Account userInfo={userInfo}/>
          </Box>
        </Toolbar>
      </AppBar>

      <PublishModal open={publishModalOpen}
                    handlePublishModalOpen={() => handlePublishModalIsOpened()}
                    tenantId={clientId} />
    </Box>
  );
};

TopBar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {
  }
};

export default connect(stateToProps, actionsToProps)(TopBar);
