import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import {middleware as languageMiddleware} from '../utils/language';

import {middleware as apiMiddleware} from 'AppUtils/api';
import rootReducer from './reducers';
import rootSaga from './sagas';

const sagasOptions = {
  onError(error) {
    console.log(error);
  },
};

if (process.env.REACT_APP_WEBAPP_SAGAS_LOGGER === 'true') {
  sagasOptions.sagaMonitor = require('./sagaMonitor').default;
}

const sagaMiddleware = createSagaMiddleware(sagasOptions);

const historyOptions = {};

const history = createBrowserHistory(historyOptions);

const middlewares = [];
const initialState = {};
middlewares.push(sagaMiddleware);
middlewares.push(routerMiddleware(history));
middlewares.push(apiMiddleware);
middlewares.push(languageMiddleware);

// Get your redux logger from https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
if (process.env.REACT_APP_WEBAPP_REDUX_LOGGER === 'true') {
  /* eslint-disable global-require */
  const {createLogger} = require('redux-logger');
  const logger = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
  });
  middlewares.push(logger);
}

const store = createStore(
  rootReducer(history),
  initialState,
  compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({
      serialize: true,
      trace: true
    }) : f => f,
  ),
);
sagaMiddleware.run(rootSaga);
store.close = () => store.dispatch('END');

export default () => ({store, history});
