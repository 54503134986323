import {Box, TextField} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import {useState} from "react";
import _ from 'lodash';
import {Clear} from "@mui/icons-material";

const stateToProps = (state) => ({});

const actionToProps = (dispatch) => ({});

const ReportRowsLimit = ({setReportRowsLimit, ...props}) => {
  const [limit, setLimit] = useState('');

  const handleReportRowsLimitChange = _.debounce((newLimit) => {
    setLimit(newLimit ? newLimit : '');
    setReportRowsLimit(newLimit ? newLimit: undefined);
  }, 50);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', left: -40, top: 22 }}>
        <Clear onClick={() => handleReportRowsLimitChange(undefined)} sx={{ cursor: 'pointer', color: 'text.secondary', '&:hover': { color: 'text.primary' } }} />
      </Box>
      <Box>
        <TextField type="number" id="textfield_report_limit" label="Rows limit" variant="standard" sx={{ width: 1 }} value={limit}
                   onChange={(event) => handleReportRowsLimitChange(event.target.value)} />
      </Box>
    </Box>
  );
}

export default connect(stateToProps, actionToProps)(ReportRowsLimit);