import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Box, TextField } from "@mui/material";

export default function DateFilter({
  minDate,
  maxDate,
  value,
  label,
  onChange,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        value={value}
        onChange={(newValue) => {
          onChange && onChange(newValue.format("YYYY-MM-DD"));
        }}
        renderInput={(params) => <TextField sx={{ width: 1 }} {...params} />}
        minDate={minDate}
        maxDate={maxDate}
      />
    </LocalizationProvider>
  );
}
