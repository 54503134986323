import connect from "react-redux/es/connect/connect";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  Link,
  Tooltip,
  Typography
} from "@mui/material";
import Page from "../../../components/Page";
import {Link as RouterLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {userLoadChannels} from "../../user/store/actions";
import {userChannels} from "../../user/store/selectors";
import {Android, Apple, ContentCopy} from "@mui/icons-material";

const stateToProps = state => ({
  userChannels: userChannels(state),
});

const actionsToProps = dispatch => ({
  userLoadChannels: data => dispatch(userLoadChannels(data)),
});

const Onboarding = ({ ...props }) => {
  const [copyTooltipTitle, setCopyTooltipTitle] = useState('Copy to clipboard');

  useEffect(() => {
    props.userLoadChannels({
      tenantId: props.match.params.clientId,
    });
  }, []);

  const getDefaultCampaigns = () => {
    return props.userChannels.data.map(channel => {
      return channel.campaigns.find(campaign => campaign.locale === 'default');
    });
  };

  const flashCopiedText = () => {
    setCopyTooltipTitle('Copied!');

    setTimeout(() => {
      setCopyTooltipTitle('Copy to clipboard');
    }, 1000);
  }

  const copyToClipboard = text => {
    if (!navigator.clipboard) {
      // Fallback for browsers that do not support the Clipboard API
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.display = 'none';

      document.body.appendChild(textarea);
      textarea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          flashCopiedText();
        } else {
          console.log('Unable to copy text to clipboard');
        }
      } catch (error) {
        console.log('An error occurred while copying text to clipboard');
      }

      document.body.removeChild(textarea);
      return;
    }

    navigator.clipboard.writeText(text)
      .then(() => {
        flashCopiedText();
      })
      .catch(() => {
        console.log('Unable to copy text to clipboard');
      });
  };

  const breadCrumbs =
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to={`/clients/${props.match.params.clientId}/dashboard`} component={RouterLink}>
        Dashboard
      </Link>

      <Link bariant="body1" to="#" component={RouterLink}>
        Onboarding
      </Link>
    </Breadcrumbs>;

  return (
    <Page title="Set up your SDK keyboard" breadCrumbs={breadCrumbs}>
        <Box sx={{ maxWidth: 'md' }}>
          <Typography variant="body1">Congratulations! You've set up your client and campaigns for your Tappa Keyboard SDK!</Typography>
          <Typography variant="body1">You will need your campaign IDs to set up your SDK. You can safely continue to your new keyboard content management solution, as you can review these IDs at any time. For further information on setting up your SDK and more, please refer to <Link href="https://developers.tappa.com/docs/getting-started">our docs</Link>.</Typography>
        </Box>

        <Box sx={{ mt: 8 }}>
          {getDefaultCampaigns().map(campaign => (
            <Card sx={{ minWidth: 275, maxWidth: 400, my: 6 }} key={campaign.kee2_identifier}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  {campaign.name.toLowerCase().endsWith('ios') && (
                    <Apple color="primary" sx={{ mr: 4 }} />
                  )}

                  {campaign.name.toLowerCase().endsWith('android') && (
                    <Android color="primary" sx={{ mr: 4 }} />
                  )}

                  <Typography variant="overline" sx={{ color: 'text.secondary' }}>{campaign.name}</Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <Typography variant="body1" sx={{ color: 'text.primary' }}>{campaign.kee2_identifier}</Typography>

                  <Tooltip title={copyTooltipTitle}>
                    <IconButton aria-label="copy" onClick={() => copyToClipboard(campaign.kee2_identifier)}>
                      <ContentCopy />
                    </IconButton>
                  </Tooltip>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>

      <Box sx={{ maxWidth: 'md' }}>
        <Typography variant="body1">You can now safely proceed to your content keyboard management platform.</Typography>

        <Button variant="contained" sx={{ mt: 4 }} onClick={() => {window.location.href = '/';}}>
          Open Keyboard Content Manager
        </Button>
      </Box>
    </Page>
  );
};

export default connect(stateToProps, actionsToProps)(Onboarding);