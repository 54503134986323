import Observable from 'Observable';
import * as AUTH from '../containers/auth/store/types';
import {param} from 'AppUtils/objects';
import {getXsrfValueFromCookie} from "./cookies";

let store;
let xsrfToken = null;
const REQUESTS_STACK = new Map();

const host = process.env.REACT_APP_API_HOST;
const path = process.env.REACT_APP_API_PATH;
const sanctumPath = process.env.REACT_APP_SANCTUM_PATH;

xsrfToken = getXsrfValueFromCookie();

const middleware = store => next => (action) => {
  const result = next(action);

  if( action.type === AUTH.SET_LOGIN_OBTAIN_XSRF_TOKEN && action.payload.xsrfTokenRequestSuccessful ) {
    xsrfToken = action.payload.xsrfToken;
  }

  return result;
};

middleware.register = (_store) => {
  store = _store;
};

function encodeLongQueryData(obj, prefix) {
  const str = [];

  let p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? `${prefix}[${p}]` : p;


      const v = obj[p];
      str.push((v !== null && typeof v === 'object')
        ? encodeLongQueryData(v, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    }
  }
  return str.join('&');
}

const adjustUrl = function (url, options = {}) {
  return host + (options.sanctum ? options.sanctum : path) + url;
};

const adjustUrlData = function (url, data = {}, options = {}) {
  if (options.doNotTouchUrl) {
    return url;
  }

  if (Object.keys(data).length) {
    const query = encodeLongQueryData(data);
    if (query) url = `${url}?${query}`;
  }

  const basePath = host + (options.sanctum ? sanctumPath : path);

  return `${basePath}${url}`;
};

const requestInterceptor = (response) => {
  if( response &&
    response.url !==  adjustUrlData('/user') &&
    [401, 419].includes(response.status)  ) {
    window.location = '/';
  }
  return response;
};

const getHeaders = (options = {}) => {
  let headers = {
    'Accept': 'application/json',
  };

  if (!options.emptyHeaders) {
    headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
    };
  } else if (options.headers) {
    headers = {...options.headers};
  }

  if(xsrfToken) {
    headers['X-XSRF-TOKEN'] = xsrfToken;
  }

  return headers;
};

const apiGet = (url, data = {}, options = {}) => {
  // Network request cancellation should only be done on `GET` !
  const URI = adjustUrlData(url, data, options);
  const reqController = new AbortController();
  const prevReq = REQUESTS_STACK.get(URI);

  if (prevReq) {
    prevReq.abort();
    console.info(
      `%c__REQUEST__ Cancelled for URI: %c${URI}`,
      'color: blue',
      'font-weight: bold',
    );
  }

  REQUESTS_STACK.set(URI, reqController);

  const result = Observable.from(fetch(URI, {
    method: 'GET',
    credentials: 'include',
    responseType: 'text', // force text because older browser version of Safari do not support json as response type
    headers: getHeaders(options),
  }).then(requestInterceptor));

  return result
    .catch(handleError)
    .finally(() => {
      if (REQUESTS_STACK.has(URI)) {
        REQUESTS_STACK.delete(URI);
      }
    });
};

const apiGetPlain = (url, data = {}, options = {}) => {
  const result = Observable.fromPromise(fetch(adjustUrlData(url, data, options), {
    method: 'GET',
    mode: 'no-cors',
    responseType: 'text',
    credentials: 'include',
    headers: getHeaders(options),
  }).then(requestInterceptor));

  return result
    .map((e) => {
      return e.response;
    })
    .catch(handleError);
};

const apiCachedGet = (url) => {
  const result = Observable.fromPromise(fetch(url, {
    method: 'GET',
    mode: 'no-cors',
    headers: getHeaders(),
    cache: "force-cache",
    credentials: 'include',
  }).then(requestInterceptor));

  return result
    .map(e => e.response)
    .catch(handleError);
};

const apiDelete = (url, data = {}, options = {}) => {
  const result = Observable
    .from(fetch(adjustUrl(url), {
      method: 'DELETE',
      headers: getHeaders(options),
      body: param(data),
      credentials: 'include',
    }).then(requestInterceptor));

  return result.catch(handleError);
};

const apiPut = (url, data = {}, options = {}) => {
  const result = Observable
    .from(fetch(adjustUrl(url), {
      method: 'PUT',
      headers: getHeaders(options),
      body: param(data),
      credentials: 'include',
    }).then(requestInterceptor));

  return result.catch(handleError);
};

const apiPutJson = (url, data = {}) => {
  const result = Observable
      .from(fetch(adjustUrl(url), {
        method: 'PUT',
        headers: getHeaders({
          emptyHeaders: true,
          headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
        }),
        body: JSON.stringify(data),
        credentials: 'include',
      }).then(requestInterceptor));

  return result.catch(handleError);
};

function apiPutFile(url, data = {}) {
  let formData = new FormData();

  formData.append('_method', 'PUT')
  for(const name in data) {
    if( Array.isArray(data[name]) ) {
      for( let i = 0; i < data[name].length; i++ ) {
        formData.append(name + '[]', data[name][i].id);
      }
    } else {
      formData.append(name, data[name]);
    }
  }
  const result = Observable.fromPromise(fetch(adjustUrl(url), {
    method: 'POST',
    headers: getHeaders({
      emptyHeaders: true,
      headers: {
        'Accept': 'application/json',
      }
    }),
    body: formData,
    credentials: 'include',
  }).then(requestInterceptor));

  return result.catch(handleError);
}

const apiPost = (url, data = {}, options = {}) => {
  if (options.postFile) {
    return apiPostFile(url, data);
  } else {
    const result = Observable
      .from(fetch(adjustUrl(url), {
        method: 'POST',
        headers: getHeaders(options),
        body: param(data),
        credentials: 'include',
      }).then(requestInterceptor));

    return result.catch(handleError);
  }
};

const apiPostJson = (url, data = {}, options) => {
  const result = Observable
    .from(fetch(adjustUrl(url), {
      method: 'POST',
      headers: !options ? getHeaders({
        emptyHeaders: true, 
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
      }) : getHeaders(options),
      body: JSON.stringify(data),
      credentials: 'include',
    }).then(requestInterceptor));

  return result.catch(handleError);
};

const apiPatch = (url, data = {}) => {
  const result = Observable
    .from(fetch(adjustUrl(url), {
      method: 'PATCH',
      headers: getHeaders({
        emptyHeaders: true,
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
      }),
      body: JSON.stringify(data),
      credentials: 'include',
    }).then(requestInterceptor));

  return result.catch(handleError);
};

function apiPostFile(url, data = {}) {
  const result = Observable.fromPromise(fetch(adjustUrl(url), {
    method: 'POST',
    headers: getHeaders({emptyHeaders: true}),
    body: data,
    credentials: 'include',
  }).then(requestInterceptor));

  return result.catch(handleError);
}

const fetchPost = (url, data = {}) => {
  return fetch(adjustUrl(url), {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
  }).then(requestInterceptor);
};

const handleError = (err) => {
  return Observable.throw(err);
};


export {
  apiGet, apiGetPlain, apiCachedGet, apiDelete, apiPut, apiPutJson, apiPutFile, apiPost, apiPostJson, fetchPost, apiPatch, apiPostFile, middleware,
};
