import { createAction } from "redux-actions";

import * as INSIGHTS from "./types";

const setFilters = createAction(INSIGHTS.SET_FILTERS, (data) => data);
const filtersChanged = createAction(INSIGHTS.FILTERS_CHANGED, (data) => data);
const loadInsights = createAction(INSIGHTS.LOAD_INSIGHTS, (data) => data);

const loadAudiences = createAction(INSIGHTS.LOAD_AUDIENCES, (data) => data);
const resetAudiences = createAction(INSIGHTS.RESET_AUDIENCES, (data) => data);
const loadAudienceMembers = createAction(INSIGHTS.LOAD_AUDIENCE_MEMBERS, (data) => data);
const loadCreateAudience = createAction(INSIGHTS.LOAD_CREATE_AUDIENCE, (data) => data);
const loadUpdateAudience = createAction(INSIGHTS.LOAD_UPDATE_AUDIENCE, (data) => data);
const loadDeleteAudience = createAction(INSIGHTS.LOAD_DELETE_AUDIENCE, (data) => data);
const loadCheckAudiencesIntersection = createAction(INSIGHTS.LOAD_CHECK_AUDIENCES_INTERSECTION, (data) => data);
const resetCheckAudiencesIntersection = createAction(INSIGHTS.RESET_CHECK_AUDIENCES_INTERSECTION, (data) => data);
const loadIntersectAudiences = createAction(INSIGHTS.LOAD_INTERSECT_AUDIENCES, (data) => data);

const loadCustomInsights = createAction(INSIGHTS.LOAD_CUSTOM_INSIGHTS, (data) => data);
const loadCreateCustomInsight = createAction(INSIGHTS.LOAD_CREATE_CUSTOM_INSIGHT, (data) => data);
const loadUpdateCustomInsight = createAction(INSIGHTS.LOAD_UPDATE_CUSTOM_INSIGHT, (data) => data);
const loadArchiveCustomInsight = createAction(INSIGHTS.LOAD_ARCHIVE_CUSTOM_INSIGHT, (data) => data);
const loadUnarchiveCustomInsight = createAction(INSIGHTS.LOAD_UNARCHIVE_CUSTOM_INSIGHT, (data) => data);

const loadCustomInsightsTemplates = createAction(INSIGHTS.LOAD_CUSTOM_INSIGHTS_TEMPLATES, (data) => data);

const setSelectedLeftAxisItems = createAction(INSIGHTS.SET_SELECTED_LEFT_AXIS_ITEMS, (data) => data);
const setLeftAxisRenditionType = createAction(INSIGHTS.SET_LEFT_AXIS_RENDITION_TYPE, (data) => data);
const setSelectedRightAxisItems = createAction(INSIGHTS.SET_SELECTED_RIGHT_AXIS_ITEMS, (data) => data);
const setRightAxisRenditionType = createAction(INSIGHTS.SET_RIGHT_AXIS_RENDITION_TYPE, (data) => data);
const setAxisUseCommonYScale = createAction(INSIGHTS.SET_AXIS_USE_COMMON_Y_SCALE, (data) => data);
const setSelectedBreakdownItem = createAction(INSIGHTS.SET_SELECTED_BREAKDOWN_ITEM, (data) => data);

export {
  setFilters,
  filtersChanged,
  loadInsights,
  loadAudiences,
  resetAudiences,
  loadAudienceMembers,
  loadCreateAudience,
  loadUpdateAudience,
  loadDeleteAudience,
  loadIntersectAudiences,
  loadCheckAudiencesIntersection,
  resetCheckAudiencesIntersection,
  loadCustomInsights,
  loadCreateCustomInsight,
  loadUpdateCustomInsight,
  loadArchiveCustomInsight,
  loadUnarchiveCustomInsight,
  loadCustomInsightsTemplates,
  setSelectedLeftAxisItems,
  setSelectedRightAxisItems,
  setLeftAxisRenditionType,
  setRightAxisRenditionType,
  setAxisUseCommonYScale,
  setSelectedBreakdownItem,
};
