// START modules
import React, { useState, useEffect } from "react";
import connect from "react-redux/es/connect/connect";
import { useSnackbar } from "notistack";
import moment from "moment";
// END modules

// START components
import Page from "../../../components/Page";
import Folders from "../subcomponents/Folders";
import TabPanel from "../../../components/TabPanel";
// END components

// START material
import {
  Tabs,
  Tab,
  Box,
  Link,
  Breadcrumbs,
} from "@mui/material";
// END material

// START actions
import {
  contentLoadTypes,
  loadContentFolders,
  loadCreateContentFolder,
  loadReadContentFolder,
  loadReadContentAssets,
  loadResetContentTypes,
  loadDeleteContentFolder,
} from "../store/actions";
// END actions

// START selectors
import {
  contentTypes,
  contentFolders,
  contentFolder,
} from "../store/selectors";
import {Link as RouterLink} from "react-router-dom";
import {userLoadResetChannels} from "../../user/store/actions";
import {getValue} from "AppUtils/objects";
// END selectors

const actionsToProps = (dispatch) => ({
  userLoadResetChannels: (data) => dispatch(userLoadResetChannels(data)),
  contentLoadTypes: (data) => dispatch(contentLoadTypes(data)),
  loadContentFolders: (data) => dispatch(loadContentFolders(data)),
  loadCreateContentFolder: (data) => dispatch(loadCreateContentFolder(data)),
  loadReadContentFolder: (data) => dispatch(loadReadContentFolder(data)),
  loadReadContentAssets: (data) => dispatch(loadReadContentAssets(data)),
  loadResetContentTypes: (data) => dispatch(loadResetContentTypes(data)),
  loadDeleteContentFolder: (data) => dispatch(loadDeleteContentFolder(data)),
});

const stateToProps = (state) => ({
  contentTypes: contentTypes(state),
  contentFolders: contentFolders(state),
  contentFolder: contentFolder(state),
});

const KeyboardContent = (props) => {
  const clientId = getValue(props, 'match.params.clientId');
  const [value, setValue] = useState(0);
  const [selection, setSelection] = useState("Stickers");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    props.contentLoadTypes();

    return () => {
      props.loadResetContentTypes();
      props.userLoadResetChannels();
    };
  }, []);

  useEffect(() => {
    if (typeof props.contentTypes.data[value] !== "undefined") {
      props.loadContentFolders({
        tenantId: clientId,
        contentTypeUid: props.contentTypes.data[value].uid,
      });
    }
  }, [props.contentTypes.data, value]);

  useEffect(() => {
    if (
      props.contentFolders.isLoading === false &&
      props.contentFolders.message !== ""
    ) {
      enqueueSnackbar(props.contentFolders.message, {
        variant: "error",
        persist: false,
      });
    }
  }, [props.contentFolders]);

  useEffect(() => {
    if (
      props.contentFolder.isLoading === false &&
      props.contentFolder.message !== ""
    ) {
      enqueueSnackbar(props.contentFolder.message, {
        variant: "error",
        persist: false,
      });
    }
  }, [props.contentFolder]);

  const handleTabChange = (e, value) => {
    setValue(value);
    setSelection(e.target.textContent);
  };

  const handleControlModalIsOpened = (opened, uid) => {
    if (opened === true) {
      if (typeof uid === "undefined") {
        props.loadCreateContentFolder({
          tenantId: clientId,
          contentTypeUid: props.contentTypes.data[value].uid,
          queryParams: {
            name: `Folder#${moment().format("YYYY-MM-DD HH:mm:ss")}`,
            type: "custom",
            rank: props.contentFolders.data.length + 1,
          },
        });
      } else {
        props.loadReadContentFolder({
          tenantId: clientId,
          contentTypeUid: props.contentTypes.data[value].uid,
          folderUid: uid,
        });
        props.loadReadContentAssets({
          tenantId: clientId,
          contentTypeUid: props.contentTypes.data[value].uid,
          folderUid: uid,
        });
      }
    }
  };

  const handleSelectedPacksDeletion = () => {
    let selectedContentFolders = props.contentFolders.data.filter(contentFolder => contentFolder.selected);

    selectedContentFolders.forEach((folder) => {
      props.loadDeleteContentFolder({
        tenantId: clientId,

        contentTypeUid: folder.type.uid,
        folderUid: folder.uid
      });
    });
  }

  const breadCrumbs =
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to="#" component={RouterLink}>
        Management
      </Link>

      <Link bariant="body1" to="#" component={RouterLink}>
        Content
      </Link>

      <Link bariant="body1" to="#" component={RouterLink}>
        {selection}
      </Link>
    </Breadcrumbs>;

  return (
    <Page title="Keyboard Content" breadCrumbs={breadCrumbs}>
      <Box mt={3}>
        <Tabs selectionFollowsFocus
              value={value}
              onChange={handleTabChange}
              style={{
                borderBottom: "1px solid #ddd",
                minHeight: 40,
              }}>
          {props.contentTypes.data.length > 0 &&
          props.contentTypes.data.map((contentType, index) => {
            return (
              <Tab id={`tab_button_${contentType.name.toLowerCase()}`} label={contentType.name} sx={{ px: 12, color: 'text.primary', textTransform: 'capitalize' }} key={'label_' + contentType.uid} />
            );
          })}
        </Tabs>

        {props.contentTypes.data.length > 0 &&
        props.contentTypes.data.map((contentType, index) => {
          return (
            <TabPanel value={value} index={index} name={contentType.name} key={`tab_panel_${contentType.uid}`}>
              <Folders packName={contentType.name}
                       contentFolders={props.contentFolders}
                       controlModalIsOpened={(opened, uid) => handleControlModalIsOpened(opened, uid)}
                       handleSelectedPacksDeletion={() => handleSelectedPacksDeletion()}
                       contentFolder={props.contentFolder}
                       tenantId={clientId}
                       contentType={props.contentTypes.data[value]} />
            </TabPanel>
          );
        })}
      </Box>
    </Page>
  );
};

export default connect(stateToProps, actionsToProps)(KeyboardContent);
