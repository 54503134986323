import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import {useState} from "react";

const stateToProps = (state) => ({});

const actionToProps = (dispatch) => ({});

const OperatorSelector = ({filterName, availableOperators, handleOperatorChange, ...props}) => {
  const [selectedOperator, setSelectedOperator] = useState(availableOperators[0]);

  const handleChange = (event) => {
    setSelectedOperator(event.target.value);
    handleOperatorChange(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`${filterName}-operator-selector-label`}>operator</InputLabel>
      <Select labelId={`${filterName}-operator-selector-label`}
              id={`${filterName}-operator-selector-select`}
              label={filterName}
              value={selectedOperator}
              onChange={handleChange}>
        {availableOperators.map(availableOperator => (
          <MenuItem key={`operator-possible-value-${availableOperator}`} value={availableOperator}>{availableOperator}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default connect(stateToProps, actionToProps)(OperatorSelector);

