const getSimpleDailyDashboard = (state) => state.insights.sections.simpleDailyDashboard;
const getAdvancedDailyDashboard = (state) => {
  const { selectedBreakdownItem, data } = state.insights.sections.advancedDailyDashboard;

  // Grouping and summing up dynamically based on date and selectedBreakdownItem
  const groupedData = data.reduce((acc, obj) => {
    const date = obj.date;
    const breakdownValue = obj[selectedBreakdownItem];

    // Check if the group already exists for this date
    let group = acc.find(item => item.date === date);

    // If not, create a new group
    if (!group) {
      group = {
        date
      };
      acc.push(group);
    }

    // If breakdown value exists, add properties with breakdown prefix
    if (breakdownValue) {
      // Sum up values for the breakdown item
      Object.keys(obj).forEach(key => {
        // Skip the 'date' property and properties not relevant to the breakdown item,
        // and properties to be removed
        if (key === 'date' || key === selectedBreakdownItem || key === 'operating_system' || key === 'region' || key === 'country_code') return;

        const newKey = `${breakdownValue}___${key}`;
        group[newKey] = (group[newKey] || 0) + obj[key];
      });
    } else {
      // If breakdown value is null, directly merge properties into the group
      Object.keys(obj).forEach(key => {
        // Skip the 'date' property and properties to be removed
        if (key === 'date' || key === 'operating_system' || key === 'region' || key === 'country_code') return;

        group[key] = (group[key] || 0) + obj[key];
      });
    }

    return acc;
  }, []);

  // Return the state object with the updated grouped data
  return {
    ...state.insights.sections.advancedDailyDashboard,
    data: groupedData
  };
};
const getActiveUsersCount = (state) => state.insights.activeUsersCount;
const getRetentionDashboard = (state) => state.insights.sections.retentionDashboard;
const getInsightsSections = (state) => state.insights.sections;
const getDevices = (state) => state.insights.sections.devices;
const getFilters = (state) => state.insights.filters;
const getTenantId = (state) => state.clients.organization.data.id;
const getAudiences = (state) => state.insights.audiences;
const getCreateAudience = (state) => state.insights.createAudience;
const getUpdateAudience = (state) => state.insights.updateAudience;
const getDeleteAudience = (state) => state.insights.deleteAudience;
const getIntersectAudiences = (state) => state.insights.intersectAudiences;
const getCheckAudiencesIntersection = (state) => state.insights.checkAudiencesIntersection;
const getCustomInsights = (state) => state.insights.customInsights;
const getCreateCustomInsight = (state) => state.insights.createCustomInsight;
const getUpdateCustomInsight = (state) => state.insights.updateCustomInsight;
const getArchiveCustomInsight = (state) => state.insights.archiveCustomInsight;
const getUnarchiveCustomInsight = (state) => state.insights.unarchiveCustomInsight;
const getCustomInsightsTemplates = (state) => state.insights.customInsightsTemplates;
const getCustomInsightResults = (state) => state.insights.sections.customInsightsResults;

export {
  getSimpleDailyDashboard,
  getAdvancedDailyDashboard,
  getRetentionDashboard,
  getInsightsSections,
  getActiveUsersCount,
  getDevices,
  getFilters,
  getTenantId,
  getAudiences,
  getCreateAudience,
  getUpdateAudience,
  getDeleteAudience,
  getIntersectAudiences,
  getCheckAudiencesIntersection,
  getCustomInsights,
  getCreateCustomInsight,
  getUpdateCustomInsight,
  getArchiveCustomInsight,
  getUnarchiveCustomInsight,
  getCustomInsightsTemplates,
  getCustomInsightResults,
};