import {Box, Typography} from "@mui/material";
import SimpleRectangleVisSkeleton from "../../../../components/vis/SimpleRectangleVisSkeleton";
import React from "react";
import SingleTextVisSkeleton from "../../../../components/vis/SingleTextVisSkeleton";
import BlendedVis from "../../../../components/vis/BlendedVis";

const VisInstallsKaKoBlend = ({ sx, isLoading, isLoadingForALongTime, data, filters }) => {
  const leftAxisItems = ['app_installs', 'keyboard_activations'];
  const leftAxisRendition = "bar";
  const rightAxisItems = ['keyboard_opens'];
  const rightAxisRendition = "line";

  return (
    <Box sx={{ ...sx }}>
      <Typography sx={{ mb: 2, color: 'text.primary' }}>Installs, Keyboard Activations and Keyboard opens</Typography>

      {isLoading && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "400px" }}
                                    animation="wave"
                                    title={isLoadingForALongTime ? 'Almost finished...' : 'Loading...'} />
      )}

      {!isLoading && (!data || data.length === 0) && (
        <SimpleRectangleVisSkeleton sx={{  bgcolor: "background.paper", width: '100%' }}
                               animation={false}
                               title="No data" />
      )}

      {!isLoading && data && data.length > 0 && (
        <BlendedVis
          height={400}
          data={data}
          leftAxisSelectedItems={leftAxisItems}
          leftAxisRendition={leftAxisRendition}
          rightAxisSelectedItems={rightAxisItems}
          rightAxisRendition={rightAxisRendition}
          axisUseCommonYScale={false}
          x0ScaleValueAccessor={(d) => d.date}
          xAxisLabel="Date"
          yAxisLabel="Amount"
        />
      )}
    </Box>
  );
};

export default VisInstallsKaKoBlend;