import connect from "react-redux/es/connect/connect";
import {Box, useTheme} from "@mui/material";
import SimpleRectangleVisSkeleton from "../../../../components/vis/SimpleRectangleVisSkeleton";
import React from "react";
import HorizontalBar from "../../../../components/vis/HorizontalBar";

const stateToProps = ({ insights: {sections: { typedTerms } }}) => {
  return {
    isLoading: typedTerms.isLoading,
    isLoadingForALongTime: typedTerms.isLoadingForALongTime,
    data: typedTerms.data,
  };
};

function mapValues(data) {
  return data.map((d, i) => ({
    label: d.typed_terms,
    value: d.occurrences,
  }));
}

function VisTypedTerms({ sx = {}, isLoading, isLoadingForALongTime, data, filters }) {
  const theme = useTheme();
  const hasData = data && data.length > 0;

  const values = hasData && mapValues(data);

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "100%" }}
                                    animation="wave"
                                    title={isLoadingForALongTime ? "Typing - working" : "Typing"} />
      )}

      {!isLoading && !hasData && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "100%" }}
                                    animation={false}
                                    title="Typing - no data" />
      )}

      {!isLoading && hasData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <HorizontalBar title="Typing" width="550"
                         yLabel="Words" xLabel="Occurences"
                         data={values} filters={filters} color={theme.palette.vis.histogram.one} />
        </Box>
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisTypedTerms);