import connect from "react-redux/es/connect/connect";
import {
  Box, Button, CircularProgress, Drawer, Typography, Alert, AlertTitle,
  Accordion, AccordionSummary, AccordionDetails, Tooltip, Checkbox, FormControlLabel, IconButton, TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
  loadArchiveCustomInsight,
  loadCreateCustomInsight,
  loadCustomInsights,
  loadUpdateCustomInsight,
  loadCustomInsightsTemplates,
  loadUnarchiveCustomInsight
} from "../../store/actions";
import {
  getArchiveCustomInsight,
  getCreateCustomInsight,
  getCustomInsights,
  getCustomInsightsTemplates,
  getUnarchiveCustomInsight,
  getUpdateCustomInsight
} from "../../store/selectors";
import {Check, Circle, Close, DeleteOutlined, Edit, ExpandMore, RestoreFromTrash} from "@mui/icons-material";
import CustomInsightForm from "./CusotmInsightForm";
import {useFormik} from "formik";
import * as Yup from "yup";

const stateToProps = (state) => ({
  customInsights: getCustomInsights(state),
  createCustomInsight: getCreateCustomInsight(state),
  updateCustomInsight: getUpdateCustomInsight(state),
  archiveCustomInsight: getArchiveCustomInsight(state),
  unarchiveCustomInsight: getUnarchiveCustomInsight(state),
  customInsightsTemplates: getCustomInsightsTemplates(state),
});

const actionToProps = (dispatch) => ({
  loadCustomInsights: payload => dispatch(loadCustomInsights(payload)),
  loadCreateCustomInsight: payload => dispatch(loadCreateCustomInsight(payload)),
  loadUpdateCustomInsight: payload => dispatch(loadUpdateCustomInsight(payload)),
  loadArchiveCustomInsight: payload => dispatch(loadArchiveCustomInsight(payload)),
  loadUnarchiveCustomInsight: payload => dispatch(loadUnarchiveCustomInsight(payload)),
  loadCustomInsightsTemplates: payload => dispatch(loadCustomInsightsTemplates(payload)),
});

const CustomInsightsDrawer = ({ tenantId, isOpen, onClose, ...props }) => {
  const [showArchivedInsights, setShowArchivedInsights] = useState(false);
  const [customInsightUidToBeEdited, setCustomInsightUidToBeEdited] = useState(null);
  const [expandedCustomInsightUid, setExpandedCustomInsightUid] = React.useState(false);

  useEffect(() => {
    props.loadCustomInsightsTemplates();
  }, []);

  useEffect(() => {
    if( tenantId && props.customInsights.data.length === 0 ) {
      props.loadCustomInsights({ tenantId: tenantId });
    }
  }, [tenantId]);

  useEffect(() => {
    if( !props.updateCustomInsight.isLoading ) {
      customInsightUpdateFormik.resetForm();
      setCustomInsightUidToBeEdited(null);
      props.loadCustomInsights({ tenantId: tenantId });
    }
  }, [props.updateCustomInsight.isLoading]);

  const customInsightUpdateFormik = useFormik({
    initialValues: {
      customInsightName: '',
      validationSchema: Yup.object().shape({
        customInsightName: Yup.string().required('Insight name is required.')
          .min(1, 'Insight name must be at least 1 characters long')
          .max(64, 'Insight name cannot be longer than 64 characters'),
      })
    }
  });

  const handleArchiveCustomInsight = (customInsightUid) => {
    if( !props.archiveCustomInsight.isLoading && !props.unarchiveCustomInsight.isLoading ) {
      props.loadArchiveCustomInsight({
        tenantId: tenantId,
        customInsightUid: customInsightUid,
      });
    }
  };

  const handleUnarchiveCustomInsight = (customInsightUid) => {
    if( !props.archiveCustomInsight.isLoading && !props.unarchiveCustomInsight.isLoading ) {
      props.loadUnarchiveCustomInsight({
        tenantId: tenantId,
        customInsightUid: customInsightUid,
      });
    }
  };

  const handleEnterCustomInsightEditMode = (customInsight) => {
    setCustomInsightUidToBeEdited(customInsight.uid);
    customInsightUpdateFormik.setFieldValue('customInsightName', customInsight.model_name);
  };

  const handleCustomInsightUpdateSave = () => {
    customInsightUpdateFormik.validateForm().then((errors) => {
      if( Object.keys(errors).length !== 0 ) {
        return;
      }

      props.loadUpdateCustomInsight({
        tenantId: tenantId,
        customInsightUid: customInsightUidToBeEdited,
        ...customInsightUpdateFormik.values,
      });
    })
  }

  const handleAccordionChange = (customInsightUid) => {
    if( !customInsightUidToBeEdited ) {
      setExpandedCustomInsightUid(customInsightUid === expandedCustomInsightUid ? false : customInsightUid);
    }
  }

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
    >
      <Box sx={{ p: 4 }} style={{ width: '400px' }}>
        <Alert severity="info" sx={{ mb: 6, mt: 2 }}>
          <AlertTitle>Custom Insights</AlertTitle>
          Custom Insights are a way you can interrogate your keyboard's data through a written prompt and obtain the insightful conclusions of our AI.
        </Alert>

        {props.customInsights.isLoading && (
          <Box sx={{ mt: 3, width: 1, display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <CircularProgress id="spinner_custom_insights" size="50px" color="primary" />
          </Box>
        )}

        <Box sx={{ my: 3, width: 1, display: "flex" }}>
          <FormControlLabel control={
              <Checkbox onChange={() => setShowArchivedInsights(!showArchivedInsights)}
                        checked={showArchivedInsights}
                        name="archived_insights_show_toggle" />
            }
            label="Show archived insights"
          />
        </Box>

        <Box>
          {!props.customInsights.isLoading && props.customInsights.data.filter(ci => !ci.is_archived || showArchivedInsights).map(customInsight => (
            <Accordion key={customInsight.uid} expanded={expandedCustomInsightUid === customInsight.uid} onChange={() => handleAccordionChange(customInsight.uid)}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`${customInsight.uid}_content`}
                id={`${customInsight.uid}_header`}
              >
                {(!customInsightUidToBeEdited || customInsight.uid !== customInsightUidToBeEdited) && (
                  <>
                    {!!customInsight.is_archived && (
                      <Tooltip title="Insight is archived" placement="top-start">
                        <Circle sx={{ color: 'secondary.main', mr: 1 }}/>
                      </Tooltip>
                    )}

                    {!customInsight.is_archived && !!customInsight.is_done && (
                      <Tooltip title="Insight was processed and will soon appear on your dashboard" placement="top-start">
                        <Circle sx={{ color: 'success.main', mr: 1 }}/>
                      </Tooltip>
                    )}

                    {!customInsight.is_archived && !customInsight.is_done && (
                      <Tooltip title="Insight not processed yet" placement="top-start">
                        <Circle sx={{ color: 'danger.main', mr: 1 }}/>
                      </Tooltip>
                    )}
                  </>
                )}

                {customInsightUidToBeEdited && customInsight.uid === customInsightUidToBeEdited && (
                  <form noValidate onSubmit={customInsightUpdateFormik.handleSubmit}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TextField error={Boolean(customInsightUpdateFormik.touched.customInsightName && customInsightUpdateFormik.errors.customInsightName)}
                                 helperText={customInsightUpdateFormik.touched.customInsightName && customInsightUpdateFormik.errors.customInsightName}
                                 label="Custom insight name"
                                 margin="normal"
                                 name="customInsightName"
                                 onBlur={customInsightUpdateFormik.handleBlur}
                                 onChange={customInsightUpdateFormik.handleChange}
                                 type="text"
                                 value={customInsightUpdateFormik.values.customInsightName}
                                 variant="outlined"  />

                      {!props.updateCustomInsight.isLoading && (
                        <>
                          <IconButton aria-label="submit"
                                      color="secondary.dark"
                                      sx={{ ml: 4 }}
                                      onClick={handleCustomInsightUpdateSave}
                                      disabled={!customInsightUpdateFormik.touched.customInsightName}>
                            <Check />
                          </IconButton>

                          <IconButton aria-label="cancel" color="secondary.dark" onClick={() => setCustomInsightUidToBeEdited(null)}>
                            <Close />
                          </IconButton>
                        </>
                      )}
                    </Box>
                  </form>
                )}

                {(!customInsightUidToBeEdited || customInsight.uid !== customInsightUidToBeEdited) && (
                  <Box sx={{ display: 'flex', alignItems: 'center', '&:hover .edit-icon': {visibility: 'visible'} }}>
                    <Typography sx={{ ml: 2 }}>{customInsight.model_name}</Typography>

                    {expandedCustomInsightUid === customInsight.uid && (
                      <IconButton className="edit-icon" aria-label="edit" color="secondary.dark" sx={{ ml: 4, visibility: 'hidden' }} onClick={() => handleEnterCustomInsightEditMode(customInsight)}>
                        <Edit />
                      </IconButton>
                    )}
                  </Box>
                )}
              </AccordionSummary>

              <AccordionDetails>
                <Typography variant="body2">
                  {customInsight.display_prompt}
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                  {customInsight.is_archived === 0 && (
                    <Tooltip title="Archived Insights will no longer show on your dashboard. You can undo this operation at any time.">
                      <Button size="small" color="danger" onClick={() => handleArchiveCustomInsight(customInsight.uid)}>
                        {(props.archiveCustomInsight.isLoading || props.unarchiveCustomInsight.isLoading) && (
                          <CircularProgress color="danger" size="20px" sx={{ mr: 2 }} />
                        )}
                        {(!props.archiveCustomInsight.isLoading && !props.unarchiveCustomInsight.isLoading) && (
                          <DeleteOutlined color="danger" size="20px" sx={{ mr: 2 }} />
                        )}
                        Archive Insight
                      </Button>
                    </Tooltip>
                  )}

                  {customInsight.is_archived === 1 && (
                    <Tooltip title="Insight will be shown on your dashboard again. You can archive it back at any time.">
                      <Button size="small" color="primary" onClick={() => handleUnarchiveCustomInsight(customInsight.uid)}>
                        {(props.archiveCustomInsight.isLoading || props.unarchiveCustomInsight.isLoading) && (
                          <CircularProgress color="primary" size="28px" sx={{ mr: 2 }} />
                        )}
                        {(!props.archiveCustomInsight.isLoading && !props.unarchiveCustomInsight.isLoading) && (
                          <RestoreFromTrash color="primary" size="28px" sx={{ mr: 2 }} />
                        )}
                        Unarchive Insight
                      </Button>
                    </Tooltip>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <CustomInsightForm tenantId={tenantId}
                           customInsightsTemplates={props.customInsightsTemplates}
                           createCustomInsight={props.createCustomInsight}
                           loadCreateCustomInsight={props.loadCreateCustomInsight} />
      </Box>
    </Drawer>
  );
};

export default connect(stateToProps, actionToProps)(CustomInsightsDrawer);