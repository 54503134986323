import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import {Box, Typography} from "@mui/material";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';


function UploadBox(props) {
  const [acceptedArr, setAcceptedArr] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop(acceptedFiles, rejectedFiles, event) {
      acceptedFiles.map((file) => {
        setAcceptedArr(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      });

      props.addFiles(acceptedFiles);
    },
  });

  if( props.small ) {
    return (
      <Box sx={{ width: 1,
                 display: 'flex',
                 justifyContent: 'center',
                 alignItems: 'center',
                 border: 1,
                 borderStyle: 'dashed',
                 borderColor: 'primary.main',
                 bgcolor: 'primary.lightest',
                 borderRadius: 1,
                 flexDirection: 'column',
                 py: 2,
                 my: 8 }}>
        <Box {...getRootProps({})}
             sx={{ width: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
          <input id="input_upload_box" {...getInputProps()} />

          <FolderOpenIcon color="primary" sx={{ fontSize: '2rem' }} />

          <Typography color="text.secondary" sx={{ m: 2, textAlign: "center" }}>
            Drag & drop files or
          </Typography>

          <Typography color="primary" sx={{  cursor: "pointer" }}>
            Upload
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ height: 256,
               width: 512,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               border: 1,
               borderStyle: 'dashed',
               borderColor: 'primary.main',
               bgcolor: 'primary.lightest',
               borderRadius: 1,
               flexDirection: 'column',
               margin: '13% auto' }}>
      <Box {...getRootProps({})}
           sx={{ width: 1,height: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <input id="input_upload_box" {...getInputProps()} />

        <FolderOpenIcon color="primary" sx={{ fontSize: '2rem' }} />

        <Typography color="text.secondary" sx={{ my: 4, width: "100%", textAlign: "center" }}>
          Drag & drop files or
        </Typography>

        <Typography color="primary" sx={{  cursor: "pointer" }}>
          Upload
        </Typography>
      </Box>
    </Box>
  );
}

export default UploadBox;
