import connect from "react-redux/es/connect/connect";
import {
  Box,
  Checkbox, CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
} from "@mui/material";
import React, {useEffect} from "react";
import {loadQuicklinks} from "../store/actions";
import {Android, Apple} from "@mui/icons-material";

const stateToProps = state => ({
});

const actionsToProps = dispatch => ({
  loadQuicklinks: data => dispatch(loadQuicklinks(data)),
});

const CampaignSetup = ({campaignSetupFormik, quicklinks, errors, ...props}) => {
  useEffect(() => {
    if( quicklinks.length === 0 ) {
      props.loadQuicklinks();
    }
  }, []);

  return (
    <form noValidate onSubmit={campaignSetupFormik.handleSubmit}>
      <Grid container={true} spacing={2}>
        <Grid item xs={12}>
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" error={Boolean(campaignSetupFormik.errors.platforms)}>
            <FormLabel component="legend">Which platforms does your app run on?</FormLabel>

            <FormGroup>
              <FormControlLabel control={<Checkbox name="platforms"
                                                   value="ios"
                                                   icon={<Apple sx={{ color: "secondary.main" }} />}
                                                   checkedIcon={<Apple color="primary" />}
                                                   checked={campaignSetupFormik.values.platforms.includes('ios')}
                                                   onChange={(event) => {
                                                     campaignSetupFormik.handleChange(event);
                                                   }} />}
                                label="iOS" />

              <FormControlLabel control={<Checkbox name="platforms"
                                                   value="android"
                                                   icon={<Android sx={{ color: "secondary.main" }} />}
                                                   checkedIcon={<Android color="primary" />}
                                                   checked={campaignSetupFormik.values.platforms.includes('android')}
                                                   onChange={(event) => {
                                                     campaignSetupFormik.handleChange(event);
                                                   }} />}
                                label="Android" />
            </FormGroup>

            <FormHelperText>{campaignSetupFormik.errors.platforms}</FormHelperText>
          </FormControl>

          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">What are the countries you're more interested in?</FormLabel>

            {quicklinks.length !== 0 && (
              <FormGroup>
                {quicklinks.map(quicklink => (
                  <FormControlLabel control={<Checkbox name='quicklinkSets'
                                                       value={quicklink.country_code}
                                                       checked={campaignSetupFormik.values.quicklinkSets.includes(quicklink.country_code)}
                                                       onChange={campaignSetupFormik.handleChange} />}
                                    label={quicklink.country_name}
                                    key={quicklink.country_code} />
                ))}
              </FormGroup>
            )}

            {quicklinks.length === 0 && (
              <Box sx={{ m: 4 }}>
                <CircularProgress id="spinner_preview_upload_item" size="26px" color="primary" />
              </Box>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default connect(stateToProps, actionsToProps)(CampaignSetup);