const loginIsLoading = state => state.auth.login.isLoading;
const loginStatusCode = state => state.auth.login.statusCode;
const loginXsrfToken = state => state.auth.login.xsrfToken;
const loginSessionId = state => state.auth.login.sessionId;
const loginAuthenticated = state => state.auth.login.authenticated;
const loginMsg = state => state.auth.login.msg;
const loginErrors = state => state.auth.login.errors;
const loginEmailVerified = state => state.auth.login.emailVerified;

const passwordConfirmationIsLoading = state => state.auth.passwordConfirmation.isLoading;
const passwordConfirmed = state => state.auth.passwordConfirmation.passwordConfirmed;
const passwordConfirmationErrors = state => state.auth.passwordConfirmation.errors;

const twoFactorIsLoading = state => state.auth.twoFactor.isLoading;
const twoFactorInitialized = state => state.auth.twoFactor.initialized;
const twoFactorQrCodeIsLoading = state => state.auth.twoFactor.qrCodeIsLoading;
const twoFactorQrCode = state => state.auth.twoFactor.qrCode;
const twoFactorConfirmed = state => state.auth.twoFactor.confirmed;
const twoFactorErrors = state => state.auth.twoFactor.errors;

const registrationIsLoading = state => state.auth.registration.isLoading;
const registrationErrors = state => state.auth.registration.errors;

const resendEmailConfirmationIsLoading = state => state.auth.emailConfirmationResend.isLoading;
const resendEmailConfirmationResent = state => state.auth.emailConfirmationResend.resent;

export {
  loginIsLoading,
  loginStatusCode,
  loginXsrfToken,
  loginSessionId,
  loginAuthenticated,
  loginMsg,
  loginErrors,
  loginEmailVerified,
  passwordConfirmationIsLoading,
  passwordConfirmed,
  passwordConfirmationErrors,
  twoFactorIsLoading,
  twoFactorInitialized,
  twoFactorQrCodeIsLoading,
  twoFactorQrCode,
  twoFactorConfirmed,
  twoFactorErrors,
  registrationIsLoading,
  registrationErrors,
  resendEmailConfirmationIsLoading,
  resendEmailConfirmationResent,
};
