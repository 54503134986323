import React from "react";
import connect from "react-redux/es/connect/connect";
import {Box} from "@mui/material";

import Bar from "@/components/vis/Bar";
import SimpleRectangleVisSkeleton from "../../../../components/vis/SimpleRectangleVisSkeleton";

const stateToProps = ({ insights: {sections: { keyboardOpens } }}) => {
  return {
    isLoading: keyboardOpens.isLoading,
    isLoadingForALongTime: keyboardOpens.isLoadingForALongTime,
    data: keyboardOpens.data?.avg_keyboard_opens?.value,
  };
};

function formatLabel(s) {
  return s.replace(`_to_`, ` - `);
}

function mapData(data) {
  const sortOrder = {
    "1_to_20": 1,
    "20_to_40": 2,
    "40_to_60": 3,
    "60_to_80": 4,
    "80_to_100": 5,
    "100_to_120": 6,
    "120_plus": 7
  };

  return data
    .sort((a,b) => (sortOrder[a.category] > sortOrder[b.category]) ? 1 : ((sortOrder[b.category] > sortOrder[a.category]) ? -1 : 0))
    .map((d) => ({
      label: formatLabel(d.category),
      value: d.percentage,
    }));
}

function VisKeyboardOpens({ sx = {}, isLoading, isLoadingForALongTime, data, filters }) {
  const hasData = data && data.length > 0;

  return (
    <Box sx={{ display: "flex", bgcolor: "background.paper", ...sx }}>
      <Box sx={{ width: "100%", height: "100%" }}>
        {isLoading && (
          <SimpleRectangleVisSkeleton animation="wave" sx={{ width: "100%", height: "100%" }}
                                      title={isLoadingForALongTime ? "Number of keyboard opens - working" : "Number of keyboard opens"} />
        )}

        {!isLoading && !hasData && (
          <SimpleRectangleVisSkeleton animation={false} sx={{ width: "100%", height: "100%", bgcolor: "background.paper" }}
                                      title="Number of keyboard opens - no data" />
        )}

        {!isLoading && hasData && (
          <Bar title="Keyboard opens" height="400"
               xLabel="Number of keyboard opens" yLabel="% of users"
               data={mapData(data)} filters={filters} />
        )}
      </Box>
    </Box>
  );
}

export default connect(stateToProps)(VisKeyboardOpens);
