import connect from "react-redux/es/connect/connect";
import {Box, CircularProgress, IconButton, InputBase} from "@mui/material";
import GifBoxIcon from '@mui/icons-material/GifBox';
import React from "react";
import {Check} from "@mui/icons-material";
import {contentItem} from "../store/selectors";
import {setLinkedItemUrl} from "../store/actions";

const stateToProps = (state) => ({
  contentItem: contentItem(state),
});

const actionsToProps = (dispatch) => ({
  setUrl: (url) => dispatch(setLinkedItemUrl({url})),
});

const LinkedUploadBox = (props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {props.contentItem.isLoading ?
        <Box>
          <CircularProgress id="spinner_new_linked_item_preview" size="56px" color="primary"/>
        </Box> :
        <Box>
          {props.contentItem.validationPassed ?
              <img id="img_new_liked_asset_preview"
                   src={props.contentItem.data.preview_url}
                   style={{ width: 56, height: 56, objectFit: 'cover' }} /> :
              <GifBoxIcon id="icon_gif_new_linked_item" sx={{width: 56, height: 56, color: 'tertiary.main'}}/>}
        </Box>}

        <InputBase id="input_new_linked_item"
                   type="text"
                   variant="outlined"
                   size={"small"}
                   value={props.contentItem.data.url}
                   sx={{ bgcolor: 'background.default',
                         border: 1,
                         borderRadius: 0.5,
                         borderColor: props.contentItem.validationPassed || props.contentItem.validationPassed == null ? 'secondary.dark' : 'danger.main',
                         color: 'text.secondary',
                         px: 4,
                         py: 1.5,
                         width: 1,
                         ml: 3,
                         '&:focus': {
                           borderColor: 'secondary.main',
                         }
                   }}
                   onChange={(event) => {props.setUrl(event.target.value)}}
                   onBlur={(event) => {props.checkLinkedFile(event.target.value)}} />

        {props.addLinkedFile &&
          <Box sx={{ width: 68  , ml: 8 }}>
            {props.contentItem.isUploading ?
              <CircularProgress id="spinner_new_linked_item_confirm" size="28px" color="primary"/> :
              <IconButton id="button_new_linked_item_confirm" color="primary" aria-label="store"
                          disabled={props.contentItem.validationPassed !== true}
                          onClick={() => props.addLinkedFile(props.contentItem.data)}>
                <Check sx={{ fontSize: 28 }} />
              </IconButton>}
          </Box>
        }
    </Box>
  );
}

export default connect(stateToProps, actionsToProps)(LinkedUploadBox)