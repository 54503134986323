import connect from "react-redux/es/connect/connect";
import {Box, Typography} from "@mui/material";
import {Circle} from "@mui/icons-material";

const actionsToProps = dispatch => ({});

const stateToProps = state => ({});

const DiffAssetsCollection = ({title, assets, kind, ...props}) => {
  return (
    <Box>
      {assets.length > 0 ? (
        <Box sx={{ display: 'flex', itemsAlign: 'center' }}>
          <Circle sx={{ mr: 2, color: 'danger.main' }} />
          <Typography id={`label_diff_${kind}_${title}`}>{kind.capitalize()} {title}</Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', itemsAlign: 'center' }}>
          <Circle sx={{ mr: 2, color: 'success.main' }} />
          <Typography id={`label_diff_${kind}_${title}`}>No {kind} {title}</Typography>
        </Box>
      )}

      <Box sx={{ mt: 1, display: 'flex', width: 1, flexWrap: 'wrap', overflowX: 'hidden' }}>
        {assets.map((asset, index) => {
          return (
            <Box key={`${index}_${asset.uid}`} sx={{ height: 56, m: 1 }}>
              <img id={`img_diff_${kind}_${title}_${index}`}
                   src={title === 'vibes' ? asset.thumbnail : asset.previewImageUrl}
                   style={{ width: '56px', objectFit: 'cover' }}  />
            </Box>
          );
        })}
      </Box>
    </Box>
  )
}

export default connect(stateToProps, actionsToProps)(DiffAssetsCollection);