import {setFilters} from "../../store/actions";
import connect from "react-redux/es/connect/connect";
import React, {useState} from "react";
import {
  Box,
  Button, CircularProgress,
  FormControl,
  InputLabel, MenuItem,
  Paper,
  Select,
  Typography
} from "@mui/material";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

const stateToProps = (state) => {
  return {};
};


const UserFilter = ({sx = {}, announceFilterChanges, setFilters, audiences}) => {
  const [selectedAudienceUid, setSelectedAudienceUid] = useState('');

  return (
    <Box sx={{ ...sx }}>
      <Paper sx={{ p: 4, display: "flex", flexDirection: "column" }}>
        <Typography>
          Do you want to track a particular group of users? Upload or build an audience and select it here so we can create their profile for you.
          This way you can target more of the same or connect other potential users. All insights will be changed to reflect that audience's behavior.
        </Typography>

        {!audiences.isLoading && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 4 }}>
            <FormControl sx={{ width: '250px' }}>
              <InputLabel id="audience-uid-select-label">Audience</InputLabel>
              <Select
                labelId="audience-uid-select-label"
                id="audience-uid-select"
                value={selectedAudienceUid}
                label="Audience"
                onChange={e => {
                  setSelectedAudienceUid(e.target.value);
                  setFilters("audience_uid", e.target.value);
                }}
              >
                {audiences.data && audiences.data.filter(a => !a.is_under_construction).map(audience => (
                  <MenuItem key={audience.uid} value={audience.uid}>{audience.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ display: 'flex' }}>
              <Button variant="contained" sx={{ py: 3, px: 6 }} startIcon={<AutoGraphIcon />}
                      onClick={e => announceFilterChanges()}
                      disabled={!selectedAudienceUid}>
                Generate
              </Button>

              <Button variant="text" sx={{ py: 3, px: 6, ml: 4 }} startIcon={<RotateLeftIcon />}
                      onClick={e => {
                        setSelectedAudienceUid('');
                        setFilters("audience_uid", null);
                        announceFilterChanges();
                      }}>
                Reset
              </Button>
            </Box>
          </Box>
        )}

        {audiences.isLoading && (
          <CircularProgress sx={{ ml: 2 }} size="26px" color="secondary" />
        )}
      </Paper>
    </Box>
  );
};

export default connect(stateToProps)(UserFilter);