import {Box, CircularProgress, IconButton, Input, TextField, Typography} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import {loadCheckCompoundItem, setCheckCompoundItem} from "../store/actions";
import {Check, CheckCircle, KeyboardAltOutlined, Warning} from "@mui/icons-material";
import {additionalContentItems, contentItem, previewContentItem} from "../store/selectors";
import zipCrawler from "../../../utils/zipCrawler";

const stateToProps = (state) => ({
  mainContentItem: contentItem(state),
  previewContentItem: previewContentItem(state),
  additionalContentItems: additionalContentItems(state),
});

const actionToProps = (dispatch) => ({
  loadCheckCompoundItem: data => dispatch(loadCheckCompoundItem(data)),
  loadSetCompoundItem: data => dispatch(setCheckCompoundItem(data)),
});

const ThemeUploadBox = (props) => {
  let fileInputRef = React.createRef();

  const handleOnChangeFile = (event) => {
    let zip = event.target.files[0];
    fileInputRef.current.value = null;

    let report = {
      mainItem: {},
      mainItemPasses: false,
      mainItemMessage: '',
      previewItem: {},
      previewItemPasses: false,
      previewItemMessage: '',
      all: [],
    };

    props.loadCheckCompoundItem();

    zipCrawler(zip).then((crawlResult) => {
      props.loadSetCompoundItem({
        ...report,
        mainItem: crawlResult.themeZip,
        mainItemPasses: true,
        mainItemMessage : '',
        previewItem: crawlResult.cmsPreview,
        previewItemPasses: true,
        previewItemMessage: '',
        restOfTheItems: crawlResult.restOfTheFiles,
        themeVersion: crawlResult.themeVersion,
      });

      return crawlResult.cmsPreview;
    }).catch(e => {
      if( e.name === 'PreviewException' ) {
        props.loadSetCompoundItem({
          ...report,
          mainItemPasses: true,
          previewItemPasses: false,
          previewItemMessage: e.message,
          restOfTheItems: []
        });
      } else {
        props.loadSetCompoundItem({
          ...report,
          mainItemMessage : e.message,
          previewItemMessage: 'Could not read the archive',
          restOfTheItems: []
        });
      }
    });
  }

  const addCompoundItem = () => {
    props.addCompoundItem({
      zip: props.mainContentItem.data,
      preview: props.previewContentItem.data,
      additional: props.additionalContentItems.map(item => item.data),
    })
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ width: 1/2, display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontWeight: 'medium', mb: 3 }}>New theme</Typography>

        <Box sx={{ display: 'flex', flex: '1 1 0%' }}>
          <Box sx={{ display: 'flex', flex: '1 1 0%', alignItems: 'center', position: 'relative' }}>
            <TextField id="input_new_theme_name" label=".zip file" size="normal" fullWidth disabled
                       sx={{
                         '& fieldset': { borderRadius: '3px' },
                         '& p': { bgcolor: 'background.dark', m: 0, px: 4 },
                         '& input': { pr: 12 },
                         bgcolor: 'white'
                       }}
                       value={ props.mainContentItem.data.name }
                       error={ !props.mainContentItem.isLoading && !props.mainContentItem.validationPassed && props.mainContentItem.message !== '' }
                       helperText={ (!props.mainContentItem.isLoading && !props.mainContentItem.validationPassed && props.mainContentItem.message !== '') ? props.mainContentItem.message : '' } />

            <label htmlFor="icon-button-file">
              <Input id="input_new_theme_file"
                     sx={{ display: 'none' }} accept="image/*" id="icon-button-file" type="file"
                     onChange={event => handleOnChangeFile(event)}
                     inputProps={{ref: fileInputRef}} />

              <IconButton id="button_new_theme_file_upload" color="primary"
                          aria-label="upload picture" component="span" sx={{ position: 'absolute', right: 8, top: 8 }}>
                <FolderIcon />
              </IconButton>
            </label>
          </Box>

          <Box sx={{ mx: 4, mt: 0.25 }}>
            {(props.mainContentItem.isLoading || props.mainContentItem.validationPassed == null) ?
              (
                <Box sx={{bgcolor: 'background.default', p: 3, borderRadius: '3px'}}>
                  <Warning id="icon_warning_neutral_new_theme" sx={{color: "secondary.main", fontSize: 24}}/>
                </Box>
              ) :
              (
                <Box sx={{bgcolor: 'background.default', p: 3, borderRadius: '3px'}}>
                  {!props.mainContentItem.validationPassed ? (
                    <Warning id="icon_warning_new_theme" sx={{color: "danger.main", fontSize: 24}}/>
                  ) : (
                    <CheckCircle id="icon_check_new_theme" sx={{color: 'success.main', fontSize: 24}}/>
                  )}
                </Box>
              )
            }
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {props.previewContentItem.isLoading ?
          <Box sx={{ width: 224, height: 169, bgcolor: 'white', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress id="spinner_new_theme_preview" sx={{ mt: 8.5 }} size="56px" color="primary"/>
          </Box> :
          <Box sx={{ width: 224, height: 169, display: 'flex', justifyContent: 'center', bgcolor: 'white' }}>
            {props.previewContentItem.validationPassed  ?
              <Box sx={{ position: 'relative' }}>
                {props.mainContentItem &&
                    <Box sx={{
                      position: 'absolute',
                      bottom: 0,
                      right: '-4px',
                      px: 1,
                      bgcolor: 'background.paper',
                      opacity: 0.9,
                      borderRadius: 1,
                    }}>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>v{props.mainContentItem.data.version}</Typography>
                    </Box>
                }

                <img id="image_new_theme_preview"
                     src={props.previewContentItem.data.preview_url} style={{  width: 224, height: 169, objectFit: 'cover' }} />
              </Box> :
              <Box>
                {props.previewContentItem.message === '' ?
                  <KeyboardAltOutlined id="icon_neutral_new_theme_preview"
                                       sx={{ mt: 14, width: 48, height: 48, color: 'secondary.main' }}/> :
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <KeyboardAltOutlined id="icon_warning_new_theme_preview"
                                         sx={{ mt: 14, width: 48, height: 48, color: 'danger.main' }}/>
                    <Typography id="label_new_theme_validation_message"
                                sx={{ fontSize: '0.75rem', px: 4, color: 'danger.main' }}>{ props.previewContentItem.message }</Typography>
                  </Box>}
              </Box>
            }
          </Box>
        }

        <Box sx={{ width: 68  , ml: 8 }}>
          {props.previewContentItem.isUploading ?
            <CircularProgress id="spinner_new_theme_confirm" size="28px" color="primary"/> :
            <IconButton id="button_new_theme_confirm" color="primary" aria-label="store"
                        disabled={props.previewContentItem.validationPassed !== true}
                        onClick={addCompoundItem} >
              <Check sx={{ fontSize: 28 }} />
            </IconButton>}
        </Box>
      </Box>
    </Box>
  );
};

export default connect(stateToProps, actionToProps)(ThemeUploadBox);
