import connect from "react-redux/es/connect/connect";
import GuestPage from "../../../components/GuestPage";
import {Box, Card, CardContent, CircularProgress, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import Logo from "../../../components/vectors/Logo";
import React, {useEffect, useState} from "react";
import {loadVerifyEmail} from "../store/actions";

const stateToProps = state => ({
});

const actionsToProps = dispatch => ({
  loadVerifyEmail: data => dispatch(loadVerifyEmail(data)),
});

const VerifyEmail = (props) => {
  const [willVerify, setWillVerify] = useState(false);

  useEffect(() => {
    const url = (new URLSearchParams(props.location.search)).get('url');

    try {
      if( !url ) {
        return false;
      }

      const decodedUrl = atob(url);
      const urlObj = new URL(decodedUrl);

      if( urlObj.origin !== process.env.REACT_APP_API_HOST || !urlObj.pathname.startsWith('/api/email/verify') ) {
        return false;
      }

      setWillVerify(true);

      props.loadVerifyEmail({
        url: decodedUrl,
      });
    } catch (ex) {
      return false;
    }
  }, []);

  return (
    <GuestPage title="Two Factor Setup">
      <Box sx={{ maxWidth:'sm', mx: 'auto', mt: {lg: '20%'} }}>
        <Box mb={8} display="flex" justifyContent="center">
          <RouterLink to="/">
            <Logo/>
          </RouterLink>
        </Box>

        <Card variant="outlined">
          <CardContent>
            {willVerify && (
              <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h5" sx={{ mr: 2 }}>Verifying...</Typography>

                <CircularProgress size="26px" color="secondary" />
              </Box>
            )}

            {!willVerify && (
              <Box>
                <Typography sx={{ mr: 2 }} color="textSecondary">We cannot perform the verification. You probably ended up here by doing shady things...</Typography>

                <Box sx={{ mt: 2,  display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Typography sx={{ mt: 2 }} variant="h5" >Redirecting...</Typography>
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </GuestPage>
  );
};

export default connect(stateToProps, actionsToProps)(VerifyEmail);