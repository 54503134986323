import {createAction} from 'redux-actions';

import * as USER from './types';

const loadMsg = createAction(USER.LOAD_MSG);
const setMsg = createAction(USER.SET_MSG);
const userLoadInfo = createAction(USER.USER_LOAD_INFO);
const userLoadUpdateInfo = createAction(USER.USER_LOAD_UPDATE_INFO);
const userLoadNotifications = createAction(USER.USER_LOAD_NOTIFICATIONS);
const userLoadNotificationsConsume = createAction(USER.USER_LOAD_NOTIFICATIONS_CONSUME, data => data);
const userLoadUpdateProfile = createAction(USER.USER_LOAD_UPDATE_PROFILE, data => data);

const userLoadChannels = createAction(USER.USER_LOAD_CHANNELS, data => data);
const userLoadOtherChannels = createAction(USER.USER_LOAD_OTHER_CHANNELS, data => data);
const userLoadCreateChannels = createAction(USER.USER_LOAD_CREATE_CHANNELS, data => data);
const userLoadChannelsDetails = createAction(USER.USER_LOAD_CHANNELS_DETAILS, data => data);
const userLoadUpdateChannels = createAction(USER.USER_LOAD_UPDATE_CHANNELS, data => data);
const userLoadDeleteChannels = createAction(USER.USER_LOAD_DELETE_CHANNELS, data => data);
const userLoadUnmappedChannels = createAction(USER.USER_LOAD_UNMAPPED_CHANNELS, data => data);
const userLoadConnectChannels = createAction(USER.USER_LOAD_CONNECT_CHANNELS, data => data);
const userLoadConnectChannelsDetails = createAction(USER.USER_LOAD_CONNECT_CHANNELS_DETAILS, data => data);
const userLoadUpdateCampaign = createAction(USER.USER_LOAD_UPDATE_CAMPAIGN, data => data);
const userLoadStatsLast7Days = createAction(USER.USER_LOAD_STATS_LAST_7DAYS, data => data);
const userLoadStatsLast28Days = createAction(USER.USER_LOAD_STATS_LAST_28DAYS, data => data);
const userLoadStatsLastMonth = createAction(USER.USER_LOAD_STATS_LAST_MONTH, data => data);
const userLoadSeries = createAction(USER.USER_LOAD_SERIES, data => data);
const userLoadDataSeries = createAction(USER.USER_LOAD_DATA_SERIES, data => data);
const userLoadStatsLeaderboard = createAction(USER.USER_LOAD_STATS_LEADERBOARD, data => data);
const userLoadCountrySeries = createAction(USER.USER_LOAD_COUNTRY_SERIES, data => data);
const userLoadResetDataSeries = createAction(USER.USER_LOAD_RESET_DATA_SERIES, data => data);
const userLoadResetCountrySeries = createAction(USER.USER_LOAD_RESET_COUNTRY_SERIES, data => data);
const userLoadResetOtherChannels = createAction(USER.USER_LOAD_RESET_OTHER_CHANNELS);
const userLoadResetChannels = createAction(USER.USER_LOAD_RESET_CHANNELS);
const userLoadResetChannelsDetails = createAction(USER.USER_LOAD_RESET_CHANNELS_DETAILS);
const userLoadResetChannelsUpdate = createAction(USER.USER_LOAD_RESET_CHANNELS_UPDATE);
const userLoadResetChannelsCreate = createAction(USER.USER_LOAD_RESET_CHANNELS_CREATE);
const userLoadResetChannelsDelete = createAction(USER.USER_LOAD_RESET_CHANNELS_DELETE);
const userLoadResetConnect = createAction(USER.USER_LOAD_RESET_CONNECT);
const userLoadResetMsg = createAction(USER.USER_LOAD_RESET_MSG);

export {
  loadMsg,
  setMsg,
  userLoadInfo,
  userLoadUpdateInfo,
  userLoadNotifications,
  userLoadNotificationsConsume,
  userLoadUpdateProfile,
  userLoadChannels,
  userLoadOtherChannels,
  userLoadCreateChannels,
  userLoadChannelsDetails,
  userLoadUpdateChannels,
  userLoadDeleteChannels,
  userLoadUnmappedChannels,
  userLoadConnectChannels,
  userLoadConnectChannelsDetails,
  userLoadUpdateCampaign,
  userLoadStatsLast7Days,
  userLoadStatsLast28Days,
  userLoadStatsLastMonth,
  userLoadSeries,
  userLoadDataSeries,
  userLoadCountrySeries,
  userLoadResetCountrySeries,
  userLoadResetDataSeries,
  userLoadStatsLeaderboard,
  userLoadResetOtherChannels,
  userLoadResetChannels,
  userLoadResetChannelsDetails,
  userLoadResetChannelsUpdate,
  userLoadResetChannelsCreate,
  userLoadResetChannelsDelete,
  userLoadResetConnect,
  userLoadResetMsg,
}
