import React from 'react';
import {useTheme} from "@mui/material";

const Logo = (props) => {
  const theme = useTheme();
  const fillColor = props.fillColor ? props.fillColor : theme.palette.primary.main;
  const secondaryFillColor = theme.palette.primary.darkest;

  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         height="48px" viewBox="0 0 1600.000000 790.000000"
         preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,790.000000) scale(0.100000,-0.100000)"
         fill={fillColor} stroke="none">
        <path d="M12105 7890 c-593 -49 -1105 -446 -1303 -1011 -59 -169 -77 -275 -77
-469 0 -198 11 -264 77 -468 43 -130 151 -358 215 -454 58 -86 1305 -1558
1320 -1558 11 0 13 125 13 686 0 474 3 685 10 682 6 -2 60 -98 119 -213 l108
-210 964 -3 c669 -2 999 1 1079 8 365 37 697 199 949 466 441 466 541 1158
250 1725 -182 354 -495 622 -874 748 -119 39 -220 59 -363 71 -136 11 -2350
11 -2487 0z m291 -1185 c115 -34 199 -123 230 -244 41 -162 -48 -333 -205
-392 -79 -30 -194 -24 -269 15 -100 51 -164 138 -181 248 -39 243 189 443 425
373z m2086 -13 c70 -30 141 -102 172 -172 55 -123 30 -274 -62 -368 -66 -67
-128 -94 -227 -100 -100 -5 -172 19 -242 83 -193 172 -122 490 126 570 63 20
173 14 233 -13z m-817 -349 c-10 -73 -59 -166 -111 -212 -149 -131 -394 -99
-502 64 -32 48 -44 82 -58 158 l-6 37 341 0 342 0 -6 -47z"/>
      </g>
      <g transform="translate(0.000000,790.000000) scale(0.100000,-0.100000)"
         fill={secondaryFillColor} stroke="none">
        <path d="M430 4270 l0 -480 -215 0 -215 0 0 -410 0 -410 426 -2 425 -3 -210
-190 -210 -190 -1 -615 c0 -673 0 -664 60 -787 42 -84 136 -178 226 -225 126
-67 139 -68 717 -68 l517 0 0 385 0 384 -287 3 c-280 3 -289 4 -319 25 -66 47
-64 28 -64 687 l0 596 350 0 350 0 0 410 0 410 -350 0 -350 0 0 480 0 480
-425 0 -425 0 0 -480z"/>
        <path d="M3475 3890 c-666 -74 -1194 -555 -1336 -1220 -19 -88 -23 -136 -23
-295 0 -211 16 -315 76 -490 150 -442 516 -805 963 -954 394 -131 857 -87
1209 114 44 25 83 45 88 45 4 0 8 -45 8 -100 l0 -100 425 0 425 0 -2 1448 -3
1447 -422 3 -423 2 0 -252 -1 -253 -44 50 c-24 28 -140 158 -257 290 l-213
240 -70 12 c-100 16 -306 23 -400 13z m309 -910 c113 -29 189 -73 282 -165
137 -135 190 -273 182 -475 -4 -98 -9 -126 -35 -192 -97 -246 -318 -399 -578
-400 -185 -1 -318 54 -450 186 -60 60 -90 101 -118 157 -121 245 -74 532 118
724 78 79 193 145 292 169 86 21 216 20 307 -4z"/>
        <path d="M13825 3890 c-341 -37 -649 -180 -894 -415 -191 -184 -317 -380 -396
-619 -60 -181 -70 -247 -70 -481 0 -183 3 -223 23 -314 68 -301 198 -539 416
-756 131 -130 245 -214 391 -288 366 -183 789 -212 1170 -80 94 33 253 107
299 139 11 8 24 14 28 14 5 0 8 -45 8 -100 l0 -100 425 0 425 0 0 1450 0 1450
-422 -2 -423 -3 -5 -244 -5 -245 -229 260 c-126 142 -241 271 -256 285 -21 20
-44 29 -104 38 -109 16 -287 21 -381 11z m294 -905 c208 -49 394 -227 455
-434 25 -85 22 -293 -5 -374 -32 -97 -76 -166 -158 -247 -125 -125 -263 -183
-436 -183 -351 0 -629 277 -628 628 1 177 58 311 188 440 90 91 165 136 277
165 89 23 219 25 307 5z"/>
        <path d="M7115 3884 c-134 -20 -105 3 -380 -308 l-250 -283 -3 244 -2 243
-425 0 -425 0 0 -1890 0 -1890 425 0 425 0 0 545 c0 300 3 545 6 545 3 0 36
-18 72 -39 301 -175 695 -237 1051 -165 531 108 974 506 1139 1024 57 181 67
247 67 465 0 173 -3 214 -23 303 -34 152 -66 243 -133 378 -202 408 -590 707
-1049 809 -73 16 -422 29 -495 19z m340 -899 c323 -82 532 -417 461 -737 -91
-407 -521 -614 -896 -430 -153 74 -283 238 -326 411 -23 92 -16 263 15 351 67
192 229 344 426 401 77 23 240 24 320 4z"/>
        <path d="M10507 3875 c-45 -8 -84 -20 -90 -29 -7 -8 -120 -136 -252 -285
l-240 -270 -3 244 -2 245 -425 0 -425 0 0 -1890 0 -1890 425 0 425 0 0 546 c0
300 4 544 8 542 4 -2 58 -29 119 -61 503 -259 1086 -223 1558 96 123 83 314
275 394 397 117 175 188 341 233 541 20 91 23 131 23 314 0 234 -10 300 -70
481 -175 528 -630 921 -1173 1014 -134 24 -381 26 -505 5z m430 -901 c96 -32
167 -78 248 -159 127 -126 185 -264 185 -435 0 -180 -58 -319 -185 -445 -135
-136 -281 -192 -470 -182 -146 8 -248 46 -359 133 -150 118 -236 297 -236 494
0 103 18 180 67 280 67 139 236 280 383 321 106 29 269 26 367 -7z"/>
        <path d="M10277 373 c-4 -3 -7 -19 -7 -35 l0 -28 70 0 70 0 0 -155 0 -155 40
0 40 0 0 155 0 155 65 0 65 0 0 35 0 35 -168 0 c-93 0 -172 -3 -175 -7z"/>
        <path d="M10670 190 l0 -190 40 0 39 0 3 117 3 117 64 -98 c42 -64 70 -96 80
-94 9 2 44 46 78 98 l62 95 3 -115 3 -115 38 -3 37 -3 0 190 0 191 -32 0 c-31
0 -38 -8 -111 -122 l-78 -121 -81 121 c-77 116 -83 122 -114 122 l-34 0 0
-190z"/>
      </g>
    </svg>
  );
}

export default Logo;
