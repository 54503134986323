import { useState } from "react";
import {
  Box,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Button,
  FormControl,
} from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";

export default function DatePresetFilter({
  sx,
  presets,
  onChange,
  label,
  value,
  custom,
  customLabel,
  startDate,
  endDate,
}) {
  const [showCustomRange, setShowCustomRange] = useState(
    Boolean(value) === false && Boolean(startDate) && Boolean(endDate)
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function labelForValue(value) {
    return presets.find((p) => p.value === value)?.label;
  }

  const selectedLabel = Boolean(value) ? labelForValue(value) : customLabel;

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, preset) => {
    setAnchorEl(null);
    onChange(preset.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onShowCustomRange = () => {
    setShowCustomRange(true);
  };

  return (
    <Box sx={sx}>
      <FormControl>
        <Button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label={label}
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          variant="text"
          color="tertiary"
          size="large"
          sx={{ height: "56px" }}
        >
          <ListItemText primary={label} secondary={selectedLabel} />
        </Button>
      </FormControl>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {showCustomRange && (
          <Box sx={{ margin: 4, display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 8 }}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIosNew />}
              onClick={() => setShowCustomRange(false)}
            >
              Show Presets
            </Button>
            {custom()}
          </Box>
        )}

        {!showCustomRange &&
          presets?.map((preset) => (
            <MenuItem
              key={preset.value}
              selected={preset === value}
              onClick={(event) => handleMenuItemClick(event, preset)}
            >
              {preset.label}
            </MenuItem>
          ))}
        {!showCustomRange && custom && (
          <MenuItem onClick={() => onShowCustomRange()}>
            {customLabel || "Custom Range"}
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}
