import React, {useEffect, useState} from 'react';
import connect from 'react-redux/es/connect/connect';

import {getValue} from "AppUtils/objects";
import {Link as RouterLink} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card, CardHeader,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Edit as EditIcon, Trash as TrashIcon,} from 'react-feather';
import {
	userLoadConnectChannels,
	userLoadConnectChannelsDetails,
	userLoadDeleteChannels,
	userLoadResetChannelsDelete,
	userLoadResetConnect
} from "../../user/store/actions";
import {
  userConnectChannels,
  userConnectChannelsDetails,
  userDeleteChannels,
  userMsg,
  userPermissions
} from "../../user/store/selectors";
import {getQueryVariable} from "AppUtils/url";
import {Visibility} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  buttonWrapper: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 16
  },
  tableTitle: {
    marginBottom: 10
  },
  connectBtn: {
    marginLeft: 10
  }
}));

const stateToProps = state => ({
  userConnectChannels: userConnectChannels(state),
  userConnectChannelsDetails: userConnectChannelsDetails(state),
  userMsg: userMsg(state),
  userDeleteChannels: userDeleteChannels(state),
  userPermissions: userPermissions(state),
});

const actionsToProps = dispatch => ({
  userLoadConnectChannels: (payload) => dispatch(userLoadConnectChannels(payload)),
  userLoadConnectChannelsDetails: (payload) => dispatch(userLoadConnectChannelsDetails(payload)),
  userLoadDeleteChannels: (payload) => dispatch(userLoadDeleteChannels(payload)),
  userLoadResetChannelsDelete: (payload) => dispatch(userLoadResetChannelsDelete(payload)),
  userLoadResetConnect: (payload) => dispatch(userLoadResetConnect(payload)),
});

const RevenueChannels = ({
                           className,
                           revenueChannels,
                           ...props
                         }) => {
  const classes = useStyles();
  const isCallback = getQueryVariable(props.location.search, 'iscallback');
  const channelId = getQueryVariable(props.location.search, 'channelid');
  const platform = getQueryVariable(props.location.search, 'platform');
  const state = getQueryVariable(props.location.search, 'state');
  const uuid = getQueryVariable(props.location.search, 'uuid');
  const clientId = getValue(props, 'match.params.clientId');
  const {enqueueSnackbar} = useSnackbar();
  const [userMsg, setUserMsg] = useState(props.userMsg);


  const canCreateChannels = props.userPermissions.includes('channel.create');
  const canEditChannels = props.userPermissions.includes('channel.update');
  const canDeleteChannels = props.userPermissions.includes('channel.delete');

  useEffect(() => {
    setUserMsg(props.userMsg);
  }, [props.userMsg]);

  useEffect(() => {
    if (props.userDeleteChannels.data == 'deleted') {
      enqueueSnackbar('Channel has been deleted!', {
        variant: 'success',
        persist: true
      });
      props.refreshChannels();
    } else if (!userMsg && props.userMsg) {
      enqueueSnackbar(props.userMsg, {
        variant: 'error',
        persist: true
      });
      setUserMsg(props.userMsg);
    }
    props.userLoadResetChannelsDelete();
  }, [props.userDeleteChannels.data]);

  useEffect(() => {
    if (props.userConnectChannelsDetails.data) {
      enqueueSnackbar('Connected', {
        variant: 'success',
        persist: true
      });
      props.refreshChannels();
      props.userLoadResetConnect();
    } else if (!userMsg && props.userMsg) {
      enqueueSnackbar(props.userMsg, {
        variant: 'error',
        persist: true
      });
      setUserMsg(props.userMsg);
      props.userLoadResetConnect();
    }
  }, [props.userConnectChannelsDetails.data]);

  useEffect(() => {
    if (isCallback) {
      props.userLoadConnectChannelsDetails({
        tenantId: clientId,
        channelId,
        platform,
        channel: {
          request_key: uuid,
          state
        }
      });
    }
  }, []);

  const deleteChannel = (revenueChannel) => {
    if (window.confirm(`Are you sure you want to delete channel "${revenueChannel.name}"?`)) {
      props.userLoadDeleteChannels({tenantId: clientId, channelId: revenueChannel.id});
    }
  };

  return (
    <>
      <Card>
        <CardHeader title="Applications"/>

        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableBody>
                {revenueChannels.map((revenueChannel) => {
                  return (
                    <TableRow hover key={revenueChannel.id}>
                      <TableCell>{revenueChannel.name}</TableCell>

                      <TableCell>{revenueChannel.type}</TableCell>

                      <TableCell>{revenueChannel.platform}</TableCell>

                      <TableCell>
                        <Typography gutterBottom variant="overline" color={'primary'}>
                          {revenueChannel.store}
                        </Typography>
                      </TableCell>

                      <TableCell align="right">
                        <Tooltip title={canEditChannels ? "Edit" : "View"}>
                          <IconButton component={RouterLink} to={`/clients/${clientId}/settings/revenue/${revenueChannel.id}`}>
                            <SvgIcon fontSize="small">
                              {canEditChannels && (
                                <EditIcon/>
                              )}

                              {!canEditChannels && (
                                <Visibility />
                              )}
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>

                        {canDeleteChannels && (
                          <Tooltip title="Delete">
                            <IconButton onClick={() => deleteChannel(revenueChannel)}>
                              <SvgIcon fontSize="small">
                                <TrashIcon/>
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>

        {canCreateChannels && (
          <div className={classes.buttonWrapper}>
            <RouterLink to={`/clients/${clientId}/settings/revenue-add`}>
              <Button color="primary" variant="outlined">
                Add application
              </Button>
            </RouterLink>
          </div>
        )}
      </Card>
    </>
  );
};

RevenueChannels.propTypes = {
  className: PropTypes.string,
  revenueChannels: PropTypes.array.isRequired
};

RevenueChannels.defaultProps = {
  revenueChannels: []
};

export default connect(stateToProps, actionsToProps)(RevenueChannels);
