// Content types
const LOAD_CONTENT_TYPES = "LOAD_CONTENT_TYPES";
const SET_CONTENT_TYPES = "SET_CONTENT_TYPES";
const LOAD_RESET_CONTENT_TYPES = "LOAD_RESET_CONTENT_TYPES";
const SET_RESET_CONTENT_TYPES = "SET_RESET_CONTENT_TYPES";

// Content items
const UPLOAD_CONTENT_ITEMS = "UPLOAD_CONTENT_ITEMS";
const LOAD_CONTENT_ITEMS = "LOAD_CONTENT_ITEMS";
const SET_CONTENT_ITEMS = "SET_CONTENT_ITEMS";
const REMOVE_CONTENT_ITEMS = "REMOVE_CONTENT_ITEMS";
const LOAD_DELETE_CONTENT_ITEM = "LOAD_DELETE_CONTENT_ITEM";
const SET_DELETE_CONTENT_ITEM = "SET_DELETE_CONTENT_ITEM";
const SET_DELETE_CONTENT_ITEM_BY_INDEX = "SET_DELETE_CONTENT_ITEM_BY_INDEX";
const LOAD_UPDATE_CONTENT_ITEM = "LOAD_UPDATE_CONTENT_ITEM";
const LOAD_UPDATE_LINKED_CONTENT_ITEM = "LOAD_UPDATE_LINKED_CONTENT_ITEM";
const SET_UPDATE_CONTENT_ITEM = "SET_UPDATE_CONTENT_ITEM";
const RESET_CONTENT_ITEM = "RESET_CONTENT_ITEM";
const LOAD_CHECK_LINKED_ITEM = "LOAD_CHECK_LINKED_ITEM";
const SET_CHECK_LINKED_ITEM = "SET_CHECK_LINKED_ITEM";
const SET_LINKED_ITEM_URL = "SET_LINKED_ITEM_URL";
const UPLOAD_LINKED_ITEM = "UPLOAD_LINKED_ITEM";
const LOAD_UPLOAD_LINKED_ITEM = "LOAD_UPLOAD_LINKED_ITEM";
const SET_UPLOAD_LINKED_ITEM = "SET_UPLOAD_LINKED_ITEM";
const LOAD_REPLACE_ADDITIONAL_ITEMS = "LOAD_REPLACE_ADDITIONAL_ITEMS";
const SET_REPLACE_ADDITIONAL_ITEMS = "SET_REPLACE_ADDITIONAL_ITEMS";

// Compound items (themes)
const LOAD_CHECK_COMPOUND_ITEM = "LOAD_CHECK_COMPOUND_ITEM";
const SET_CHECK_COMPOUND_ITEM = "SET_CHECK_COMPOUND_ITEM";
const ADD_COMPOUND_ITEM = "ADD_COMPOUND_ITEM";
const LOAD_ADD_COMPOUND_ITEM = "LOAD_ADD_COMPOUND_ITEM";
const SET_ADD_COMPOUND_ITEM = "SET_ADD_COMPOUND_ITEM";

// Giphy channels
const SET_GIPHY_CHANNEL_NAME = "SET_GIPHY_CHANNEL_NAME";
const LOAD_CHECK_GIPHY_CHANNEL = "LOAD_CHECK_GIPHY_CHANNEL";
const SET_CHECK_GIPHY_CHANNEL = "SET_CHECK_GIPHY_CHANNEL";
const ADD_GIPHY_CHANNEL = "ADD_GIPHY_CHANNEL";
const LOAD_ADD_GIPHY_CHANNEL = "LOAD_ADD_GIPHY_CHANNEL";
const SET_ADD_GIPHY_CHANNEL = "SET_ADD_GIPHY_CHANNEL";
const REMOVE_GIPHY_CHANNELS = "REMOVE_GIPHY_CHANNELS";
const RESET_GIPHY_CHANNEL = "RESET_GIPHY_CHANNEL";
const LOAD_DELETE_GIPHY_CHANNEL = "LOAD_DELETE_GIPHY_CHANNEL";
const SET_DELETE_GIPHY_CHANNEL = "SET_DELETE_GIPHY_CHANNEL";

// Keywords
const LOAD_CONTENT_KEYWORDS = "LOAD_CONTENT_KEYWORDS";
const SET_CONTENT_KEYWORDS = "SET_CONTENT_KEYWORDS";
const LOAD_CONTENT_CREATE_KEYWORDS = "LOAD_CONTENT_CREATE_KEYWORDS";
const SET_CONTENT_CREATE_KEYWORDS = "SET_CONTENT_CREATE_KEYWORDS";
const SET_CONTENT_RESET_CREATE_KEYWORDS = "SET_CONTENT_RESET_CREATE_KEYWORDS";

// Packs
const LOAD_CONTENT_CREATE_PACK = "LOAD_CONTENT_CREATE_PACK";
const SET_CONTENT_CREATE_PACK = "SET_CONTENT_CREATE_PACK";

// asset list
const LOAD_CONTENT_CREATE_ASSET_LIST = "LOAD_CONTENT_CREATE_ASSET_LIST";
const SET_CONTENT_CREATE_ASSET_LIST = "SET_CONTENT_CREATE_ASSET_LIST";

// Content folders
const LOAD_CONTENT_FOLDERS = "LOAD_CONTENT_FOLDERS";
const SET_CONTENT_FOLDERS = "SET_CONTENT_FOLDERS";
const LOAD_CREATE_CONTENT_FOLDER = "LOAD_CREATE_CONTENT_FOLDER";
const SET_CREATE_CONTENT_FOLDER = "SET_CREATE_CONTENT_FOLDER";
const LOAD_READ_CONTENT_FOLDER = "LOAD_READ_CONTENT_FOLDER";
const SET_READ_CONTENT_FOLDER = "SET_READ_CONTENT_FOLDER";
const LOAD_UPDATE_CONTENT_FOLDER = "LOAD_UPDATE_CONTENT_FOLDER";
const SET_UPDATE_CONTENT_FOLDER = "SET_UPDATE_CONTENT_FOLDER";
const LOAD_DELETE_CONTENT_FOLDER = "LOAD_DELETE_CONTENT_FOLDER";
const SET_DELETE_CONTENT_FOLDER = "SET_DELETE_CONTENT_FOLDER";
const LOAD_MODIFY_CONTENT_FOLDER = "LOAD_MODIFY_CONTENT_FOLDER";
const SET_MODIFY_CONTENT_FOLDER = "SET_MODIFY_CONTENT_FOLDER";
const LOAD_PUBLISH_CONTENT = "LOAD_PUBLISH_CONTENT";
const SET_DIFFS = "SET_DIFFS";
const PUBLISH_TO_MOCHA = "PUBLISH_TO_MOCHA";
const PUBLISH_TO_MOCHA_FINISHED = "PUBLISH_TO_MOCHA_FINISHED";
const LOAD_DELETE_CONTENT_FOLDER_ASSET = "LOAD_DELETE_CONTENT_FOLDER_ASSET";
const SET_DELETE_CONTENT_FOLDER_ASSET = "SET_DELETE_CONTENT_FOLDER_ASSET";
const LOAD_RESET_CONTENT_FOLDERS = "LOAD_RESET_CONTENT_FOLDERS";
const SET_RESET_CONTENT_FOLDERS = "SET_RESET_CONTENT_FOLDERS";
const TOGGLE_ENABLED_FOLDER = "TOGGLE_ENABLED_FOLDER";
const TOGGLE_SELECTED_FOLDER = "TOGGLE_SELECTED_FOLDER";

// Content assets
const LOAD_CONTENT_ASSETS = "LOAD_CONTENT_ASSETS";
const SET_CONTENT_ASSETS = "SET_CONTENT_ASSETS";
const SET_MODIFY_CONTENT_ASSETS = "SET_MODIFY_CONTENT_ASSETS";
const SET_TOGGLE_CONTENT_ASSET_REPLACE_FORM = "SET_TOGGLE_CONTENT_ASSET_REPLACE_FORM";
const LOAD_CREATE_CONTENT_ASSETS = "LOAD_CREATE_CONTENT_ASSETS";
const SET_CREATE_CONTENT_ASSETS = "SET_CREATE_CONTENT_ASSETS";
const LOAD_READ_CONTENT_ASSETS = "LOAD_READ_CONTENT_ASSETS";
const SET_READ_CONTENT_ASSETS = "SET_READ_CONTENT_ASSETS";
const LOAD_UPDATE_CONTENT_ASSET = "LOAD_UPDATE_CONTENT_ASSET";
const SET_UPDATE_CONTENT_ASSET = "SET_UPDATE_CONTENT_ASSET";
const LOAD_UPDATE_CONTENT_ASSET_VERSION = "LOAD_UPDATE_CONTENT_ASSET_VERSION";
const SET_UPDATE_CONTENT_ASSET_VERSION = "SET_UPDATE_CONTENT_ASSET_VERSION";
const LOAD_DELETE_CONTENT_ASSET = "LOAD_DELETE_CONTENT_ASSET";
const SET_DELETE_CONTENT_ASSET = "SET_DELETE_CONTENT_ASSET";
const SET_RESET_CONTENT_ASSETS = "SET_RESET_CONTENT_ASSETS";

export {
  LOAD_CONTENT_CREATE_ASSET_LIST,
  SET_CONTENT_CREATE_ASSET_LIST,
  LOAD_CONTENT_TYPES,
  SET_CONTENT_TYPES,
  LOAD_RESET_CONTENT_TYPES,
  SET_RESET_CONTENT_TYPES,
  LOAD_CONTENT_KEYWORDS,
  SET_CONTENT_KEYWORDS,
  LOAD_CONTENT_CREATE_KEYWORDS,
  SET_CONTENT_CREATE_KEYWORDS,
  SET_CONTENT_RESET_CREATE_KEYWORDS,
  LOAD_CONTENT_CREATE_PACK,
  SET_CONTENT_CREATE_PACK,
  UPLOAD_CONTENT_ITEMS,
  LOAD_CONTENT_ITEMS,
  SET_CONTENT_ITEMS,
  REMOVE_CONTENT_ITEMS,
  LOAD_DELETE_CONTENT_ITEM,
  SET_DELETE_CONTENT_ITEM,
  SET_DELETE_CONTENT_ITEM_BY_INDEX,
  LOAD_UPDATE_CONTENT_ITEM,
  LOAD_UPDATE_LINKED_CONTENT_ITEM,
  SET_UPDATE_CONTENT_ITEM,
  LOAD_CHECK_COMPOUND_ITEM,
  SET_CHECK_COMPOUND_ITEM,
  ADD_COMPOUND_ITEM,
  LOAD_ADD_COMPOUND_ITEM,
  SET_ADD_COMPOUND_ITEM,
  LOAD_CHECK_LINKED_ITEM,
  SET_CHECK_LINKED_ITEM,
  SET_LINKED_ITEM_URL,
  UPLOAD_LINKED_ITEM,
  LOAD_UPLOAD_LINKED_ITEM,
  SET_UPLOAD_LINKED_ITEM,
  RESET_CONTENT_ITEM,
  LOAD_REPLACE_ADDITIONAL_ITEMS,
  SET_REPLACE_ADDITIONAL_ITEMS,
  SET_CHECK_GIPHY_CHANNEL,
  LOAD_CHECK_GIPHY_CHANNEL,
  ADD_GIPHY_CHANNEL,
  LOAD_ADD_GIPHY_CHANNEL,
  SET_ADD_GIPHY_CHANNEL,
  SET_GIPHY_CHANNEL_NAME,
  REMOVE_GIPHY_CHANNELS,
  RESET_GIPHY_CHANNEL,
  LOAD_DELETE_GIPHY_CHANNEL,
  SET_DELETE_GIPHY_CHANNEL,
  LOAD_CONTENT_FOLDERS,
  SET_CONTENT_FOLDERS,
  LOAD_CREATE_CONTENT_FOLDER,
  SET_CREATE_CONTENT_FOLDER,
  LOAD_READ_CONTENT_FOLDER,
  SET_READ_CONTENT_FOLDER,
  LOAD_UPDATE_CONTENT_FOLDER,
  SET_UPDATE_CONTENT_FOLDER,
  LOAD_DELETE_CONTENT_FOLDER,
  SET_DELETE_CONTENT_FOLDER,
  LOAD_MODIFY_CONTENT_FOLDER,
  SET_MODIFY_CONTENT_FOLDER,
  TOGGLE_ENABLED_FOLDER,
  TOGGLE_SELECTED_FOLDER,
  LOAD_PUBLISH_CONTENT,
  SET_DIFFS,
  PUBLISH_TO_MOCHA,
  PUBLISH_TO_MOCHA_FINISHED,
  LOAD_DELETE_CONTENT_FOLDER_ASSET,
  SET_DELETE_CONTENT_FOLDER_ASSET,
  LOAD_RESET_CONTENT_FOLDERS,
  SET_RESET_CONTENT_FOLDERS,
  LOAD_CONTENT_ASSETS,
  SET_CONTENT_ASSETS,
  SET_MODIFY_CONTENT_ASSETS,
  SET_TOGGLE_CONTENT_ASSET_REPLACE_FORM,
  LOAD_CREATE_CONTENT_ASSETS,
  SET_CREATE_CONTENT_ASSETS,
  LOAD_READ_CONTENT_ASSETS,
  SET_READ_CONTENT_ASSETS,
  LOAD_UPDATE_CONTENT_ASSET,
  SET_UPDATE_CONTENT_ASSET,
  LOAD_UPDATE_CONTENT_ASSET_VERSION,
  SET_UPDATE_CONTENT_ASSET_VERSION,
  LOAD_DELETE_CONTENT_ASSET,
  SET_DELETE_CONTENT_ASSET,
  SET_RESET_CONTENT_ASSETS,
};
