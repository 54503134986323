import Observable from 'Observable';
import {fork, put, select, takeEvery} from 'redux-saga/effects';
import {getValue} from 'AppUtils/objects';
import {apiDelete, apiGet, apiPost, apiPut} from "AppUtils/api";

import * as CLIENTS from './types';
import * as COMMON from '../../../store/types';
import {userInfo} from "../../user/store/selectors";
import {apiPutFile} from "../../../utils/api";
import mashValidationMessage from "../../../utils/laravelValidationMessagesMasher";

function* onLoadOrganizations(action) {
  const page = getValue(action, 'payload.page', 1);
  const rows_per_page = getValue(action, 'payload.rows_per_page', 1000000);

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  yield apiGet(`/organizations`, {page, rows_per_page})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && !response.errors) {
        data = response;
      } else {
        msg = response.message;
      }
    });

  yield put({type: CLIENTS.SET_ORGANIZATIONS, payload: {data, msg, statusCode}});
}

function* onLoadOrganization(action) {
  const id = getValue(action, 'payload.id', '');

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiGet(`/organizations/${id}`)
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise();

  if (response && !response.errors) {
    data = response.data;
  } else {
    msg = response.message;
  }
  if (statusCode === 403) {
    yield put({type: COMMON.UI_REDIRECT, payload: "/not-authorized"});
  }

  yield put({type: CLIENTS.SET_ORGANIZATION, payload: {data, msg, statusCode}});
}

function* onLoadUpdateOrganization(action) {
  const id = getValue(action, 'payload.id', '');
  const organization = getValue(action, 'payload.organization', {});

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  yield apiPutFile(`/organizations/${id}`, {...organization})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode == 200) {
        data = 'updated';
      } else {
        msg = response.message;
      }
    });

  yield put({type: CLIENTS.SET_UPDATE_ORGANIZATION, payload: {data, msg, statusCode}});
}

function* onLoadDeleteOrganization(action) {
  const id = getValue(action, 'payload.tenantId');

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  yield apiDelete(`/organizations/${id}`)
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode == 200) {
        data = 'deleted';
      } else {
        msg = response.message;
      }
    });

  yield put({type: CLIENTS.SET_DELETE_ORGANIZATION, payload: {data, msg, statusCode}});
}

function* onLoadCreateOrganization(action) {
  const name = getValue(action, 'payload.name', '');
  const filter_alias = getValue(action, 'payload.filter_alias', '');
  const kee2_identifier = getValue(action, 'payload.kee2_identifier', '');

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiPost(`/organizations`, {name, filter_alias,  kee2_identifier})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && !response.errors) {
        data = response.data;
      } else {
        msg = {
          msg: response.message,
          errors: response.errors || {}
        };
      }
    });

  yield put({type: CLIENTS.SET_CREATE_ORGANIZATION, payload: {data, msg, statusCode}});
}

function* onLoadUsers(action) {
  const page = getValue(action, 'payload.page', 1);
  const rows_per_page = getValue(action, 'payload.rows_per_page', 1000000);
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiGet(`/${tenantId}/users`, {page, rows_per_page})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && !response.errors) {
        data = response;
      } else {
        msg = response.message;
      }
    });

  yield put({type: CLIENTS.SET_USERS, payload: {data, msg, statusCode}});
}

function* onLoadCreateUsers(action) {
  const user = getValue(action, 'payload.user', {});
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiPost(`/${tenantId}/users`, {...user})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && (statusCode == 201 || statusCode == 200)) {
        data = response;
      } else {
        msg = {
          msg: response.message,
          errors: response.errors || {}
        };
      }
    });

  yield put({type: CLIENTS.SET_CREATE_USERS, payload: {data, msg, statusCode}});
}

function* onLoadTenantUsers(action) {
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));
  const userId = getValue(action, 'payload.userId', '');

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiGet(`/${tenantId}/users/${userId}`)
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && !response.errors) {
        data = response;
      } else {
        msg = response.message;
      }
    });

  yield put({type: CLIENTS.SET_TENANT_USERS, payload: {data, msg, statusCode}});
}

function* onLoadUpdateTenantUsers(action) {
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));
  const userId = getValue(action, 'payload.userId', '');
  const user = getValue(action, 'payload.user', {});

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiPut(`/${tenantId}/users/${userId}`, {...user})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode == 200) {
        data = 'updated';
      } else {
        msg = response.message;
      }
    });

  yield put({type: CLIENTS.SET_UPDATE_TENANT_USERS, payload: {data, msg, statusCode}});
}

function* onLoadDeleteTenantUsers(action) {
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));
  const userId = getValue(action, 'payload.userId', '');

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiDelete(`/${tenantId}/users/${userId}`)
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode == 200) {
        data = 'deleted';
      } else {
        msg = response.message;
      }
    });

  yield put({type: CLIENTS.SET_DELETE_TENANT_USERS, payload: {data, msg, statusCode}});
}

function* onLoadInviteTenantUsers(action) {
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));
  const userId = getValue(action, 'payload.userId', '');
  const url = getValue(action, 'payload.url', '');

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiPost(`/${tenantId}/users/${userId}/invite`, {url})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode == 200) {
        data = 'sent';
      } else {
        msg = response.message;
      }
    });

  yield put({type: CLIENTS.SET_INVITE_TENANT_USERS, payload: {data, msg, statusCode}});
}

function* onLoadClients(action) {
  const page = getValue(action, 'payload.page', 1);
  const rows_per_page = getValue(action, 'payload.rows_per_page', 1000000);

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiGet(`/clients`, {page, rows_per_page})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && !response.errors) {
        data = response;
      } else {
        msg = response.message;
      }
    });

  yield put({type: CLIENTS.SET_CLIENTS, payload: {data, msg, statusCode}});
}

function* onLoadResetTenantUsers(action) {
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));
  const userId = getValue(action, 'payload.userId', '');
  const url = getValue(action, 'payload.url', '');

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiPost(`/${tenantId}/users/${userId}/reset-password`, {url})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode == 200) {
        data = 'reset';
      } else {
        msg = response.message;
      }
    });

  yield put({type: CLIENTS.SET_RESET_TENANT_USERS, payload: {data, msg, statusCode}});
}

function* parseTenantId(tenantId) {
  const info = yield select(userInfo);
  const url = new URL(window.location);
  const str = url.pathname;
  const res = str.match(/\/clients\/([0-9]*)\//);
  const clientId = res && res[1] ? parseInt(res[1]) : '';
  let selectedTenantId = tenantId || clientId;

  if (!selectedTenantId) {
    selectedTenantId = getValue(info, 'organizations.0.id');
  }

  return selectedTenantId;
}

function* onLoadStatsReportSeries(action) {
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));
  const dateStart = getValue(action, 'payload.dateStart', '');
  const dateEnd = getValue(action, 'payload.dateEnd', '');
  const channels = getValue(action, 'payload.channels', [])
  const groupBy = getValue(action, 'payload.groupBy', '');
  const countryCodes = getValue(action, 'payload.countryCodes', []);

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';
  let payload = {
      dateStart: dateStart,
      dateEnd: dateEnd,
      channels: channels,
      groupBy: groupBy,
      countryCodes: countryCodes,
  };

  yield apiGet(`/${tenantId}/stats/report-series`, payload)
      .retryWhen(errors => errors.delay(1000).take(retry))
      .catch(e => Observable.of([]))
      .mergeMap(res => {
        const resp = res.json();
        statusCode = res.status;
        return resp;
      }).toPromise().then(function (response) {
        if (response && !response.errors) {
          data = response.data;
        } else {
          msg = response.message;
        }
      });

  yield put({type: CLIENTS.SET_STATS_REPORT_SERIES, payload: {data, msg, statusCode}});
}

function* onLoadTags(action) {
  const page = getValue(action, "payload.page", 1);
  const rows_per_page = getValue(action, "payload.rows_per_page", 1000000);
  const filter = getValue(action, "payload.filter", "");

  const retry = 3;
  let data = "";
  let msg = "";
  let statusCode = "";
  let meta = {};

  yield apiGet('/tags', {
    page,
    rows_per_page,
    filter,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(function(response) {
      if (response && !response.errors) {
        data = response.data;
        meta.current_page = response.meta.current_page;
        meta.last_page = response.meta.last_page;
      } else {
        msg = response.message;
      }
    });

  yield put({
    type: CLIENTS.SET_TAGS,
    payload: { data, msg, statusCode, meta },
  });
}

function* onLoadCreateTags(action) {
  const tag = getValue(action, "payload.tag");
  const callback = getValue(action, "payload.callback");

  const retry = 3;
  let data = "";
  let msg = "";
  let statusCode = "";

  yield apiPost(`/tags`, { name: tag.label })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => {Observable.of([])})
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(function(response) {
      if (response && !response.errors) {
        data = response.data;
        callback(tag);
      } else {
        msg = mashValidationMessage(response.message, response.errors);
      }
    });

  yield put({
    type: CLIENTS.SET_CREATE_TAGS,
    payload: { data, msg, statusCode },
  });
}

function* userWatchInitialize() {
  yield takeEvery(CLIENTS.LOAD_ORGANIZATIONS, onLoadOrganizations);
  yield takeEvery(CLIENTS.LOAD_ORGANIZATION, onLoadOrganization);
  yield takeEvery(CLIENTS.LOAD_UPDATE_ORGANIZATION, onLoadUpdateOrganization);
  yield takeEvery(CLIENTS.LOAD_DELETE_ORGANIZATION, onLoadDeleteOrganization);
  yield takeEvery(CLIENTS.LOAD_CREATE_ORGANIZATION, onLoadCreateOrganization);
  yield takeEvery(CLIENTS.LOAD_CLIENTS, onLoadClients);
  yield takeEvery(CLIENTS.LOAD_USERS, onLoadUsers);
  yield takeEvery(CLIENTS.LOAD_CREATE_USERS, onLoadCreateUsers);
  yield takeEvery(CLIENTS.LOAD_TENANT_USERS, onLoadTenantUsers);
  yield takeEvery(CLIENTS.LOAD_DELETE_TENANT_USERS, onLoadDeleteTenantUsers);
  yield takeEvery(CLIENTS.LOAD_UPDATE_TENANT_USERS, onLoadUpdateTenantUsers);
  yield takeEvery(CLIENTS.LOAD_INVITE_TENANT_USERS, onLoadInviteTenantUsers);
  yield takeEvery(CLIENTS.LOAD_RESET_TENANT_USERS, onLoadResetTenantUsers);
  yield takeEvery(CLIENTS.LOAD_STATS_REPORT_SERIES, onLoadStatsReportSeries);
  yield takeEvery(CLIENTS.LOAD_TAGS, onLoadTags);
  yield takeEvery(CLIENTS.LOAD_CREATE_TAGS, onLoadCreateTags);
}

export default function* sagas() {
  yield fork(userWatchInitialize);
}
