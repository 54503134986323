import connect from "react-redux/es/connect/connect";
import {Box, Grid, TextField} from "@mui/material";
import React from "react";

const stateToProps = state => ({});

const actionsToProps = dispatch => ({});

const ClientCreation = ({clientCreationFormik, errors, ...props}) => {
  return (
    <form noValidate onSubmit={clientCreationFormik.handleSubmit}>
      <Grid container={true} spacing={2}>
        <Grid item xs={12}>
          <TextField error={Boolean(clientCreationFormik.touched.clientName && clientCreationFormik.errors.clientName)}
                     fullWidth
                     autoFocus
                     helperText={clientCreationFormik.touched.clientName && clientCreationFormik.errors.clientName}
                     label="Your app's name"
                     margin="normal"
                     name="clientName"
                     onBlur={clientCreationFormik.handleBlur}
                     onChange={clientCreationFormik.handleChange}
                     type="text"
                     value={clientCreationFormik.values.clientName}
                     variant="outlined" />
        </Grid>
      </Grid>
    </form>
  );
};

export default connect(stateToProps, actionsToProps)(ClientCreation);