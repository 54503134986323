import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import Wrapper from "./Wrapper";

import './App.scss';

import {appGetInterfaceLang, appGetStatus, uiGetModal, uiGetSection} from "./store/selectors";
import {appInitialize, uiRedirect, uiToggleModal} from 'AppStore/actions';
import {userInfo} from "./containers/user/store/selectors";

const stateToProps = state => ({
  appGetStatus: appGetStatus(state),
  section: uiGetSection(state),
  uiGetModal: uiGetModal(state),
  lang: appGetInterfaceLang(state),
  userInfo: userInfo(state),
});

const actionsToProps = dispatch => ({
  appInitialize: () => dispatch(appInitialize()),
  uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
  uiRedirect: (url) => dispatch(uiRedirect(url)),
});

class App extends Component {
  openModal = (title, message, button, buttonText, button2, button2Text, restrictClose, callback, callback2) => {
    this.props.uiToggleModal({}, {toggle: true}, {title, message, button, buttonText, restrictClose, callback});
  };

  closeModal = () => {
    this.props.uiToggleModal({}, {toggle: false}, {title: '', message: ''});
  };

  componentDidMount() {
    this.props.appInitialize();
  }

  componentDidUpdate(prevProps, prevState) {

  }

  render() {
    return (
      <div className="App">
        <Wrapper/>
      </div>
    );
  }
}

export default connect(stateToProps, actionsToProps)(App);
