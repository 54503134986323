import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Breadcrumbs, Link, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Header = ({className, clientId, clientName, edit, ...rest}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small"/>}
        aria-label="breadcrumb"
      >
        <Link
          variant="body1"
          color="inherit"
          to={`/clients`}
          component={RouterLink}
        >
          Management
        </Link>
        <Link
          variant="body1"
          color="inherit"
          to={`/clients/${clientId}/settings`}
          component={RouterLink}
        >
          {clientName}
        </Link>
        <Typography
          variant="body1"
          color="textPrimary"
        >
          {edit ? 'Edit user' : 'Add user'}
        </Typography>
      </Breadcrumbs>
      <Typography
        variant="h3"
        color="textPrimary"
      >
        {edit ? 'Edit user' : 'Add user'}
      </Typography>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
