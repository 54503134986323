import React, {useState} from 'react';
import PropTypes from 'prop-types';
import NavBar from "../navbar/main/NavBar";
import TopBar from '../topbar/TopBar';
import {Box} from "@mui/material";


const PageLayout = ({hasNavigation = true, ...props}) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <Box sx={{ bgcolor: 'background.dark', display: 'flex', minHeight: '100vh', width: '100%' }}>
      <TopBar clientId={props.match?.params?.clientId || false} onMobileNavOpen={() => setMobileNavOpen(true)}/>

      {hasNavigation && (
        <>
          <NavBar onMobileClose={() => setMobileNavOpen(false)}
                  openMobile={isMobileNavOpen}
                  clientId={props.match?.params?.clientId || false}/>

          <Box sx={{ pl: { lg: 72 }, width: 1 }}>
            <Box sx={{ px: 8, py: 16, overflowX: 'hidden', overflowY: 'auto' }}>
              {props.children}
            </Box>
          </Box>
        </>
      )}

      {!hasNavigation && (
        <>
          <Box sx={{ px: 8, p: 16, overflowX: 'hidden', overflowY: 'auto', width: 1 }}>
            {props.children}
          </Box>
        </>
      )}
    </Box>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node
};

export default PageLayout;
