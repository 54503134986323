import React from 'react';
import * as COMMON from '../store/types';
import {getValue} from "AppUtils/objects";
import {getCookie} from "AppUtils/cookies";
import PropTypes from 'prop-types';

let __store;

//it's useful to force showing XXXXXX to easily detect what's not wrapper for translations
const __fakeValue = getCookie('__APP_LANG_FAKE_VALUES', '');

//Store as a map all objects
let __repository = {};
const __missing = {};

export const middleware = store => next => action => {
  const result = next(action);
  if (action.type == COMMON.APP_SET_TRANSLATIONS) {
    __repository.global = action.payload.__store;
  }
  return result;
};
middleware.register = _store => {
  __store = _store;
};


const getText = (label, local = false, group = 'global') => {
  let labelFiltered = generateKey(label);

  let val = getValue(__repository, `${group}.${labelFiltered}`, null);

  //__repository.global &&
  if (!local) {
    if (val) {
      return val;
    }

    return __fakeValue || label;
  } else if (local) {
    return label ? label.replace('%s', '{0}') : '';
  } else {
    return '';
  }
};

/**
 * Returns a hashed form for longer phrases
 * Find, match and chat with people nearby => Find, match and chat wit1786646157
 * @param text
 * @returns {*}
 */
function generateKey(text) {
  text = text ? text.replace(/[\W]+/g, "_") : ''; //replaces all alphanumeric except _

  if (text.length > 48) {
    return text.substr(0, 16) + hashCode(text);
  }
  return text;
}

/**
 * Creates a quick hash for texts.
 * @param text
 * @returns {number}
 */
function hashCode(text) {
  let hash = 0;
  if (text.length == 0) return hash;
  for (let i = 0; i < text.length; i++) {
    const char = text.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
}

/**
 * Parser for the HTML.
 * @param html
 * @returns react version of the html
 */
class HtmlParser extends React.Component {

  getHtml() {
    const {html, strFormat, strFormatPlurals, group = 'global', local = false} = this.props;

    if (strFormat) {
      return getText(html, local, group).strFormat(...strFormat);
    } else if (strFormatPlurals) {
      return getText(html, local, group).strFormatPlurals(...strFormatPlurals);
    }
    return getText(html, local, group);
  }

  render() {
    const style = this.props.style ? this.props.style : {};
    //sanitize maybe... in case of js injection
    return <div dangerouslySetInnerHTML={{__html: this.getHtml()}} style={style}/>;
  }
}

HtmlParser.propTypes = {
  children(props, propName, componentName) {
    if (props[propName]) {
      return new Error(`The  ${componentName} does not support children`);
    }
  },
  html: PropTypes.string,
  strFormat: PropTypes.array,
  strFormatPlurals: PropTypes.array,
};

HtmlParser.defaultProps = {
  html: null,
  strFormat: null,
  strFormatPlurals: null,
};

export {getText as default, HtmlParser};
