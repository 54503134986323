const LOAD_MSG = 'LOAD_MSG';
const SET_MSG = 'SET_MSG';

const USER_LOAD_INFO = 'USER_LOAD_INFO';
const USER_SET_INFO = 'USER_SET_INFO';

const USER_LOAD_UPDATE_INFO = 'USER_LOAD_UPDATE_INFO';
const USER_SET_UPDATE_INFO = 'USER_SET_UPDATE_INFO';

const USER_LOAD_NOTIFICATIONS = 'USER_LOAD_NOTIFICATIONS';
const USER_SET_NOTIFICATIONS = 'USER_SET_NOTIFICATIONS';

const USER_LOAD_NOTIFICATIONS_CONSUME = 'USER_LOAD_NOTIFICATIONS_CONSUME';
const USER_SET_NOTIFICATIONS_CONSUME = 'USER_SET_NOTIFICATIONS_CONSUME';

const USER_LOAD_UPDATE_PROFILE = 'USER_LOAD_UPDATE_PROFILE';
const USER_SET_UPDATE_PROFILE = 'USER_SET_UPDATE_PROFILE';

const USER_LOAD_CHANNELS = 'USER_LOAD_CHANNELS';
const USER_SET_CHANNELS = 'USER_SET_CHANNELS';

const USER_LOAD_OTHER_CHANNELS = 'USER_LOAD_OTHER_CHANNELS';
const USER_SET_OTHER_CHANNELS = 'USER_SET_OTHER_CHANNELS';

const USER_LOAD_CREATE_CHANNELS = 'USER_LOAD_CREATE_CHANNELS';
const USER_SET_CREATE_CHANNELS = 'USER_SET_CREATE_CHANNELS';

const USER_LOAD_CHANNELS_DETAILS = 'USER_LOAD_CHANNELS_DETAILS';
const USER_SET_CHANNELS_DETAILS = 'USER_SET_CHANNELS_DETAILS';

const USER_LOAD_UPDATE_CHANNELS = 'USER_LOAD_UPDATE_CHANNELS';
const USER_SET_UPDATE_CHANNELS = 'USER_SET_UPDATE_CHANNELS';

const USER_LOAD_DELETE_CHANNELS = 'USER_LOAD_DELETE_CHANNELS';
const USER_SET_DELETE_CHANNELS = 'USER_SET_DELETE_CHANNELS';

const USER_LOAD_UNMAPPED_CHANNELS = 'USER_LOAD_UNMAPPED_CHANNELS';
const USER_SET_UNMAPPED_CHANNELS = 'USER_SET_UNMAPPED_CHANNELS';

const USER_LOAD_CONNECT_CHANNELS = 'USER_LOAD_CONNECT_CHANNELS';
const USER_SET_CONNECT_CHANNELS = 'USER_SET_CONNECT_CHANNELS';

const USER_LOAD_CONNECT_CHANNELS_DETAILS = 'USER_LOAD_CONNECT_CHANNELS_DETAILS';
const USER_SET_CONNECT_CHANNELS_DETAILS = 'USER_SET_CONNECT_CHANNELS_DETAILS';

const USER_LOAD_UPDATE_CAMPAIGN = 'USER_LOAD_UPDATE_CAMPAIGN';

const USER_LOAD_STATS_LAST_7DAYS = 'USER_LOAD_STATS_LAST_7DAYS';
const USER_SET_STATS_LAST_7DAYS = 'USER_SET_STATS_LAST_7DAYS';

const USER_LOAD_STATS_LAST_28DAYS = 'USER_LOAD_STATS_LAST_28DAYS';
const USER_SET_STATS_LAST_28DAYS = 'USER_SET_STATS_LAST_28DAYS';

const USER_LOAD_STATS_LAST_MONTH = 'USER_LOAD_STATS_LAST_MONTH';
const USER_SET_STATS_LAST_MONTH = 'USER_SET_STATS_LAST_MONTH';

const USER_LOAD_SERIES = 'USER_LOAD_SERIES';
const USER_SET_SERIES = 'USER_SET_SERIES';

const USER_LOAD_DATA_SERIES = 'USER_LOAD_DATA_SERIES';
const USER_SET_DATA_SERIES = 'USER_SET_DATA_SERIES';

const USER_LOAD_STATS_LEADERBOARD = 'USER_LOAD_STATS_LEADERBOARD';
const USER_SET_STATS_LEADERBOARD = 'USER_SET_STATS_LEADERBOARD';

const USER_LOAD_COUNTRY_SERIES = 'USER_LOAD_COUNTRY_SERIES';
const USER_SET_COUNTRY_SERIES = 'USER_SET_COUNTRY_SERIES';

const USER_LOAD_RESET_DATA_SERIES = 'USER_LOAD_RESET_DATA_SERIES';
const USER_LOAD_RESET_COUNTRY_SERIES = 'USER_LOAD_RESET_COUNTRY_SERIES';
const USER_LOAD_RESET_CHANNELS_DETAILS = 'USER_LOAD_RESET_CHANNELS_DETAILS';
const USER_LOAD_RESET_OTHER_CHANNELS = 'USER_LOAD_RESET_OTHER_CHANNELS';
const USER_LOAD_RESET_CHANNELS = 'USER_LOAD_RESET_CHANNELS';
const USER_LOAD_RESET_CHANNELS_UPDATE = 'USER_LOAD_RESET_CHANNELS_UPDATE';
const USER_LOAD_RESET_CHANNELS_CREATE = 'USER_LOAD_RESET_CHANNELS_CREATE';
const USER_LOAD_RESET_CHANNELS_DELETE = 'USER_LOAD_RESET_CHANNELS_DELETE';
const USER_LOAD_RESET_CONNECT = 'USER_LOAD_RESET_CONNECT';
const USER_LOAD_RESET_MSG = 'USER_LOAD_RESET_MSG';

export {
  LOAD_MSG,
  SET_MSG,
  USER_LOAD_INFO,
  USER_SET_INFO,
  USER_LOAD_UPDATE_INFO,
  USER_SET_UPDATE_INFO,
  USER_LOAD_NOTIFICATIONS,
  USER_SET_NOTIFICATIONS,
  USER_LOAD_NOTIFICATIONS_CONSUME,
  USER_SET_NOTIFICATIONS_CONSUME,
  USER_LOAD_UPDATE_PROFILE,
  USER_SET_UPDATE_PROFILE,
  USER_LOAD_CHANNELS,
  USER_SET_CHANNELS,
  USER_LOAD_OTHER_CHANNELS,
  USER_SET_OTHER_CHANNELS,
  USER_LOAD_CREATE_CHANNELS,
  USER_SET_CREATE_CHANNELS,
  USER_LOAD_CHANNELS_DETAILS,
  USER_SET_CHANNELS_DETAILS,
  USER_LOAD_UPDATE_CHANNELS,
  USER_SET_UPDATE_CHANNELS,
  USER_LOAD_DELETE_CHANNELS,
  USER_SET_DELETE_CHANNELS,
  USER_LOAD_UNMAPPED_CHANNELS,
  USER_SET_UNMAPPED_CHANNELS,
  USER_LOAD_CONNECT_CHANNELS,
  USER_SET_CONNECT_CHANNELS,
  USER_LOAD_CONNECT_CHANNELS_DETAILS,
  USER_SET_CONNECT_CHANNELS_DETAILS,
  USER_LOAD_UPDATE_CAMPAIGN,
  USER_LOAD_STATS_LAST_7DAYS,
  USER_SET_STATS_LAST_7DAYS,
  USER_LOAD_STATS_LAST_28DAYS,
  USER_SET_STATS_LAST_28DAYS,
  USER_LOAD_STATS_LAST_MONTH,
  USER_SET_STATS_LAST_MONTH,
  USER_LOAD_SERIES,
  USER_SET_SERIES,
  USER_LOAD_DATA_SERIES,
  USER_SET_DATA_SERIES,
  USER_LOAD_STATS_LEADERBOARD,
  USER_SET_STATS_LEADERBOARD,
  USER_LOAD_COUNTRY_SERIES,
  USER_SET_COUNTRY_SERIES,
  USER_LOAD_RESET_DATA_SERIES,
  USER_LOAD_RESET_COUNTRY_SERIES,
  USER_LOAD_RESET_OTHER_CHANNELS,
  USER_LOAD_RESET_CHANNELS,
  USER_LOAD_RESET_CHANNELS_DETAILS,
  USER_LOAD_RESET_CHANNELS_UPDATE,
  USER_LOAD_RESET_CHANNELS_CREATE,
  USER_LOAD_RESET_CHANNELS_DELETE,
  USER_LOAD_RESET_CONNECT,
  USER_LOAD_RESET_MSG,
};
