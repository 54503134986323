const capitalizeSnakeCase = (snakeCaseText) => {
  try {
    return snakeCaseText.split("_")
      .filter(x => x.length > 0)
      .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
      .join(" ");
  }
  catch (e) {
    return snakeCaseText[0][0].split("_")
      .filter(x => x.length > 0)
      .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
      .join(" ");
  }
}

export {
  capitalizeSnakeCase
}