import {FormControl, FormControlLabel, RadioGroup, Radio} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import {capitalizeSnakeCase} from "../../../utils/string";

const stateToProps = (state) => ({});

const actionToProps = (dispatch) => ({});

const ReportOrderings = ({availableOrderings, defaultOrdering, setReportOrderings, ...props}) => {
  return (
    <FormControl>
      <RadioGroup name="report_orderings"
                  onChange={(event) => setReportOrderings(event.target.value)}>
        {availableOrderings.map(availableOrdering => (
          <FormControlLabel key={availableOrdering} value={availableOrdering} control={<Radio />} label={capitalizeSnakeCase(availableOrdering)} sx={{ color: 'text.secondary' }} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default connect(stateToProps, actionToProps)(ReportOrderings);

