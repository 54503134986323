// START modules
import React, {createRef, useEffect, useState} from "react";
// END modules

// START material
import {
  Box, Button, Checkbox, Chip, Fab, FormControlLabel, FormGroup, IconButton, Paper, Switch, Tooltip, Typography,
} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  ArrowBackIosNew,
  ArrowForwardIos, NotInterested,
  PublishedWithChanges,
  UnpublishedOutlined
} from "@mui/icons-material";
import {contentFolder} from "../store/selectors";
// END material

function Folder(props) {
  const [folderEnabled, setFolderEnabled] = useState(props.contentFolder.enabled);
  const [folderSelected, setFolderSelected] = useState(props.contentFolder.selected);

  useEffect(() => {
    setFolderEnabled(props.contentFolder.enabled);
  }, [props.contentFolder.enabled]);

  const handlePackEnabledSwitchChange = (enabled) => {
    props.handlePackEnabledSwitchChange(props.contentFolder, enabled);
  };

  const handleFolderSelectionChange = (checked) => {
    setFolderSelected(checked);
    props.handleFolderSelectionChange(props.contentFolder.uid, checked);
  };

  let carousel = createRef();

  const handleCarouselScrollForwardClick = () => {
    let carouselAssetNodes = carousel.current.childNodes;

    for( let index = 0; index < carouselAssetNodes.length; index ++ ) {
      let currentItemRightOffset = carouselAssetNodes[index].offsetWidth * (index + 1);
      let currentItemLeftOffset = carouselAssetNodes[index].offsetWidth * (index);
      let carouselRightMarginScroll = carousel.current.offsetWidth + carousel.current.scrollLeft;

      if( currentItemRightOffset > carouselRightMarginScroll && currentItemLeftOffset <= carouselRightMarginScroll ) {
        carousel.current.scrollLeft = carousel.current.scrollLeft + (currentItemRightOffset - carouselRightMarginScroll);
        break;
      }
    }
  };

  const handleCarouselScrollBackwardClick = () => {
    let carouselAssetNodes = carousel.current.childNodes;

    for( let index = 0; index < carouselAssetNodes.length; index ++ ) {
      let currentItemRightOffset = carouselAssetNodes[index].offsetWidth * (index + 1);
      let currentItemLeftOffset = carouselAssetNodes[index].offsetWidth * (index);
      let carouselLeftMarginScroll = carousel.current.scrollLeft;

      if( currentItemRightOffset >= carouselLeftMarginScroll && currentItemLeftOffset < carouselLeftMarginScroll ) {
        carousel.current.scrollLeft = carousel.current.scrollLeft - (carouselLeftMarginScroll - currentItemLeftOffset);
        break;
      }
    }
  };

  return (
    <Box sx={{ position: 'relative', my: 6, ml: 5 }}>
      {props.isDraggable && (
        <Box sx={{ position: 'absolute', right: '100%', mr: 5, height: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {props.index === 0 ? (
            <IconButton id="button_sort_up" size="small" disabled >
              <ArrowUpwardIcon />
            </IconButton>
          ) : (
            <IconButton id="button_sort_up" size="small" onClick={() => props.handleMoveUp(props.index)}>
              <ArrowUpwardIcon color="primary" />
            </IconButton>
          )}

          <Typography variant="h4" color="text.secondary">{props.contentFolder.rank}</Typography>

          {props.index === props.contentFolders.data.length - 1 ? (
            <IconButton id="button_sort_down" size="small" disabled >
              <ArrowDownwardIcon />
            </IconButton>
          ) : (
            <IconButton id="button_sort_down" size="small" onClick={() => props.handleMoveDown(props.index)}>
              <ArrowDownwardIcon color="primary" />
            </IconButton>
          )}
        </Box>
      )}

      <Paper elevation={1} sx={{ width: 1,
                                 maxWidth: 1,
                                 pb:  props.contentFolder.type.identifier === 'sticker' ? 0 : 4 }}>
        <Box sx={{px: 6, py: 2, flex: '1 1 0%'}}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 2, borderColor: 'background.dark' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
              <FormGroup sx={{ color: 'text.primary' }}>
                <FormControlLabel control={<Checkbox id={'checkbox_pack_selected_' + props.index}
                                                     color="primary" checked={folderSelected} sx={{ color: 'text.primary' }}
                                                     onChange={((event) => handleFolderSelectionChange(event.target.checked))} />}
                                  label={`${props.contentFolder.name}`}/>
              </FormGroup>

              <Typography id={'label_pack_assets_count_' + props.index}>({ props.contentFolder.assets.data.length } assets / { props.contentFolder.totalAssetClicksCount } clicks)</Typography>

              <Box sx={{ borderBottom: 2, borderColor: 'background.dark', pl: 6 }}>
                <Chip variant="outlined" size="small" id={'chip_pack_has_published_changes_' + props.index} label={props.contentFolder.inSyncWithMocha ? 'No unpublished changes' : 'Unpublished changes'}
                      icon={props.contentFolder.inSyncWithMocha ?
                        <PublishedWithChanges id={'icon_no_unpublished_changes_' + props.index} /> :
                        <UnpublishedOutlined id={'icon_has_unpublished_changes_' + props.index } />
                      }
                      sx={{ borderColor: props.contentFolder.inSyncWithMocha ? 'success.main' : 'danger.main' }} />
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignitems: 'center' }}>
              {props.contentFolder.channels.data.map((channel, channelIndex) => {
                return (
                  <Fab id={`fab_folder_${props.index}_channel_${channelIndex}`} key={`fab_folder_${props.index}_channel_${channelIndex}`} variant="extended" size="small"
                       sx={{ textTransform: 'capitalize',
                         fontWeight: 'regular',
                         fontSize: '0.8rem',
                         m: 2,
                         py: 2,
                         px: 4,
                         bgcolor: 'tertiary.light',
                         boxShadow: 'none' }}>
                    {channel.name}
                  </Fab>
                );
              })}

              <Switch id={`switch_pack_enabled_${props.index}`}
                      disabled={props.contentFolder.isLoading}
                      checked={folderEnabled} size="small" color="success" size="large"
                      onChange={(event) => handlePackEnabledSwitchChange(event.target.checked)} />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', width: 1, maxWidth: 1, justifyContent: 'space-between', pt: 6 }}>
            <Box sx={{  display: 'flex', flex: '1 1 0%', alignItems: 'center', minWidth: 0, position: 'relative' }}>
              {!props.contentFolder.enabled &&
                <Box sx={{
                  position: 'absolute',
                  top: 0, bottom: 0, right: 0, left: 0,
                  bgcolor: 'background.paper',
                  opacity: 0.7,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1
                }}>
                  <Tooltip title="Pack is disabled">
                    <NotInterested sx={{ width: 64, height: 64 }}></NotInterested>
                  </Tooltip>
                </Box>
              }

              {props.contentFolder.type.identifier !== 'sticker' &&
                <IconButton id={`button_scroll_backwards_${props.index}`} aria-label="scroll-back"
                            onClick={() => handleCarouselScrollBackwardClick()}
                            sx={{ boxShadow: 4, borderRadius: 1, mr: 12 }} >
                  <ArrowBackIosNew sx={{ my: 3, color: 'primary.main' }} />
                </IconButton>
              }

              <Box id={`carousel_${props.index}`}
                   ref={carousel}
                   sx={{ display: 'flex',
                         flex: '1 1 0',
                         flexWrap: props.contentFolder.type.identifier === 'sticker' ? 'wrap' : 'nowrap',
                         scrollBehavior: 'smooth',
                         overflow: 'hidden',
                        }}>
                { props.contentFolder.assets.data.map((item, assetIndex) => {
                  switch( props.contentFolder.type.identifier ) {
                    case 'sticker':
                      return (
                        <Box id={`content_pack_${props.index}_content_asset_${assetIndex}`}
                             key={`content_asset_${item.uid}`}
                             sx={{ height: 64, width: 64, mr: 4, borderRadius: '3px', overflow: 'hidden' }}>
                          <img src={item.mainResource.preview_url} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </Box>
                      );

                    case 'wallpaper':
                      return (
                        <Box id={`content_pack_${props.index}_content_asset_${assetIndex}`} key={`content_asset_${item.uid}`}>
                          <Box sx={{ height: 138, width: 88, mr: 4, borderRadius: '3px', overflow: 'hidden', flexShrink: 0 }}>
                            <img src={item.mainResource.preview_url} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                          </Box>
                        </Box>
                      );

                    case 'gif':
                      return (
                        <Box id={`content_pack_${props.index}_content_asset_${assetIndex}`} key={`content_asset_${item.uid}`}>
                          <Box sx={{ height: 98, width: 144, mr: 4, borderRadius: '3px', overflow: 'hidden', flexShrink: 0 }}>
                            <img src={item.mainResource.preview_url} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                          </Box>
                        </Box>
                      );

                    case 'theme':
                      return (
                        <Box id={`content_pack_${props.index}_content_asset_${assetIndex}`} key={`content_asset_${item.uid}`}>
                          <Box sx={{ height: 98, width: 144, mr: 4, borderRadius: '3px', overflow: 'hidden', flexShrink: 0, position: 'relative' }}>
                            <Box sx={{
                              position: 'absolute',
                              bottom: 0,
                              right: '-4px',
                              px: 1,
                              bgcolor: 'background.paper',
                              opacity: 0.9,
                              borderRadius: 1,
                            }}>
                              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>v{item.version}</Typography>
                            </Box>

                            <img src={item.previewResource.preview_url} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                          </Box>
                        </Box>
                      );

                    default:
                      return false;
                  }
                })}
              </Box>

              {props.contentFolder.type.identifier !== 'sticker' &&
                <IconButton id={`button_scroll_forward_${props.index}`} aria-label="scroll-forward"
                            onClick={() => handleCarouselScrollForwardClick()}
                            sx={{boxShadow: 4, borderRadius: 1, mx: 12}}>
                  <ArrowForwardIos sx={{my: 3, color: 'primary.main'}}/>
                </IconButton>
              }
            </Box>

            <Box sx={{ pl: 2 }}>
              <Button id={`button_edit_pack_${props.index}`}
                      variant="contained" size="large" onClick={() => props.handleControlModalOpen(props.contentFolder.uid)}
                      sx={{
                        boxShadow: 'none',
                        border: 'none',
                        px: 8,
                        letterSpacing: 1.2,
                      }}>
                Edit
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default Folder;
