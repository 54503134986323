import connect from "react-redux/es/connect/connect";
import {Box, IconButton, Paper, Tooltip} from "@mui/material";

import Table from "@/components/vis/Table";
import { toHuman, toHumanPercentage } from "@/utils/numbers";
import React from "react";
import SimpleTextVisSkeleton from "../../../../components/vis/SimpleTextVisSkeleton";
import {CloudDownload} from "@mui/icons-material";
import {convertJSONtoCSV, downloadCSV} from "../../../../utils/csvDownload";

const stateToProps = ({ insights: {sections: { retention } }}) => {
  return {
    isLoading: retention.isLoading,
    isLoadingForALongTime: retention.isLoadingForALongTime,
    data: retention.data,
  };
};

function labelForDay(d) {
  return `Day ${Number(d.split('_').at(-1))}`;
}

function VisKeyboardUsage({ sx = {}, isLoading, isLoadingForALongTime, data, filters }) {
  const dayPairs = data ? Object.entries(data).filter(([key]) => key !== "day_0") : [];

  const headings = [
    { key: "day", label: "Day" },
    { key: "percentValue", label: "%", align: "right" },
    { key: "value", label: "value", align: "right" },
  ];

  const rows = dayPairs.map(([day, { percentValue, value }]) => ({
    day: labelForDay(day),
    percentValue: toHumanPercentage(percentValue),
    value: toHuman(value)
  }));

  const download = () => {
    // Transform the data into the desired structure
    const transformedData = rows.reduce((acc, x) => {
      acc[x.day] = {
        percent: x.percentValue,
        value: x.value
      };
      return acc;
    }, {});

    // Combine the transformed data with metadata
    const downloadableData = {
      ...transformedData,
      ...filters,
      time: (new Date()).toDateString()
    };

    const csvContent = convertJSONtoCSV(downloadableData);

    if (csvContent) {
      downloadCSV(downloadableData, "Keyboard Usage");
    }
  };

  return (
    <Box sx={{ display: "flex", ...sx }}>
      {isLoading &&
        <SimpleTextVisSkeleton sx={{ width:  "100%", bgcolor: "background.paper" }}
                               animation="wave"
                               title={isLoadingForALongTime ? "Retention - working" : "Retention"} />
      }

      {!isLoading && (
        <Paper sx={{ width: "100%", height: "100%", p: 4, position: 'relative', '&:hover .download-icon': { visibility: 'visible'} }}>
          <Table
            headings={headings}
            rows={rows}
            title="Retention"
            hideHeadings={true}
          />

          <Tooltip title="Download">
            <IconButton aria-label="download" className="download-icon" sx={{ position: 'absolute', top: '-16px', right: '-16px', visibility: 'hidden' }}
                        onClick={download}>
              <CloudDownload />
            </IconButton>
          </Tooltip>
        </Paper>
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisKeyboardUsage);
