import React, { useEffect } from 'react';

const ResizeListener = ({ onResize }) => {
  useEffect(() => {
    let timeoutId;

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // Call the onResize function passed from parent component
        if (typeof onResize === 'function') {
          onResize();
        }
      }, 250); // Debounce time: 250 milliseconds
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Dispatch resize event when component mounts
    if (typeof onResize === 'function') {
      onResize();
    }

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [onResize]); // Re-run effect if onResize function changes

  // Since this component doesn't render anything, you can return null
  return null;
};

export default ResizeListener;