import connect from "react-redux/es/connect/connect";
import { Box } from "@mui/material";

import _languages from "./languages.json";
import Pie from "../../../../components/vis/Pie";
import MultiDimensionalRoundedChartSkeleton from "../../../../components/vis/MultiDimensionalRoundedChartSkeleton";

const stateToProps = ({ insights: {sections: { kbLanguages } }}) => {
  return {
    isLoading: kbLanguages.isLoading,
    isLoadingForALongTime: kbLanguages.isLoadingForALongTime,
    data: kbLanguages.data,
  };
};

function mapData(data, key) {
  return data.map(d => ({ label: _languages[d[key]?.toLowerCase()] ?? d[key], value: Number(d.percentage) }));
}

function VisKbLanguages({
                      sx = {},
                      isLoading,
                      isLoadingForALongTime,
                      data,
                      filters,
                    }) {
  const hasData = data?.kb_languages && data.kb_languages.value.length > 0;
  const kbLanguageData = data && data.kb_languages && mapData(data.kb_languages.value, 'kb_language');

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && (
        <MultiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                              animation="wave"
                                              vertical={true}
                                              title={isLoadingForALongTime ? "Keyboard Language - working" : "Keyboard Language"} />
      )}

      {!isLoading && !hasData && (
        <MultiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                              animation={false}
                                              vertical={true}
                                              title="Keyboard Language - no data" />
      )}

      {!isLoading && hasData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Pie title="Keyboard Language" data={kbLanguageData} height={250} width={250} filters={filters} vertical={true} />
        </Box>
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisKbLanguages);
