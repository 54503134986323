import * as CONTENT from "./types";

const defaultState = {
  section: "contentKeyboard",
  keywords: {
    data: "",
    isLoading: false,
    success: null,
    msg: "",
  },
  createKeywords: {
    data: "",
    isLoading: false,
    msg: "",
  },
  createPack: {
    data: "",
    isLoading: false,
    msg: "",
  },
  contentTypes: {
    data: [],
    isLoading: false,
    msg: "",
  },
  createAssetList: {
    data: "",
    isLoading: false,
    msg: "",
  },
  items: [],
  contentItem: {
    data: {
      uid: "",
      name: "",
      file_type: "",
      file_size_bytes: "",
      storage_provider: "",
      storage_meta: "",
      storage_path: null,
      url: "",
      preview_url: "",
      file: null,
    },
    isLoading: false,
    isUploading: false,
    validationPassed: null,
    message: "",
  },
  previewContentItem: {
    data: {
      uid: "",
      name: "",
      file_type: "",
      file_size_bytes: "",
      storage_provider: "",
      storage_meta: "",
      storage_path: null,
      url: "",
      preview_url: "",
      file: null,
    },
    isLoading: false,
    isUploading: false,
    validationPassed: null,
    message: "",
  },
  additionalContentItems: [],
  contentFolders: {
    data: [],
    isLoading: false,
    message: "",
    selectedCount: 0,
  },
  contentFolder: {
    data: {
      uid: "",
      type: "",
      name: "",
      enabled: true,
      numTotalAssets: 0,
      hasUnpublishedChanges: false,
      createdAt: "",
      updatedAt: "",
      keywords: {},
      revenueChannels: {
        data: [],
      },
      giphyChannels: [],
      schedule: [],
    },
    isLoading: false,
    rankValidationPassed: null,
    categoryValidationPassed: null,
    nameValidationPassed: null,
    message: "",
  },
  contentAssets: {
    data: [],
    isLoading: false,
    message: "",
  },
  giphyChannels: [],
  giphyChannel: {
    data: {
      uid: '',
      name: '',
      gifsCount: 0,
      previewUrl: '',
    },
    isLoading: false,
    isUploading: false,
    validationPassed: null,
    message: "",
  },
  diffs: {
    data: [],
    isLoading: false,
    success: null,
    message: "",
  },
  publishToMocha: {
    isLoading: false,
    success: null,
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CONTENT.LOAD_CONTENT_ITEMS: {
      const { data, msg } = action.payload;
      return {
        ...state,
        items: [
          ...state.items,
          {
            data: data,
            msg: msg,
            isLoading: true,
          },
        ],
      };
    }

    case CONTENT.SET_CONTENT_ITEMS: {
      // Ghergheles cosmin
      const { data, msg } = action.payload;
      return {
        ...state,
        items: state.items.map((item, index) =>
          index === data.index
            ? { ...item, data: data, msg: msg, isLoading: false }
            : item
        ),
      };
    }

    case CONTENT.REMOVE_CONTENT_ITEMS: {
      return {
        ...state,
        items: [],
      };
    }

    case CONTENT.LOAD_DELETE_CONTENT_ITEM: {
      return {
        ...state,
      };
    }

    case CONTENT.SET_DELETE_CONTENT_ITEM: {
      let stateItems = [];

      for (let i = 0; i < state.items.length; i++) {
        if (state.items[i].data.uid !== action.payload.data.contentItemUid &&
           (state.items[i].data.zip && state.items[i].data.zip.uid) !== action.payload.data.contentItemUid &&
           (state.items[i].data.preview && state.items[i].data.preview.uid ) !== action.payload.data.contentItemUid) {
          stateItems.push(state.items[i]);
        }
      }

      return {
        ...state,
        items: stateItems,
      };
    }

    case CONTENT.SET_DELETE_CONTENT_ITEM_BY_INDEX: {
      let stateItems = [];

      for (let i = 0; i < state.items.length; i++) {
        if (state.items[i].data.index !== action.payload.index) {
          stateItems.push(state.items[i]);
        }
      }

      return {
        ...state,
        items: stateItems,
      };
    }

    case CONTENT.LOAD_UPDATE_CONTENT_ITEM: {
      return {
        ...state,
        contentItem: {
          data: state.contentItem.data,
          isLoading: true,
          message: "",
        },
      };
    }

    case CONTENT.LOAD_UPDATE_LINKED_CONTENT_ITEM: {
      return {
        ...state,
        contentItem: {
          data: state.contentItem.data,
          isLoading: true,
          message: "",
        },
      };
    }

    case CONTENT.SET_UPDATE_CONTENT_ITEM: {
      return {
        ...state,
        contentAssets: {
          ...state.contentAssets,
          data: state.contentAssets.data.map((asset) => {
            return {
              ...asset,
              items: asset.items.map((item) => {
                if (item.uid === action.payload.data.uid) {
                  return {
                    ...item,
                    url: action.payload.data.url,
                    preview_url: action.payload.data.preview_url
                  };
                }

                return item;
              })
            };
          })
        },
        contentItem: {
          data: defaultState.contentItem.data,
          validationPassed: null,
          isLoading: false,
          message: action.payload.message,
        },
      };
    }

    case CONTENT.LOAD_CHECK_COMPOUND_ITEM:
      return {
        ...state,
        contentItem: {
          ...state.contentItem,
          validationPassed: null,
          isLoading: true,
          isUploading: false,
          message: "",
        },
        previewContentItem: {
          ...state.contentItem,
          validationPassed: null,
          isLoading: true,
          isUploading: false,
          message: "",
        },
      };

    case CONTENT.SET_CHECK_COMPOUND_ITEM:
      const { mainItem, mainItemPasses, mainItemMessage, previewItem, previewItemPasses, previewItemMessage, restOfTheItems, themeVersion } = action.payload;

      return {
        ...state,
        contentItem: {
          ...state.contentItem,
          data: {
            uid: "",
            name: mainItem.name ?? defaultState.contentItem.data.name,
            file_type: mainItem.file != null ? mainItem.file.type : defaultState.contentItem.data.file_type,
            file_size_bytes: mainItem.file != null ? mainItem.file.size : defaultState.contentItem.data.file_size_bytes,
            storage_provider: "s3",
            storage_meta: "",
            storage_path: null,
            url: mainItem.url,
            preview_url: "",
            file: mainItem.file,
            version: themeVersion,
          },
          validationPassed: mainItemPasses,
          isLoading: false,
          isUploading: false,
          message: mainItemMessage,
        },
        previewContentItem: {
          ...state.contentItem,
          data: {
            uid: "",
            name: previewItem.name ?? defaultState.previewContentItem.data.name,
            file_type: previewItem.file != null ? previewItem.file.type : defaultState.previewContentItem.data.file_type,
            file_size_bytes: previewItem.file != null ? previewItem.file.size : defaultState.previewContentItem.data.file_size_bytes,
            storage_provider: "s3",
            storage_meta: "",
            storage_path: null,
            url: previewItem.url,
            preview_url: previewItem.url,
            file: previewItem.file,
          },
          validationPassed: previewItemPasses,
          isLoading: false,
          isUploading: false,
          message: previewItemMessage,
        },
        additionalContentItems: restOfTheItems.map(item => {
          return {
            data: {
              uid: "",
              name: item.name,
              file_type: item.file.type,
              file_size_bytes: item.file.size,
              storage_provider: "s3",
              storage_meta: "",
              storage_path: null,
              url: item.url,
              preview_url: item.url,
              file: item.file,
            },
            isLoading: false,
            isUploading: false,
            validationPassed: null,
            message: "",
          }
        })
      };

    case CONTENT.SET_ADD_COMPOUND_ITEM:
      return {
        ...state,
        items: [
          ...state.items,
          {
            data: action.payload.items,
            msg: action.payload.msg ?? "",
            isLoading: false,
          }
        ],
        contentItem: defaultState.contentItem,
        previewContentItem: defaultState.previewContentItem,
      };

    case CONTENT.LOAD_CHECK_LINKED_ITEM:
      return {
        ...state,
        contentItem: {
          ...state.contentItem,
          validationPassed: null,
          isLoading: true,
          isUploading: false,
          message: "",
        }
      }

    case CONTENT.SET_CHECK_LINKED_ITEM:
      const { validatedUrl, passes } = action.payload;

      return {
        ...state,
        contentItem: {
          data: {
            ...state.contentItem.data,
            preview_url: validatedUrl,
          },
          validationPassed: passes,
          isLoading: false,
          isUploading: false,
          message: "",
        }
      }

    case CONTENT.LOAD_REPLACE_ADDITIONAL_ITEMS:
      return {
        ...state,
        contentAssets: {
          ...state.contentAssets,
          data: state.contentAssets.data.map(asset => {
            if( asset.uid === action.payload.contentAsset.uid ) {
              return {
                ...asset,
                isLoading: true,
              }
            }

            return asset;
          })
        }
      }

    case CONTENT.SET_REPLACE_ADDITIONAL_ITEMS:
      return {
        ...state,
        contentAssets: {
          ...state.contentAssets,
          data: state.contentAssets.data.map(asset => {
            if( asset.uid === action.payload.contentAsset.uid ) {
              return {
                ...asset,
                isLoading: false,
              }
            }

            return asset;
          })
        }
      }

    case CONTENT.SET_LINKED_ITEM_URL:
      const { url } = action.payload;

      return {
        ...state,
        contentItem: {
          data: {
            ...state.contentItem.data,
            url: url,
          },
          validationPassed: state.contentItem.validationPassed,
          isLoading: state.contentItem.isLoading,
          isUploading: state.contentItem.isUploading,
          message: state.contentItem.message,
        }
      }

    case CONTENT.LOAD_UPLOAD_LINKED_ITEM:
      return {
        ...state,
        contentItem: {
          ...state.contentItem,
          isUploading: true,
        },
      };

    case CONTENT.SET_UPLOAD_LINKED_ITEM:
      return {
        ...state,
        items: [
          ...state.items,
          {
            data: action.payload.data,
            msg: action.payload.msg ?? "",
            isLoading: false,
          },
        ],
        contentItem: defaultState.contentItem
      }

    case CONTENT.RESET_CONTENT_ITEM: {
      return {
        ...state,
        contentItem: defaultState.contentItem,
        previewContentItem: defaultState.previewContentItem,
      };
    }

    case CONTENT.SET_GIPHY_CHANNEL_NAME:
      const { name } = action.payload;

      return {
        ...state,
        giphyChannel: {
          ...state.giphyChannel,
          data: {
            ...state.giphyChannel.data,
            name: name,
          },
        },
      };

    case CONTENT.LOAD_CHECK_GIPHY_CHANNEL:
      return {
        ...state,
        giphyChannel: {
          ...state.giphyChannel,
          isLoading: true,
        }
      }

    case CONTENT.SET_CHECK_GIPHY_CHANNEL:
      const { gifsCount, giphyChannelValidationPasses, previewUrl } = action.payload;

      return {
        ...state,
        giphyChannel: {
          ...state.giphyChannel,
          isLoading: false,
          validationPassed: giphyChannelValidationPasses,
          data: {
            ...state.giphyChannel.data,
            gifsCount: gifsCount,
            previewUrl: previewUrl,
          },
        }
      };

    case CONTENT.LOAD_ADD_GIPHY_CHANNEL:
      return {
        ...state,
        giphyChannel: {
          ...state.giphyChannel,
          isUploading: true,
        }
      }

    case CONTENT.SET_ADD_GIPHY_CHANNEL:
      return {
        ...state,
        items: [
          ...state.items,
          ...action.payload.items.map((item) => {
            return {
              data: item,
              msg: action.payload.msg ?? "",
              isLoading: false,
            };
          })
        ],
        giphyChannels: [
          ...state.giphyChannels,
          action.payload.giphyChannel
        ],
        giphyChannel: defaultState.giphyChannel,
        contentFolders: {
          ...state.contentFolders,
          data: state.contentFolders.data.map(contentFolder => {
            if( contentFolder.uid === action.payload.contentFolderUid ) {
              return {
                ...contentFolder,
                giphyChannels: [
                  ...contentFolder.giphyChannels,
                  action.payload.giphyChannel,
                ],
              };
            }

            return contentFolder;
          }),
        },
        contentFolder: {
          ...state.contentFolder,
          data: state.contentFolder.data.uid === action.payload.contentFolderUid ? {
            ...state.contentFolder.data,
            giphyChannels: [
              ...state.contentFolder.data.giphyChannels,
              action.payload.giphyChannel,
            ],
          } : state.contentFolder.data,
        },
      }

    case CONTENT.REMOVE_GIPHY_CHANNELS: {
      return {
        ...state,
        giphyChannels: [],
      };
    }

    case CONTENT.RESET_GIPHY_CHANNEL: {
      return {
        ...state,
        giphyChannel: defaultState.giphyChannel,
      };
    }

    case CONTENT.SET_DELETE_GIPHY_CHANNEL:
      let newGiphyChannels = state.giphyChannels.filter(giphyChannel => giphyChannel.uid !== action.payload.data.giphyChannelUid);
      let newItems = state.items.filter(item => item.data.giphy_channel_uid !== action.payload.data.giphyChannelUid);
      let newFoldersData = state.contentFolders.data.map(folder => {
        return {
          ...folder,
          giphyChannels: folder.giphyChannels.filter(giphyChannel => giphyChannel.uid !== action.payload.data.giphyChannelUid)
        }
      });
      let newContentFolder = {
        ...state.contentFolder,
        data: {
          ...state.contentFolder.data,
          giphyChannels: state.contentFolder.data.giphyChannels.filter(giphyChannel => giphyChannel.uid !== action.payload.data.giphyChannelUid),
        }
      };
      let newContentAssets = {
        ...state.contentAssets,
        data: state.contentAssets.data.filter(contentAsset => (contentAsset.giphyChannel ? contentAsset.giphyChannel.uid : '') !== action.payload.data.giphyChannelUid),
      }

      return {
        ...state,
        giphyChannels: newGiphyChannels,
        items: newItems,
        contentFolders: {
          ...state.contentFolders,
          data: newFoldersData,
        },
        contentFolder: newContentFolder,
        contentAssets: newContentAssets,
      };

    case CONTENT.LOAD_CONTENT_TYPES:
      return {
        ...state,
        contentTypes: {
          ...state.contentTypes,
          isLoading: true,
        },
      };

    case CONTENT.SET_CONTENT_TYPES:
      const { data, msg, statusCode } = action.payload;
      return {
        ...state,
        contentTypes: {
          data,
          isLoading: false,
          msg,
        },
      };

    case CONTENT.LOAD_RESET_CONTENT_TYPES:
      return {
        ...state,
      };

    case CONTENT.SET_RESET_CONTENT_TYPES:
      return {
        ...state,
        contentTypes: {
          data: action.payload.data,
          isLoading: false,
          msg: "",
        },
      };

    case CONTENT.LOAD_CONTENT_KEYWORDS:
      return {
        ...state,
        keywords: {
          ...state.keywords,
          isLoading: true,
        },
      };

    case CONTENT.SET_CONTENT_KEYWORDS: {
      const { data, msg, statusCode, meta } = action.payload;

      return {
        ...state,
        keywords: {
          data,
          meta,
          isLoading: false,
        },
      };
    }

    case CONTENT.LOAD_CONTENT_CREATE_KEYWORDS:
      return {
        ...state,
        createKeywords: {
          ...state.createKeywords,
          isLoading: true,
        },
      };

    case CONTENT.SET_CONTENT_CREATE_KEYWORDS: {
      const { data, msg, statusCode } = action.payload;

      return {
        ...state,
        createKeywords: {
          data,
          isLoading: false,
          success: statusCode === 201,
          msg: msg,
        },
      };
    }

    case CONTENT.SET_CONTENT_RESET_CREATE_KEYWORDS: {
      return {
        ...state,
        createKeywords: defaultState.createKeywords,
      };
    }

    case CONTENT.LOAD_CONTENT_CREATE_ASSET_LIST: {
      return {
        ...state,
        createAssetList: {
          ...state.createAssetList,
          isLoading: true,
        },
      };
    }

    case CONTENT.SET_CONTENT_CREATE_ASSET_LIST: {
      const { data, msg } = action.payload;
      return {
        ...state,
        createAssetList: {
          data,
          msg,
          isLoading: false,
        },
      };
    }

    case CONTENT.LOAD_CONTENT_FOLDERS: {
      return {
        ...state,
        contentFolders: {
          data: [],
          message: "",
          isLoading: true,
          selectedCount: 0,
        },
      };
    }

    case CONTENT.SET_CONTENT_FOLDERS: {
      return {
        ...state,
        contentFolders: {
          data: action.payload.data.map(folder => {
            return {
              ...folder,
              isLoading: false,
            };
          }),
          message: action.payload.message,
          isLoading: false,
          selectedCount: 0,
        },
      };
    }

    case CONTENT.LOAD_CREATE_CONTENT_FOLDER: {
      return {
        ...state,
        contentFolder: {
          data: state.contentFolder.data,
          isLoading: true,
          message: "",
        },
      };
    }

    case CONTENT.SET_CREATE_CONTENT_FOLDER: {
      return {
        ...state,
        contentFolder: {
          data: {
            ...state.contentFolder.data,
            ...action.payload.data,
            schedule: [],
          },
          isLoading: false,
          message: action.payload.message,
        },
      };
    }

    case CONTENT.LOAD_READ_CONTENT_FOLDER: {
      return {
        ...state,
        contentFolder:  {
          data: state.contentFolder.data,
          isLoading: true,
          message: "",
        },
      };
    }

    case CONTENT.SET_READ_CONTENT_FOLDER: {
      return {
        ...state,
        contentFolder: {
          data: action.payload.data,
          isLoading: false,
          message: action.payload.message,
        },
      };
    }

    case CONTENT.LOAD_UPDATE_CONTENT_FOLDER: {
      return {
        ...state,
        contentFolder: {
          ...state.contentFolder,
          isLoading: true,
          message: "",
        },
        contentFolders: {
          ...state.contentFolders,
          data: state.contentFolders.data.map((folder) => {
            if( folder.uid === action.payload.folderUid ) {
              return {
                ...folder,
                isLoading: true,
              };
            }

            return folder;
          }),
        }
      };
    }

    case CONTENT.SET_UPDATE_CONTENT_FOLDER: {
      return {
        ...state,
        contentFolder: {
          data: action.payload.data,
          isLoading: false,
          message: action.payload.message,
        },
        contentFolders: {
          ...state.contentFolders,
          data: state.contentFolders.data.map((folder) => {
            if( folder.uid === action.payload.data.uid ) {
              return {
                ...folder,
                channels: action.payload.data.channels,
                enabled: action.payload.data.enabled,
                isLoading: false,
              };
            }

            return folder;
          })
        }
      };
    }

    case CONTENT.LOAD_DELETE_CONTENT_FOLDER: {
      let remainingContentFolders = [];

      state.contentFolders.data.forEach((folder) => {
        if( folder.uid !== action.payload.uid ) {
          remainingContentFolders.push(folder);
        }
      });

      return {
        ...state,
        contentFolders: {
          ...state.contentFolders,
          data: remainingContentFolders,
          selectedCount: state.contentFolders.selectedCount --
        },
      };
    }

    case CONTENT.SET_DELETE_CONTENT_FOLDER: {
      return {
        ...state,
      }
    }

    case CONTENT.LOAD_MODIFY_CONTENT_FOLDER: {
      return {
        ...state,
      };
    }

    case CONTENT.SET_MODIFY_CONTENT_FOLDER: {
      return {
        ...state,
        contentFolder: {
          data: {
            ...state.contentFolder.data,
            ...action.payload.data,
          },
          isLoading: false,
          message: action.payload.message,
        },
      };
    }

    case CONTENT.TOGGLE_ENABLED_FOLDER: {
      return {
        ...state,
        contentFolders: {
          ...state.contentFolders,
          data: state.contentFolders.data.map((item) =>
            item.uid === action.payload.id
              ? { ...item, enabled: action.payload.checked }
              : item
          ),
        },
      };
    }

    case CONTENT.TOGGLE_SELECTED_FOLDER: {
      return {
        ...state,
        contentFolders: {
          ...state.contentFolders,
          data: state.contentFolders.data.map((item) => item.uid === action.payload.id ? { ...item, selected: action.payload.selected } :item),
          selectedCount: state.contentFolders.selectedCount += (action.payload.selected ? 1 : -1),
        }
      }
    }

    case CONTENT.LOAD_DELETE_CONTENT_FOLDER_ASSET: {
      return {
        ...state,
      };
    }

    case CONTENT.SET_DELETE_CONTENT_FOLDER_ASSET: {
      return {
        ...state,
        contentFolders: {
          ...state.contentFolders,
          data: state.contentFolders.data.map(contentFolder => {
            return {
              ...contentFolder,
              giphyChannels: contentFolder.giphyChannels.map(giphyChannel => {
                return {
                  ...giphyChannel,
                  gifs_count: giphyChannel.uid === action.payload.giphyChannelUid ? giphyChannel.gifs_count - 1 : giphyChannel.gifs_count
                };
              }),
            };
          })
        },
        contentFolder: {
          ...state.contentFolder,
          data: {
            ...state.contentFolder.data,
            giphyChannels: state.contentFolder.data.giphyChannels.map(giphyChannel => {
              return {
                ...giphyChannel,
                gifs_count: giphyChannel.uid === action.payload.giphyChannelUid ? giphyChannel.gifs_count - 1 : giphyChannel.gifs_count
              }
            })
          },
        }
      };
    }

    case CONTENT.LOAD_RESET_CONTENT_FOLDERS: {
      return {
        ...state,
      };
    }

    case CONTENT.SET_RESET_CONTENT_FOLDERS: {
      return {
        ...state,
        contentFolders: {
          data: action.payload.data,
          isLoading: false,
          message: action.payload.message,
        },
      };
    }

    case CONTENT.LOAD_CONTENT_ASSETS: {
      return {
        ...state,
      };
    }

    case CONTENT.SET_CONTENT_ASSETS: {
      return {
        ...state,
      };
    }

    case CONTENT.SET_MODIFY_CONTENT_ASSETS: {
      const { assets } = action.payload;

      return {
        ...state,
        contentAssets: {
          data: [...assets],
          isLoading: false,
          message: "",
        },
      };
    }

    case CONTENT.SET_TOGGLE_CONTENT_ASSET_REPLACE_FORM:
      return {
        ...state,
        contentAssets: {
          ...state.contentAssets,
          data: state.contentAssets.data.map(asset => {
            if (asset.uid === action.payload.assetUid) {
              asset.showReplaceAsset = !asset.showReplaceAsset;
            }

            return asset;
          })
        }
      }

    case CONTENT.LOAD_CREATE_CONTENT_ASSETS: {
      return {
        ...state,
        contentAssets: {
          data: state.contentAssets.data,
          isLoading: true,
          message: "",
        },
      };
    }

    case CONTENT.SET_CREATE_CONTENT_ASSETS: {
      return {
        ...state,
        contentAssets: {
          data: [
            ...state.contentAssets.data,
            ...action.payload.data.map(asset => {
              return {
                ...asset,
                folders: asset.folders.map(folder => {
                  return {
                    ...folder,
                    pivot: {
                      rank: folder.rank,
                      enabled: folder.enabled,
                      featured: folder.featured,
                    }
                  };
                }),
              };
            }),
          ],
          isLoading: false,
          message: action.payload.message,
        },
      };
    }

    case CONTENT.LOAD_READ_CONTENT_ASSETS: {
      return {
        ...state,
        contentAssets: {
          data: state.contentAssets.data,
          isLoading: true,
          message: "",
        },
      };
    }

    case CONTENT.SET_READ_CONTENT_ASSETS: {
      return {
        ...state,
        contentAssets: {
          data: action.payload.data.map(asset => {
            asset.isDirty = false;
            return asset;
          }),
          isLoading: false,
          message: action.payload.message,
        },
      };
    }

    case CONTENT.LOAD_UPDATE_CONTENT_ASSET: {
      return {
        ...state,
      };
    }

    case CONTENT.SET_UPDATE_CONTENT_ASSET: {
      return {
        ...state,
        contentAssets: {
          ...state.contentAssets,
          data: state.contentAssets.data.map(asset => {
            if( asset.uid === action.payload.asset.uid ) {
              return {
                ...asset,
                ...action.payload.asset,
                folders: asset.folders.map(folder => {
                  let payloadFolder = action.payload.asset.folders.find(pFolder => pFolder.uid === folder.uid);

                  return {
                    ...folder,
                    pivot: {
                      ...folder.pivot,
                      rank: payloadFolder.rank,
                      enabled: payloadFolder.enabled,
                      featured: payloadFolder.featured,
                    }
                  };
                }),
              };
            }

            return asset;
          })
        }
      };
    }

    case CONTENT.LOAD_DELETE_CONTENT_ASSET: {
      return {
        ...state,
      };
    }

    case CONTENT.SET_DELETE_CONTENT_ASSET: {
      return {
        ...state,
      };
    }

    case CONTENT.SET_RESET_CONTENT_ASSETS: {
      return {
        ...state,
        contentAssets: {
          data: [],
          isLoading: false,
          message: "",
        },
      };
    }

    case CONTENT.LOAD_PUBLISH_CONTENT:
      return {
        ...state,
        publishToMocha: {
          ...state.publishToMocha,
          isLoading: false,
          success: null,
        },
        diffs: {
          ...state.diffs,
          isLoading: true,
          message: "",
          success: null
        }
      }

    case CONTENT.SET_DIFFS:
      return {
        ...state,
        diffs: {
          ...state.diffs,
          data: action.payload.diffs,
          isLoading: false,
          message: action.payload.message,
          success: action.payload.success,
        }
      }

    case CONTENT.PUBLISH_TO_MOCHA:
      return {
        ...state,
        publishToMocha: {
          ...state.publishToMocha,
          isLoading: true,
          success: null,
        }
      }

    case CONTENT.PUBLISH_TO_MOCHA_FINISHED:
      return {
        ...state,
        publishToMocha: {
          ...state.publishToMocha,
          isLoading: false,
          success: action.payload.success,
        },
        diffs: {
          ...state.diffs,
          data: Object.fromEntries(Object.entries(state.diffs.data).map(([key, value]) => {
            return [key, {
              vibes: {
                new: [],
                modified: [],
                deleted: [],
              },
              keyboard_themes: {
                new: [],
                modified: [],
                deleted: [],
              },
              sdk_themes: {
                new: [],
                modified: [],
                deleted: [],
              },
            }];
          }))
        }
      }

    default:
      return state;
  }
}
