import React, {useEffect, useState} from 'react';
import connect from 'react-redux/es/connect/connect';
import {getValue} from 'AppUtils/objects';
import {Box, Breadcrumbs, Container, Link} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Page from '../../../components/Page';
import UserAddForm from '../subcomponents/UserAddForm';
import Header from '../subcomponents/Header';
import {useSnackbar} from 'notistack';
import {
  clientsLoadCreateUsers,
  clientsLoadOrganization,
  clientsLoadResetMsg,
  clientsLoadResetOrganization,
  clientsLoadTenantUsers,
  clientsLoadUpdateTenantUsers
} from "../../clients/store/actions";
import {
  clientsCreateUsers,
  clientsMsg,
  clientsOrganization,
  clientsTenantUsers,
  clientsUpdateTenantUsers
} from "../../clients/store/selectors";

import {uiRedirect} from '../../../store/actions';
import {Link as RouterLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const stateToProps = state => ({
  clientsOrganization: clientsOrganization(state),
  clientsCreateUsers: clientsCreateUsers(state),
  clientsUpdateTenantUsers: clientsUpdateTenantUsers(state),
  clientsTenantUsers: clientsTenantUsers(state),
  clientsMsg: clientsMsg(state),
});

const actionsToProps = dispatch => ({
  uiRedirect: (url) => dispatch(uiRedirect(url)),
  clientsLoadOrganization: (payload) => dispatch(clientsLoadOrganization(payload)),
  clientsLoadResetOrganization: (payload) => dispatch(clientsLoadResetOrganization(payload)),
  clientsLoadCreateUsers: (payload) => dispatch(clientsLoadCreateUsers(payload)),
  clientsLoadUpdateTenantUsers: (payload) => dispatch(clientsLoadUpdateTenantUsers(payload)),
  clientsLoadTenantUsers: (payload) => dispatch(clientsLoadTenantUsers(payload)),
  clientsLoadResetMsg: () => dispatch(clientsLoadResetMsg()),
});

const UserAdd = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [confirmation, setConfirmation] = useState(false);
  const [isSubmittingState, setIsSubmittingState] = useState(false);
  const [user, setUser] = useState('');
  const clientId = getValue(props, 'match.params.clientId');
  const userId = getValue(props, 'match.params.userId');
  const [errorsServer, setErrorsServer] = useState([]);


  useEffect(() => {
    props.clientsLoadOrganization({id: clientId});
    if (userId) {
      props.clientsLoadTenantUsers({tenantId: clientId, userId});
    }

    return function cleanup() {
      props.clientsLoadResetOrganization();
      props.clientsLoadResetMsg();
    }
  }, []);

  useEffect(() => {
    if (getValue(props, 'clientsTenantUsers.data.data')) {
      setUser(getValue(props, 'clientsTenantUsers.data.data'));
    } else if (props.clientsMsg) {
      enqueueSnackbar(props.clientsMsg.msg, {
        variant: 'error',
        persist: true
      });

      setErrorsServer(props.clientsMsg.errors);
    }
  }, [props.clientsTenantUsers.data]);

  useEffect(() => {
    if (getValue(props, 'clientsCreateUsers.data.data')) {
      setConfirmation(true);
      enqueueSnackbar('User was created successfully!', {
        variant: 'success',
        persist: true
      });
      props.uiRedirect({
        pathname: `/clients/${clientId}/settings`
      })
    } else if (props.clientsMsg) {
      enqueueSnackbar(props.clientsMsg.msg, {
        variant: 'error',
        persist: true
      });
      setErrorsServer(props.clientsMsg.errors);
    }

    setIsSubmittingState(false);
  }, [props.clientsCreateUsers]);

  useEffect(() => {
    if (getValue(props, 'clientsUpdateTenantUsers.data') == 'updated') {
      enqueueSnackbar('User was updated successfully!', {
        variant: 'success',
        persist: true
      });
      props.uiRedirect({
        pathname: `/clients/${clientId}/settings`
      })
    } else if (props.clientsMsg) {
      enqueueSnackbar(props.clientsMsg.msg, {
        variant: 'error',
        persist: true
      });

      setErrorsServer(props.clientsMsg.errors);
    }

    setIsSubmittingState(false);
  }, [props.clientsUpdateTenantUsers.data]);

  const addUser = (values) => {
    values.role = getValue(values, 'role.value');

    props.clientsLoadCreateUsers({user: values, tenantId: clientId});
  };

  const updateUser = (values) => {
    values.role = getValue(values, 'role.value');
    delete values['email'];

    props.clientsLoadUpdateTenantUsers({user: values, tenantId: clientId, userId});
  };

  const breadCrumbs =
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to={`/clients/${props.match.params.clientId}/settings`} component={RouterLink}>
        Management
      </Link>

      <Link bariant="body1" to={`/clients/${props.match.params.clientId}/settings`} component={RouterLink}>
        {props.clientsOrganization.data.name}
      </Link>

      <Link bariant="body1" to="#" component={RouterLink}>
        Add user
      </Link>
    </Breadcrumbs>;

  return (
    <Page title="New user" key="page_add_user" breadCrumbs={breadCrumbs}>
      <Box mt={3}>
        <Container maxWidth="sm">
          <UserAddForm
            isSubmittingState={isSubmittingState}
            setIsSubmittingState={setIsSubmittingState}
            addUser={addUser}
            updateUser={updateUser}
            user={user}
            edit={userId ? true : false}
            errorsServer={errorsServer}
            setErrorsServer={setErrorsServer}
          />
        </Container>
      </Box>
    </Page>
  );
};

export default connect(stateToProps, actionsToProps)(UserAdd);
