import React, {useRef, useState} from 'react';
import {capitalCase} from 'change-case';
import {
  Badge,
  Box,
  Button,
  IconButton,
  Popover,
  SvgIcon,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import useSettings from '../../hooks/useSettings';
import {THEMES} from '../../constants';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

const Settings = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const {settings, saveSettings} = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Settings">
        <IconButton color="inherit" onClick={handleOpen} ref={ref}>
          <SvgIcon sx={{ color: 'text.secondary' }}>
            <Brightness4Icon />
          </SvgIcon>
        </IconButton>
      </Tooltip>

      <Popover anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
               PaperProps={{ sx: { width: 320, p: 4 } }}
               anchorEl={ref.current}
               onClose={handleClose}
               open={isOpen}>
        <Typography variant="h4" color="textPrimary">
          Settings
        </Typography>

        <Box mt={2}>
          <TextField fullWidth label="Theme" name="theme" select SelectProps={{native: true}} value={values.theme} variant="outlined"
                      onChange={(event) => handleChange('theme', event.target.value)}>
            {Object.keys(THEMES).map((theme) => (
                <option key={theme} value={theme}>
                  {capitalCase(theme)}
                </option>
              ))}
          </TextField>
        </Box>

        <Box mt={2}>
          <Button variant="contained" color="secondary" fullWidth onClick={handleSave}>
            Save Settings
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default Settings;
