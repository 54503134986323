import moment from "moment";
import connect from "react-redux/es/connect/connect";
import {Box, Button, Link} from "@mui/material";

import {filtersChanged, setFilters} from "../../store/actions";
import DatePresetFilter from "./DatePresetFilter";
import DateFilter from "./DateFilter";
import MultiSelectFilter from "./MultiSelectFilter";

import _countries from "./countries.json";
import UserFilter from "./UserFilter";
import React, {useState} from "react";
import {Done, Visibility, VisibilityOff} from "@mui/icons-material";

const DATE_PRESETS = [
  { label: "Last Day", value: "last_day" },
  { label: "Last 3 Days", value: "last_3_days" },
  { label: "Last 7 Days", value: "last_7_days" },
  { label: "Last 14 Days", value: "last_14_days" },
  { label: "Last 30 Days", value: "last_30_days" },
  { label: "This Month", value: "this_month" },
  { label: "Last Month", value: "last_month" },
  { label: "This Year", value: "this_year" },
  { label: "Last Year", value: "last_year" },
];

const OS_TYPES = [
  { label: "Android", value: "android" },
  { label: "iOS", value: "ios" },
];

const GENDERS = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

const COUNTRIES = _countries.map((c) => ({
  label: c.name,
  value: c["alpha-2"],
}));

const stateToProps = ({
  insights: {
    filters: { date_from, date_to, date_preset, os_types, genders, countries },
  },
}) => {
  return {
    dateFrom: date_from,
    dateTo: date_to,
    datePreset: date_preset,
    osTypes: os_types,
    genders,
    countries,
  };
};

const actionToProps = (dispatch) => ({
  setFilters: (key, value) => dispatch(setFilters({ [key]: value })),
  filtersChanged: (data) => dispatch(filtersChanged({...data})),
});

function Filters({
  dateFrom,
  dateTo,
  datePreset,
  osTypes,
  genders,
  countries,
  setFilters,
  filtersChanged,
  audiences,
  dashboard,
  showUserSpecificFilters = true,
}) {
  const [filtersShown, setFiltersShown] = useState(false);

  const announceFiltersChange = () => {
    setTimeout(() => {
      filtersChanged({ dashboard: dashboard });
    }, 500);
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {showUserSpecificFilters && (
        <Link href="#" underline="hover" color="inherit" variant="body1"
              onClick={() => setFiltersShown(!filtersShown)}
              sx={{ position: 'absolute', right: 0, top: '-35px', display: 'flex', alignItems: 'center', color: "text.secondary" }}>
          <Box sx={{ mr: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {filtersShown ? (<VisibilityOff />) : (<Visibility />)}
          </Box>

          <Box>
            {filtersShown ? 'Hide audience filter' : 'Show audience filter'}
          </Box>
        </Link>
      )}

      {/*Always on filters*/}
      <Box sx={{ display: "flex", gap: 3, mb: 4 }}>
        <DatePresetFilter
          presets={DATE_PRESETS}
          label="Date Range"
          onChange={(value) => {
            setFilters("date_preset", value);
            setFilters("date_to", undefined);
            setFilters("date_from", undefined);
          }}
          value={datePreset}
          customLabel="Custom Range"
          dateFrom={dateFrom}
          dateTo={dateTo}
          custom={() => {
            return (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                <DateFilter
                  label="Start Date"
                  value={dateFrom}
                  onChange={(newDateFrom) => {
                    setFilters("date_preset", undefined);
                    setFilters("date_from", newDateFrom);
                  }}
                  minDate={moment().subtract(3, "months").format("YYYY-MM-DD")}
                  maxDate={dateTo}
                />
                <DateFilter
                  label="End Date"
                  value={dateTo}
                  minDate={dateFrom}
                  maxDate={moment().format("YYYY-MM-DD")}
                  onChange={(newDateTo) => {
                    setFilters("date_preset", undefined);
                    setFilters("date_to", newDateTo);
                  }}
                />
              </Box>
            );
          }}
        />
        <MultiSelectFilter
          sx={{ flex: "1 1 0px" }}
          label="Device Type"
          options={OS_TYPES}
          onChange={(t) => {
            setFilters("os_types", t);
          }}
          value={osTypes}
        />

        {showUserSpecificFilters && (
          <MultiSelectFilter
            sx={{ flex: "1 1 0px" }}
            label="Gender"
            options={GENDERS}
            onChange={(t) => {
              setFilters("genders", t);
            }}
            value={genders}
          />
        )}

        <MultiSelectFilter
          sx={{ flex: "1 1 0px" }}
          label="Country"
          options={COUNTRIES}
          onChange={(t) => {
            setFilters("countries", t);
          }}
          value={countries}
        />

        <Button variant="contained" endIcon={<Done />} onClick={() => {
          announceFiltersChange();
        }}>
          Apply filters
        </Button>
      </Box>

      {/*Toggleable filters*/}
      {filtersShown && (
        <Box>
          {/*Individual user filter*/}
          <UserFilter announceFilterChanges={announceFiltersChange} setFilters={setFilters} audiences={audiences} sx={{ flex: "1 1 0%" }} />
        </Box>
      )}
    </Box>
  );
}

export default connect(stateToProps, actionToProps)(Filters);
