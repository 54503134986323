const activeFormSectionUid = state => {
  if( state.orchestration.clientCreation.formSectionIsActive ) {
    return state.orchestration.clientCreation.formSectionUid;
  }

  if( state.orchestration.campaignSetup.formSectionIsActive ) {
    return state.orchestration.campaignSetup.formSectionUid;
  }

  if( state.orchestration.socialSetup.formSectionIsActive ) {
    return state.orchestration.socialSetup.formSectionUid;
  }

  return state.orchestration.review.formSectionUid;
};
const orchestrationIsLoading = state => state.orchestration.orchestration.isLoading;
const orchestrationErrors = state => state.orchestration.orchestration.errors;

const clientCreationData = state => state.orchestration.clientCreation.data;
const campaignSetupData = state => state.orchestration.campaignSetup.data;
const socialSetupData = state => state.orchestration.socialSetup.data;

const quicklinks = state => state.orchestration.quicklinks.data;

export {
  activeFormSectionUid,
  orchestrationIsLoading,
  orchestrationErrors,
  clientCreationData,
  campaignSetupData,
  socialSetupData,
  quicklinks,
};
