import Observable from 'Observable';
import {fork, put, takeEvery} from 'redux-saga/effects';
import * as ORCHESTRATION from "./types";
import {apiGet, apiPost, apiPostJson} from "../../../utils/api";
import {UI_REDIRECT} from "../../../store/types";
import {USER_LOAD_CHANNELS} from "../../user/store/types";


function* onLoadQuicklinks(action) {
  const retry = 3;
  let msg;
  let statusCode = 0;
  let data = [];

  yield apiGet('/orchestration/quicklinks')
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if (response && !response.errors) {
        data = response.data;
      } else {
        msg = response.message;
      }
    });

  yield put({
    type: ORCHESTRATION.SET_QUICKLINKS,
    payload: {data, msg, statusCode}
  });
}

function* onLoadOrchestration(action) {
  const retry = 3;
  let msg;
  let statusCode = 0;
  let errors = [];
  let newTenantId;

  const payload = {
    clientName: action.payload.clientName,
    hasIosCampaign: action.payload.hasIosCampaign,
    hasAndroidCampaign: action.payload.hasAndroidCampaign,
    quicklinkSets: action.payload.quicklinkSets,
  };

  if( action.payload.twitterId.trim().length > 0 ) {
    payload.twitterId = action.payload.twitterId;
  }

  if( action.payload.youtubeId.trim().length > 0 ) {
    payload.youtubeId = action.payload.youtubeId;
  }

  if( action.payload.facebookId.trim().length > 0 ) {
    payload.facebookId = action.payload.facebookId;
  }

  yield apiPostJson('/orchestration', payload)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if ( !response || response.errors ) {
        msg = response.message;
        errors = response.errors;
      } else {
        newTenantId = response.tenantId;
      }
    });

  yield put({
    type: ORCHESTRATION.SET_ORCHESTRATION,
    payload: {errors, msg, statusCode},
  });

  if( newTenantId ) {
    const url = new URL(window.location);

    window.location.href = `${url.origin}/clients/${newTenantId}/onboarding`
  }
}

function* userWatchInitialize() {
  yield takeEvery(ORCHESTRATION.LOAD_QUICKLINKS, onLoadQuicklinks);
  yield takeEvery(ORCHESTRATION.LOAD_ORCHESTRATION, onLoadOrchestration);
}

export default function* sagas() {
  yield fork(userWatchInitialize);
}
