import {Box, Skeleton, Typography, useTheme} from "@mui/material";
import React from "react";

function SimpleTextVisSkeleton ({sx, animation = false, title, ...props}) {
  const theme = useTheme();

  return (
    <Box sx={sx}>
      <Box
        borderRadius={1}
        border={1}
        sx={{ width: "100%", height: "100%",  display: "flex", flexDirection: "column", borderColor: theme.palette.background.darkest, p: 4, gap: 4 }}
      >
        {title && (
          <Typography variant="body2" component="div" sx={{ color: 'text.primary' }} >
            {title}
          </Typography>
        )}

        {!title && (
          <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "50%" }} />
        )}

        <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "100%" }} />
        <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "100%" }} />
        <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "100%", mb: "32px" }} />

        <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "50%" }} />
        <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "100%" }} />
        <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "100%" }} />
      </Box>
    </Box>
  );
}

export default SimpleTextVisSkeleton;