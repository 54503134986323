import {Box, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import {useState} from "react";
import OperatorSelector from "../OperatorSelector";
import {Clear} from "@mui/icons-material";
import {capitalizeSnakeCase} from "../../../../utils/string";

const stateToProps = (state) => ({});

const actionToProps = (dispatch) => ({});

const SelectionFilter = ({filterName, possibleOperators, possibleValues, handleValueChange, handleOperatorChange}) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (newValue) => {
    setSelectedValue(newValue);
    handleValueChange(newValue);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', left: -40, top: 16 }}>
        <Clear onClick={() => handleChange('')} sx={{ cursor: 'pointer', color: 'text.secondary', '&:hover': { color: 'text.primary' } }} />
      </Box>

      <Box sx={{ display: 'flex' }}>
        {possibleOperators.length > 1 && (
          <Box sx={{ width: '25%', mr: 2 }}>
            <OperatorSelector filterName={filterName}
                              availableOperators={possibleOperators}
                              handleOperatorChange={handleOperatorChange}>
            </OperatorSelector>
          </Box>
        )}

        <Box sx={{ flex: '1 1 0%' }}>
          <FormControl fullWidth>
            <InputLabel id={`${filterName}-filter-label`}>{capitalizeSnakeCase(filterName)}</InputLabel>
            <Select labelId={`${filterName}-filter-label`}
                    id={`${filterName}-filter-select`}
                    label={capitalizeSnakeCase(filterName)}
                    value={selectedValue}
                    onChange={(event) => handleChange(event.target.value)}>
              {possibleValues.map(possibleValue => (
                <MenuItem key={`month-possible-value-${possibleValue}`} value={possibleValue}>{possibleValue}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default connect(stateToProps, actionToProps)(SelectionFilter);

