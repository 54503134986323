//modules
import React, { useState, useEffect } from "react";
import connect from "react-redux/es/connect/connect";

// material ui
import {
  Box,
  Container,
  Typography,
  CircularProgress, InputUnstyled, InputBase, Grid,
} from "@mui/material";
import {makeStyles} from '@mui/styles';
import { CheckCircle, Delete, Warning } from "@mui/icons-material";
import clsx from "clsx";

// state
import { contentTypes } from "../store/selectors";

const stateToProps = (state) => ({
  contentTypes: contentTypes(state),
});

const UploadItem = (props) => {
  const [file, setFile] = useState(props.item);
  const { handleDeleteItem } = props;

  useEffect(() => {
    setFile(props.item);
  }, [props]);

  return (
    <Box sx={{ p: 0, bgcolor: 'background.paper', position: 'relative', borderRadius: 1 }}>
      <Box sx={{ bgcolor: 'background.dark', px: 4, py: 3, m: 3 }}>
        <Box container="true" sx={{ display: 'flex', alignItems: 'start' }}>
          <Box sx={{ alignSelf: 'center' }}>
            {file.isLoading ? (
              <Box>
                <CircularProgress id="spinner_preview_upload_item" size="26px" color="primary" />
              </Box>
            ) : (
              <Box>
                {props.contentType.identifier === 'sticker' && (
                  <img id="image_preview_upload_item" src={props.item.data.preview} style={{ height: 56, width: 56, objectFit: 'cover' }} />
                )}
                {props.contentType.identifier === 'wallpaper' && (
                  <img id="image_preview_upload_item" src={props.item.data.preview} style={{ height: 88, width: 56, objectFit: 'cover' }} />
                )}
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', flex: '1 1 0%', alignItems: 'center' }}>
            <Box sx={{ flex: '1 1 0%', mx: 4 }}>
              <InputBase id={`input_upload_item_name_${props.item.data.uid}`}
                         type="text"
                         label={file.data.name}
                         variant="outlined"
                         size={"small"}
                         value={file.isLoading ? '' : file.data.name}
                         sx={{ bgcolor: 'background.default',
                           border: 1,
                           borderRadius: 0.5,
                           borderColor: 'secondary.dark',
                           color: 'text.secondary',
                           px: 4,
                           py: 1.5,
                           width: 1,
                           '&:focus': {
                             borderColor: 'secondary.main',
                           }}}
                         onChange={(e) => setFile({ ...file, name: e.target.value })} />
            </Box>

            <Box sx={{ bgcolor: 'background.default', width: 72, mx: 1, py: 2.5, borderRadius: '3px' }}>
              <Typography id={`label_upload_item_size_${props.item.data.uid}`}
                          sx={{ textAlign: 'center', fontSize: '0.8rem', color: 'text.secondary' }}>
                {`${Number(file.data.file_size_bytes / 1000).toFixed(0)} KB`}
              </Typography>
            </Box>

            <Box sx={{ bgcolor: 'background.default', width: 72, mx: 1, py: 2.5, borderRadius: '3px' }}>
              <Typography id={`label_upload_item_type_${props.item.data.uid}`}
                          sx={{ textTransform: "uppercase", fontSize: '0.8rem', textAlign: 'center', color: 'text.secondary' }}>
                {file.data.file_type}
              </Typography>
            </Box>

            <Box sx={{ bgcolor: 'background.default', mx: 1, p: 1.8, borderRadius: '3px' }}>
              {file.isLoading ? (
                ""
              ) : file.msg !== "" ? (
                <Warning id={`icon_warning_neutral_upload_item_${props.item.data.uid}`} sx={{ color: "danger.main", fontSize: 20 }} />
              ) : (
                <CheckCircle id={`icon_check_upload_item`} sx={{ color: 'success.main', fontSize: 20 }} />
              )}
            </Box>

            <Box sx={{ bgcolor: 'background.default', ml: 1, p: 1.8, borderRadius: '3px' }}>
              {file.isLoading ? (
                <Delete id={`button_delete_upload_item_${props.item.data.uid}`} style={{ fontSize: 20, color: "#ddd" }} />
              ) : (
                <Delete id={`button_delete_upload_item_${props.item.data.uid}`}
                        sx={{ fontSize: 20, cursor: 'pointer', '&:hover': { color: 'danger.main' } }}
                        onClick={() => {handleDeleteItem(props.item.data.index, props.item.data.uid);}}/>
              )}
            </Box>
          </Box>
        </Box>

        {file.msg !== "" ? (
          <Typography sx={{ color: 'danger.main' }}>
            {file.msg}
          </Typography>
        ) : ("")}
      </Box>
    </Box>
  );
};

export default connect(stateToProps)(UploadItem);
