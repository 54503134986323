import React, {useEffect} from 'react';
import connect from 'react-redux/es/connect/connect';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useSnackbar} from 'notistack';
import {
  Box,
  Button,
  Card,
  CardHeader,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Edit as EditIcon, Trash as TrashIcon,} from 'react-feather';
import {
  clientsLoadDeleteTenantUsers,
  clientsLoadInviteTenantUsers,
  clientsLoadResetMsg,
  clientsLoadResetTenantUsers
} from "../../clients/store/actions";
import {
  clientsDeleteTenantUsers,
  clientsInviteTenantUsers,
  clientsMsg,
  clientsResetTenantUsers
} from "../../clients/store/selectors";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  buttonWrapper: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 16
  },
  tableTitle: {
    marginBottom: 10
  }
}));

const stateToProps = state => ({
  clientsInviteTenantUsers: clientsInviteTenantUsers(state),
  clientsDeleteTenantUsers: clientsDeleteTenantUsers(state),
  clientsResetTenantUsers: clientsResetTenantUsers(state),
  clientsMsg: clientsMsg(state),
});

const actionsToProps = dispatch => ({
  clientsLoadInviteTenantUsers: (payload) => dispatch(clientsLoadInviteTenantUsers(payload)),
  clientsLoadDeleteTenantUsers: (payload) => dispatch(clientsLoadDeleteTenantUsers(payload)),
  clientsLoadResetTenantUsers: (payload) => dispatch(clientsLoadResetTenantUsers(payload)),
  clientsLoadResetMsg: () => dispatch(clientsLoadResetMsg()),
});

const Users = ({
                 className,
                 users,
                 clientId,
                 ...props
               }) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const url = new URL(window.location);

  useEffect(() => {
    return () => {
      props.clientsLoadResetMsg();
    }
  }, []);

  useEffect(() => {
    if (props.clientsMsg) {
      enqueueSnackbar(props.clientsMsg, {
        variant: 'error',
        persist: true
      });
    }
  }, [props.clientsMsg]);

  useEffect(() => {
    if (props.clientsInviteTenantUsers.data == "sent") {
      props.getClients();
      enqueueSnackbar('Invitation has been sent to user email!', {
        variant: 'success',
        persist: true
      });
      props.clientsLoadResetMsg();
    }
  }, [props.clientsInviteTenantUsers.data]);

  useEffect(() => {
    if (props.clientsResetTenantUsers.data == "reset") {
      props.getClients();
      enqueueSnackbar('A reset password request has been sent to user email!', {
        variant: 'success',
        persist: true
      });
      props.clientsLoadResetMsg();
    }
  }, [props.clientsResetTenantUsers.data]);

  useEffect(() => {
    if (props.clientsDeleteTenantUsers.data == "deleted") {
      props.getClients();
      enqueueSnackbar('User has been deleted!', {
        variant: 'success',
        persist: true
      });
      props.clientsLoadResetMsg();
    }
  }, [props.clientsDeleteTenantUsers.data]);

  const onSendInvite = (user) => {
    props.clientsLoadInviteTenantUsers({userId: user.id, tenantId: clientId, url: `${url.origin}/invitation`});
  };

  const onResetPassword = (user) => {
    props.clientsLoadResetTenantUsers({userId: user.id, tenantId: clientId, url: `${url.origin}/recover`});
  };

  const onUserDelete = (user) => {
    if (window.confirm(`Are you sure you want to delete user "${user.name}"?`)) {
      props.clientsLoadDeleteTenantUsers({userId: user.id, tenantId: clientId});
    }
  };

  return (
    <>
      <Card className={clsx(classes.root, className)}>
        <CardHeader title="Users"/>

        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableBody>
                {users.map((user) => {
                  return (
                    <TableRow hover key={user.id}>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">
                          {user.name}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="body2" color="textSecondary">
                          {user.email}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        {user.role}
                      </TableCell>

                      <TableCell align="right">
                        {user.onboard_needed ?
                          <Button color="secondary" type="submit" variant="contained" onClick={() => onSendInvite(user)}>
                            Send invite
                          </Button>
                          :
                          <Button color="secondary" type="submit" variant="contained" onClick={() => onResetPassword(user)}>
                            Reset password
                          </Button>}

                        <Tooltip title="Edit user">
                          <IconButton component={RouterLink} to={`/clients/${clientId}/settings/user-edit/${user.id}`}>
                            <SvgIcon fontSize="small">
                              <EditIcon/>
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete user">
                          <IconButton onClick={() => onUserDelete(user)}>
                            <SvgIcon fontSize="small">
                              <TrashIcon/>
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>

                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <div className={classes.buttonWrapper}>
          <RouterLink to={`/clients/${clientId}/settings/user-add`}>
            <Button color="primary" variant="outlined">
              Add user
            </Button>
          </RouterLink>
        </div>
      </Card>
    </>
  );
};

Users.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

Users.defaultProps = {
  users: []
};

export default connect(stateToProps, actionsToProps)(Users);
