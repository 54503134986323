import {
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function MultiSelectFilter({ sx, onChange, label, value, options }) {
  function labelForValue(value) {
    return options.find((option) => option.value === value)?.label;
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    onChange(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl sx={sx}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          selected.map((s) => labelForValue(s)).join(", ")
        }
        MenuProps={MenuProps}
      >
        {options.map((t) => (
          <MenuItem key={t.value} value={t.value}>
            <Checkbox checked={value?.indexOf(t.value) > -1} />
            <ListItemText primary={t.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
