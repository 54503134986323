import {Box, Chip, Paper, Tooltip, Typography, useTheme} from "@mui/material";
import React from "react";
import {FORMAT_LONG, FORMAT_PERC, FORMATTERS} from "../../../../components/vis/NumberStat";
import SimpleRectangleVisSkeleton from "../../../../components/vis/SimpleRectangleVisSkeleton";
import {Today} from "@mui/icons-material";
import {THEMES} from "../../../../constants";

const VisRetentionDay = ({
  app_users,
  app_retention,
  keyboard_users,
  keyboard_retention,
  amount_of_days,
  title,
  isLoading,
  isLoadingForALongTime,
}) => {
  const hasData = app_users && keyboard_users;
  const theme = useTheme();

  return (
    <>
      {isLoading && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "100%" }}
                                    animation="wave"
                                    title={isLoadingForALongTime ? `${title} - working` : title} />
      )}

      {!isLoading && !hasData && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "100%" }}
                                    animation={false}
                                    title={`${title} - no data`} />
      )}

      {!isLoading && hasData && (
        <Paper sx={{
          flexGrow: 1,
          p: 0,
          position: 'relative',
          '&:hover .download-icon': { visibility: 'visible' },
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Box>
            {title && (
              <Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.primaryContrast',
                  bgcolor: 'primary.main',
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',
                  p: 4
                }}>
                  <Today sx={{ mr: 2 }} />
                  <Typography variant="h4" sx={{ fontWeight: "bold"}}>{title}</Typography>
                </Box>
              </Box>
            )}
          </Box>

          <Box sx={{
            p: 8,
            flex: '1 1 0%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Box>
                <Typography color="textPrimary" variant="body2" component="div">
                  App Users
                </Typography>

                <Tooltip title="Distinct application users in the selected time frame">
                  <Typography variant="h3" sx={{ fontWeight: "bold", flexGrow: 1 }} component="div">
                    {FORMATTERS[FORMAT_LONG](app_users).replace(',', '.')}
                  </Typography>
                </Tooltip>
              </Box>

              <Box sx={{ textAlign: 'right' }}>
                <Typography color="textPrimary" variant="body2" component="div">
                  App Retention
                </Typography>

                <Tooltip title={(`Percentage of application users retained over ${amount_of_days} ` + (amount_of_days == 1 ? 'day' : 'days'))}>
                  <Typography variant="h3" sx={{ fontWeight: "bold", flexGrow: 1 }} component="div">
                    {FORMATTERS[FORMAT_PERC](app_retention).replace(',', '.')}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Box>
                <Typography color="textPrimary" variant="body2" component="div">
                  Keyboard Users
                </Typography>

                <Tooltip title="Distinct keyboard users in the selected time frame">
                  <Typography variant="h3" sx={{ fontWeight: "bold", flexGrow: 1 }} component="div">
                    {FORMATTERS[FORMAT_LONG](keyboard_users).replace(',', '.')}
                  </Typography>
                </Tooltip>
              </Box>

              <Box>
                <Box sx={{ mb: 2, textAlign: 'right' }}>
                  <Typography color="textPrimary" variant="body2" component="div">
                    Keyboard Retention
                  </Typography>

                  <Tooltip title={(`Percentage of keyboard users retained over ${amount_of_days} ` + (amount_of_days == 1 ? 'day' : 'days'))}>
                    <Typography variant="h3" sx={{ fontWeight: "bold", flexGrow: 1 }} component="div">
                      {FORMATTERS[FORMAT_PERC](keyboard_retention).replace(',', '.')}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
}

export default VisRetentionDay;