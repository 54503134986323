import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Box, Card, Table, TableBody, TableCell, TableHead, TableRow, Typography, Checkbox} from '@mui/material';
import {makeStyles} from '@mui/styles';


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  buttonWrapper: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 16
  },
  tableTitle: {
    marginBottom: 10
  }
}));

const ChannelCampaigns = ({
                            className,
                            campaigns,
                            setCampaigns,
                            edit,
                            ...rest
                          }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h3" color="textPrimary" className={classes.tableTitle}>
        Application campaigns
      </Typography>

      <Card className={clsx(classes.root, className)}{...rest} sx={{ mt: 6 }}>
        <PerfectScrollbar>
          <Box minWidth={340}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Locale</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Kee2 ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaigns && campaigns.map((campaign, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell>{campaign.locale}</TableCell>

                      <TableCell>{campaign.name}</TableCell>

                      <TableCell>{campaign.kee2_identifier}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
};

ChannelCampaigns.propTypes = {
  className: PropTypes.string,
};

ChannelCampaigns.defaultProps = {};

export default ChannelCampaigns;
