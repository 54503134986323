import connect from "react-redux/es/connect/connect";
import {Box, CircularProgress, IconButton, Input, TextField, Typography} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import {CheckCircle, Delete, Warning} from "@mui/icons-material";
import React from "react";

const stateToProps = (state) => ({
});

const actionToProps = (dispatch) => ({
});

const Theme = (props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ width: 1/2, display: 'flex', flexDirection: 'column' }}>
        <Typography id={`label_theme_rank_${props.index}`} sx={{ fontWeight: 'medium', mb: 3 }}>Theme {props.index + 1}</Typography>

        <Box sx={{ display: 'flex', flex: '1 1 0%' }}>
          <Box sx={{ display: 'flex', flex: '1 1 0%', alignItems: 'center', position: 'relative' }}>
            <TextField label=".zip file" size="normal" fullWidth disabled
                       id={`input_theme_zip_name_${props.index}`}
                       sx={{
                         '& fieldset': { borderRadius: '3px' },
                         '& p': { bgcolor: 'background.dark', m: 0, px: 4 },
                         '& input': { pr: 12 },
                         bgcolor: 'white'
                       }}
                       value={ props.theme.data.zip.name } />

            <FolderIcon sx={{ position: 'absolute', right: 12, top: 16, color: 'primary.main' }} />
          </Box>

          <Box sx={{ mx: 4, mt: 0.25 }}>
            {(props.theme.isLoading) ?
              (
                <Box sx={{bgcolor: 'background.default', p: 3, borderRadius: '3px'}}>
                  <Warning id={`icon_warning_neutral_neutral_${props.index}`} sx={{color: "secondary.main", fontSize: 24}}/>
                </Box>
              ) :
              (
                <Box id={`icon_check_success_theme_${props.index}`} sx={{bgcolor: 'background.default', p: 3, borderRadius: '3px'}}>
                  <CheckCircle sx={{color: 'success.main', fontSize: 24}}/>
                </Box>
              )
            }
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {props.theme.isLoading ?
          <Box sx={{ width: 224, height: 169, bgcolor: 'white', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress id={`spinner_preview_theme_${props.index}`} sx={{ mt: 14 }} size="56px" color="primary"/>
          </Box> :
          <Box sx={{ width: 224, height: 169, display: 'flex', justifyContent: 'center', bgcolor: 'white', position: 'relative' }}>
              <Box sx={{
                position: 'absolute',
                bottom: 0,
                right: '-4px',
                px: 1,
                bgcolor: 'background.paper',
                opacity: 0.9,
                borderRadius: 1,
              }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>v{props.theme.data.zip.version}</Typography>
              </Box>

              <img id={`image_preview_theme_${props.index}`} src={props.theme.data.preview.url} style={{  width: 224, height: 169, objectFit: 'cover' }} />
          </Box>}

        <Box sx={{ width: 68  , ml: 8 }}>
          <IconButton id={`button_delete_theme_${props.index}`} aria-label="store"
                      onClick={() => props.handleDelete(props.index, props.theme.data.zip.uid, props.theme.data.preview.uid)}>
            <Delete sx={{ fontSize: 28 }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default connect(stateToProps, actionToProps)(Theme);