import React, {useEffect, useState} from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Box,
  Breadcrumbs, Button, Card, CardContent,
  Container,
  Grid, LinearProgress,
  Link,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import Page from "../../../components/Page";
import {Link as RouterLink} from "react-router-dom";
import {
  activeFormSectionUid,
  campaignSetupData,
  clientCreationData, orchestrationErrors, orchestrationIsLoading,
  quicklinks,
  socialSetupData
} from "../store/selectors";
import ClientCreation from "../subcomponents/ClientCreation";
import CampaignSetup from "../subcomponents/CampaignSetup";
import Review from "../subcomponents/Review";
import {
  loadOrchestration,
  setCampaignSetup,
  setClientCreation,
  setNextSection,
  setPreviousSection,
  setSocialSetup
} from "../store/actions";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import SocialSetup from "../subcomponents/SocialSetup";


const stateToProps = state => ({
  activeFormSectionUid: activeFormSectionUid(state),
  orchestrationIsLoading: orchestrationIsLoading(state),
  orchestrationErrors: orchestrationErrors(state),
  clientCreationData: clientCreationData(state),
  campaignSetupData: campaignSetupData(state),
  socialSetupData: socialSetupData(state),
  quicklinks: quicklinks(state),
});

const actionsToProps = dispatch => ({
  setPreviousSection: data => dispatch(setPreviousSection(data)),
  setNextSection: data => dispatch(setNextSection(data)),
  setClientCreation: data => dispatch(setClientCreation(data)),
  setCampaignSetup: data => dispatch(setCampaignSetup(data)),
  setSocialSetup: data => dispatch(setSocialSetup(data)),
  loadOrchestration: data => dispatch(loadOrchestration(data)),
});

const steps = [
  {
    id: 0,
    uid: 'clientCreation',
    label: 'Create your client',
    description: `Tell us how you would like your application to be known to us.`,
  },
  {
    id: 1,
    uid: 'campaignSetup',
    label: 'Set up your campaigns',
    description: 'Let\'s dig into the configuration details for your SDK.',
  },
  {
    id: 2,
    uid: 'socialSetup',
    label: 'Your social media connections',
    description: 'Help us link your SDK keyboard with your social media accounts.',
  },
  {
    id: 3,
    uid: 'review',
    label: 'Review your setup',
    description: `One more review before we can get going.`,
  },
];

const Orchestration = (props) => {
  const [activeStep, setActiveStep] = useState();
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    setActiveStep(steps.find(step => step.uid === props.activeFormSectionUid));
  }, [props.activeFormSectionUid]);

  const handleNext = () => {
    if( props.activeFormSectionUid === 'clientCreation' ) {
      clientCreationFormik.validateForm().then((errors) => {
        if( Object.keys(errors).length === 0 ) {
          props.setClientCreation({
            name: clientCreationFormik.values.clientName,
          });
          setActiveStep(steps.find(step => step.id === activeStep.id + 1));
          props.setNextSection();
        } else {
          enqueueSnackbar('There are some errors above, please fix them', {
            variant: 'error',
            persist: true
          });
        }
      });
    }

    if( props.activeFormSectionUid === 'campaignSetup' ) {
      campaignSetupFormik.validateForm().then((errors) => {
        if( Object.keys(errors).length === 0 ) {
          props.setCampaignSetup({
            iosCampaign: {
              checked: campaignSetupFormik.values.platforms.includes('ios'),
            },
            androidCampaign: {
              checked: campaignSetupFormik.values.platforms.includes('android'),
            },
            locales: campaignSetupFormik.values.quicklinkSets,
          });

          setActiveStep(steps.find(step => step.id === activeStep.id + 1));
          props.setNextSection();
        } else {
          enqueueSnackbar('There are some errors above, please fix them', {
            variant: 'error',
            persist: true
          });
        }
      });
    }

    if( props.activeFormSectionUid === 'socialSetup' ) {
      socialSetupFormik.validateForm().then((errors) => {
        if( Object.keys(errors).length === 0 ) {
          props.setSocialSetup({
            twitterId: socialSetupFormik.values.twitterId,
            youtubeId: socialSetupFormik.values.youtubeId,
            facebookId: socialSetupFormik.values.facebookId,
          });

          setActiveStep(steps.find(step => step.id === activeStep.id + 1));
          props.setNextSection();
        } else {
          enqueueSnackbar('There are some errors above, please fix them', {
            variant: 'error',
            persist: true
          });
        }
      })
    }

    if( props.activeFormSectionUid === 'review' ) {
      props.loadOrchestration({
        clientName: props.clientCreationData.name,
        hasIosCampaign: props.campaignSetupData.iosCampaign.checked,
        hasAndroidCampaign: props.campaignSetupData.androidCampaign.checked,
        quicklinkSets: props.campaignSetupData.locales,
        twitterId: props.socialSetupData.twitterId,
        youtubeId: props.socialSetupData.youtubeId,
        facebookId: props.socialSetupData.facebookId,
      });
    }
  };

  const handleBack = () => {
    setActiveStep(steps.find(step => step.id === activeStep.id - 1));
    props.setPreviousSection();
  };

  const clientCreationFormik = useFormik({
    initialValues: {
      clientName: '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      clientName: Yup.string().max(128).required('You have to tell us the name of your app'),
    }),
  });

  const campaignSetupFormik = useFormik({
    initialValues: {
      platforms: [],
      quicklinkSets: [],
    },
    validationSchema: Yup.object().shape({
      platforms: Yup.array().test(
        'at-least-one-platform-is-selected',
        'You must select at least one platform to create a campaign for',
        value => {
          return value.includes('android') || value.includes('ios');
        }
      ),
    }),
  });

  const socialSetupFormik = useFormik({
    initialValues: {
      twitterId: '',
      youtubeId: '',
      facebookId: '',
    },
    validationSchema: Yup.object().shape({
      twitterId: Yup.string(),
      youtubeId: Yup.string(),
      facebookId: Yup.string().url(),
    }),
  });

  const breadCrumbs =
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to={`/clients/${props.match.params.clientId}/dashboard`} component={RouterLink}>
        Dashboard
      </Link>

      <Link bariant="body1" to="#" component={RouterLink}>
        Setup
      </Link>
    </Breadcrumbs>;

  return (
    <Page title="Set up your SDK keyboard" breadCrumbs={breadCrumbs}>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={4} sm={4} xs={4}>
            <Box>
              {activeStep && (
                <Stepper activeStep={activeStep.id} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.uid}>
                      <StepLabel optional={index === steps.length -1 ? (<Typography variant="overline">Last step</Typography>) : null}>
                        {step.label}
                      </StepLabel>

                      <StepContent>
                        <Typography>{step.description}</Typography>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              )}
            </Box>
          </Grid>

          <Grid item lg={8} sm={8} xs={8}>
            <Card variant="outlined" sx={{ width: 1 }}>
              <CardContent>
                <Box sx={{ maxWidth: 'sm' }}>
                  {props.activeFormSectionUid === 'clientCreation' && (<ClientCreation clientCreationFormik={clientCreationFormik}
                                                                                       errors={props.orchestrationErrors} />)}
                  {props.activeFormSectionUid === 'campaignSetup' && (<CampaignSetup campaignSetupFormik={campaignSetupFormik}
                                                                                     quicklinks={props.quicklinks}
                                                                                     errors={props.orchestrationErrors} />)}
                  {props.activeFormSectionUid === 'socialSetup' && (<SocialSetup socialSetupFormik={socialSetupFormik}
                                                                                 errors={props.orchestrationErrors} />)}
                  {props.activeFormSectionUid === 'review' && (<Review clientCreationData={props.clientCreationData}
                                                                       campaignSetupData={props.campaignSetupData}
                                                                       socialSetupData={props.socialSetupData}
                                                                       quicklinks={props.quicklinks}
                                                                       errors={props.orchestrationErrors} />)}
                </Box>

                {props.orchestrationIsLoading && (
                  <Box sx={{ my: 8, width: 1, px: 8 }}>
                    <LinearProgress sx={{ borderRadius: 5, height: '7px' }} />
                  </Box>
                )}
              </CardContent>
            </Card>

            <Box sx={{ display: 'flex', justifyContent: 'end', mt: 4 }}>
              <Button disabled={props.activeFormSectionUid === 'clientCreation'} onClick={handleBack} sx={{ mr: 4 }}>
                Back
              </Button>
              <Button variant="contained" onClick={handleNext}>
                {props.activeFormSectionUid === 'review' ? 'Finish' : 'Continue'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default connect(stateToProps, actionsToProps)(Orchestration);
