import {
  Box, Breadcrumbs, CircularProgress, Grid, Link, List, ListItemButton, Typography,
  ListItemText, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Button, IconButton, TextField,
} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Page from "../../../components/Page";
import {getAudiences, getDeleteAudience, getUpdateAudience} from "../store/selectors";
import connect from "react-redux/es/connect/connect";
import {
  loadAudienceMembers,
  resetAudiences,
  loadDeleteAudience,
  loadUpdateAudience,
  loadAudiences
} from "../store/actions";
import TableContainer from "@mui/material/TableContainer";
import {Add, Check, Circle, Close, CloudDownload, Delete, Edit} from "@mui/icons-material";
import AudienceAdd from "../subcomponents/audiences/AudienceAdd";
import AlertDialog from "../../../components/AlertDialogue";
import * as Yup from "yup";
import {useFormik} from "formik";

const stateToProps = (state) => ({
  audiences: getAudiences(state),
  deleteAudience: getDeleteAudience(state),
  updateAudience: getUpdateAudience(state),
});

const actionToProps = (dispatch) => ({
  loadAudiences: payload => dispatch(loadAudiences(payload)),
  resetAudiences: payload => dispatch(resetAudiences(payload)),
  loadAudienceMembers: payload => dispatch(loadAudienceMembers(payload)),
  loadUpdateAudience: payload => dispatch(loadUpdateAudience(payload)),
  loadDeleteAudience: payload => dispatch(loadDeleteAudience(payload)),
});

const Audiences = ({ ...props }) => {
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [selectedAudienceIsLoading, setSelectedAudienceIsLoading] = useState(false);
  const [membersTablePage, setMembersTablePage] = useState(0);
  const [membersTableRowsPerPage, setMembersTableRowsPerPage] = useState(10);
  const [audienceAddModalOpen, setAudienceAddModalOpen] = useState(false);
  const [audienceDeletionDialogOpen, setAudienceDeletionDialogOpen] = useState(false);
  const [audienceEditMode, setAudienceEditMode] = useState(false);

  useEffect(() => {
    if( !props.audiences.isLoading ) {
      setSelectedAudience(false);
      setSelectedAudienceIsLoading(false);
      setAudienceAddModalOpen(false);
      setAudienceDeletionDialogOpen(false);
      setAudienceEditMode(false);

      loadAudiences();
    }
  }, []);

  useEffect(() => {
    if( props.audiences.data.length > 0 ) {
      setSelectedAudience(props.audiences.data[0]);
      audienceUpdateFormik.setFieldValue('audienceName', props.audiences.data[0].name);

      if( !props.audiences.data[0].members ) {
        setSelectedAudienceIsLoading(true);
        props.loadAudienceMembers({
          tenantId: props.match.params.clientId,
          audienceUid: props.audiences.data[0].uid
        });
      }
    } else {
      setSelectedAudience(null);
    }

    if( selectedAudience && !selectedAudience.members ) {
      const foundSelectedAudience = props.audiences.data.find(audience => audience.uid === selectedAudience.uid);

      if( foundSelectedAudience ) {
        setSelectedAudience(foundSelectedAudience);
        audienceUpdateFormik.setFieldValue('audienceName', foundSelectedAudience.name);
        setSelectedAudienceIsLoading(false);
      }
    }
  }, [props.audiences.data]);

  useEffect(() => {
    if( !props.deleteAudience.isLoading ) {
      setAudienceDeletionDialogOpen(false);
    }
  }, [props.deleteAudience.isLoading]);

  useEffect(() => {
    if( !props.updateAudience.isLoading ) {
      audienceUpdateFormik.resetForm();
      setAudienceEditMode(false);
    }
  }, [props.updateAudience.isLoading]);

  const loadAudiences = () => {
    props.resetAudiences();

    props.loadAudiences({
      tenantId: props.match.params.clientId
    });
  };

  const selectAudience = audience => {
    setSelectedAudience(audience);
    setAudienceEditMode(false);
    audienceUpdateFormik.setFieldValue('audienceName', audience.name);

    if( !audience.members ) {
      setSelectedAudienceIsLoading(true);

      props.loadAudienceMembers({
        tenantId: props.match.params.clientId,
        audienceUid: audience.uid
      });
    }
  };

  const handleAudienceUpdateSave = () => {
    audienceUpdateFormik.validateForm().then((errors) => {
      if( Object.keys(errors).length !== 0 ) {
        return;
      }
      props.loadUpdateAudience({
        tenantId: props.match.params.clientId,
        audienceUid: selectedAudience.uid,
        ...audienceUpdateFormik.values,
      });
    });
  };

  const audienceUpdateFormik = useFormik({
    initialValues: {
      audienceName: (selectedAudience && selectedAudience.name) || '',
      validationSchema: Yup.object().shape({
        audienceName: Yup.string().required('Audience name is required.').max(128, 'Audience name cannot be larger than 128 characters.')
      })
    }
  });

  const handleMembersTableChangePage = (event, newPage) => {
    setMembersTablePage(newPage);
  };

  const handleMembersTableChangeRowsPerPage = (event) => {
    setMembersTableRowsPerPage(+event.target.value);
    setMembersTablePage(0);
  };

  const handleDownloadAsCSV = () => {
    // Prepare the CSV content
    const csvContent = selectedAudience.members.map((member) => `${member.third_party_id},`).join('\n');

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a data URL for the Blob
    const dataUrl = URL.createObjectURL(blob);

    // Create an anchor element for downloading the CSV
    const a = document.createElement('a');
    a.href = dataUrl;
    a.download = selectedAudience.name;

    // Trigger a click event to initiate the download
    a.click();

    // Revoke the data URL to release resources
    URL.revokeObjectURL(dataUrl);
  };

  const handleAudienceDelete = (audienceUid) => {
    props.loadDeleteAudience({
      tenantId: props.match.params.clientId,
      audienceUid: audienceUid,
    });
  };

  const handleEnterAudienceEditMode = () => {
    if( selectedAudience ) {
      audienceUpdateFormik.setFieldValue('audienceName', selectedAudience.name);
      setAudienceEditMode(true);
    }
  };

  const breadCrumbs = (
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to="#" component={RouterLink}>
        Reporting
      </Link>

      <Link variant="body1" to="#" component={RouterLink}>
        Audience management
      </Link>
    </Breadcrumbs>
  );

  const callToAction = (
    <Button variant="contained" startIcon={<Add />} onClick={() => setAudienceAddModalOpen(true)}>New audience</Button>
  );

  return (
    <Page title="Audience Management" breadCrumbs={breadCrumbs} callToAction={callToAction}>
      {props.audiences.isLoading && (
        <Box sx={{ mt: 3, width: 1, display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <CircularProgress id="spinner_audiences" size="100px" color="primary" />
        </Box>
      )}

      {!props.audiences.isLoading && (
        <Grid container>
          <Grid item xs={3}>
            <List>
              {props.audiences && props.audiences.data.map(audience => (
                <ListItemButton
                  key={`audience_${audience.uid}`}
                  selected={audience.uid === selectedAudience?.uid}
                  onClick={event => selectAudience(audience)}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 1 }}>
                    <Typography>{audience.name}</Typography>
                    <Typography sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{audience.members_count}</Typography>
                  </Box>
                </ListItemButton>
              ))}
            </List>
          </Grid>

          <Grid item xs={9}>
            <Paper sx={{ p: 4, ml: 2, mt: 2 }}>
              {selectedAudience && (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                  {selectedAudience.is_under_construction ? (
                    <>
                      <Circle sx={{ color: 'danger.main', mr: 1 }}/>
                      <Typography variant="body2" color="text.secondary">Audience under construction</Typography>
                    </>
                  ) : (
                    <>
                      <Circle sx={{ color: 'success.main', mr: 1 }}/>
                      <Typography variant="body2" color="text.secondary">Audience ready</Typography>
                    </>
                  )}
                </Box>
              )}

              {audienceEditMode && (
                <form noValidate onSubmit={audienceUpdateFormik.handleSubmit}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField error={Boolean(audienceUpdateFormik.touched.audienceName && audienceUpdateFormik.errors.audienceName)}
                               helperText={audienceUpdateFormik.touched.audienceName && audienceUpdateFormik.errors.audienceName}
                               label="Audience name"
                               margin="normal"
                               name="audienceName"
                               onBlur={audienceUpdateFormik.handleBlur}
                               onChange={audienceUpdateFormik.handleChange}
                               type="text"
                               value={audienceUpdateFormik.values.audienceName}
                               variant="outlined"
                               sx={{ width: '300px' }} />

                    {!props.updateAudience.isLoading && (
                      <>
                        <IconButton aria-label="submit"
                                    color="secondary.dark"
                                    sx={{ ml: 4 }}
                                    onClick={handleAudienceUpdateSave}
                                    disabled={!audienceUpdateFormik.touched.audienceName}>
                          <Check />
                        </IconButton>

                        <IconButton aria-label="cancel" color="secondary.dark" onClick={() => setAudienceEditMode(false)}>
                          <Close />
                        </IconButton>
                      </>
                    )}

                    {props.updateAudience.isLoading && (
                      <CircularProgress id="spinner_audience_update" size="20px" color="primary" sx={{  ml: 4 }} />
                    )}
                  </Box>
                </form>
              )}

                {!audienceEditMode && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h4">{selectedAudience?.name}</Typography>

                    <IconButton aria-label="edit" color="secondary.dark" sx={{ ml: 4 }} onClick={() => handleEnterAudienceEditMode()}>
                      <Edit />
                    </IconButton>
                  </Box>
                )}

              {!selectedAudienceIsLoading && selectedAudience?.members && (
                <>
                  <TableContainer>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>Id type</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {selectedAudience.members
                          .slice(membersTablePage * membersTableRowsPerPage, membersTablePage * membersTableRowsPerPage + membersTableRowsPerPage)
                          .map(member => (
                            <TableRow hover key={member.uid}>
                              <TableCell>
                                {member.third_party_id}
                              </TableCell>

                              <TableCell>
                                {selectedAudience.third_party_id_type}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={selectedAudience.members.length}
                    rowsPerPage={membersTableRowsPerPage}
                    page={membersTablePage}
                    onPageChange={handleMembersTableChangePage}
                    onRowsPerPageChange={handleMembersTableChangeRowsPerPage}
                  />
                </>
              )}

              {selectedAudienceIsLoading && (
                <Box sx={{ mt: 3, width: 1, display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <CircularProgress id="spinner_audience_members" size="50px" color="primary" />
                </Box>
              )}
            </Paper>

            {selectedAudience && !selectedAudience.is_under_construction && (
              <Grid item xs={12} sx={{ mt: 4, display: 'flex', justifyContent: 'end' }}>
                <Button sx={{ mr: 2 }} onClick={handleDownloadAsCSV} startIcon={<CloudDownload />}>Download as CSV</Button>

                <Button color="danger" onClick={() => setAudienceDeletionDialogOpen(true)} startIcon={<Delete />}>Delete Audience</Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      <AudienceAdd open={audienceAddModalOpen}
                   onClose={() => setAudienceAddModalOpen(false)}
                   tenantId={props.match.params.clientId}
                   audiences={props.audiences}
                   loadAudiences={loadAudiences} />

      <AlertDialog id={`dialog_delete_selected_audience`}
                   open={audienceDeletionDialogOpen}
                   handleAgree={() => handleAudienceDelete(selectedAudience.uid)}
                   handleDisagree={() => setAudienceDeletionDialogOpen(false)}
                   title="Are you sure you want to delete the selected audience?"
                   body="This operation is irreversible." />
    </Page>
  );
}

export default connect(stateToProps, actionToProps)(Audiences);