import connect from "react-redux/es/connect/connect";
import {Box, IconButton, Tooltip} from "@mui/material";
import {useState} from "react";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";

const stateToProps = (state) => ({});

const actionToProps = (dispatch) => ({});

const OrderingDirection = ({handleOrderingsDirectionChange, ...props}) => {
  const [direction, setDirection] = useState('asc');

  const handleDirectionChange = (newDirection) => {
    setDirection(newDirection);
    handleOrderingsDirectionChange(newDirection);
  }

  return (
    <Box>
      {direction === 'asc' && (
        <Tooltip title="Sort descending">
          <IconButton id="button_sort_ascending" onClick={() => handleDirectionChange('desc')} aria-label="sort_asc" size="small">
            <ArrowDownward />
          </IconButton>
        </Tooltip>
      )}

      {direction === 'desc' && (
        <Tooltip title="Sort ascending">
          <IconButton id="button_sort_descending" onClick={() => handleDirectionChange('asc')} aria-label="sort_asc" size="small">
            <ArrowUpward />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default connect(stateToProps, actionToProps)(OrderingDirection);