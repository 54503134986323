import { Switch, Route } from "react-router-dom";
import PageLayout from './layout/page-layout/PageLayout';
import PrivateRoute from "./components/PrivateRoute";
import Login from './containers/auth/login/Login';
import Recover from './containers/recover/main/Recover';
import Clients from './containers/clients/main/Clients';
import ClientAdd from "./containers/client-add/main/ClientAdd";
import ClientDetails from "./containers/client-details/main/ClientDetails";
import RevenueAdd from "./containers/revenue-add/main/RevenueAdd";
import UserAdd from './containers/user-add/main/UserAdd';
import NotFound from './components/NotFoundView';
import KeyboardContent from "./containers/keyboard-content/main/KeyboardContent";
import Reporting from "./containers/reporting/main/ContentUsage";
import Register from "./containers/auth/register/Register";
import TwoFactor from "./containers/auth/two-factor/TwoFactor";
import EmailVerification from "./containers/auth/register/EmailVerification";
import VerifyEmail from "./containers/auth/register/VerifyEmail";
import Orchestration from "./containers/orechestration/main/Orchestration";
import Onboarding from "./containers/orechestration/main/Onboarding";
import Invitation from "./containers/onboarding/main/Invitation";
import Insights from "./containers/insights/main/Insights";
import Audiences from "./containers/insights/main/Audiences";
import AdvancedDailyDashboard from "./containers/insights/main/AdvancedDailyDashboard";
import DailyDashboard from "./containers/insights/main/SimpleDailyDashboard";
import RetentionDashboard from "./containers/insights/main/RetentionDashboard";

const Routes = () => {
	return (
		<Switch>
			{/* Registration currently disabled */}
			{/*<Route path="/register" exact render={props => (*/}
			{/*	<Register />*/}
			{/*)} />*/}

			<Route path="/login" exact render={props => (
				<Login />
			)} />

			<Route path="/invitation" exact render={props => (
				<Invitation {...props} />
			)} />

			<Route path="/recover" exact render={props => (
				<Recover {...props} />
			)} />

			<PrivateRoute path="/email-verification" exact render={props => (
				<EmailVerification />
			)} />

			<PrivateRoute path="/verify-email" exact render={props => (
				<VerifyEmail {...props} />
			)} />

			<PrivateRoute path="/two-factor" exact render={props => (
				<TwoFactor {...props} />
			)} />

			<PrivateRoute path="/" exact render={props => (
				<PageLayout {...props}><KeyboardContent {...props} /></PageLayout>
			)} />

			<PrivateRoute path="/clients" exact render={props => (
				<PageLayout {...props}><Clients /></PageLayout>
			)} />

			<PrivateRoute path="/client-add" exact render={props => (
				<PageLayout {...props}><ClientAdd {...props} /></PageLayout>
			)} />

			<PrivateRoute path="/clients/:clientId/settings" exact render={props => (
				<PageLayout {...props}><ClientDetails {...props} /></PageLayout>
			)} />

			<PrivateRoute path="/clients/:clientId/settings/revenue-add" exact render={props => (
				<PageLayout {...props}><RevenueAdd {...props} /></PageLayout>
			)} />

			<PrivateRoute path="/clients/:clientId/settings/user-add" exact render={props => (
				<PageLayout {...props}><UserAdd {...props} /></PageLayout>
			)} />

			<PrivateRoute path="/clients/:clientId/settings/user-edit/:userId" exact render={props => (
				<PageLayout {...props}><UserAdd {...props} /></PageLayout>
			)} />

			<PrivateRoute path="/clients/:clientId/settings/revenue/:idChannel" exact render={props => (
				<PageLayout {...props}><RevenueAdd {...props} /></PageLayout>
			)} />

			<PrivateRoute path="/orchestration" exact render={props => (
				<PageLayout {...props} hasNavigation={false}><Orchestration {...props} /></PageLayout>
			)} />

			<PrivateRoute path="/clients/:clientId/onboarding" exact render={props => (
				<PageLayout {...props} hasNavigation={false}><Onboarding {...props} /></PageLayout>
			)} />

			<PrivateRoute path="/clients/:clientId/keyboard-content" exact render={props => (
				<PageLayout {...props}><KeyboardContent {...props} /></PageLayout>
			)} />

			<PrivateRoute path="/clients/:clientId/content-usage" exact render={props => (
				<PageLayout {...props}><Reporting {...props} /></PageLayout>
			)} />

			<PrivateRoute path="/content-usage" exact render={props => (
				<PageLayout {...props}><Reporting {...props} /></PageLayout>
			)} />

			<PrivateRoute path="/clients/:clientId/insights" exact render={props => (
				<PageLayout {...props}><Insights {...props} /></PageLayout>
			)} />;

			<PrivateRoute path="/clients/:clientId/daily-dashboard" exact render={props => (
				<PageLayout {...props}><DailyDashboard {...props} /></PageLayout>
			)} />;

			<PrivateRoute path="/clients/:clientId/daily-dashboard/advanced" exact render={props => (
				<PageLayout {...props}><AdvancedDailyDashboard {...props} /></PageLayout>
			)} />;

			<PrivateRoute path="/clients/:clientId/retention-dashboard" exact render={props => (
				<PageLayout {...props}><RetentionDashboard {...props} /></PageLayout>
			)} />;

			<PrivateRoute path="/clients/:clientId/audiences" exact render={props => (
				<PageLayout {...props}><Audiences {...props} /></PageLayout>
			)} />;

			<Route component={NotFound} />
		</Switch>
	);
};

export default Routes;
