import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Box, Container, Grid, Typography} from "@mui/material";

const Page = forwardRef(({
                           children,
                           breadCrumbs,
                           callToAction,
                           title = '',
                           ...rest
                         }, ref) => {

  return (
    <Container maxWidth={false} sx={{ py: 8, maxWidth: '1632px' }} ref={ref} {...rest}>
      <Box sx={{ mb: 2 }}>
        {breadCrumbs}
      </Box>

      <Grid container={true}>
        <Grid item xs={6}>
          <Typography variant="h2" sx={{ fontWeight: 'regular', color: 'text.primary', mb: 12 }}>{title}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            {callToAction}
          </Box>
        </Grid>
      </Grid>

      {children}
    </Container>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  breadCrumbs: PropTypes.node.isRequired,
  callToAction: PropTypes.node,
};

export default Page;
