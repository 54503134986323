import * as USER from './types';
import {getValue} from 'AppUtils/objects';

const defaultState = {
  section: 'user',
  msg: null,
  statusCode: null,
  info: null,
  updateInfo: null,
  organizations: '',
  roles: '',
  profileUpdate: {
    data: '',
    isLoading: false,
  },
  channels: {
    data: [],
    isLoading: false,
  },
  otherChannels: {
    data: '',
    isLoading: false,
  },
  createChannels: {
    data: '',
    isLoading: false,
  },
  statsLast7days: {
    data: '',
    isLoading: false,
  },
  statsLast28days: {
    data: '',
    isLoading: false,
  },
  statsLastMonth: {
    data: '',
    isLoading: false,
  },
  series: {
    data: '',
    isLoading: false,
  },
  dataSeries: {
    data: '',
    isLoading: false,
  },
  statsLeaderboard: {
    data: '',
    isLoading: false,
  },
  countrySeries: {
    data: '',
    isLoading: false,
  },
  channelsDetails: {
    data: '',
    isLoading: false,
  },
  updateChannels: {
    data: '',
    isLoading: false,
  },
  deleteChannels: {
    data: '',
    isLoading: false,
  },
  unmappedChannels: {
    data: '',
    isLoading: false,
  },
  connectChannels: {
    data: '',
    provider: '',
    isLoading: false,
  },
  connectChannelsDetails: {
    data: '',
    isLoading: false,
  },
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case USER.USER_SET_INFO: {
      const {info, msg, statusCode} = action.payload;

      return {
        ...state,
        info,
        roles: getValue(info, 'roles', []),
        globalPermissions: getValue(info, 'globalPermissions', []),
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_UPDATE_INFO: {
      return {
        ...state,
        msg: '',
        statusCode: '',
      };
    }
    case USER.USER_SET_UPDATE_INFO: {
      const {updateInfo, msg, statusCode} = action.payload;

      return {
        ...state,
        updateInfo,
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_NOTIFICATIONS: {
      const {list, msg, statusCode} = action.payload;

      return {
        ...state,
        notifications: {
          list,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_NOTIFICATIONS_CONSUME:
      return {
        ...state,
        notificationsConsume: {
          ...state.notificationsConsume,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_NOTIFICATIONS_CONSUME: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        notificationsConsume: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_UPDATE_PROFILE:
      return {
        ...state,
        profileUpdate: {
          ...state.profileUpdate,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_UPDATE_PROFILE: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        profileUpdate: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_CHANNELS:
      return {
        ...state,
        channels: {
          ...state.channels,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_CHANNELS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        channels: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_OTHER_CHANNELS:
      return {
        ...state,
        otherChannels: {
          ...state.otherChannels,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_OTHER_CHANNELS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        otherChannels: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_CREATE_CHANNELS:
      return {
        ...state,
        createChannels: {
          ...state.createChannels,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_CREATE_CHANNELS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        createChannels: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_CHANNELS_DETAILS:
      return {
        ...state,
        channelsDetails: {
          ...state.channelsDetails,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_CHANNELS_DETAILS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        channelsDetails: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_UPDATE_CHANNELS:
      return {
        ...state,
        updateChannels: {
          ...state.updateChannels,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_UPDATE_CHANNELS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        updateChannels: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_DELETE_CHANNELS:
      return {
        ...state,
        deleteChannels: {
          ...state.deleteChannels,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_DELETE_CHANNELS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        deleteChannels: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_UNMAPPED_CHANNELS:
      return {
        ...state,
        unmappedChannels: {
          ...state.unmappedChannels,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_UNMAPPED_CHANNELS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        unmappedChannels: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_CONNECT_CHANNELS:
      return {
        ...state,
        connectChannels: {
          ...state.connectChannels,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_CONNECT_CHANNELS: {
      const {data, msg, statusCode, provider} = action.payload;

      return {
        ...state,
        connectChannels: {
          data,
          provider,
          msg,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_CONNECT_CHANNELS_DETAILS:
      return {
        ...state,
        connectChannelsDetails: {
          ...state.connectChannelsDetails,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_CONNECT_CHANNELS_DETAILS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        connectChannelsDetails: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_STATS_LAST_7DAYS:
      return {
        ...state,
        statsLast7days: {
          ...state.statsLast7days,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_STATS_LAST_7DAYS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        statsLast7days: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_STATS_LAST_28DAYS:
      return {
        ...state,
        statsLast28days: {
          ...state.statsLast28days,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_STATS_LAST_28DAYS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        statsLast28days: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_STATS_LAST_MONTH:
      return {
        ...state,
        statsLastMonth: {
          ...state.statsLastMonth,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_STATS_LAST_MONTH: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        statsLastMonth: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_SERIES:
      return {
        ...state,
        series: {
          ...state.series,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_SERIES: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        series: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_DATA_SERIES:
      return {
        ...state,
        dataSeries: {
          ...state.dataSeries,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_DATA_SERIES: {
      const {metric, data, msg, statusCode} = action.payload;

      return {
        ...state,
        dataSeries: {
          data: {
            ...state.dataSeries.data,
            [metric]: data
          },
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_STATS_LEADERBOARD:
      return {
        ...state,
        statsLeaderboard: {
          ...state.statsLeaderboard,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_STATS_LEADERBOARD: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        statsLeaderboard: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_COUNTRY_SERIES:
      return {
        ...state,
        countrySeries: {
          ...state.countrySeries,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_COUNTRY_SERIES: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        countrySeries: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_RESET_DATA_SERIES:
      return {
        ...state,
        dataSeries: defaultState.dataSeries,
        msg: '',
        statusCode: '',
      };
    case USER.USER_LOAD_RESET_COUNTRY_SERIES:
      return {
        ...state,
        countrySeries: {
          data: '',
          isLoading: false,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_LOAD_RESET_OTHER_CHANNELS:
      return {
        ...state,
        otherChannels: {
          data: '',
          isLoading: false,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_LOAD_RESET_CHANNELS:
      return {
        ...state,
        channels: {
          data: [],
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_LOAD_RESET_CHANNELS_DETAILS:
      return {
        ...state,
        channelsDetails: {
          data: '',
          isLoading: false,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_LOAD_RESET_CHANNELS_UPDATE:
      return {
        ...state,
        updateChannels: {
          data: '',
          isLoading: false,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_LOAD_RESET_CHANNELS_CREATE:
      return {
        ...state,
        createChannels: {
          data: '',
          isLoading: false,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_LOAD_RESET_CHANNELS_DELETE:
      return {
        ...state,
        deleteChannels: {
          data: '',
          isLoading: false,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_LOAD_RESET_CONNECT:
      return {
        ...state,
        connectChannels: {
          data: '',
          provider: '',
          isLoading: false,
        },
        connectChannelsDetails: {
          data: '',
          isLoading: false,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_LOAD_RESET_MSG:
      return {
        ...state,
        msg: '',
        statusCode: '',
      };
    default:
      return state;
  }
}
