const reorderByRank = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return refreshOrder(result);
};

const refreshOrder = (list) => {
  return list.map((item, index) => {
    if( item.rank !== index + 1 ) {
      item.rank = index + 1
      item.isDirty = true;
    }

    return item;
  });
};

export {
  reorderByRank,
  refreshOrder,
};