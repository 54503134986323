import {Box, Skeleton, Typography, useTheme} from "@mui/material";
import React from "react";

function MultiDimensionalRoundedChartSkeleton ({sx, animation = false, vertical = false, title, ...props}) {
  const theme = useTheme();

  return (
    <Box sx={sx}>
      <Box
        borderRadius={1}
        border={1}
        sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", borderColor: theme.palette.background.darkest, p: 4, gap: 4 }}
      >
        {title && <Typography sx={{ fontWeight: "bold", mb: 2, color: 'text.primary' }}>{title}</Typography>}
        {!title && <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "50%" }} />}

        <Box sx={{ display: "flex", flexDirection: vertical ? "column" : "row", gap: 4 }}>
          <Skeleton variant="circular" animation={animation} sx={{ width: "150px", height: "150px", alignSelf: "center" }} />

          <Box sx={{ display: "flex", flexDirection: "column", gap: 4, flex: "1 1 0%" }}>
            <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "100%" }} />
            <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "100%" }} />
            <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "100%" }} />
            <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "100%" }} />
            <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "100%" }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default MultiDimensionalRoundedChartSkeleton;