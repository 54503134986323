import {createAction} from 'redux-actions';

import * as ORCHESTRATION from './types';

const setPreviousSection = createAction(ORCHESTRATION.SET_PREVIOUS_SECTION, data => data);
const setNextSection = createAction(ORCHESTRATION.SET_NEXT_SECTION, data => data);

const setClientCreation = createAction(ORCHESTRATION.SET_CLIENT_CREATION, data => data);
const setCampaignSetup = createAction(ORCHESTRATION.SET_CAMPAIGN_SETUP, data => data);
const setSocialSetup = createAction(ORCHESTRATION.SET_SOCIAL_SETUP, data => data);

const loadQuicklinks = createAction(ORCHESTRATION.LOAD_QUICKLINKS, data => data);

const loadOrchestration = createAction(ORCHESTRATION.LOAD_ORCHESTRATION, data => data);
const setOrchestration = createAction(ORCHESTRATION.SET_ORCHESTRATION, data => data);

export {
  setPreviousSection,
  setNextSection,
  setClientCreation,
  setCampaignSetup,
  setSocialSetup,
  loadQuicklinks,
  loadOrchestration,
  setOrchestration,
};
