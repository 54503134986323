import React, {useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import {
  MessageCircle as MessageIcon,
  Package as PackageIcon,
  Truck as TruckIcon
} from 'react-feather';
import NotificationsIcon from '@mui/icons-material/Notifications';

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const Notifications = () => {
  const notifications = [];
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <SvgIcon sx={{ color: 'text.secondary' }}>
            <NotificationsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>

      <Popover anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
               PaperProps={{ sx: { width: 320, p: 4 } }}
               anchorEl={ref.current}
               onClose={handleClose}
               open={isOpen}>
        <Box>
          <Typography variant="h5" color="textPrimary">
            Notifications
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map((notification) => {
                const Icon = iconsMap[notification.type];

                return (
                  <ListItem component={RouterLink} divider key={notification.id} to="#">
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'secondary.main', color: 'secondary.contrastText' }}>
                        <SvgIcon fontSize="small">
                          <Icon/>
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText primary={notification.title}
                                  primaryTypographyProps={{variant: 'subtitle2', color: 'textPrimary'}}
                                  secondary={notification.description}/>
                  </ListItem>
                );
              })}
            </List>

            <Box p={1} display="flex" justifyContent="center">
              <Button component={RouterLink} size="small" to="#">
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default Notifications;
