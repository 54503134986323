import React from "react";
import {Box, Button, ButtonGroup, IconButton, Tooltip} from "@mui/material";
import {
  AddCircleRounded,
  DeleteSharp,
  FileCopySharp,
} from "@mui/icons-material";

function ControlHeader(props) {
  const handleSelectedUserChannel = (userChannel) => {
    if( props.selectedUserChannel && (props.selectedUserChannel.kee2_identifier === userChannel.kee2_identifier) ) {
      props.setSelectedUserChannel(undefined);
      props.handleSelectAllUserChannels(true);
    } else {
      props.setSelectedUserChannel(userChannel);
      props.handleSelectAllUserChannels(false);
    }
  };

  const handleSelectAllUserChannels = () => {
    if( props.selectedUserChannel ) {
      props.setSelectedUserChannel(undefined);
      props.handleSelectAllUserChannels(true);
    }
  }

  return (
    <Box sx={{ position: 'relative', p: 0, mt: 4 }}>
      <ButtonGroup variant="outlined" size="small">
        <Button id={`button_all_user_channels`}
                key={`button_all_user_channels`}
                onClick={() => handleSelectAllUserChannels()}
                sx={{ bgcolor: props.allUserChannelsSelected ? 'primary.main' : 'background.default',
                      color: props.allUserChannelsSelected ? 'text.primaryContrast' : 'text.secondary',
                      borderColor: 'secondary.main',
                      letterSpacing: 1,
                      py: 1,
                      px: 6,
                }}>
          All
        </Button>
        {props.userChannels && props.userChannels.data.map((userChannel, userChannelIndex) => (
          <Button id={`buttton_${userChannelIndex}`}
                  key={`buttton_${userChannelIndex}`}
                  disabled={props.contentFolders.data.length <= 0}
                  onClick={() => handleSelectedUserChannel(userChannel)}
                  sx={{ bgcolor: props.selectedUserChannel && (props.selectedUserChannel.kee2_identifier === userChannel.kee2_identifier) ? 'primary.main' : 'background.default',
                        color: props.selectedUserChannel && (props.selectedUserChannel.kee2_identifier === userChannel.kee2_identifier) ? 'text.primaryContrast' : 'text.secondary',
                        borderColor: 'secondary.main',
                        letterSpacing: 1,
                        py: 1,
                        px: 6,
                        '&:hover': {
                          bgcolor: props.selectedUserChannel && (props.selectedUserChannel.kee2_identifier === userChannel.kee2_identifier) ? 'primary.dark' : 'background.default',
                          borderColor: 'secondary.main',
                        }
                  }}>
            {userChannel.name}
          </Button>
        ))}
      </ButtonGroup>

      <Box sx={{ display: 'flex', position: 'absolute', right: '0px', top: '4px' }}>
        <Box sx={{ display: 'flex', mr: 4, borderRight: 1, borderColor: 'secondary.dark' }}>
          <Box sx={{ mr: 4 }}>
            <Tooltip disabled title={'Delete packs'}>
              <div>
                <IconButton id="button_delete_selected_packs" aria-label="delete" size="small"
                            onClick={() => { if( props.contentFolders.selectedCount > 0 ) props.handlePackDeletionDialogueOpen(); }}
                            disabled={ props.contentFolders.selectedCount === 0  }
                            sx={{ bgcolor: 'text.secondary',
                              '&:disabled': { bgcolor: 'text.secondary' },
                              '&:hover': { bgcolor: 'primary.main' } }}>
                  <DeleteSharp sx={{ fontSize: '1.5rem', color: 'background.dark' }} />
                </IconButton>
              </div>
            </Tooltip>
          </Box>

          <Box sx={{ mr: 4 }}>
            <Tooltip title={'To be implemented'}>
              <div>
                <IconButton id="button_copy_selected_packs"
                            disabled aria-label="copy" size="small"
                            sx={{ bgcolor: 'text.secondary',
                              '&:disabled': { bgcolor: 'text.lightGray' },
                              '&:hover': { bgcolor: 'primary.main' } }}>
                  <FileCopySharp sx={{ fontSize: '1.5rem', color: 'background.dark' }} />
                </IconButton>
              </div>
            </Tooltip>
          </Box>
        </Box>

        <Box>
          <Tooltip title={'Add new pack'}>
            <div>
              <IconButton id="button_add_new_pack" aria-label="add_new" size="small"
                          onClick={() => props.handleControlModalOpen()}
                          sx={{ bgcolor: 'text.secondary',
                            '&:disabled': { bgcolor: 'text.secondary' },
                            '&:hover': { bgcolor: 'primary.main' } }}>
                <AddCircleRounded sx={{ fontSize: '1.5rem', color: 'background.dark' }} />
              </IconButton>
            </div>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
}

export default ControlHeader;
