import connect from "react-redux/es/connect/connect";
import {Box, IconButton, Tooltip, Typography} from "@mui/material";
import React, {useState} from "react";
import GiphyLogo from "../../../components/vectors/GiphyLogo";
import {Delete} from "@mui/icons-material";
import AlertDialog from "../../../components/AlertDialogue";

const actionsToProps = dispatch => ({});

const stateToProps = state => ({});

const GiphyChannelsTab = ({
  giphyChannels,
  deleteGiphyChannel,
  ...props
}) => {
  const [giphyChannelDeleteDialogOpen, setGiphyChannelDeleteDialogOpen] = useState(false);
  const [giphyChannelUidToDelete, setGiphyChannelUidToDelete] = useState('');

  const handleOnClickTrash = (giphyChannel) => {
    setGiphyChannelDeleteDialogOpen(true);
    setGiphyChannelUidToDelete(giphyChannel.uid);
  };

  const handleGiphyChannelDeletionAgree = () => {
    deleteGiphyChannel(giphyChannelUidToDelete);
    setGiphyChannelDeleteDialogOpen(false);
  };

  const handleGiphyChannelDeletionDisagree = () => {
    setGiphyChannelDeleteDialogOpen(false);
  };

  return (
    <Box sx={{ height: 1, overflowY: 'auto', px: 12 }}>
      <Box display="flex" justifyContent="space-between" sx={{ pt: 8 }}>
        <Box alignSelf="center">
          <Typography id="label_content_type_your" color="text.secondary" variant="h3" sx={{ fontWeight: 'regular', mb: 2 }}>
            {`Giphy channels`}
          </Typography>

          <Typography id="label_content_type_counter" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
            {`${giphyChannels.length} channel` + (giphyChannels.length !== 1 ? 's' : '')}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ pt: 6 }}>
        {giphyChannels.map((giphyChannel, index) => {
          return (
            <Box id={`box_giphy_channel_${index}`} key={`box_giphy_channel_${index}`}
                 sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: 'background.dark', px: 8, py: 6, mb: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <GiphyLogo width='18px' height='18px' />

                <Typography sx={{ pl: 2 }}>{giphyChannel.name}</Typography>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ bgcolor: 'background.default', mx: 1.5, p: 2.5, borderRadius: '3px', whiteSpace: 'nowrap' }}>
                    <Typography id={`label_giphy_channel_gifs_count_${index}`} sx={{ textAlign: 'center', fontSize: '0.8rem', color: 'text.secondary' }}>
                      {`${giphyChannel.gifs_count} gifs`}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ width: 144, height: 99, display: 'flex', justifyContent: 'center' }}>
                <img id={`img_preview_giphy_channel_${props.index}`}
                     src={giphyChannel.preview_url}
                     style={{ width: 144, height: 99, objectFit: 'cover' }} />

                <Box sx={{ mx: 6, display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="Remove Giphy channel">
                      <span>
                        <IconButton id={`button_delete_giphy_channel_${props.index}`} aria-label="delete" size="small"
                                    onClick={() => handleOnClickTrash(giphyChannel)}
                                    sx={{ '&:hover': { '& .MuiSvgIcon-root': { color: 'danger.main' } } }} >
                          <Delete />
                        </IconButton>
                      </span>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>

      <AlertDialog id={`dialog_delete_giphy_channel`} open={giphyChannelDeleteDialogOpen}
                   handleAgree={() => handleGiphyChannelDeletionAgree()}
                   handleDisagree={() => handleGiphyChannelDeletionDisagree()}
                   title="Are you sure you want to delete the Giphy channel?"
                   body="Doing so will remove its assets from this pack." />
    </Box>
  );
};

export default connect(stateToProps, actionsToProps)(GiphyChannelsTab);