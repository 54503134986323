const LOAD_REPORT_DESCRIPTORS = "LOAD_REPORT_DESCRIPTORS";
const SET_REPORT_DESCRIPTORS = "SET_REPORT_DESCRIPTORS";
const SET_REPORT_FILTERS = "SET_REPORT_FILTERS";
const SET_REPORT_GROUPINGS = "SET_REPORT_GROUPINGS";
const SET_REPORT_ORDERINGS = "SET_REPORT_ORDERINGS";
const SET_REPORT_ROWS_LIMIT = "SET_REPORT_ROWS_LIMIT";
const LOAD_REPORT = "LOAD_REPORT";
const SET_REPORT = "SET_REPORT";
const LOAD_REPORT_EXCEL_DOWNLOAD = "LOAD_REPORT_EXCEL_DOWNLOAD";
const SET_REPORT_EXCEL_DOWNLOAD = "SET_REPORT_EXCEL_DOWNLOAD";

export {
  LOAD_REPORT_DESCRIPTORS,
  SET_REPORT_DESCRIPTORS,
  SET_REPORT_FILTERS,
  SET_REPORT_GROUPINGS,
  SET_REPORT_ORDERINGS,
  SET_REPORT_ROWS_LIMIT,
  LOAD_REPORT,
  SET_REPORT,
  LOAD_REPORT_EXCEL_DOWNLOAD,
  SET_REPORT_EXCEL_DOWNLOAD,
};