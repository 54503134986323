import connect from "react-redux/es/connect/connect";
import {Box, Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import React, {useState} from "react";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import Timeline from '@mui/icons-material/Timeline';
import {StackedBarChart, StackedLineChart} from "@mui/icons-material";
import {BarChart} from "react-feather";
const AdvancedDailyDashboardAxisControl = ({ axis, setAxisItems, setAxisRenditionType, stackedBarAllowed, ...props }) => {
  const [selectedItems, setSelectedItems] = useState(axis.items);
  const [selectedRenditionType, setSelectedRenditionType] = useState(axis.renditionType);

  const handleItemsSelectionChanged = item => {
    if( selectedRenditionType === 'stacked_bar' ) {
      if( selectedItems.includes(item) ) {
        setSelectedItems([]);
        setAxisItems({
          items: [],
        });
      } else {
        setSelectedItems([item]);
        setAxisItems({
          items: [item],
        });
      }
    } else {
      const newItems = selectedItems.includes(item) ?
        selectedItems.filter(i => i !== item) :
        [item, ...selectedItems];

      setSelectedItems(newItems);
      setAxisItems({
        items: newItems,
      });
    }
  };

  const handleRenditionTypeChange = value => {
    setSelectedRenditionType(value);
    setAxisRenditionType({
      renditionType: value
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <ToggleButtonGroup
        sx={{ my: 4 }}
        color="primary"
        value={selectedRenditionType}
        exclusive
        onChange={event => handleRenditionTypeChange(event.target.value || event.target.closest('button').value)}
        aria-label="Rendition Type"
      >
        <ToggleButton value="line">
          <>
            <Timeline sx={{ mr: 1 }} />
            Line
          </>
        </ToggleButton>

        <ToggleButton value="area">
          <StackedLineChart sx={{ mr: 1 }} />
          Area
        </ToggleButton>

        <ToggleButton value="bar">
          <BarChart sx={{ mr: 1 }} />
          Bar
        </ToggleButton>

        <ToggleButton value="stacked_bar" disabled={selectedItems.length > 1}>
          <StackedBarChart sx={{ mr: 1 }} />
          Stacked Bar
        </ToggleButton>
      </ToggleButtonGroup>

      <Box sx={{ display: 'flex' }}>
        <FormGroup>
          <FormControlLabel label="App Installs" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('app_installs')} checked={selectedItems.includes('app_installs')} />
          } />

          <FormControlLabel label="App Users (DAU)" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('app_users')}  checked={selectedItems.includes('app_users')} />
          } />

          <FormControlLabel label="App Opens" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('app_opens')} checked={selectedItems.includes('app_opens')} />
          } />

          <FormControlLabel label="Total Revenue" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('total_revenue')} checked={selectedItems.includes('total_revenue')} />
          } />
        </FormGroup>

        <FormGroup>
          <FormControlLabel label="Keyboard Activations" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('keyboard_activations')} checked={selectedItems.includes('keyboard_activations')} />
          } />

          <FormControlLabel label="Keyboard Users (DAKU)" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('keyboard_users')} checked={selectedItems.includes('keyboard_users')} />
          } />

          <FormControlLabel label="Keyboard Opens" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('keyboard_opens')} checked={selectedItems.includes('keyboard_opens')} />
          } />

          <FormControlLabel label="Re-engagement Clicks" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('re_engagement_clicks')} checked={selectedItems.includes('re_engagement_clicks')} />
          } />
        </FormGroup>
      </Box>
    </Box>
  );
}

export default AdvancedDailyDashboardAxisControl;