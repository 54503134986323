import connect from "react-redux/es/connect/connect";
import { Box } from "@mui/material";
import React from "react";
import Bar from "../../../../components/vis/Bar";
import SimpleRectangleVisSkeleton from "../../../../components/vis/SimpleRectangleVisSkeleton";

const stateToProps = ({ insights: {sections: { keyboardEngagement } }}) => {
  return {
    isLoading: keyboardEngagement.isLoading,
    isLoadingForALongTime: keyboardEngagement.isLoadingForALongTime,
    data: keyboardEngagement.data?.most_active_time?.value,
  };
};

function toAmPm(hour) {
  if (hour < 12) {
    return `${hour}am`;
  }

  return `${hour-12}pm`;
}


function mapValues(data) {
  return data.map((d) => ({
    label: toAmPm(d.hour),
    value: d.appearances,
  }))
}

function VisActiveTime({ sx = {}, isLoading, isLoadingForALongTime, data, filters }) {
  const hasData = data && (data.hour_from !== 0 || data.hour_to !== 0);

  const values = data && mapValues(data);

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "100%" }}
                                    animation="wave"
                                    title={isLoadingForALongTime ? "Active time - working" : "Active time"} />
      )}

      {!isLoading && !hasData && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "100%" }}
                                    animation={false}
                                    title="Active time - no data" />
      )}

      {!isLoading && hasData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Bar title="Active time" height="400"
               xLabel="Hour" yLabel="% of activity"
               data={values} filters={filters} />
        </Box>
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisActiveTime);
