import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

function Heading({ align, label }) {
  return <TableCell align={align}>{label}</TableCell>;
}

function Cell({ align, value }) {
  return <TableCell align={align}>{value}</TableCell>;
}

export default function TableVis({
  title,
  rows,
  headings,
  size,
  hideHeadings,
  borders = true,
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {title && <Typography>{title}</Typography>}
      <TableContainer component={Paper} sx={borders ? {} : { boxShadow: 0 }}>
        <Table aria-label={title} size={size} sx={ borders ? {} : { border: 0 } }>
          {hideHeadings === false && (
            <TableHead>
              <TableRow>
                {headings.map((heading, i) => (
                  <Heading key={heading.key} {...heading} />
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={i}
                sx={ borders ? { "&:last-child td, &:last-child th": { border: 0 } } : { "& td": { border: 0 } }}
              >
                {headings.map((heading, i) => (
                  <Cell value={row[heading.key]} {...heading} />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}


TableVis.propTypes = {
  title: PropTypes.string,
  hideHeadings: PropTypes.bool,
  headings: PropTypes.array,
  rows: PropTypes.array,
};

TableVis.defaultProps = {
  hideHeadings: false,
};
