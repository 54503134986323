// Types
const contentTypes = (state) => state.contentKeyboard.contentTypes;

// Content items
const contentItems = (state) => state.contentKeyboard.items;
const contentItem = (state) => state.contentKeyboard.contentItem;
const previewContentItem = (state) => state.contentKeyboard.previewContentItem;
const additionalContentItems = (state) => state.contentKeyboard.additionalContentItems;

// Giphy channels
const giphyChannels = (state) => state.contentKeyboard.giphyChannels;
const giphyChannel = (state) => state.contentKeyboard.giphyChannel;

// Keywords
const contentKeywords = (state) => state.contentKeyboard.keywords;
const contentCreateKeywords = (state) => state.contentKeyboard.createKeywords;

// Packs
const contentCreatePack = (state) => state.contentKeyboard.createPack;

// Content folders
const contentFolders = state => state.contentKeyboard.contentFolders;
const contentFolder = state => state.contentKeyboard.contentFolder;

// Content assets
const contentAssets = state => state.contentKeyboard.contentAssets;

// Publish to Mocha controls
const diffs = state => state.contentKeyboard.diffs;
const publish = state => state.contentKeyboard.publishToMocha;

export {
  contentItems,
  contentItem,
  previewContentItem,
  additionalContentItems,
  contentTypes,
  contentKeywords,
  contentCreateKeywords,
  contentCreatePack,
  contentFolders,
  contentFolder,
  contentAssets,
  giphyChannel,
  giphyChannels,
  diffs,
  publish,
};
