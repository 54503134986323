import {Box, Skeleton, Typography, useTheme} from "@mui/material";
import React from "react";

function SimpleRectangleVisSkeleton ({sx, animation = false, title, ...props}) {
  const theme = useTheme();

  return (
    <Box sx={sx}>
      <Box
        borderRadius={1}
        border={1}
        sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", borderColor: theme.palette.background.darkest, p: 4, gap: 4, minHeight: "300px" }}
      >
        {title && <Typography sx={{ fontWeight: "bold", mb: 4, color: 'text.primary' }}>{title}</Typography>}

        {!title && <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "25%" }} />}

        <Skeleton variant="rectangular" animation={animation} sx={{ flex: "1 1 0%" }} />
      </Box>
    </Box>
  );
}

export default SimpleRectangleVisSkeleton;