import JSZip from "jszip";

const PLATFORM_ANDROID = 'android';
const PLATFORM_ANDROID_SDK = 'android_sdk';
const PLATFORM_IOS = 'ios';
const PLATFORM_IOS_NEW = 'ios_new';

const REQUIRED_FILES = {
  'android': [
    'kb_theme_preview_large_keyborders_image_1.5x.png',
    'kb_theme_preview_large_keyborders_image_1x.png',
    'kb_theme_preview_large_keyborders_image_3x.png',
    'kb_theme_preview_large_keyborders_image_4x.png',
    'kb_theme_preview_large_keyborders_image.png',
    'kb_theme_preview_large_nokeyborders_image_1.5x.png',
    'kb_theme_preview_large_nokeyborders_image_1x.png',
    'kb_theme_preview_large_nokeyborders_image_3x.png',
    'kb_theme_preview_large_nokeyborders_image_4x.png',
    'kb_theme_preview_large_nokeyborders_image.png',
    'kb_theme_preview_small_image_1.5x.png',
    'kb_theme_preview_small_image_1x.png',
    'kb_theme_preview_small_image_3x.png',
    'kb_theme_preview_small_image_4x.png',
    'kb_theme_preview_small_image.png',
    'obj.json'
  ],
  'android_sdk': [
    'kb_toolbar_items_logo_icon_0.png',
    'kb_wallpaper_landscape_image_0.png',
    'kb_wallpaper_portrait_image_0.png',
    'obj.json',
    'preview.png',
  ],
  'ios': [
    'preview.png',
    'obj.json'
  ],
  'ios_new': [
    'obj.json',
    'kb_theme_preview_large_keyborders_image_0.png',
    'kb_theme_preview_large_nokeyborders_image_0.png',
    'kb_theme_preview_small_image_0.png',
    'kb_toolbar_items_logo_icon_0.png',
    'kb_wallpaper_landscape_image_0.png',
    'kb_wallpaper_portrait_image_0.png'
  ],
};

function ZipException(message) {
  this.message = message;
  this.name = 'ZipException';
}

function PreviewException(message) {
  this.message = message;
  this.name = 'PreviewException';
}

const allFilesAreHere = (actualFiles, expectedFiles) => {
  const actualFileNames = actualFiles.map(file => file.name);

  for( const expectedFile of expectedFiles ) {
    if( !actualFileNames.includes(expectedFile) ) {
      return false;
    }
  }

  return true;
}

const determinePlatform = (files) => {
  const isIosTheme = allFilesAreHere(files, REQUIRED_FILES[PLATFORM_IOS]);
  const isIosNewTheme = allFilesAreHere(files, REQUIRED_FILES[PLATFORM_IOS_NEW]);
  const isAndroidTheme = allFilesAreHere(files, REQUIRED_FILES[PLATFORM_ANDROID]);
  const isAndroidSdkTheme = allFilesAreHere(files, REQUIRED_FILES[PLATFORM_ANDROID_SDK]);

  console.log(files);
  console.log(REQUIRED_FILES[PLATFORM_ANDROID_SDK]);

  if( !isIosTheme && !isIosNewTheme && !isAndroidTheme && !isAndroidSdkTheme ) {
    throw new ZipException('The contents of the archive do not meet neither the iOS nor the Android theme requirements.');
  }

  if( isAndroidTheme ) {
    return PLATFORM_ANDROID;
  }

  if( isIosNewTheme ) {
    return PLATFORM_IOS_NEW;
  }

  if( isAndroidSdkTheme ) {
    return PLATFORM_ANDROID_SDK;
  }

  if( isIosTheme ) {
    return PLATFORM_IOS;
  }
}

const pickCmsPreview = (platform, files) => {
  let potentialPreview = files.filter(file => {
    switch (platform) {
      case PLATFORM_IOS:
        return file.name.includes('preview.png');
      case PLATFORM_IOS_NEW:
        return file.name.includes('kb_theme_preview_large_keyborders_image_0.png');
      case PLATFORM_ANDROID:
        return file.name.includes('kb_theme_preview_large_keyborders_image_1x.png');
      case PLATFORM_ANDROID_SDK:
        return file.name.includes('preview.png');
      default:
        return false;
    }
  });

  if( potentialPreview.length === 0 || potentialPreview.length > 1 ) {
    throw new PreviewException('Could not pick the preview from the zip.');
  }

  return potentialPreview[0];
}

const pickThemeZip = (files) => {
  let potentialThemeZip = files.filter(file => {
    return file.name.split('.').pop() === 'zip';
  });

  if( potentialThemeZip.length === 0 || potentialThemeZip.length > 1 ) {
    throw new ZipException('Could not pick the theme archive from the zip.');
  }

  if( potentialThemeZip[0].file.size > 26214400 ) {
    throw new ZipException('The inner zip file is larger than 25MB.');
  }

  return potentialThemeZip[0];
}

const pickThemeVersion = async (files) => {
  let potentialThemeJson = files.filter(file => {
    return file.name.split('.').pop() === 'json';
  });

  if (potentialThemeJson.length === 0 || potentialThemeJson.length > 1) {
    throw new ZipException('Could not pick up the JSON from the zip.');
  }

  return await potentialThemeJson[0].file.text().then(text => {
    return JSON.parse(text).version;
  });
};

const crawl = (zip) => {
  return JSZip.loadAsync(zip).then(function (zip) {
    const extensionRegex = /(.jpg|.png|.gif|.ps|.jpeg|.json|.zip)$/;

    const promises = Object.keys(zip.files).filter(fileName => {
      return extensionRegex.test(fileName.toLowerCase());
    }).map(function (fileName) {
      const file = zip.files[fileName];

      return file.async("blob").then(function (blob) {
        return [
          blob,
          fileName,  // keep the link between the file name and the content
          URL.createObjectURL(blob) // create an url. img.src = URL.createObjectURL(...) will work
        ];
      });
    });

    return Promise.all(promises);
  }).then(result => {
    return result.map(image => {
      return {
        file: new File([image[0]], image[1]),
        name: image[1].startsWith('/') ? image[1].slice(1) : image[1],
        url: image[2],
      };
    }, {})
  });
}

const zipCrawler = (zip) => {
  return crawl(zip).then(outerZipFiles => {
    const innerZip = pickThemeZip(outerZipFiles);

    return crawl(innerZip.file).then(async innerZipFiles => {
      const platform = determinePlatform(outerZipFiles.concat(innerZipFiles));
      console.log(platform);
      const cmsPreview = pickCmsPreview(platform, platform === PLATFORM_IOS_NEW ? innerZipFiles : outerZipFiles);
      const themeVersion = await pickThemeVersion(outerZipFiles);

      const restOfTheOuterZipFiles = outerZipFiles.filter(
        file => file.name !== cmsPreview.name &&
          file.name !== innerZip.name &&
          REQUIRED_FILES[platform].includes(file.name)
      );
      const restOfTheFiles = (platform === PLATFORM_IOS_NEW ?
        restOfTheOuterZipFiles.concat(innerZipFiles.filter(
          file => file.name !== cmsPreview.name &&
            REQUIRED_FILES[platform].includes(file.name)
        )) :
        restOfTheOuterZipFiles);

      return {
        'cmsPreview': cmsPreview,
        'themeZip': innerZip,
        'restOfTheFiles': restOfTheFiles,
        'themeVersion': themeVersion,
      };
    });
  })
}

export default zipCrawler;