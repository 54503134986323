const APP_INITIALIZE = 'APP_INITIALIZE';
const APP_STATUS = 'APP_STATUS';
const APP_LOAD_TRANSLATIONS = 'APP_LOAD_TRANSLATIONS';
const APP_SET_TRANSLATIONS = 'APP_SET_TRANSLATIONS';
const APP_SET_CONFIG = 'APP_SET_CONFIG';
const APP_LOAD_SET_STATUS_CODE = 'APP_LOAD_SET_STATUS_CODE';
const APP_SET_STATUS_CODE = 'APP_SET_STATUS_CODE';

const UI_SECTION_CHANGE = 'UI_SECTION_CHANGE';
const UI_MENU_TOGGLE = 'UI_MENU_TOGGLE';
const UI_MODAL_TOGGLE = 'UI_MODAL_TOGGLE';
const UI_PREFERENCE_UPDATE = 'UI_PREFERENCE_UPDATE';
const UI_DEVICE_INFO = 'UI_DEVICE_INFO';
const UI_REDIRECT = 'UI_REDIRECT';
const UI_REPLACE = 'UI_REPLACE';
const UI_URL = 'UI_URL';
const UI_MESSAGE_ADD = 'UI_MESSAGE_ADD';
const UI_FOOTER_TOGGLE = 'UI_FOOTER_TOGGLE';

export {
  APP_INITIALIZE,
  APP_SET_CONFIG,
  APP_LOAD_SET_STATUS_CODE,
  APP_SET_STATUS_CODE,
  APP_STATUS,
  APP_LOAD_TRANSLATIONS,
  APP_SET_TRANSLATIONS,
  UI_SECTION_CHANGE,
  UI_MENU_TOGGLE,
  UI_MODAL_TOGGLE,
  UI_PREFERENCE_UPDATE,
  UI_DEVICE_INFO,
  UI_REDIRECT,
  UI_REPLACE,
  UI_URL,
  UI_MESSAGE_ADD,
  UI_FOOTER_TOGGLE,
};
