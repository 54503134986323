// START modules
import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import connect from 'react-redux/es/connect/connect';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import {TextField, Typography, Chip, Box, FormControlLabel, Checkbox, Tooltip} from '@mui/material';
import {makeStyles} from '@mui/styles';
import moment from 'moment';
// END modules

// START components
import ChipWithInput from '../../../components/ChipWithInput';
import {HelpCenter, InfoOutlined, QuestionMark} from "@mui/icons-material";
// END components


// START styles
const useStyles = makeStyles((theme) => ({
  dateCol: {
    display: "flex"
  },
  dateItem: {
    marginRight: 24,
    "&:last-child": {
      marginRight: 0
    }
  },
  timeStatus: {
    marginTop: 15,
    display: "inline-block",
    padding: "8.5px 32px",
    border: `1px solid ${theme.palette.action.active}`,
    borderRadius: 7,
  },
  toDate: {
    position: "relative",
    width: 70,
  },
  toDateIcon: {
    position: "absolute",
    bottom: 18,
    left: "50%",
    transform: "translateX(-50%)"
  }
}));
// END styles

const actionsToProps = dispatch => ({
});

const stateToProps = state => ({
});

const Settings = ({...rest}) => {
  const {enqueueSnackbar} = useSnackbar();

  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [keywordsOptions, setKeywordsOptions] = useState({
    page: 1,
    rows_per_page: 10,
    hasMore: true
  });
  const [availableKeywords, setAvailableKeywords] = useState([]);
  const [availableChannels, setAvailableChannels] = useState([]);
  const [showAllKeywords, setShowAllKeywords] = useState(false);

  const loadKeywords = _.debounce((query, isFirstFetch = false) => {
    let page = !isFirstFetch ? keywordsOptions.page : 1;

    if(query.length > 2) {
      rest.handleContentLoadKeywords({
        page: page,
        rows_per_page: keywordsOptions.rows_per_page,
        filter: query,
      });

      setKeywordsOptions({...keywordsOptions, page: page + 1});
    } else {
      if(availableKeywords.length > 0) {
        setAvailableKeywords([]);
      }
    }
  }, 350);

  const handleContentFolder = _.debounce(values => {
    rest.handleModifyContentFolder(values);
  }, 350);

  const deserializeKeywords = (keywords) => {
    let arr = [];
    if (Object.keys(keywords).length > 0) {
      for (const id in keywords) {
        arr.push({id, label: keywords[id]});
      }
    }
    return arr;
  }

  const serializeKeywords = (keywords) => {
    let ob = {};
    keywords.forEach(({id, label}) => ob[id] = label);
    return ob;
  }

  const deserializeChannels = (channels) => {
    const arr = [];

    for (let i = 0; i < channels.data.length; i++) {
      arr.push({
        id: channels.data[i].id,
        label: channels.data[i].name,
        rank: channels.data[i].rank,
      });
    }
    return arr;
  }

  const serializeChannels = (channels) => {
    const ob = {data: []};
    channels.forEach(channel => ob.data.push({
      id: channel.id,
      name: channel.label,
      rank: channel.rank,
    }));
    return ob;
  }

  const deserializePackName = (name) => {
    const packCategoryMatches = name.match(/[^#]+(?=#)/);
    const packNameMatches = name.match(/(?<=#)[^!]+/);
    const packIsFeaturedMatches = name.match(/!?$/);

    if( packCategoryMatches === null || packNameMatches === null  ) {
      return null;
    }

    return {
      packCategory: packCategoryMatches[0],
      packName: packNameMatches[0],
      packIsFeatured: packIsFeaturedMatches[0] === '!'
    };
  }

  const serializePackName = (components) => {
    return components.pack_category + '#' + components.pack_name + (components.pack_is_featured ? '!' : '');
  }

  useEffect(() => {
    if(rest.userChannels.data) {
      const formatAvailableChannels = rest.userChannels.data.map((channel) => {
        return {
          id: channel.id,
          label: channel.name
        }
      });

      setAvailableChannels(formatAvailableChannels);
    }
  }, [rest.userChannels.data])

  useEffect(() => {
    const {meta} = rest.contentKeywords;
    const current_page = meta?.current_page;
    const last_page = meta?.last_page;

    let hasMore = !(current_page === last_page);
    let newAvailableKeywords = [];


    if(rest.contentKeywords.data?.length !== 0) {
      const formatAvailableKeywords = rest.contentKeywords.data.map((keyword) => {
        return {
          id: keyword.slug,
          label: keyword.name
        }
      });

      newAvailableKeywords = formatAvailableKeywords;

      if(keywordsOptions.page > 2) {
          newAvailableKeywords = [...availableKeywords, ...formatAvailableKeywords];
      }
    }

    setAvailableKeywords(newAvailableKeywords);
    setKeywordsOptions({...keywordsOptions, hasMore});
  }, [rest.contentKeywords.data]);

  useEffect(() => {
    if(rest.contentCreateKeywords.msg) {
      enqueueSnackbar(rest.contentCreateKeywords.msg, {
        variant: 'error',
        persist: true,
      });
    }
  }, [rest.contentCreateKeywords.msg])

  useEffect(() => {
    // Set keywords
    setSelectedKeywords(deserializeKeywords(rest.contentFolder.data.keywords));

    // Set channels
    setSelectedChannels(deserializeChannels(rest.contentFolder.data.channels));
  }, []);

  const handleSetSelectedItems = (values, type) => {
    switch (type) {
      case 'keywords':
        setSelectedKeywords(values);
        rest.handleModifyContentFolder({keywords: serializeKeywords(values)});
        break;

      case 'channels':
        setSelectedChannels(values);
        rest.handleModifyContentFolder({channels: serializeChannels(values)});
        break;

      default:
        break;
    }
  }

  const handleOnDelete = item => {
    const newSelectedKeywords = selectedKeywords.filter(v => !(_.isEqual(v.id, item.id) || _.isEqual(v.label, item.label)));
    setSelectedKeywords(newSelectedKeywords);
  }

  const handleIsTrim = (id, value) => {
    if (value === false) {
      setShowAllKeywords(false);
    }
  }

  return (
  <Box sx={{ height: 1, overflowY: 'auto', px: 12, pb: 12 }}>
    <Box sx={{ pt: 8 }}>
      <Typography color="text.secondary" variant="h3" sx={{ fontWeight: 'regular', mb: 2 }}>
        Global Settings
      </Typography>
    </Box>

    <Box sx={{ mt: 12 }}>
      <Formik innerRef={rest.createPackFormRef}
              initialValues={{
                name: rest.contentFolder.data.name,
                pack_category: deserializePackName(rest.contentFolder.data.name) !== null ? deserializePackName(rest.contentFolder.data.name).packCategory : '',
                pack_name: deserializePackName(rest.contentFolder.data.name) !== null ? deserializePackName(rest.contentFolder.data.name).packName : '',
                pack_is_featured: deserializePackName(rest.contentFolder.data.name) !== null ? deserializePackName(rest.contentFolder.data.name).packIsFeatured : false,
                keywords: selectedKeywords,
                revenueChannels: selectedChannels,
                submit: null
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().max(255).required('Pack name is required'),
                pack_category: Yup.string()
                  .required('Pack category is required')
                  .min(2)
                  .max(64),
                pack_name: Yup.string()
                  .required('Pack name is required')
                  .min(2)
                  .max(64),
                pack_is_featured: Yup.boolean()
                  .required('You must specify if the pack is featured')
              })}
              validateOnChange={true}
              validateOnBlur={true}>
        {({
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          isValid,
          isValidating,
          validateForm
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ mt: 8 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                <Typography variant="body1" color="textSecondary"
                            sx={{ fontSize: '0.8rem', fontWeight: 'medium', letterSpacing: '1.2', textTransform: 'uppercase', mr: 2 }} >
                  Name your pack
                </Typography>

                <Tooltip title="A pack's name is composed of the values you can edit in the fields below. All the information is required and you can see the result in the chip." placement="right" >
                  <InfoOutlined />
                </Tooltip>
              </Box>

              <Box sx={{ mb: 6 }}>
                <Chip variant="outlined"
                      label={rest.contentFolder.data.name}
                      sx={{ fontSize: '1rem', px: 6, py: 4, fontWeight: 'medium' }} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'top' }}>
                <TextField id="input_pack_category"
                           error={Boolean(touched.pack_category && errors.pack_category)}
                           helperText={touched.pack_category && errors.pack_category}
                           sx={{ width: '256px' }}
                           label="Category"
                           name="pack_category"
                           size="small"
                           onBlur={handleBlur}
                           onChange={event => {
                             handleChange(event);
                             setFieldValue('pack_category', event.target.value);
                             validateForm({...values, pack_category: event.target.value}).then((errors) => {
                               handleContentFolder({
                                 name: serializePackName({
                                   ...values,
                                   pack_category: event.target.value
                                 }),
                                 categoryValidationPassed: !Boolean(errors.pack_category),
                                 nameValidationPassed: !Boolean(errors.pack_name),
                               });
                             });
                           }}
                           required
                           value={values.pack_category}
                           variant="outlined" />

                <Typography variant="h4" sx={{ mx: 4, mt: 2 }}>#</Typography>

                <TextField id="input_pack_name"
                           error={Boolean(touched.pack_name && errors.pack_name)}
                           helperText={touched.pack_name && errors.pack_name}
                           sx={{ width: '256px' }}
                           label="Name"
                           name="pack_name"
                           size="small"
                           onBlur={handleBlur}
                           onChange={event => {
                             handleChange(event);
                             setFieldValue('pack_name', event.target.value);
                             validateForm({...values, pack_name: event.target.value}).then((errors) => {
                               handleContentFolder({
                                 name: serializePackName({
                                   ...values,
                                   pack_name: event.target.value
                                 }),
                                 categoryValidationPassed: !Boolean(errors.pack_category),
                                 nameValidationPassed: !Boolean(errors.pack_name),
                               });
                             });
                           }}
                           required
                           value={values.pack_name}
                           variant="outlined" />

                <FormControlLabel control={
                                    <Checkbox name="pack_is_featured"
                                              checked={values.pack_is_featured}
                                              onChange={event => {
                                                handleChange(event);
                                                handleContentFolder({name: serializePackName({
                                                    ...values,
                                                    pack_is_featured: event.target.checked
                                                  })});
                                              }} />
                                  } label="Featured" sx={{ ml: 4 }} />
              </Box>
            </Box>


            {rest.contentType.identifier !== 'theme' &&
              <Box sx={{mt: 8}}>
                <Typography component="p" gutterBottom variant="body1" color="textSecondary"
                            sx={{ mb: 4, fontSize: '0.8rem', fontWeight: 'medium', letterSpacing: '1.2', textTransform: 'uppercase'}}>
                  Global keywords
                </Typography>

                <Box sx={{p: 4, borderRadius: 1, bgcolor: 'background.dark'}}>
                  <ChipWithInput id="keywords" name="keywords"
                                 loading={rest.contentKeywords.isLoading}
                                 options={availableKeywords}
                                 selectedItems={selectedKeywords}
                                 setSelectedItems={values => handleSetSelectedItems(values, 'keywords')}
                                 loadItems={loadKeywords}
                                 label="Enter a keyword"
                                 isSelect={false}
                                 hasMore={keywordsOptions.hasMore}
                                 handleOnClickSeeMore={id => setShowAllKeywords(!showAllKeywords)}
                                 showAllKeywords={showAllKeywords}
                                 idForChipList={undefined}
                                 createKeyword={(keyword, callback) => rest.handleContentLoadCreateKeywords({keyword, callback})}
                                 handleIsTrim={(id, value) => handleIsTrim(id, value)}/>
                </Box>

                {showAllKeywords === true && (
                  <Box id="card_all_keywords" sx={{position: 'relative', border: 1}}>
                    {
                      selectedKeywords.map(keyword => {
                        return (
                          <Chip key={`chip_keyword_${keyword.uid}`} label={keyword.label} onDelete={() => handleOnDelete(keyword)}
                                sx={{my: 3, mx: 1, bgcolor: 'background.default', color: 'text.secondary'}}/>
                        );
                      })
                    }
                  </Box>
                )}
              </Box>
            }

            <Box sx={{ mt: 8 }}>
              <Typography component="p" gutterBottom variant="body1" color="textSecondary"
                          sx={{ mb: 4, fontSize: '0.8rem', fontWeight: 'medium', letterSpacing: '1.2', textTransform: 'uppercase' }} >
                Revenue channels
              </Typography>

              <Box sx={{ p: 4, borderRadius: 1, bgcolor: 'background.dark' }}>
                <ChipWithInput id="revenue_channels" name="revenueChannels"
                               options={availableChannels}
                               selectedItems={selectedChannels}
                               setSelectedItems={values => handleSetSelectedItems(values, 'channels')}
                               label="Available channels"
                               isSelect={true}
                               handleIsTrim={(id, value) => {}} />
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  </Box>
  );
}

export default connect(stateToProps, actionsToProps)(Settings);
