import connect from "react-redux/es/connect/connect";
import {Box, Divider, ImageList, ImageListItem, Paper, Typography} from "@mui/material";
import DiffAssetsCollection from "./DiffAssetsCollection";

const actionsToProps = dispatch => ({});

const stateToProps = state => ({});


const diffs = (props) => {
  return (
    <Box>
      {Object.keys(props.diffs.data).map((campaign, index) => {
        return (
          <Paper id={`card_diffs_campaign_${index}`} key={`card_diffs_campaign_${campaign}`} sx={{ bgcolor: 'background.paper', p: 4, mb: 6 }}>
            <Typography id={`label_campaign_${index}`} variant="h5" sx={{ mb: 3 }}>{campaign}</Typography>

            <DiffAssetsCollection id={`card_diff_new_vibes_${index}`} assets={props.diffs.data[campaign].vibes.new} title="vibes" kind="new" />

            <Divider sx={{ my: 3 }} />

            <DiffAssetsCollection id={`card_diff_modified_vibes_${index}`} assets={props.diffs.data[campaign].vibes.modified} title="vibes" kind="modified" />

            <Divider sx={{ my: 3 }} />

            <DiffAssetsCollection id={`card_diff_deleted_vibes_${index}`} assets={props.diffs.data[campaign].vibes.deleted} title="vibes" kind="deleted" />

            <Divider sx={{ my: 3 }} />

            <DiffAssetsCollection id={`card_diff_new_keyboard_themes_${index}`} assets={props.diffs.data[campaign].keyboard_themes.new} title="keyboard themes" kind="new" />

            <Divider sx={{ my: 3 }} />

            <DiffAssetsCollection id={`card_diff_modified_keyboard_themes_${index}`} assets={props.diffs.data[campaign].keyboard_themes.modified} title="keyboard themes" kind="modified" />

            <Divider sx={{ my: 3 }} />

            <DiffAssetsCollection id={`card_diff_deleted_keyboard_themes_${index}`} assets={props.diffs.data[campaign].keyboard_themes.deleted} title="keyboard themes" kind="deleted" />

            <Divider sx={{ my: 3 }} />

            <DiffAssetsCollection id={`card_diff_new_sdk_themes_${index}`} assets={props.diffs.data[campaign].sdk_themes.new} title="sdk themes" kind="new" />

            <Divider sx={{ my: 3 }} />

            <DiffAssetsCollection id={`card_diff_modified_sdk_themes_${index}`} assets={props.diffs.data[campaign].sdk_themes.modified} title="sdk themes" kind="modified" />

            <Divider sx={{ my: 3 }} />

            <DiffAssetsCollection id={`card_diff_deleted_sdk_themes_${index}`} assets={props.diffs.data[campaign].sdk_themes.deleted} title="sdk themes" kind="deleted" />
          </Paper>
        );
      })}
    </Box>
  );
}

export default connect(stateToProps, actionsToProps)(diffs)