import React from 'react';
import {Box, Button, Container, Typography} from '@mui/material';
import Page from './Page';

const NotFoundView = () => {
  return (
    <Page breadCrumbs={(<></>)}>
      <Container maxWidth="lg">
        <Typography align="center" color="textPrimary" variant="h3">
          404: The page you are looking for isn’t here
        </Typography>

        <Typography align="center" variant="subtitle2" color="textSecondary">
          You either tried some shady route or you
          came here by mistake. Whichever it is, try using the navigation.
        </Typography>

        <Box mt={6} display="flex" justifyContent="center">
          <Box sx={{ maxWidth: '700px' }}>
            <img style={{ width: '100%' }} alt="Under development" src="/static/images/undraw_page_not_found_su7k.svg" />
          </Box>
        </Box>

        <Box mt={6} display="flex" justifyContent="center">
          <a href="/">
            <Button color="primary" variant="outlined">
              Back to home
            </Button>
          </a>
        </Box>
      </Container>
    </Page>
  );
};

export default NotFoundView;
