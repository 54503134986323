import { createAction } from "redux-actions";
import * as REPORTING from "./types";

const loadReportDescriptors = createAction(REPORTING.LOAD_REPORT_DESCRIPTORS, (data) => data);
const setReportFilters = createAction(REPORTING.SET_REPORT_FILTERS, (data) => data);
const setReportGroupings = createAction(REPORTING.SET_REPORT_GROUPINGS, (data) => data);
const setReportOrderings = createAction(REPORTING.SET_REPORT_ORDERINGS, (data) => data);
const setReportRowsLimit = createAction(REPORTING.SET_REPORT_ROWS_LIMIT, (date) => date);
const loadReport = createAction(REPORTING.LOAD_REPORT, (data) => data);
const loadExcelDownload = createAction(REPORTING.LOAD_REPORT_EXCEL_DOWNLOAD, (data) => data);

export {
  loadReportDescriptors,
  setReportFilters,
  setReportGroupings,
  setReportOrderings,
  setReportRowsLimit,
  loadReport,
  loadExcelDownload,
};