import connect from "react-redux/es/connect/connect";
import {Box, Button, Card, CardContent, CircularProgress, Typography} from "@mui/material";
import GuestPage from "../../../components/GuestPage";
import Logo from "../../../components/vectors/Logo";
import {Link as RouterLink} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {loadResendEmailConfirmation} from "../store/actions";
import {resendEmailConfirmationIsLoading, resendEmailConfirmationResent} from "../store/selectors";
import {useSnackbar} from "notistack";
import getText from "../../../utils/language";
import {RESEND_EMAIL_CONFIRMATION_THROTTLE_INTERVAL} from "../../../constants";

const stateToProps = state => ({
  resendEmailConfirmationIsLoading: resendEmailConfirmationIsLoading(state),
  resendEmailConfirmationResent: resendEmailConfirmationResent(state),
});

const actionsToProps = dispatch => ({
  loadResendEmailConfirmation: data => dispatch(loadResendEmailConfirmation(data)),
});

const EmailVerification = (props) => {
  const [timer, setTimer] = useState(0);
  const countdown = useRef();

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const resendEmailNotification = () => {
    if( timer === 0 ) {
      props.loadResendEmailConfirmation();
      setTimer(RESEND_EMAIL_CONFIRMATION_THROTTLE_INTERVAL);
    }
  };

  useEffect(() => {
    if( timer === RESEND_EMAIL_CONFIRMATION_THROTTLE_INTERVAL ) {
      countdown.current = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    }

    if( timer === 0 ) {
      clearInterval(countdown.current);
    }
  }, [timer]);

  useEffect(() => {
    if( props.resendEmailConfirmationResent ) {
      enqueueSnackbar(getText('Email confirmation was resent'), {
        variant: 'success',
        persist: true
      });
    }
  }, [props.resendEmailConfirmationResent]);

  return (
    <GuestPage title="Two Factor Setup">
      <Box sx={{ maxWidth:'sm', mx: 'auto', mt: {lg: '20%'} }}>
        <Box mb={8} display="flex" justifyContent="center">
          <RouterLink to="/">
            <Logo/>
          </RouterLink>
        </Box>

        <Card variant="outlined">
          <CardContent>
            <Box sx={{ p: 2 }}>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h2" sx={{ fontWeight: 'regular', color: 'text.primary' }}>
                  Verify you email address
                </Typography>
              </Box>

              <Typography sx={{ mt: 4 }} color="textSecondary">Check your inbox for an email from us - there's a chance it ended up in the spam folder so make sure to check there as well. Afterwards, just follow the link from the email to verify your account.</Typography>

              <Typography sx={{ mt: 4 }} color="textSecondary">If you haven't received anything, you can click the button below to resend the confirmation.</Typography>

              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'end' }}>
                <Button disabled={timer !== 0} color="primary" size="large" type="submit" variant="contained" onClick={resendEmailNotification}>
                  Resend

                  <Box sx={{ ml: 2 }}>
                    {props.resendEmailConfirmationIsLoading && (
                      <CircularProgress size="26px" color="secondary" />
                    )}

                    {timer !== 0 && !props.resendEmailConfirmationIsLoading && (
                      <Typography>{timer}</Typography>
                    )}
                  </Box>
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </GuestPage>
  );
}

export default connect(stateToProps, actionsToProps)(EmailVerification);