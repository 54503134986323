import React, {useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {getValue} from "AppUtils/objects";
import {
  Box, Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid, Link,
  TextField,
  Typography,
} from '@mui/material';
import Page from '../../../components/Page';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {useSnackbar} from 'notistack';
import Autocomplete from '@mui/material/Autocomplete';
import ChannelCampaigns from "../subcomponents/ChannelCampaigns";
import LoadingScreen from '../../../components/LoadingScreen';

import {uiRedirect} from '../../../store/actions';
import {
  userLoadChannelsDetails,
  userLoadCreateChannels,
  userLoadResetChannelsCreate,
  userLoadResetChannelsDetails,
  userLoadResetChannelsUpdate,
  userLoadResetMsg,
  userLoadUnmappedChannels,
  userLoadUpdateCampaign,
  userLoadUpdateChannels,
} from '../../user/store/actions';
import {
  userChannelsDetails,
  userCreateChannels,
  userMsg, userPermissions,
  userUnmappedChannels,
  userUpdateChannels
} from '../../user/store/selectors';
import {clientsLoadOrganization} from '../../clients/store/actions';
import {clientsOrganization} from '../../clients/store/selectors';

const stateToProps = state => ({
  userCreateChannels: userCreateChannels(state),
  userUpdateChannels: userUpdateChannels(state),
  userUnmappedChannels: userUnmappedChannels(state),
  clientsOrganization: clientsOrganization(state),
  userChannelsDetails: userChannelsDetails(state),
  userMsg: userMsg(state),
  userPermissions: userPermissions(state),
});

const actionsToProps = dispatch => ({
  userLoadCreateChannels: (payload) => dispatch(userLoadCreateChannels(payload)),
  userLoadUnmappedChannels: (payload) => dispatch(userLoadUnmappedChannels(payload)),
  clientsLoadOrganization: (payload) => dispatch(clientsLoadOrganization(payload)),
  userLoadChannelsDetails: (payload) => dispatch(userLoadChannelsDetails(payload)),
  userLoadUpdateChannels: (payload) => dispatch(userLoadUpdateChannels(payload)),
  userLoadResetChannelsDetails: (payload) => dispatch(userLoadResetChannelsDetails(payload)),
  userLoadResetChannelsUpdate: (payload) => dispatch(userLoadResetChannelsUpdate(payload)),
  userLoadResetChannelsCreate: (payload) => dispatch(userLoadResetChannelsCreate(payload)),
  uiRedirect: (url) => dispatch(uiRedirect(url)),
  userLoadResetMsg: (payload) => dispatch(userLoadResetMsg(payload)),
  userLoadUpdateCampaign: (payload) => dispatch(userLoadUpdateCampaign(payload)),
});

const RevenueAdd = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const clientId = getValue(props, 'match.params.clientId');
  const channelId = getValue(props, 'match.params.idChannel');
  const [channelDetails, setChannelDetails] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [isSubmittingState, setIsSubmittingState] = useState(false);
  const [hasBucket, setHasBucket] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [errorsServer, setErrorsServer] = useState([]);

  const canEditChannels = props.userPermissions.includes('channel.update');

  const optionsPlatform = [
    {"text": "Android", "value": 1},
    {"text": "iOS", "value": 2},
  ];
  const optionsStore = [
    {"text": "Google Play", "value": 1},
    {"text": "Apple App Store", "value": 2},
    {"text": "Huawei App Gallery", "value": 3},
    {"text": "Samsung", "value": 4},
  ];
  const optionsAndroid = [
    {"text": "Google Play", "value": 1},
    {"text": "Huawei App Gallery", "value": 3},
    {"text": "Samsung", "value": 4},
  ];
  const optionsIos = [
    {"text": "Apple App Store", "value": 2},
  ];

  const unmappedChannelsValues = [];
  if (props.userUnmappedChannels.data) {
    props.userUnmappedChannels.data.map(channel => {
      unmappedChannelsValues.push({text: channel.name, value: channel.name});
    })
  }

  useEffect(() => {
    props.userLoadUnmappedChannels({tenantId: clientId});
    props.clientsLoadOrganization({id: clientId});

    if (channelId) {
      props.userLoadChannelsDetails({tenantId: clientId, channelId: channelId});
    }

    return function cleanup() {
      props.userLoadResetChannelsDetails();
      props.userLoadResetChannelsUpdate();
      props.userLoadResetChannelsCreate();
      props.userLoadResetMsg();
    }
  }, [])

  useEffect(() => {
    if (props.userChannelsDetails.data) {
      let newChannelDetails = {...props.userChannelsDetails.data};
      switch (newChannelDetails.type) {
        case 'mobile app':
          newChannelDetails.type = 1;
          break;
        case 'other':
          newChannelDetails.type = 2;
          break;
      }

      switch (newChannelDetails.platform) {
        case 'Android':
          newChannelDetails.platform = 1;
          break;
        case 'iOS':
          newChannelDetails.platform = 2;
          break;
      }

      switch (newChannelDetails.store) {
        case 'Google Play':
          newChannelDetails.store = 1;
          break;
        case 'Apple App Store':
          newChannelDetails.store = 2;
          break;
        case 'Huawei App Gallery':
          newChannelDetails.store = 3;
          break;
        case 'Samsung':
          newChannelDetails.store = 4;
          break;
      }
      setChannelDetails(newChannelDetails);
      setCampaigns(newChannelDetails.campaigns);
      setHasBucket(newChannelDetails.store === 1 ? true : false);
    }
  }, [props.userChannelsDetails.data])

  //Handle revenue add response
  useEffect(() => {
    if (props.userCreateChannels.data) {
      enqueueSnackbar('Application added', {
        variant: 'success',
        persist: true
      });
      props.uiRedirect({
        pathname: `/clients/${clientId}/settings`
      })
    } else if (props.userMsg && typeof props.userMsg !== 'undefined') {
      enqueueSnackbar(props.userMsg.msg, {
        variant: 'error',
        persist: true,
      });
      setErrorsServer(props.userMsg.errors);
      setUserMsg(props.userMsg.msg);
    }
    setIsSubmittingState(false);
  }, [props.userCreateChannels]);

  useEffect(() => {
    if (!props.userUnmappedChannels.loading) {
      if (props.userMsg) {
        enqueueSnackbar(props.userMsg, {
          variant: 'error',
          persist: true,
        });
        setUserMsg(props.userMsg);
      }
    }
  }, [props.userUnmappedChannels.data]);

  //Handle revenue update response
  useEffect(() => {
    if (props.userUpdateChannels.data) {
      enqueueSnackbar('Application updated', {
        variant: 'success',
        persist: true
      });
      props.uiRedirect({
        pathname: `/clients/${clientId}/settings`
      })
    } else if (props.userMsg) {
      enqueueSnackbar(props.userMsg.msg, {
        variant: 'error',
        persist: true
      });
      setErrorsServer(props.userMsg.errors);
      setUserMsg(props.userMsg.msg);
    }
    setIsSubmittingState(false);
  }, [props.userUpdateChannels])

  const filterKee2 = (channelName) => {
    const filteredChannel = props.userUnmappedChannels.data.filter(channel => channel.name === channelName)[0];
    if (filteredChannel) {
      return filteredChannel.kee2_identifier;
    } else {
      return false;
    }
  }

  if (!userMsg && (!props.clientsOrganization.data || !props.userUnmappedChannels.data || (channelId && !channelDetails))) {
    return <LoadingScreen/>;
  }

  const breadCrumbs =
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to={`/clients/${props.match.params.clientId}/settings`} component={RouterLink}>
        Management
      </Link>

      <Link bariant="body1" to={`/clients/${props.match.params.clientId}/settings`} component={RouterLink}>
        Settings
      </Link>

      <Link bariant="body1" to="#" component={RouterLink}>
        Edit Application
      </Link>
    </Breadcrumbs>;

  return (
    <Page title="Application" key={`page_channel_${channelDetails}`} breadCrumbs={breadCrumbs}>
      <Box>
        <Formik
          initialValues={{
            name: getValue(channelDetails, 'name') || '',
            platform: optionsPlatform.filter(option => option.value === channelDetails.platform)[0] || null,
            store: optionsStore.filter(option => option.value === channelDetails.store)[0] || null,
            channel: getValue(channelDetails, 'name') ? {text: getValue(channelDetails, 'name'), value: getValue(channelDetails, 'name')} : {text: '', value: ''},
            submit: null
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).min(5, 'Must be at least 5 characters').required('Name is required'),
            platform: Yup.string().max(255).required('Platform is required').nullable(),
            store: Yup.string().max(255).required('Store is required').nullable(),
            channel: Yup.string().max(255).required('Application is required').nullable(),
          })}
          onSubmit={(values, {
            resetForm,
            setErrors,
            setStatus,
            setSubmitting
          }) => {
            setIsSubmittingState(true);
            if (!channelId) {
              props.userLoadCreateChannels({
                tenantId: clientId,
                channel: {
                  name: values.name,
                  kee2_identifier: values.channel ? filterKee2(values.channel.text) : '',
                  platform: values.platform.value,
                  store: values.store.value,
                  campaigns: campaigns,
                },
              });
            } else {
              campaigns.forEach((campaign) => {
                props.userLoadUpdateCampaign({
                  tenantId: clientId,
                  channelId: channelId,
                  campaign: campaign
                })
              });

              props.userLoadUpdateChannels({
                tenantId: clientId,
                channelId: channelId,
                channel: {
                  name: values.name,
                  kee2_identifier: channelDetails.kee2_identifier,
                  platform: values.platform.value,
                  store: values.store.value,
                }
              });
            }
          }}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              setFieldValue,
              setTouched,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
            <form onSubmit={handleSubmit}>
              <Grid container={true} spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="h3" color="textPrimary">
                    Application Description
                  </Typography>

                  <Card sx={{ my: 6 }}>
                    <CardContent>
                      <Grid container={true} spacing={3}>
                        <Grid item md={12} xs={12}>
                          <TextField error={Boolean(touched.name && errors.name) || errorsServer.name}
                                     fullWidth
                                     helperText={touched.name && errors.name || errorsServer.name}
                                     disabled={!canEditChannels}
                                     label="Name"
                                     name="name"
                                     onBlur={handleBlur}
                                     onChange={(e) => {
                                       setFieldValue("name", e.target.value);
                                       setErrorsServer({
                                         ...errorsServer,
                                         name: false
                                       })
                                     }}
                                     required
                                     value={values.name}
                                     variant="outlined"
                          />
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <Grid container={true} spacing={3}>
                            <Grid item md={6} xs={12}>
                              <Autocomplete
                                getOptionLabel={(option) => option.text || ''}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                options={optionsPlatform}
                                value={values.platform}
                                onBlur={() => setTouched({'platform': true})}
                                onChange={
                                  (e, value) => {
                                    if (value !== values.platform) {
                                      setFieldValue("store", null);
                                    }
                                    setFieldValue("platform", value || null);
                                    setErrorsServer({
                                      ...errorsServer,
                                      platform: false
                                    })
                                  }
                                }
                                includeInputInList
                                disabled={channelId ? true : false}
                                renderInput={(params) => (
                                  <TextField {...params} fullWidth label="Platform" name="platform" variant="outlined" required
                                             error={Boolean(touched.platform && errors.platform) || errorsServer.platform}
                                             helperText={(touched.platform && errors.platform) || errorsServer.platform} />
                                )}
                              />
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <Autocomplete
                                getOptionLabel={(option) => option.text || ''}
                                isOptionEqualToValue={(option, value) => option.title === value.title}
                                options={values.platform ?
                                  (values.platform.value === 1 ? optionsAndroid :
                                    values.platform.value === 2 ? optionsIos : []) :[]}
                                value={values.store}
                                onBlur={() => setTouched({'store': true})}
                                onChange={(e, value) => {
                                  setFieldValue("store", value || null)
                                  setErrorsServer({
                                    ...errorsServer,
                                    store: false
                                  })
                                }
                                }
                                includeInputInList
                                key={values.store}
                                disabled={channelId ? true : false}
                                renderInput={(params) => (
                                  <TextField {...params} fullWidth label="Store" name="store" variant="outlined" required
                                             error={Boolean(touched.store && errors.store) || errorsServer.store}
                                             helperText={touched.store && errors.store || errorsServer.store} />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Grid container={true} spacing={3}>
                    <Grid item md={12} xs={12}>
                      <Typography variant="h3" color="textPrimary">
                        {!channelId ? 'Select unmapped application' :
                          <span style={{opacity: 0}}>-</span>
                        }
                      </Typography>

                      <Card sx={{ my: 6 }}>
                        <CardContent>
                          <Autocomplete
                            getOptionLabel={(option) => option.text || ''}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            options={unmappedChannelsValues}
                            onChange={(e, value) => {
                              setFieldValue("channel", value || null);
                              setErrorsServer({
                                ...errorsServer,
                                kee2_identifier: false
                              })
                            }}
                            onBlur={() => setTouched({'channel': true})}
                            value={values.channel}
                            disabled={channelId ? true : false}
                            renderInput={(params) => (
                              <TextField fullWidth required label="Application" name="channel" variant="outlined"
                                         error={Boolean(touched.channel && errors.channel) || errorsServer.kee2_identifier}
                                         helperText={(touched.channel && errors.channel) || errorsServer.kee2_identifier}
                                         {...params} />
                            )}
                          />
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      {(props.userUnmappedChannels.data.length > 0 && (values.channel && values.channel.value) || channelId) ?
                        <ChannelCampaigns campaigns={campaigns.length !== 0 ? campaigns :
                                                                 props.userUnmappedChannels.data
                                                                   .filter(channel => channel.name === values.channel.value)[0].campaigns}
                                          setCampaigns={setCampaigns}
                                          edit={channelId ? true : false} />
                        : null
                      }
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    {canEditChannels && (
                      <Box mt={2} mr={3}>
                        <Button variant="contained" color="primary" type="submit" disabled={isSubmittingState}>
                          Save
                        </Button>
                      </Box>
                    )}

                    <Box mt={2}>
                      <Button component={RouterLink} to={`/clients/${clientId}/settings`} variant="outlined"
                              sx={{ color: 'text.secondary', borderColor: 'text.secondary' }}>
                        {canEditChannels ? 'Cancel' : 'Back'}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Page>
  );
};

export default connect(stateToProps, actionsToProps)(RevenueAdd);
