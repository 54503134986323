import connect from "react-redux/es/connect/connect";
import {Box, IconButton, Paper, Tooltip} from "@mui/material";

import Table from "@/components/vis/Table";
import { toHuman, toHumanPercentage } from "@/utils/numbers";
import React from "react";
import SimpleTextVisSkeleton from "../../../../components/vis/SimpleTextVisSkeleton";
import {convertJSONtoCSV, downloadCSV} from "../../../../utils/csvDownload";
import {CloudDownload} from "@mui/icons-material";

const stateToProps = ({ insights: {sections: { apps } }}) => {
  return {
    isLoading: apps.isLoading,
    isLoadingForALongTime: apps.isLoadingForALongTime,
    data: apps.data,
  };
};

const toTitleCase = (s) => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase());

function VisApps({ sx = {}, isLoading, isLoadingForALongTime, data, filters }) {
  const headings = [
    { key: "app_name", label: "App" },
    { key: "percentValue", label: "%", align: "right" },
    { key: "session_duration", label: "value", align: "right" },
  ];

  const rows = data?.apps?.value ?
    data.apps.value
      .sort((a, b) => {
        if ( a.session_duration > b.session_duration ) {
          return -1;
        }

        if( a.session_duration < b.session_duration ) {
          return 1;
        }

        return 0;
      })
      .map(app => {
        return {
          app_name: toTitleCase(app.app_name),
          percentValue: toHumanPercentage(app.percentValue),
          session_duration: toHuman(app.session_duration) + ' seconds',
        };
      })
    : [];

  const download = () => {
    // Transform the data into the desired structure
    const transformedData = rows.reduce((acc, x) => {
      acc[x.app_name] = {
        percent: x.percentValue,
        sessionDuration: x.session_duration
      };
      return acc;
    }, {});

    // Combine the transformed data with metadata
    const downloadableData = {
      ...transformedData,
      ...filters,
      time: (new Date()).toDateString()
    };

    const csvContent = convertJSONtoCSV(downloadableData);

    if (csvContent) {
      downloadCSV(downloadableData, "Keyboard Usage");
    }
  };

  return (
    <Box sx={{ display: "flex", ...sx }}>
      {isLoading &&
      <SimpleTextVisSkeleton sx={{ width:  "100%", bgcolor: "background.paper" }}
                             animation="wave"
                             title={isLoadingForALongTime ? "Keyboard mostly used in - working" : "Keyboard mostly used in"} />
      }

      {!isLoading && (
        <Paper sx={{ width: "100%", height: "100%", p: 4, position: 'relative', '&:hover .download-icon': { visibility: 'visible'} }}>
          <Table
            headings={headings}
            rows={rows}
            title="Keyboard mostly used in"
            hideHeadings={true}
          />

          <Tooltip title="Download">
            <IconButton aria-label="download" className="download-icon" sx={{ position: 'absolute', top: '-16px', right: '-16px', visibility: 'hidden' }}
                        onClick={download}>
              <CloudDownload />
            </IconButton>
          </Tooltip>
        </Paper>
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisApps);
