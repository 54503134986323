import {createAction} from 'redux-actions';
import * as AUTH from './types';

const setLoginObtainXsrfToken = createAction(AUTH.SET_LOGIN_OBTAIN_XSRF_TOKEN, data => data);
const loadLoginCheck = createAction(AUTH.LOAD_LOGIN_CHECK, data => data);
const loadLogin = createAction(AUTH.LOAD_LOGIN, data => data);
const setLogin = createAction(AUTH.SET_LOGIN, data => data);
const resetLogin = createAction(AUTH.RESET_LOGIN, data => data);
const loadLogout = createAction(AUTH.LOAD_LOGOUT, data => data);
const setLogout = createAction(AUTH.SET_LOGOUT, data => data);

const loadCheckPasswordConfirmed = createAction(AUTH.LOAD_CHECK_PASSWORD_CONFIRMED, data => data);
const loadConfirmPassword = createAction(AUTH.LOAD_CONFIRM_PASSWORD, data => data);

const loadInitializeTwoFactor = createAction(AUTH.LOAD_INITIALIZE_TWO_FACTOR, data => data);
const loadTwoFactorQrCode = createAction(AUTH.LOAD_TWO_FACTOR_QR_CODE, data => data);
const loadConfirmTwoFactor = createAction(AUTH.LOAD_CONFIRM_TWO_FACTOR, data => data);
const loadValidateTwoFactorChallenge = createAction(AUTH.LOAD_VALIDATE_TWO_FACTOR_CHALLENGE, data => data);

const loadRegister = createAction(AUTH.LOAD_REGISTER, data => data);

const loadResendEmailConfirmation = createAction(AUTH.LOAD_RESEND_EMAIL_CONFIRMATION, data => data);
const loadVerifyEmail = createAction(AUTH.LOAD_VERIFY_EMAIL, data => data);

export {
  setLoginObtainXsrfToken,
  loadLoginCheck,
  loadLogin,
  setLogin,
  resetLogin,
  loadLogout,
  setLogout,
  loadCheckPasswordConfirmed,
  loadConfirmPassword,
  loadInitializeTwoFactor,
  loadTwoFactorQrCode,
  loadConfirmTwoFactor,
  loadValidateTwoFactorChallenge,
  loadRegister,
  loadResendEmailConfirmation,
  loadVerifyEmail,
};
