import React, {useState} from 'react';
import connect from 'react-redux/es/connect/connect';

import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import Logo from '../../../components/vectors/Logo';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import {useSnackbar} from 'notistack';

import {apiPost} from 'AppUtils/api';
import Observable from 'Observable';
import {getValue} from 'AppUtils/objects';
import {getQueryVariable} from "../../../utils/url";
import {uiRedirect} from '../../../store/actions';
import GuestPage from "../../../components/GuestPage";

const stateToProps = state => ({});

const actionsToProps = dispatch => ({
  uiRedirect: (payload) => dispatch(uiRedirect(payload)),
});

const Recover = (props) => {
  const isMountedRef = useIsMountedRef();
  const [confirmation, setConfirmation] = useState(false);
  const [getError, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const token = getQueryVariable(window.location.search, 'token');
  const choosePassword = getValue(props, 'location.state.choosePassword');

  if (props.authIsAuthenticated) {
    props.uiRedirect({
      pathname: '/'
    });
  }

  const onSend = ({password}) => {
    setIsLoading(true);
    let statusCode = '';

    apiPost(`/user-reset-password?token=${token}`, {password})
      .catch((e) => {
        return Observable.empty();
      }).mergeMap(res => {
        const resp = res.json();
        statusCode = res.status;
        setIsLoading(false);
        return resp;
      }).toPromise().then(response => {
        if (response && statusCode === 200) {
          setConfirmation(true);
          enqueueSnackbar('Password was updated!', {
            variant: 'success',
            persist: true
          });
          props.uiRedirect({
            pathname: '/login'
          })
        } else {
          let errorMessages = '';
          response.errors.password.forEach((message) => {
            errorMessages += (message + '\n');
          });

          enqueueSnackbar(errorMessages, {
            variant: 'error',
            persist: true,
            style: { whiteSpace: 'pre-line' }
          });
          setError(response.message);
        }
    });
  };

  return (
    <GuestPage title="Recover">
      <Box sx={{ width: 1, height: 1, minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ maxWidth:'sm' }}>
          <Box mb={8} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo/>
            </RouterLink>
          </Box>

          <Card variant="outlined">
            <CardContent>
              <Box alignItems="center" display="flex" justifyContent="space-between" mb={3}>
                <div>
                  <Typography variant="h2" sx={{ fontWeight: 'regular', color: 'text.primary' }}>
                    {choosePassword ? 'Choose password' : 'Recover'}
                  </Typography>

                  <Typography variant="body2" color="textSecondary">
                    {choosePassword ? 'Enter a password for your account.' : 'Enter a new password for your account.'}
                  </Typography>
                </div>
              </Box>

              <Box flexGrow={1} mt={3}>
                {!confirmation && <Formik
                  initialValues={{
                    password: '',
                    confirmPassword: '',
                    submit: null
                  }}
                  validationSchema={Yup.object().shape({
                    password: Yup.string().max(255)
                        .required('This field is required')
                        .min(8, 'The password must be at least 8 characters long')
                        .matches(/(\p{Ll}+.*\p{Lu})|(\p{Lu}+.*\p{Ll})/u, 'The password must contain at least one uppercase and one lowercase letter')
                        .matches(/\p{Z}|\p{S}|\p{P}/u, 'The password must contain at least one symbol')
                        .matches(/\p{N}/u, 'The password must contain at least one number'),
                    confirmPassword: Yup.string().max(255).oneOf([Yup.ref('password'), null], 'Passwords must match').required('This field is required')
                  })}
                  onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting
                  }) => {
                    try {
                      setIsLoading(true);
                      onSend(values);
                      if (isMountedRef.current) {
                        setStatus({success: true});
                        setSubmitting(false);
                      }
                    } catch (err) {
                      console.error(err);
                      if (isMountedRef.current) {
                        setStatus({success: false});
                        setErrors({submit: err.message});
                        setSubmitting(false);
                      }
                    }
                  }}
                >
                  {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values
                    }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <TextField error={Boolean(touched.password && errors.password)}
                                 fullWidth
                                 autoFocus
                                 helperText={touched.password && errors.password}
                                 label="Password"
                                 margin="normal"
                                 name="password"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 type="password"
                                 value={values.email}
                                 variant="outlined" />
                      <TextField error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                 fullWidth
                                 helperText={touched.confirmPassword && errors.confirmPassword}
                                 label="Confirm password"
                                 margin="normal"
                                 name="confirmPassword"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 type="password"
                                 value={values.confirmPassword}
                                 variant="outlined" />
                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}
                      <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                        <Button color="primary" disabled={isSubmitting} size="large" type="submit" variant="contained">
                          Submit

                          {isSubmitting && (
                            <CircularProgress sx={{ ml: 2 }} size="26px" color="secondary" />
                          )}
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </GuestPage>
  );
};

export default connect(stateToProps, actionsToProps)(Recover);
