import connect from "react-redux/es/connect/connect";
import {Box, Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import React, {useState} from "react";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import Timeline from '@mui/icons-material/Timeline';
import {StackedBarChart, StackedLineChart} from "@mui/icons-material";
import {BarChart} from "react-feather";

const RetentionDashboardScalarAxisControl = ({ axis, setAxisItems, ...props }) => {
  const [selectedItems, setSelectedItems] = useState(axis.items);

  const handleItemsSelectionChanged = item => {
    const newItems = selectedItems.includes(item) ?
      selectedItems.filter(i => i !== item) :
      [item, ...selectedItems];

    setSelectedItems(newItems);
    setAxisItems({
      items: newItems,
    });
  };

  return (
    <FormGroup>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <FormControlLabel sx={{ color: 'text.primary' }} label="App Installs" control={
          <Checkbox onChange={() => handleItemsSelectionChanged('app_installs')} checked={selectedItems.includes('app_installs')} />
        } />

        <FormControlLabel sx={{ color: 'text.primary' }} label="Keyboard Activations" control={
          <Checkbox onChange={() => handleItemsSelectionChanged('keyboard_activations')} checked={selectedItems.includes('keyboard_activations')} />
        } />
      </Box>
    </FormGroup>
  );
}

export default RetentionDashboardScalarAxisControl;