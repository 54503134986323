import connect from "react-redux/es/connect/connect";
import { Box } from "@mui/material";

import Pie from "../../../../components/vis/Pie";
import MultiDimensionalRoundedChartSkeleton from "../../../../components/vis/MultiDimensionalRoundedChartSkeleton";

const stateToProps = ({ insights: {sections: { ageGroups } }}) => {
  return {
    isLoading: ageGroups.isLoading,
    isLoadingForALongTime: ageGroups.isLoadingForALongTime,
    data: ageGroups.data,
  };
};

function mapData(data, key) {
  return data.map(d => ({ label: d[key], value: Number(d.percentage) }));
}

function VisAgeGroups({
   sx = {},
   isLoading,
   isLoadingForALongTime,
   data,
   filters,
 }) {
  const hasData = data?.age_groups && data.age_groups.value.length > 0;
  const ageGroupsData = data && data.age_groups && mapData(data.age_groups.value, 'age_group');

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && (
        <MultiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                              animation="wave"
                                              title={isLoadingForALongTime ? "Age groups - working" : "Age groups"} />
      )}

      {!isLoading && !hasData && (
        <MultiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                              animation={false}
                                              title="Age groups - no data" />
      )}

      {!isLoading && hasData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Pie title="Age groups" data={ageGroupsData} height={250} width={250} filters={filters} />
        </Box>
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisAgeGroups);
