// modules
import React from "react";
import {Box} from "@mui/material";

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <>
      {value === index ?
      <Box sx={{ height: 1 }}>
        {children}
      </Box>
      :
      <></>}
    </>
  );
};

export default TabPanel;
