import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, ListItem, ListItemText, List, ListItemIcon, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {CircleOutlined} from "@mui/icons-material";
import moment from "moment";

const TimelineModal = ({
                       asset,
                       open,
                       onClose,
                       ...rest
                     }) => {
  return (
    <Box>
      <Dialog open={open}
              aria-labelledby="asset_timeline_modal_title"
              aria-describedby="asset_timeline_modal_description"
              onClose={onClose}>
        <DialogTitle id="asset_timeline_modal_title">
          Asset {asset.uid}
        </DialogTitle>

        <DialogContent>
          <List>
            {asset.timeline && asset.timeline.map((event, index) => (
              <ListItem alignItems="flex-start" key={`timeline_event_${index}`}>
                <ListItemIcon>
                  <CircleOutlined />
                </ListItemIcon>

                <ListItemText primary={
                                        <Typography sx={{ fontWeight: 500, textTransform: 'uppercase', fontSize: '0.8rem', letterSpacing: '0.05rem' }}>
                                          {event.event}
                                          {event.event === 'published' && event.snapshot.enabled && ' (enabled)'}
                                          {event.event === 'published' && !event.snapshot.enabled && ' (disabled)'}
                                        </Typography>
                                      }
                              secondary={
                                        <Typography sx={{ fontSize: '0.8rem', color: 'text.secondary'}} >
                                          On {moment(event.when).format('MMMM Do YYYY, hh:mm:ss Z')}
                                          {event.event === 'published' && (<br/>)}
                                          {event.event === 'published' && `Campaign: ${event.snapshot.campaign_kee2_identifier}`}
                                          {event.event === 'published' && (<br/>)}
                                          {event.event === 'published' && `By ${event.snapshot.user_email}`}
                                        </Typography>
                                      }>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

TimelineModal.propTypes = {
  open: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired,
};

export default TimelineModal;