import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Container} from "@mui/material";

const GuestPage = forwardRef(({
                           children,
                           title = '',
                           ...rest
                         }, ref) => {

  return (
    <Container maxWidth={false} sx={{ py: 8 }} ref={ref} {...rest}>
      {children}
    </Container>
  );
});

GuestPage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default GuestPage;
