import {
  XYChart,
  Grid,
  DataContext,
  Axis,
  Tooltip,
  buildChartTheme,
  LineSeries
} from '@visx/xychart';
import { PatternLines } from '@visx/pattern';
import React, {useContext, useRef, useState} from "react";
import {Box, IconButton, Paper, Typography, useTheme} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import ResizeListener from "../../utils/ResizeListener";

const CustomChartBackground = () => {
  const { margin, width, height, innerWidth, innerHeight } = useContext(DataContext);
  const muiTheme = useTheme();
  const patternId = 'xy-chart-pattern';
  const theme = buildChartTheme({
    backgroundColor: '#fff',
    colors: Object.values(muiTheme.palette.vis.pie),
    gridColor: '#336d88',
    gridColorDark: '#1d1b38',
    svgLabelBig: { fill: '#1d1b38' },
    tickLength: 8,
  });

  // early return values not available in context
  if (width == null || height == null || margin == null || theme == null) return null;

  return (
    <>
      <PatternLines
        id={patternId}
        width={16}
        height={16}
        orientation={['diagonal']}
        stroke={theme?.gridStyles?.stroke}
        strokeWidth={1}
      />
      <rect x={0} y={0} width={width} height={height} fill={theme?.backgroundColor ?? '#fff'} />
      <rect
        x={margin.left}
        y={margin.top}
        width={innerWidth}
        height={innerHeight}
        fill={`url(#${patternId})`}
        fillOpacity={0.3}
      />
    </>
  );
}

export default function XY({ height, data, title, handleBackButtonClicked }) {
  const muiTheme = useTheme();
  const theme = buildChartTheme({
    backgroundColor: '#fff',
    colors: Object.values(muiTheme.palette.vis.pie),
    gridColor: '#336d88',
    gridColorDark: '#1d1b38',
    svgLabelBig: { fill: '#1d1b38' },
    tickLength: 8,
  });
  const visContainerRef = useRef(null);
  const [visWidth, setVisWidth] = useState(450);

  const handleResize = () => {
    setVisWidth(visContainerRef.current.clientWidth - 28);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        "&:hover .download-icon": { visibility: "visible" },
      }}
      ref={visContainerRef}
    >
      {title && (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <IconButton aria-label="back" onClick={handleBackButtonClicked} sx={{ mt: '-7px' }}>
            <ArrowBack />
          </IconButton>

          <Typography sx={{ fontWeight: "bold", alignSelf: "flex-start" }}>
            {title}
          </Typography>
        </Box>
      )}

      <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center' }}>
        <XYChart
          xScale={{ type: 'band' }}
          yScale={{ type: 'linear' }}
          width={visWidth}
          height={height}
          numTicks={4}
          theme={theme}
        >
          <CustomChartBackground />

          <Grid
            rows={false}
            columns={false}
            animationTrajectory="center"
            numTicks={4}
          />
          <>
            {data.map((wordData, wordDataIndex) => (
              <LineSeries
                key={`area_series_${wordDataIndex}`}
                dataKey={wordDataIndex}
                data={wordData}
                xAccessor={d => d.event_date}
                yAccessor={d => d.popularity}
              />
            ))}
          </>
          <Axis
            orientation="bottom"
            numTicks={4}
            animationTrajectory="center"
          />
          <Axis
            label="Popularity"
            orientation="right"
            numTicks="4"
            animationTrajectory="center"
          />

          <Tooltip
            snapTooltipToDatumX={true}
            snapTooltipToDatumY={true}
            renderTooltip={({ tooltipData, colorScale }) => (
              <>
                {/** date */}
                {(tooltipData?.nearestDatum?.datum && tooltipData?.nearestDatum?.datum.event_date) || 'No date'}
                <br />
                <br />

                {/** popularity */}
                {(Object.values(tooltipData?.datumByKey ?? {})
                  .map((datumWrapper) => {
                    return (
                      <div key={datumWrapper.datum.words}>
                        <em
                          style={{
                            color: colorScale?.(datumWrapper.key),
                            textDecoration:
                              tooltipData?.nearestDatum?.datum.words === datumWrapper.datum.words ? 'underline' : undefined,
                          }}
                        >
                          {datumWrapper.datum.words}
                        </em>
                        {' '}
                        {datumWrapper.datum.popularity == null || Number.isNaN(datumWrapper.datum.popularity) ? '–' : `${datumWrapper.datum.popularity}`}
                      </div>
                    );
                  })
                )}
              </>
            )}
          />
        </XYChart>
      </Box>

      <ResizeListener onResize={handleResize} />
    </Paper>
  );
}