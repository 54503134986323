// START modules
import React, {useState, useEffect} from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FolderIcon from '@mui/icons-material/Folder';

import _ from 'lodash';
import {useSnackbar} from 'notistack';
// END modules

// START components
import ChipWithInput from '../../../components/ChipWithInput';
// END components

// START material
import {
  Typography,
  Box,
  TextField,
  Switch,
  Button,
  Input,
  Chip,
  IconButton,
  CircularProgress,
  Tooltip
} from '@mui/material';
import {
  AddAPhotoOutlined,
  Favorite,
  Delete,
  CheckCircle,
  Link,
  FolderZipOutlined,
  AttachFileOutlined,
  KeyboardAltOutlined,
  Warning, PublishedWithChanges, UnpublishedOutlined, Announcement, AccessTime, NotInterested
} from "@mui/icons-material";
import LinkedUploadBox from "./LinkedUploadBox";
import {contentItem, contentKeywords, previewContentItem} from "../store/selectors";
import connect from "react-redux/es/connect/connect";
import zipCrawler from "../../../utils/zipCrawler";
import AlertDialog from "../../../components/AlertDialogue";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {reorderByRank} from "../../../utils/reorder";
import TimelineModal from "./TimelineModal";
// END material

const KEYWORDS_LOAD_ROWS_PER_PAGE = 10;
const DEFAULT_KEYWORDS_OPTIONS = {
  page: 1,
  hasMore: true
}

const stateToProps = (state) => ({
  contentItem: contentItem(state),
  previewContentItem: previewContentItem(state)
});

const Manage = ({parentClasses, stickers, setStickers, ...rest}) => {
  const {enqueueSnackbar} = useSnackbar();
  const assets = rest.contentAssets.data;
  const [keywordsOptions, setKeywordsOptions] = useState(DEFAULT_KEYWORDS_OPTIONS);
  const [availableKeywords, setAvailableKeywords] = useState([]);
  const [files, setFiles] = useState({});
  const [assetDeleteDialogOpen, setAssetDeleteDialogOpen] = useState(false);
  const [contentAssetUidToDelete, setContentAssetUidToDelete] = useState('');
  const [giphyChannelUidOfContentAssetToDelete, setGiphyChannelUidOfContentAssetToDelete] = useState('');
  const [timelineModalOpen, setTimelineModalOpen] = useState(false);
  const [assetForTimelineModal, setAssetForTimelineModal] = useState({});


  useEffect(() => {
    if(rest.contentCreateKeywords.msg) {
      enqueueSnackbar(rest.contentCreateKeywords.msg, {
        variant: 'error',
        persist: true,
      });
    }
  }, [rest.contentCreateKeywords.msg])

  useEffect(() => {
    const {meta} = rest.contentKeywords;
    const current_page = meta?.current_page;
    const last_page = meta?.last_page;

    let hasMore = !(current_page === last_page);
    let newAvailableKeywords = [];

    if(rest.contentKeywords.data?.length !== 0) {
      const formatAvailableKeywords = rest.contentKeywords.data.map((keyword) => {
        return {
          id: keyword.slug,
          label: keyword.name
        }
      });

      newAvailableKeywords = formatAvailableKeywords;

      if(keywordsOptions.page > 2) {
        newAvailableKeywords = [...availableKeywords, ...formatAvailableKeywords];
      }
    }

    setAvailableKeywords(newAvailableKeywords);
    setKeywordsOptions({...keywordsOptions, hasMore});
  }, [rest.contentKeywords.data]);

  const loadKeywords = _.debounce((query, isFirstFetch = false) => {
    let page = !isFirstFetch ? keywordsOptions.page : 1;

    if(query.length >= 1) {
      rest.handleContentLoadKeywords({
        page: page,
        rows_per_page: KEYWORDS_LOAD_ROWS_PER_PAGE,
        filter: query,
      });

      setKeywordsOptions({...keywordsOptions, page: page + 1});
    } else {
      if(availableKeywords.length > 0) {
        setAvailableKeywords([]);
      }
    }
  }, 350);

  const handleOnChangeSwitch = (id, checked) => {
    let newAssets = [...assets];

    for (let i = 0; i < assets.length; i++) {
      if (assets[i].uid === id) {
        newAssets[i].enabled = checked;
        newAssets[i].isDirty = true;
      }
    }

    rest.handleSetModifyContentAssets(newAssets);
  }

  const handleOnClickFavorite = (id) => {
    let newAssets = [...assets];

    for (let i = 0; i < assets.length; i++) {
      if (assets[i].uid === id) {
        newAssets[i].featured = !assets[i].featured;
        newAssets[i].isDirty = true;
      }
    }

    rest.handleSetModifyContentAssets(newAssets);
  }

  const hideReplaceAssetFormOf = (assetUid) => {
    rest.handleSetToggleContentAssetReplaceForm(assetUid);
  }

  const handleOnClickTrash = (contentAssetUid, giphyChannelUid) => {
    setAssetDeleteDialogOpen(true);
    setGiphyChannelUidOfContentAssetToDelete(giphyChannelUid);
    setContentAssetUidToDelete(contentAssetUid);
  }

  const handleAssetDeletionAgree = () => {
    rest.handleLoadDeleteContentFolderAsset(contentAssetUidToDelete, giphyChannelUidOfContentAssetToDelete);
    setAssetDeleteDialogOpen(false);
  }

  const handleAssetDeletionDisagree = () => {
    setAssetDeleteDialogOpen(false);
  }

  const handleSetSelectedItems = (id, selectedItems) => {
    let newAssets = [...assets];

    for (let i = 0; i < assets.length; i++) {
      if (assets[i].uid === id) {
        newAssets[i].keywords = serializeKeywords(selectedItems);
        newAssets[i].isDirty = true;
      }
    }

    rest.handleSetModifyContentAssets(newAssets);
  }

  const deserializeKeywords = (keywords) => {
    const arr = [];
    keywords.forEach(({slug, name}) => {arr.push({id: slug, label: name})});
    return arr;
  }

  const serializeKeywords = (keywords) => {
    const arr = [];
    keywords.forEach(({id, label}) => {arr.push({slug: id, name: label})});
    return arr;
  }

  const handleOnClickSeeMore = id => {
    let newAssets = [...assets];

    for (let i = 0; i < assets.length; i++) {
      if(assets[i].uid === id) {
        newAssets[i].showAllKeywords = !newAssets[i].showAllKeywords;
      }
    }

    rest.handleSetModifyContentAssets(newAssets);
  }

  const debouncedCreateKeyword = _.debounce((keyword, callback) => {
    rest.handleContentLoadCreateKeywords({keyword, callback});
  }, 350);

  const handleOnChangeFile = (id, event) => {
    let mainFile = event.target.files[0];
    Object.assign(mainFile, {
      preview: URL.createObjectURL(mainFile),
      validationPasses: true,
      message: '',
    });

    if( rest.contentType.identifier === 'theme' ) {
      zipCrawler(mainFile).then((crawlResult) => {
        let previewItem = crawlResult.cmsPreview.file;
        let mainItem = crawlResult.themeZip.file;
        let additionalItems = crawlResult.restOfTheFiles.map(item => {
          Object.assign(item.file, {
            preview: null,
            validationPasses: true,
            message: ''
          });

          return item.file;
        });

        Object.assign(previewItem, {
          preview: crawlResult.cmsPreview.url,
          validationPasses: true,
          message: '',
        });
        Object.assign(mainItem, {
          preview: null,
          validationPasses: true,
          message: '',
        });

        setFiles({...files, [id]: { mainFile: mainItem, previewFile: previewItem, additionalFiles: additionalItems, themeVersion: crawlResult.themeVersion }});
      }).catch(e => {
        console.log(e);
        let previewFile = {};
        let mainFile = {};

        if( e.name === 'PreviewException' ) {
          previewFile = {
            validationPasses: false,
            message: 'Could not pick the preview from the archive.'
          };
        } else {
          previewFile = {
            validationPasses: false,
            message: 'Could not read the archive.'
          };

          mainFile = {
            validationPasses: false,
            message: 'Could not read the archive.'
          }
        }

        setFiles({...files, [id]: { mainFile, previewFile }});
      });
    } else {
      setFiles({
        ...files,
        [id]: {
          mainFile
        },
      });
    }
  }

  const updateFile = (contentAssetUid, contentItemUid, file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);
    formData.append('url', file.preview);
    formData.append('storageProviderIdentifier', rest.contentType.identifier === 'theme' ? 's3' : 'cloudinary');

    rest.handleLoadUpdateContentItem({
      contentItemUid: contentItemUid,
      form: formData,
    });
  };

  const handleOnClickUpdate = (contentAssetIndex, contentAsset) => {
    let mainResource = contentAsset.items.filter(item => { return item.placeholder === 'mainResource'; })[0];
    updateFile(contentAsset.uid, mainResource.uid, files[contentAsset.uid].mainFile);


    if( rest.contentType.identifier === 'theme' ) {
      let previewResource = contentAsset.items.filter(item => { return item.placeholder === 'previewResource'; })[0];
      updateFile(contentAsset.uid, previewResource.uid, files[contentAsset.uid].previewFile);

      rest.updateContentAssetVersion(
        rest.contentFolder.data.uid,
        contentAsset.uid,
        files[contentAsset.uid].themeVersion
      );

      rest.replaceAdditionalItems(
        contentAssetIndex,
        contentAsset,
        files[contentAsset.uid].additionalFiles,
        mainResource,
        previewResource);
    }

    hideReplaceAssetFormOf(contentAsset.uid);

    setFiles({});
  };

  const handleLinkedOnClickUpdate = (contentAssetUid, contentItemUid) => {
    const data = {
      url: rest.contentItem.data.url,
      name: 'gif',
      storageProviderIdentifier: 'linked'
    };

    rest.handleLoadUpdateLinkedContentItem({
      contentFolderUid: rest.contentFolder.data.uid,
      contentAssetUid: contentAssetUid,
      contentItemUid: contentItemUid,
      form: data
    });
  }

  const handleOnClickCancel = uid => {
    let newFiles = files;
    delete newFiles[uid];
    setFiles({...newFiles});
    hideReplaceAssetFormOf(uid);
  }

  const handleLinkedOnClickCancel = uid => {
    hideReplaceAssetFormOf(uid);

    rest.resetContentItem();
  }

  const handleOnDelete = (uid, keywords, item) => {
    const newSelectedKeywords = deserializeKeywords(keywords).filter(v => !(_.isEqual(v.id, item.id) || _.isEqual(v.label, item.label)));
    handleSetSelectedItems(uid, newSelectedKeywords);
  }

  const handleIsTrim = (id, isTrim) => {
    if (isTrim === false) {
      let newAssets = [...assets];

      for (let i = 0; i < assets.length; i++) {
        if (assets[i].uid === id) {
          newAssets[i].showAllKeywords = false;
        }
      }

      rest.handleSetModifyContentAssets(newAssets);
    }
  }

  const previewImageStyles = () => {
    let width = 0;
    let height = 0;

    switch( rest.contentType.identifier ) {
      case 'sticker':
        width = 56;
        height = 56;
        break;
      case 'wallpaper':
        width = 56;
        height = 88;
        break;
      case 'gif':
        width = 56;
        height = 56;
        break;
      case 'theme':
        width = 168;
        height = 128;
        break;
    }

    return {
      'width': width,
      'height': height,
      'objectFit': 'cover'
    };
  }

  const getPreview = (asset) => {
    const mainResource = asset.items.filter(item => item.placeholder === 'mainResource');
    const previewResource = asset.items.filter(item => item.placeholder === 'previewResource');

    if( previewResource.length === 1 ) {
      return previewResource[0].preview_url;
    }

    if( mainResource.length === 1 ) {
      return mainResource[0].preview_url;
    }

    return '';
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedContentAssets = reorderByRank(
      rest.contentAssets.data,
      result.source.index,
      result.destination.index
    );

    rest.handleSetModifyContentAssets(reorderedContentAssets);
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getAssetRepresentation = (index, value) => {
    return (
      <div>
        <Box key={`item_${index}`} sx={{ width: 1, height: 'auto', mb: 4, bgcolor: 'background.dark', borderRadius: '4px' }}>
          <Box sx={{ maxWidth: '100%', display: 'flex', overflow: 'hidden' }}>
            <Box sx={{ display: 'flex', flex: '1 1 0%', maxWidth: 1, overflow: 'hidden', p: 2,
              alignItems: rest.contentType.identifier === 'wallpaper' ? 'start' : 'center' }}>
              <Box sx={{...previewImageStyles(), display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {value.isLoading ?
                  <CircularProgress id={`spinner_item_${index}`} size="56px" color="primary"/> :
                  <Tooltip placement="right" title={
                    <React.Fragment>
                      {value.clicks.length > 0 ?
                        (<Box>
                          {value.clicks.map((click, clickIndex) => {
                            return (
                              <Box key={`clicks_data_${clickIndex}`} sx={{display: 'flex', justifyContent: 'end'}}>
                                <Box sx={{textAlign: 'end'}}>
                                  <Typography variant="caption" sx={{mr: 2}}>{click.campaign}:</Typography>
                                </Box>

                                <Box sx={{display: 'flex', width: '80px'}}>
                                  <Typography variant="caption"
                                              sx={{fontWeight: 700, mr: 1}}>{click.clicks_count}</Typography>
                                  <Typography variant="caption">clicks</Typography>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>) :
                        (<Box>
                          <Typography variant="caption">No clicks data available</Typography>
                        </Box>)
                      }
                    </React.Fragment>
                  }>
                    <Box sx={{ position: 'relative' }}>
                      {!value.enabled &&
                        <Box sx={{
                          position: 'absolute',
                          top: 0, bottom: 0, right: 0, left: 0,
                          bgcolor: 'background.paper',
                          opacity: 0.7,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                          <NotInterested sx={{ width: 32, height: 32 }}></NotInterested>
                        </Box>
                      }

                      {value.version &&
                        <Box sx={{
                          position: 'absolute',
                          bottom: 0,
                          right: '-4px',
                          px: 1,
                          bgcolor: 'background.paper',
                          opacity: 0.9,
                          borderRadius: 1,
                        }}>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>v{value.version}</Typography>
                        </Box>
                      }

                      <img id={`img_preview_item_${index}`} style={previewImageStyles()} src={getPreview(value)} alt="" title="" />
                    </Box>
                  </Tooltip>
                }
              </Box>

              <Box sx={{ display: 'flex', flex: '1 1 0%', maxWidth: `calc(100% - ${previewImageStyles().width}px)`,
                alignItems: rest.contentType.identifier === 'sticker' ? 'center' : 'start' }}>
                <Box sx={{ flex: '1 1 0%', display: 'flex', alignItems: 'center', maxWidth: 1 }}>
                  <Box sx={{ flex: '1 1 0%', overflow: 'hidden', pl: 4, maxWidth: 1 }}>
                    {(rest.contentType.identifier !== 'theme') ? (
                      <ChipWithInput name="keywords"
                                     id={`card_keywords_item_${index}`}
                                     loading={contentKeywords.isLoading}
                                     options={availableKeywords}
                                     selectedItems={deserializeKeywords(value.keywords)}
                                     setSelectedItems={selectedItems => handleSetSelectedItems(value.uid, selectedItems)}
                                     loadItems={loadKeywords}
                                     label="Enter a keyword"
                                     isSelect={false}
                                     hasMore={keywordsOptions.hasMore}
                                     handleOnClickSeeMore={id => handleOnClickSeeMore(id)}
                                     showAllKeywords={value.showAllKeywords}
                                     idForChipList={value.uid}
                                     createKeyword={(keyword, callback) => debouncedCreateKeyword(keyword, callback)}
                                     handleIsTrim={(id, value) => handleIsTrim(id, value)}
                                     fixedChipText={ value.giphyChannel != null ? value.giphyChannel.name : null } />
                    ) : (
                      <Box sx={{ display: 'flex', justifyItems: 'center', ml: 2 }}>
                        <FolderZipOutlined id={`icon_theme_${index}`} sx={{ color: 'action.active' }} />

                        <Typography id={`label_theme_name_${index}`} sx={{ ml: 2, color: 'text.primary' }}>{ value.name }</Typography>
                      </Box>
                    )}
                  </Box>

                  <Box sx={{ mx: 2 }} id="box_has_changes">
                    {value.inSyncWithMocha ?
                      <Tooltip id="tooltip_does_not_have_changes" title="Asset does not have unpublished changes">
                        <PublishedWithChanges id="icon_does_not_have_changes" sx={{ color: 'success.main' }} />
                      </Tooltip> :
                      <Tooltip id="tooltip_has_changes" title="Asset has unpublished changes">
                        <UnpublishedOutlined id="icon_has_changes" sx={{ color: 'danger.main' }} />
                      </Tooltip>
                    }
                  </Box>

                  <Box sx={{ borderRight: 1,
                    borderLeft: 1,
                    borderColor: 'secondary.dark',
                    mx: 4,
                    display: 'flex',
                    justifyContent: 'space-around' }}>
                    <Box sx={{ mx: 3 }}>
                      <Tooltip title={value.featured ? 'Asset is featured' : 'Asset is not featured'}>
                        <IconButton id={`button_favor_item_${index}`} aria-label="featured" size="small"
                                    onClick={() => handleOnClickFavorite(value.uid)} disabled={value.isLoading}>
                          {value.featured === true ?
                            <Favorite color="primary" /> :
                            <FavoriteBorderIcon />
                          }
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <Box sx={{ mx: 3 }}>
                      <Tooltip title="Update asset">
                      <span>
                        <IconButton id={`button_update_item_${index}`} aria-label="update" size="small"
                                    onClick={() => hideReplaceAssetFormOf(value.uid)}
                                    disabled={ value.giphyChannel != null || value.isLoading }>
                        {(rest.contentType.identifier === 'sticker' || rest.contentType.identifier === 'wallpaper') &&
                        <AddAPhotoOutlined />
                        }
                          {rest.contentType.identifier === 'gif' &&
                          <Link />
                          }
                          {rest.contentType.identifier === 'theme' &&
                          <AttachFileOutlined />
                          }
                      </IconButton>
                      </span>
                      </Tooltip>
                    </Box>

                    <Box sx={{ mx: 3 }}>
                      <Tooltip title="Timeline">
                        <span>
                          <IconButton id={`button_show_timeline_item_${index}`} aria-label="update" size="small" onClick={() => {
                                        setAssetForTimelineModal(value)
                                        setTimelineModalOpen(true);
                                      }}
                                      disabled={ value.isLoading }>
                            <AccessTime />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>

                    <Box sx={{ mx: 3 }}>
                      <Tooltip title="Remove asset">
                      <span>
                        <IconButton id={`button_delete_item_${index}`} aria-label="delete" size="small"
                                    onClick={() => handleOnClickTrash(value.uid, value.giphyChannel ? value.giphyChannel.uid : '')}
                                    sx={{ '&:hover': { '& .MuiSvgIcon-root': { color: 'danger.main' } } }}
                                    disabled={ value.isLoading }>
                          <Delete />
                        </IconButton>
                      </span>
                      </Tooltip>
                    </Box>
                  </Box>

                  <Box>
                    <Tooltip title={value.enabled ? 'Asset is enabled' : 'Asset is disabled'}>
                      <Switch id={`switch_enabled_item_${index}`}
                              checked={value.enabled} edge="start" name="switch" color="success"
                              disabled={value.isLoading}
                              onChange={element => handleOnChangeSwitch(value.uid, element.target.checked)} />
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {(value.showAllKeywords === true || value.showReplaceAsset === true) && (
            <Box id={`card_keywords_${index}`}
                 sx={{ py: 4, border: 1, borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderColor: 'secondary.dark', bgcolor: 'background.default' }}>
              {value.showAllKeywords === true && (
                <Box>
                  {deserializeKeywords(value.keywords).map(item => {
                    return (
                      <Chip label={item.label}
                            key={`chip_keyword_${item.id}`}
                            onDelete={() => handleOnDelete(value.uid, value.keywords, item)}
                            sx={{ mx: 4, my: 3, bgcolor: 'background.darker', color: 'text.primary',
                              '& .MuiChip-deleteIcon': {
                                color: 'text.secondary',
                                '&:hover': {
                                  color: 'text.primary',
                                }
                              } }} />
                    );
                  })}
                </Box>
              )}

              {value.showReplaceAsset === true &&
              <Box id={`card_replace_asset`}>
                {value.folders.length > 1 &&
                <Box sx={{bgcolor: 'warning', p: 4, m: 2, borderRadius: 1, display: 'flex', alignItems: 'center'}}>
                  <Announcement sx={{mr: 2, color: 'text.dark'}}/>

                  <Typography sx={{color: 'text.dark'}}>This asset is used in more than one pack. Any modifications
                    made here will be reflected elsewhere as well.</Typography>
                </Box>
                }

                {rest.contentType.identifier === 'gif' ?
                  <Box sx={{ py: 2, pr: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Box sx={{ width: 1/2 }}>
                      <LinkedUploadBox id={`card_replace_linked_item_${index}`} checkLinkedFile={rest.checkLinkedFile} />
                    </Box>

                    <Button id={`button_conform_update_linked_item_${index}`}
                            variant="contained" color="primary" style={{marginLeft: '20px'}}
                            onClick={() => handleLinkedOnClickUpdate(value.uid, value.items[0].uid)}
                            disabled={rest.contentItem.isLoading || !rest.contentItem.validationPassed}>
                      Update
                    </Button>

                    <Button id={`button_cancel_update_linked_item_${index}`}
                            variant="outlined" color="tertiary" style={{marginLeft: '10px'}}
                            onClick={() => handleLinkedOnClickCancel(value.uid)}>
                      Cancel
                    </Button>
                  </Box> :

                  <Box sx={{ py: 2, pr: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {(typeof files[value.uid] !== 'undefined' && rest.contentType.identifier !== 'theme' ) && (
                      <img id={`image_preview_updated_item_${index}`}
                           style={previewImageStyles()}
                           src={files[value.uid].mainFile.preview ?? files[value.uid].previewFile.preview}
                           alt={files[value.uid].mainFile.name}
                           title={files[value.uid].mainFile.name}/>
                    )}

                    {typeof files[value.uid] === 'undefined' && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                          <TextField id={`input_replace_item_filename_label_${index}`} label="Browse file" disabled size="small" sx={{ width: 300 }} />

                          <label htmlFor={`input_replace_item_file_${index}`}>
                            <Input id={`input_replace_item_file_${index}`} sx={{ display: 'none' }} accept="image/*" type="file" onChange={event => handleOnChangeFile(value.uid, event)} disabled={value.isLoading} />

                            <IconButton id={`button_replace_item_folder_icon`} color="primary" aria-label="upload picture" component="span" sx={{ position: 'absolute', right: 0, bottom: 0 }} disabled={value.isLoading}>
                              <FolderIcon />
                            </IconButton>
                          </label>
                        </Box>

                        {rest.contentType.identifier === 'theme' &&
                        <Box sx={{ width: 224, height: 169, display: 'flex', justifyContent: 'center', bgcolor: 'background.dark', ml: 6 }}>
                          <KeyboardAltOutlined id={`icon_replace_item_theme_${index}`} sx={{ mt: 14, width: 48, height: 48, color: 'secondary.main' }}/>
                        </Box>
                        }
                      </Box>
                    )}

                    {typeof files[value.uid] !== 'undefined' && (
                      <>
                        <TextField id={`input_replaced_item_filename_${index}`} label="File" disabled value={files[value.uid].mainFile.name} size="small" sx={{ ml: 4, width: 300}}/>

                        {rest.contentType.identifier !== 'theme' &&
                        <Box>
                          <Typography id={`label_replaced_item_size_${index}`} sx={{ marginLeft: '20px', alignSelf: 'center', color: 'text.secondary' }}>
                            {`${(files[value.uid].mainFile.size / 1000).toFixed(0)} KB`}
                          </Typography>

                          <Typography id={`label_replaced_item_type_${index}`} sx={{ marginLeft: '20px', alignSelf: 'center', color: 'text.secondary' }}>
                            {files[value.uid].mainFile.type}
                          </Typography>
                        </Box>
                        }

                        {files[value.uid].mainFile.validationPasses ?
                          <CheckCircle id={`icon_check_replaced_item_${index}`} color="success" sx={{ ml: 4 }} /> :
                          <Warning id={`icon_warning_replaced-item_${index}`} sx={{color: "danger.main", ml: 4}}/>
                        }

                        {rest.contentType.identifier === 'theme' &&
                        <Box sx={{ width: 224, height: 169, pt: 6, display: 'flex', justifyContent: 'center', bgcolor: 'background.dark', ml: 6 }}>
                          {files[value.uid].previewFile.validationPasses ?
                            <Box sx={{ position: 'relative' }}>
                              <Box sx={{
                                position: 'absolute',
                                bottom: 0,
                                right: '-4px',
                                px: 1,
                                bgcolor: 'background.paper',
                                opacity: 0.9,
                                borderRadius: 1,
                              }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>v{files[value.uid].themeVersion}</Typography>
                              </Box>

                              <img id={`image_preview_replaced_theme_${index}`}
                                   style={previewImageStyles()}
                                   src={files[value.uid].mainFile.preview ?? files[value.uid].previewFile.preview}
                                   alt={files[value.uid].mainFile.name}
                                   title={files[value.uid].mainFile.name}/>
                            </Box> :
                            <Box id={`card_error_replaced_theme_${index}`} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                              <KeyboardAltOutlined id={`icon_error_replaced_theme_${index}`} sx={{mt: 8, width: 48, height: 48, color: 'danger.main'}}/>
                              <Typography id={`label_error_replaced_theme_${index}`} sx={{ fontSize: '0.75rem', px: 4, color: 'danger.main' }}>{ files[value.uid].previewFile.message }</Typography>
                            </Box>
                          }
                        </Box>
                        }

                        {rest.contentType.identifier !== 'theme' &&
                        <Delete id={`button_remove_replaced_item_${index}`}
                                size={20} color="#546e7a" cursor="pointer"
                                style={{marginLeft: '20px', alignSelf: 'center'}}
                                onClick={() => handleOnClickCancel(value.uid)}/>
                        }
                      </>
                    )}

                    <Button id={`button_confirm_replaced_item_${index}`}
                            variant="contained" color="primary" style={{marginLeft: '20px'}}
                            onClick={() => handleOnClickUpdate(index, value)}
                            disabled={typeof files[value.uid] === 'undefined' || !files[value.uid].mainFile.validationPasses || value.isLoading}>
                      Update
                    </Button>

                    <Button id={`button_cancel_replaced_item_${index}`} variant="outlined" color="tertiary" style={{marginLeft: '10px'}}
                            onClick={() => handleOnClickCancel(value.uid)}
                            disabled={value.isLoading}>
                      Cancel
                    </Button>
                  </Box>
                }
              </Box>
              }
            </Box>
          )}
        </Box>
      </div>
    );
  }
  return (
    <Box sx={{ height: 1, overflowY: 'auto', px: 12, pb: 12 }}>
      <Box display="flex" justifyContent="space-between" sx={{ pt: 8 }}>
        <Box alignSelf="center">
          <Typography id="label_content_type_your" color="text.secondary" variant="h3" sx={{ fontWeight: 'regular', mb: 2 }}>
            {`Your ${rest.contentType.identifier}s`}
          </Typography>

          <Typography id="label_content_type_counter" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
            {`${rest.contentAssets.data.length} ${rest.contentType.identifier + (rest.contentAssets.data.length !== 1 ? 's' : '')}`}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ pt: 6 }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <Box {...provided.droppableProps}
                   ref={provided.innerRef}
                   sx={{ display: 'flex', flexDirection: 'column' }}>
                {rest.contentAssets.data.map((contentAsset, index) => (
                  <Draggable key={`contentAsset_${contentAsset.uid}`} draggableId={`draggable_${contentAsset.uid}`} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}>
                        {getAssetRepresentation(index, contentAsset)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      <AlertDialog id={`dialog_delete_asset`} open={assetDeleteDialogOpen}
                   handleAgree={() => handleAssetDeletionAgree()}
                   handleDisagree={() => handleAssetDeletionDisagree()}
                   title="Are you sure you want to delete the asset?"
                   body="Doing so will remove the asset from this pack." />

      <TimelineModal id={`modal_timeline`}
                     onClose={() => setTimelineModalOpen(false)}
                     open={timelineModalOpen}
                     asset={assetForTimelineModal} />
    </Box>
  );
}

export default connect(stateToProps)(Manage);
