import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Paper,
  Typography
} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import { Link as RouterLink } from "react-router-dom";
import React, {useEffect, useState} from "react";

import Page from "@/components/Page";

import {getAdvancedDailyDashboard, getFilters, getTenantId} from "../store/selectors";
import {
  loadInsights, setAxisUseCommonYScale,
  setLeftAxisRenditionType, setRightAxisRenditionType, setSelectedBreakdownItem,
  setSelectedLeftAxisItems,
  setSelectedRightAxisItems
} from "../store/actions";

import Filters from "../subcomponents/filters/Filters";
import AdvancedDailyDashboardAxisControl from "../subcomponents/controls/AdvancedDailyDashboardAxisControl";
import BlendedVis from "../../../components/vis/BlendedVis";
import SimpleRectangleVisSkeleton from "../../../components/vis/SimpleRectangleVisSkeleton";

const stateToProps = (state) => ({
  tenantId: getTenantId(state),
  filters: getFilters(state),
  advancedDailyDashboard: getAdvancedDailyDashboard(state),
});
const actionToProps = (dispatch) => ({
  loadInsights: payload => dispatch(loadInsights(payload)),
  setSelectedLeftAxisItems: payload => dispatch(setSelectedLeftAxisItems(payload)),
  setLeftAxisRenditionType: payload => dispatch(setLeftAxisRenditionType(payload)),
  setSelectedRightAxisItems: payload => dispatch(setSelectedRightAxisItems(payload)),
  setRightAxisRenditionType: payload => dispatch(setRightAxisRenditionType(payload)),
  setAxisUseCommonYScale: payload => dispatch(setAxisUseCommonYScale(payload)),
  setStateSelectedBreakdownItem: payload => dispatch(setSelectedBreakdownItem(payload)),
});

const AdvancedDailyDashboard = ({
                     loadInsights,
                     tenantId,
                     advancedDailyDashboard,
                     setSelectedLeftAxisItems,
                     setLeftAxisRenditionType,
                     setSelectedRightAxisItems,
                     setRightAxisRenditionType,
                     setAxisUseCommonYScale,
                     setStateSelectedBreakdownItem,
}) => {
  const [selectedBreakdownItems, setSelectedBreakdownItems] = useState([advancedDailyDashboard.selectedBreakdownItem]);

  useEffect(() => {
    if (tenantId) {
      loadInsights({ dashboard: 'advanced-daily-dashboard' });
    }
  }, [loadInsights, tenantId]);

  const handleBreakdownSelectionChance = item => {
    const newItems = selectedBreakdownItems.includes(item) ? [] : [item];

    setSelectedBreakdownItems(newItems);
    setStateSelectedBreakdownItem({
      selectedBreakdownItem: newItems.length === 0 ? null : item,
      dashboard: 'advancedDailyDashboard'
    });
  };

  const clearBreakdownItems = () => {
    setSelectedBreakdownItems([]);
    setStateSelectedBreakdownItem({
      selectedBreakdownItem: null,
      dashboard: 'advancedDailyDashboard'
    });
  };

  const breakdownFeatureEnabled =
    (advancedDailyDashboard.leftAxis.renditionType === 'stacked_bar' && advancedDailyDashboard.leftAxis.items.length === 1 && advancedDailyDashboard.rightAxis.items.length === 0) ||
    (advancedDailyDashboard.rightAxis.renditionType === 'stacked_bar' && advancedDailyDashboard.rightAxis.items.length === 1 && advancedDailyDashboard.leftAxis.items.length === 0);


  const callToAction = (
    <Link to="../daily-dashboard" component={RouterLink}>
      <Button color="primary" variant="link">
        Show default
      </Button>
    </Link>
  );

  const breadCrumbs = (
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to="#" component={RouterLink}>
        Reporting
      </Link>

      <Link variant="body1" to="../daily-dashboard" component={RouterLink}>
        Daily Dashboard
      </Link>

      <Link variant="body1" to="../daily-dashboard/advanced" component={RouterLink}>
        Advanced
      </Link>
    </Breadcrumbs>
  );

  return (
    <Page title="Advanced Daily Dashboard" callToAction={callToAction} breadCrumbs={breadCrumbs}>
      <Box mb={8}>
        <Filters dashboard="advanced-daily-dashboard" showUserSpecificFilters={false} />
      </Box>

      <Box sx={{ mt: 4 }}>
        {advancedDailyDashboard.isLoading && (
          <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "400px" }}
                                      animation="wave"
                                      title={advancedDailyDashboard.isLoadingForALongTime ? 'Almost finished...' : 'Loading...'} />
        )}

        {!advancedDailyDashboard.isLoading && (!advancedDailyDashboard.data || advancedDailyDashboard.data.length === 0) && (
          <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "400px" }}
                                      animation={false}
                                      title={'No data - try selecting a different set of filters'} />
        )}

        {!advancedDailyDashboard.isLoading && (
          <BlendedVis
            height={400}
            data={advancedDailyDashboard.data}
            leftAxisSelectedItems={advancedDailyDashboard.leftAxis.items}
            leftAxisRendition={advancedDailyDashboard.leftAxis.renditionType}
            rightAxisSelectedItems={advancedDailyDashboard.rightAxis.items}
            rightAxisRendition={advancedDailyDashboard.rightAxis.renditionType}
            axisUseCommonYScale={advancedDailyDashboard.axisUseCommonYScale}
            selectedBreakdownItem={advancedDailyDashboard.selectedBreakdownItem}
            x0ScaleValueAccessor={(d) => d.date}
            xAxisLabel="Date"
            yAxisLabel="Amount"
          />
        )}
      </Box>

      <Grid container spacing={4} sx={{ mt: 4 }}>
        <Grid item xs={6}>
          <Typography variant="h5" sx={{ color: 'text.primary' }}>Left axis</Typography>

          <AdvancedDailyDashboardAxisControl
            axis={advancedDailyDashboard.leftAxis}
            setAxisItems={(items) => {
              setSelectedLeftAxisItems({dashboard: 'advancedDailyDashboard', ...items});
            }}
            setAxisRenditionType={(renditionType) => {
              setLeftAxisRenditionType({dashboard: 'advancedDailyDashboard', ...renditionType});
              clearBreakdownItems();
            }}
            setAxisUseCommonYScale ={(axisUseCommonYScale) => setAxisUseCommonYScale({dashboard: 'advancedDailyDashboard', ...axisUseCommonYScale})}
            stackedBarAllowed={breakdownFeatureEnabled}
          />
        </Grid>

        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <Box>
            <Typography variant="h5" sx={{ color: 'text.primary' }}>Right axis</Typography>

            <AdvancedDailyDashboardAxisControl
              axis={advancedDailyDashboard.rightAxis}
              setAxisItems={(items) => {
                setSelectedRightAxisItems({dashboard: 'advancedDailyDashboard', ...items});
              }}
              setAxisRenditionType={(renditionType) => {
                setRightAxisRenditionType({dashboard: 'advancedDailyDashboard', ...renditionType});
                clearBreakdownItems();
              }}
              stackedBarAllowed={breakdownFeatureEnabled}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={4} sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ color: 'text.primary' }}>Breakdown by</Typography>
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex' }}>
          <FormGroup sx={{ mr: 2 }}>
            <FormControlLabel label="Device Type" sx={{ color: 'text.primary' }} disabled={!breakdownFeatureEnabled} control={
              <Checkbox onChange={() => handleBreakdownSelectionChance('operating_system')} checked={selectedBreakdownItems.includes('operating_system')} />
            } />
          </FormGroup>

          <FormGroup sx={{ mr: 2 }}>
            <FormControlLabel label="Region" sx={{ color: 'text.primary' }} disabled={!breakdownFeatureEnabled} control={
              <Checkbox onChange={() => handleBreakdownSelectionChance('region')} checked={selectedBreakdownItems.includes('region')} />
            } />
          </FormGroup>

          <FormGroup sx={{ mr: 2 }}>
            <FormControlLabel label="Country" sx={{ color: 'text.primary' }} disabled={!breakdownFeatureEnabled} control={
              <Checkbox onChange={() => handleBreakdownSelectionChance('country_code')} checked={selectedBreakdownItems.includes('country_code')} />
            } />
          </FormGroup>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Paper sx={{ p: '16px', width: "100%"}}>
          <FormGroup>
            <FormControlLabel label="Axis use the same scale" control={
              <Checkbox onChange={() => setAxisUseCommonYScale({
                dashboard: 'advancedDailyDashboard',
                axisUseCommonYScale: !advancedDailyDashboard.axisUseCommonYScale
              })} />
            } />
          </FormGroup>
        </Paper>
      </Box>
    </Page>
  );
};

export default connect(stateToProps, actionToProps)(AdvancedDailyDashboard);
