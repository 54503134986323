// START modules
import React from 'react';
import connect from 'react-redux/es/connect/connect';
// END modules

// STAT material
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  List,
  ListItem, ListItemIcon, ListItemSecondaryAction,
  ListItemText,
  Modal,
  Typography,
  Alert,
} from "@mui/material";
import {makeStyles} from '@mui/styles';

import {EmojiFlagsSharp, Send} from "@mui/icons-material";
// END material

// START selectors
import {diffs, publish} from "../store/selectors";
// END selectors

// START actions
import {loadContentFolders, publishToMocha} from "../store/actions";
import Diffs from "./Diffs";
// END actions

// START styles
const useStyles = makeStyles((theme) => ({
    modalShape: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "absolute",
        top: "15%",
        left: "50%",
        transform: "translateX(-50%)",
        width: "33%",
        maxWidth: 768,
        height: "66vh",
        padding: "30px 30px",
        borderBottom: "1px solid #f3f3f3",
        backgroundColor: theme.palette.background.dark,
        borderRadius: 8,
        overflow: "hidden",
    },
    modalContent: {
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        flex: "1 1 0%",
        marginTop: '16px',
    },
    modalFooter: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        height: 30,
    },
    tabElement: {
        fontSize: 14,
        minWidth: 100,
        height: 40,
    },
    buttonSpacing: {
        marginRight: "10px",
    },
    modalTitle: {
        margin: 0,
        fontSize: 24,
        lineHeight: "28px",
    },
    modalSubTitle: {
        lineHeight: "14px",
        marginBottom: 0,
        fontSize: 14,
    },
    subTitlePaper: {
        marginTop: 30,
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    subTitle: {
        width: "100%",
        height: 49,
        fontSize: 16,
        color: "gray",
        padding: 10,
        paddingLeft: 20,
    },
}));
// END styles

const actionsToProps = (dispatch) => ({
  publishToMocha: (data) => dispatch(publishToMocha(data)),
  loadContentFolders: (data) => dispatch(loadContentFolders(data)),
});

const stateToProps = state => ({
  publishState: publish(state),
  diffs: diffs(state),
});

const PublishModal = (props) => {
  const classes = useStyles();

  const thereAreDiffs = () => {
    let thereAreDiffs = false;
    Object.values(props.diffs.data).forEach(campaign => {
      if( campaign.vibes.new.length > 0 ||
          campaign.vibes.modified.length > 0 ||
          campaign.vibes.deleted.length > 0 ||
          campaign.keyboard_themes.new.length > 0 ||
          campaign.keyboard_themes.modified.length > 0 ||
          campaign.keyboard_themes.deleted.length > 0 ||
          campaign.sdk_themes.new.length > 0 ||
          campaign.sdk_themes.modified.length > 0 ||
          campaign.sdk_themes.deleted.length > 0)  {
        thereAreDiffs = true;
      }
    });

    return thereAreDiffs;
  }

  const handleOnClose = () => {
    if( !props.publishState.isLoading ) {
      props.handlePublishModalOpen();
    }
  };

  const handlePublish = () => {
    if( !props.publishState.isLoading ) {
      props.publishToMocha({
        tenantId: props.tenantId,
      });
    }
  };

  return (
    <Modal open={props.open} aria-labelledby="modal-publish" onClose={() => handleOnClose()} disabled>
      <Box className={classes.modalShape}>
        <Box sx={{ mt: 3 }}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h2">Publish to Mocha</Typography>
          </Box>

          {
            props.publishState.success === null ?
              <Alert id="message_publish_info" severity="info">
                After reviewing the differences between the CMS draft and Mocha API assets, you can publish these changes by clicking the "Publish" button from below.
              </Alert> :
              props.publishState.success === true ?
                <Alert id="message_publish_success" severity="success">
                  Successfully published the changes.
                </Alert> :
                <Alert id="message_publish_danger" severity="danger">
                  Something went wrong and we weren't able to publish to Mocha.
                </Alert>
          }
        </Box>

        <Box className={classes.modalContent}>
          {props.diffs.isLoading ?
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress id="spinner_diffs" size="64px" color="primary" sx={{ mt: 16 }} />
            </Box> :
            <Box>
              <Diffs diffs={props.diffs} />
            </Box>
          }
        </Box>

        <Box className={classes.modalFooter}>
          <Box>
            <Button id="button_cancel_publish" variant="outlined" color="tertiary"
                    onClick={() => handleOnClose()}
                    className={classes.buttonSpacing}
                    disabled={props.publishState.isLoading || props.diffs.isLoading}>
              Cancel
            </Button>

            <Button id="button_publish" variant="contained" color="primary"
                    className={classes.buttonSpacing}
                    onClick={() => handlePublish()}
                    disabled={props.diffs.isLoading || !thereAreDiffs()}
                    endIcon={
                      props.publishState.isLoading ?
                        <CircularProgress color="inherit" size={20} /> :
                        <Send/>
                    }>
              Publish
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default connect(stateToProps, actionsToProps)(PublishModal);