import connect from "react-redux/es/connect/connect";
import {Box} from "@mui/material";
import BiDimensionalRoundedChartSkeleton from "@/components/vis/BiDimensionalRoundedChartSkeleton";
import DoublePie from "@/components/vis/DoublePie";

const stateToProps = ({ insights: {sections: { connection } }}) => {
  return {
    isLoading: connection.isLoading,
    isLoadingForALongTime: connection.isLoadingForALongTime,
    data: connection.data,
  };
};

function mapData(data, key) {
  return data.map(d => ({ label: d[key], value: Number(d.percentage) }));
}

const VisConnection = ({ sx = {}, isLoading, isLoadingForALongTime, data, filters }) => {
  const connectionHasData = data?.connection && data.connection.value.length > 0;
  const carrierHasData = data?.carrier && data.carrier.value.length > 0;

  const connectionData = data && data.connection && mapData(data.connection.value, 'connection');
  const carrierData = data && data.carrier && mapData(data.carrier.value, 'carrier');

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && (
        <BiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                           animation="wave"
                                           title={isLoadingForALongTime ? "Carrier & connection - working" : "Carrier & connection"} />
      )}

      {!isLoading && (!connectionHasData || !carrierHasData ) && (
        <BiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                           animation={false}
                                           title="Carrier & connection - no data" />
      )}

      {!isLoading && connectionHasData && carrierHasData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <DoublePie title="Carrier & connection" donutData={connectionData} pieData={carrierData} height={450} width={450}  filters={filters} />
        </Box>
      )}
    </Box>
  );
};

export default connect(stateToProps)(VisConnection);