import * as CLIENTS from './types';
import * as CONTENT from "../../keyboard-content/store/types";

const defaultState = {
  section: 'clients',
  msg: null,
  statusCode: null,
  organizations: {
    data: '',
    isLoading: false,
  },
  organization: {
    data: '',
    isLoading: false,
  },
  organizationDelete: {
    data: '',
    isLoading: false,
  },
  organizationUpdate: {
    data: '',
    isLoading: false,
  },
  clients: {
    data: '',
    isLoading: false,
  },
  users: {
    data: '',
    isLoading: false,
  },
  createUsers: {
    data: '',
    isLoading: false,
  },
  createTenantUsers: {
    data: '',
    isLoading: false,
  },
  deleteTenantUsers: {
    data: '',
    isLoading: false,
  },
  updateTenantUsers: {
    data: '',
    isLoading: false,
  },
  inviteTenantUsers: {
    data: '',
    isLoading: false,
  },
  resetTenantUsers: {
    data: '',
    isLoading: false,
  },
  statsReportSeries: {
    data: '',
    isLoading :false,
  },
  tags: {
    data: '',
    isLoading: false,
    success: null,
    msg: '',
  },
  createTags: {
    data: "",
    isLoading: false,
    msg: "",
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CLIENTS.LOAD_TAGS:
      return {
        ...state,
        tags: {
          ...state.tags,
          isLoading: true,
        },
      };
    case CLIENTS.SET_TAGS:
      const { data, msg, statusCode, meta } = action.payload;

      return {
        ...state,
        tags: {
          data,
          meta,
          isLoading: false,
        },
      };
    case CLIENTS.LOAD_CREATE_TAGS:
      return {
        ...state,
        createTags: {
          ...state.createTags,
          isLoading: true,
        },
      };
    case CLIENTS.SET_CREATE_TAGS: {
      const { data, msg, statusCode } = action.payload;

      return {
        ...state,
        createTags: {
          data,
          isLoading: false,
          success: statusCode === 201,
          msg: msg,
        },
      };
    }
    case CLIENTS.LOAD_RESET_ORGANIZATION:
      return {
        ...state,
        organization: {
          data: '',
          isLoading: false,
        },
        organizationUpdate: {
          data: '',
          isLoading: false,
        },
        msg: '',
      };
    case CLIENTS.LOAD_ORGANIZATIONS:
      return {
        ...state,
        organizations: {
          ...state.organizations,
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.SET_ORGANIZATIONS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        organizations: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case CLIENTS.LOAD_CREATE_ORGANIZATION:
      return {
        ...state,
        organization: {
          ...state.organization,
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.SET_CREATE_ORGANIZATION: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        organization: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case CLIENTS.LOAD_DELETE_ORGANIZATION:
      return {
        ...state,
        organizationDelete: {
          ...state.organizationDelete,
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.SET_DELETE_ORGANIZATION: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        organizationDelete: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case CLIENTS.LOAD_RESET_DELETE_ORGANIZATION: {
      return {
        ...state,
        organizationDelete: {
          ...defaultState.organizationDelete
        }
      };
    }
    case CLIENTS.LOAD_UPDATE_ORGANIZATION:
      return {
        ...state,
        organizationUpdate: {
          ...state.organizationUpdate,
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.SET_UPDATE_ORGANIZATION: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        organizationUpdate: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case CLIENTS.LOAD_ORGANIZATION:
      return {
        ...state,
        organization: {
          ...state.organization,
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.SET_ORGANIZATION: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        organization: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case CLIENTS.LOAD_CLIENTS:
      return {
        ...state,
        clients: {
          ...state.clients,
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.SET_CLIENTS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        clients: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case CLIENTS.LOAD_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.LOAD_RESET_USERS:
      return {
        ...state,
        users: {
          data: '',
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.SET_USERS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        users: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case CLIENTS.LOAD_CREATE_USERS:
      return {
        ...state,
        createUsers: {
          ...state.createUsers,
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.SET_CREATE_USERS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        createUsers: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case CLIENTS.LOAD_TENANT_USERS:
      return {
        ...state,
        createTenantUsers: {
          ...state.createTenantUsers,
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.SET_TENANT_USERS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        createTenantUsers: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case CLIENTS.LOAD_DELETE_TENANT_USERS:
      return {
        ...state,
        deleteTenantUsers: {
          ...state.deleteTenantUsers,
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.SET_DELETE_TENANT_USERS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        deleteTenantUsers: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case CLIENTS.LOAD_UPDATE_TENANT_USERS:
      return {
        ...state,
        updateTenantUsers: {
          ...state.updateTenantUsers,
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.SET_UPDATE_TENANT_USERS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        updateTenantUsers: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case CLIENTS.LOAD_INVITE_TENANT_USERS:
      return {
        ...state,
        inviteTenantUsers: {
          ...state.inviteTenantUsers,
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.SET_INVITE_TENANT_USERS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        inviteTenantUsers: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case CLIENTS.LOAD_RESET_TENANT_USERS:
      return {
        ...state,
        resetTenantUsers: {
          ...state.resetTenantUsers,
          isLoading: true,
        },
        msg: '',
      };
    case CLIENTS.SET_RESET_TENANT_USERS: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        resetTenantUsers: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case CLIENTS.LOAD_STATS_REPORT_SERIES:
      return {
        ...state,
        statsReportSeries: {
          ...state.statsReportSeries,
          isLoading: true,
        },
      };
    case CLIENTS.LOAD_RESET_STATS_REPORT_SERIES:
      return {
        ...state,
        statsReportSeries: {
          data: '',
          isLoading: false,
        },
      };
    case CLIENTS.SET_STATS_REPORT_SERIES: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        statsReportSeries: {
          data,
          msg,
          statusCode,
          isLoading: false,
        },
      };
    }
    case CLIENTS.LOAD_RESET_MSG: {
      return {
        ...state,
        createTenantUsers: {
          data: '',
          isLoading: false,
        },
        deleteTenantUsers: {
          data: '',
          isLoading: false,
        },
        updateTenantUsers: {
          data: '',
          isLoading: false,
        },
        inviteTenantUsers: {
          data: '',
          isLoading: false,
        },
        resetTenantUsers: {
          data: '',
          isLoading: false,
        },
        createUsers: {
          data: '',
          isLoading: false,
        },
        msg: '',
        statusCode: '',
      };
    }
    default:
      return state;
  }
}
