import { Box } from "@mui/material";

import Donut from "@/components/vis/Donut";
import BiDimensionalRoundedChartSkeleton from "../../../../components/vis/BiDimensionalRoundedChartSkeleton";

function mapData(data, key) {
  return data.map(d => ({ label: d[key], value: Number(d.percentage) }));
}

function VisOperatingSystems({
  sx = {},
  isLoading,
  isLoadingForALongTime,
  data,
  filters,
}) {
  const hasData = data && data.length > 0;

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && (
        <BiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                           animation="wave"
                                           title={isLoadingForALongTime ? "Operating System - working" : "Operating System"} />
      )}

      {!isLoading && !hasData && (
        <BiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                           animation={false}
                                           title="Operating System - no data" />
      )}

      {!isLoading && hasData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Donut title="Operating System" data={data} height={300} width={300} thickness={30} filters={filters} />
        </Box>
      )}
    </Box>
  );
}

export default VisOperatingSystems
