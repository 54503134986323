import connect from "react-redux/es/connect/connect";
import { Box } from "@mui/material";

import Pie from "../../../../components/vis/Pie";
import MultiDimensionalRoundedChartSkeleton from "../../../../components/vis/MultiDimensionalRoundedChartSkeleton";

const stateToProps = ({ insights: {sections: { interests } }}) => {
  return {
    isLoading: interests.isLoading,
    isLoadingForALongTime: interests.isLoadingForALongTime,
    data: interests.data,
  };
};

function mapData(data, key) {
  return data.map(d => ({ label: d[key], value: Number(d.percentage) }));
}

function VisInterests({
                        sx = {},
                        isLoading,
                        isLoadingForALongTime,
                        data,
                        filters,
                      }) {
  const hasData = data?.interests && data.interests.value.length > 0;
  const interestsData = data && data.interests && mapData(data.interests.value, 'interest');

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && (
        <MultiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                              animation="wave"
                                              title={isLoadingForALongTime ? "Interests - working" : "Interests"} />
      )}

      {!isLoading && !hasData && (
        <MultiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                              animation={false}
                                              title="Interests - no data" />
      )}

      {!isLoading && hasData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Pie title="Interests" data={interestsData} height={250} width={250} filters={filters} />
        </Box>
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisInterests);
