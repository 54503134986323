import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography
} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import { Link as RouterLink } from "react-router-dom";
import React, {useEffect, useState} from "react";

import Page from "@/components/Page";

import RetentionDashboardDaysAxisControl from "../subcomponents/controls/RetentionDashboardDaysAxisControl";

import {getRetentionDashboard, getFilters, getTenantId} from "../store/selectors";
import {
  loadInsights,
  setLeftAxisRenditionType,
  setSelectedLeftAxisItems,
  setSelectedRightAxisItems
} from "../store/actions";

import Filters from "../subcomponents/filters/Filters";
import BlendedVis from "../../../components/vis/BlendedVis";
import SimpleRectangleVisSkeleton from "../../../components/vis/SimpleRectangleVisSkeleton";
import {toHumanPercentage} from "../../../utils/numbers";
import VisGenericNumberStat from "../subcomponents/vis/VisGenericNumberStat";
import {FORMAT_PERC} from "../../../components/vis/NumberStat";
import VisRetentionDay from "../subcomponents/vis/VisRetentionDay";
import RetentionDashboardScalarAxisControl from "../subcomponents/controls/RetentionDashboardScalarAxisControl";

const stateToProps = (state) => ({
  tenantId: getTenantId(state),
  filters: getFilters(state),
  retentionDashboard: getRetentionDashboard(state),
});
const actionToProps = (dispatch) => ({
  loadInsights: payload => dispatch(loadInsights(payload)),
  setSelectedLeftAxisItems: payload => dispatch(setSelectedLeftAxisItems(payload)),
  setSelectedRightAxisItems: payload => dispatch(setSelectedRightAxisItems(payload)),
});

const RetentionDashboard = ({
  loadInsights,
  tenantId,
  retentionDashboard,
  setSelectedLeftAxisItems,
  setSelectedRightAxisItems,
  filters
}) => {
  useEffect(() => {
    if (tenantId) {
      loadInsights({ dashboard: 'advanced-retention-dashboard' });
    }
  }, [loadInsights, tenantId]);

  const [showBasicMetrics, setShowBasicMetrics] = useState(true);

  const blendedVisValueFormatters = {
    app_retention_day_1: toHumanPercentage,
    app_retention_day_2: toHumanPercentage,
    app_retention_day_3: toHumanPercentage,
    app_retention_day_4: toHumanPercentage,
    app_retention_day_5: toHumanPercentage,
    app_retention_day_6: toHumanPercentage,
    app_retention_day_7: toHumanPercentage,
    keyboard_retention_day_1: toHumanPercentage,
    keyboard_retention_day_2: toHumanPercentage,
    keyboard_retention_day_3: toHumanPercentage,
    keyboard_retention_day_4: toHumanPercentage,
    keyboard_retention_day_5: toHumanPercentage,
    keyboard_retention_day_6: toHumanPercentage,
    keyboard_retention_day_7: toHumanPercentage,
  };

  const callToAction = (
    <>
      {!showBasicMetrics && (
        <Button color="primary" variant="contained" onClick={() => setShowBasicMetrics(true)}>
          Show basic metrics
        </Button>
      )}

      {showBasicMetrics && (
        <Button color="primary" variant="text" onClick={() => setShowBasicMetrics(false)}>
          Hide basic metrics
        </Button>
      )}
    </>
  );

  const breadCrumbs = (
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to="#" component={RouterLink}>
        Reporting
      </Link>

      <Link variant="body1" to="../retention-dashboard" component={RouterLink}>
        Retention Dashboard
      </Link>

      <Link variant="body1" to="../retention-dashboard/advanced" component={RouterLink}>
        Advanced
      </Link>
    </Breadcrumbs>
  );

  return (
    <Page title="Retention Dashboard" callToAction={callToAction} breadCrumbs={breadCrumbs}>
      <Box mb={8}>
        <Filters dashboard="advanced-retention-dashboard" showUserSpecificFilters={false} />
      </Box>

      {showBasicMetrics && (
        <Box sx={{ mt:4 }}>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={3}>
              <VisGenericNumberStat data={retentionDashboard.data?.simple?.app_installs}
                                    statKey="App Installs"
                                    isLoading={retentionDashboard.isLoading}
                                    isLoadingForALongTime={retentionDashboard.isLoadingForALongTime}
                                    filters={filters}
                                    sx={{ width: '100%', mb: 4 }} />

              <VisGenericNumberStat data={retentionDashboard.data?.simple?.keyboard_activations}
                                    statKey="Keyboard Activations"
                                    isLoading={retentionDashboard.isLoading}
                                    isLoadingForALongTime={retentionDashboard.isLoadingForALongTime}
                                    filters={filters}
                                    sx={{ width: '100%', mb: 4 }} />

              <VisGenericNumberStat data={retentionDashboard.data?.simple?.activation_rate}
                                    formatter={FORMAT_PERC}
                                    statKey="Activation Rate"
                                    isLoading={retentionDashboard.isLoading}
                                    isLoadingForALongTime={retentionDashboard.isLoadingForALongTime}
                                    filters={filters}
                                    sx={{ width: '100%' }} />
            </Grid>

            <Grid item xs={3}>
              <VisRetentionDay app_users={retentionDashboard.data?.simple?.app_users_age_1}
                               app_retention={retentionDashboard.data?.simple?.app_retention_day_1}
                               keyboard_users={retentionDashboard.data?.simple?.keyboard_users_age_1}
                               keyboard_retention={retentionDashboard.data?.simple?.keyboard_retention_day_1}
                               install_keyboard_retention={retentionDashboard.data?.simple?.install_keyboard_retention_day_1}
                               title="Day 1"
                               amount_of_days={1}
                               isLoading={retentionDashboard.isLoading}
                               isLoadingForALongTime={retentionDashboard.isLoadingForALongTime} />
            </Grid>

            <Grid item xs={3}>
              <VisRetentionDay app_users={retentionDashboard.data?.simple?.app_users_age_7}
                               app_retention={retentionDashboard.data?.simple?.app_retention_day_7}
                               keyboard_users={retentionDashboard.data?.simple?.keyboard_users_age_7}
                               keyboard_retention={retentionDashboard.data?.simple?.keyboard_retention_day_7}
                               install_keyboard_retention={retentionDashboard.data?.simple?.install_keyboard_retention_day_7}
                               title="Day 7"
                               amount_of_days={7}
                               isLoading={retentionDashboard.isLoading}
                               isLoadingForALongTime={retentionDashboard.isLoadingForALongTime} />
            </Grid>

            <Grid item xs={3}>
              <VisRetentionDay app_users={retentionDashboard.data?.simple?.app_users_age_15}
                               app_retention={retentionDashboard.data?.simple?.app_retention_day_15}
                               keyboard_users={retentionDashboard.data?.simple?.keyboard_users_age_15}
                               keyboard_retention={retentionDashboard.data?.simple?.keyboard_retention_day_15}
                               install_keyboard_retention={retentionDashboard.data?.simple?.install_keyboard_retention_day_15}
                               title="Day 15"
                               amount_of_days={15}
                               isLoading={retentionDashboard.isLoading}
                               isLoadingForALongTime={retentionDashboard.isLoadingForALongTime} />
            </Grid>
          </Grid>
        </Box>
      )}

      <Box sx={{ mt: 4 }}>
        {retentionDashboard.isLoading && (
          <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "400px" }}
                                      animation="wave"
                                      title={retentionDashboard.isLoadingForALongTime ? 'Almost finished...' : 'Loading...'} />
        )}

        {!retentionDashboard.isLoading && (!retentionDashboard?.data?.advanced || retentionDashboard.data.advanced.length === 0) && (
          <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "400px" }}
                                      animation={false}
                                      title={'No data - try selecting a different set of filters'} />
        )}

        {!retentionDashboard.isLoading && (
          <BlendedVis
            height={400}
            data={retentionDashboard.data.advanced ?? {}}
            leftAxisSelectedItems={retentionDashboard.leftAxis.items}
            leftAxisRendition={retentionDashboard.leftAxis.renditionType}
            rightAxisSelectedItems={retentionDashboard.rightAxis.items}
            rightAxisRendition={retentionDashboard.rightAxis.renditionType}
            axisUseCommonYScale={retentionDashboard.axisUseCommonYScale}
            x0ScaleValueAccessor={(d) => d.install_date}
            xAxisLabel="Date"
            yAxisLabel="Percentage %"
            valueFormatters={blendedVisValueFormatters}
          />
        )}
      </Box>

      <Grid container spacing={4} sx={{ mt: 4 }}>
        <Grid item xs={6}>
          <Typography variant="h5" sx={{ color: 'text.primary' }}>Left axis</Typography>

          <RetentionDashboardDaysAxisControl
            axis={retentionDashboard.leftAxis}
            setAxisItems={(items) => setSelectedLeftAxisItems({dashboard: 'retentionDashboard', ...items})}
          />
        </Grid>

        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <Box>
            <Typography variant="h5" sx={{ color: 'text.primary' }}>Right axis</Typography>

            <RetentionDashboardScalarAxisControl
              axis={retentionDashboard.rightAxis}
              setAxisItems={(items) => setSelectedRightAxisItems({dashboard: 'retentionDashboard', ...items})}
              />
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

export default connect(stateToProps, actionToProps)(RetentionDashboard);
