import connect from "react-redux/es/connect/connect";
import {Box, useTheme} from "@mui/material";
import SimpleRectangleVisSkeleton from "../../../../components/vis/SimpleRectangleVisSkeleton";
import React from "react";
import Bar from "../../../../components/vis/Bar";
import HorizontalBar from "../../../../components/vis/HorizontalBar";

const stateToProps = ({ insights: {sections: { searchTerms } }}) => {
  return {
    isLoading: searchTerms.isLoading,
    isLoadingForALongTime: searchTerms.isLoadingForALongTime,
    data: searchTerms.data,
  };
};

function mapValues(data) {
  return data.map((d, i) => ({
    label: d.search_terms,
    value: d.occurrences,
  }));
}

function VisSearchTerms({ sx = {}, isLoading, isLoadingForALongTime, data, filters }) {
  const theme = useTheme();
  const hasData = data && data.length > 0;

  const values = hasData && mapValues(data);

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "100%" }}
                                    animation="wave"
                                    title={isLoadingForALongTime ? "Searches - working" : "Searches"} />
      )}

      {!isLoading && !hasData && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "100%" }}
                                    animation={false}
                                    title="Searches - no data" />
      )}

      {!isLoading && hasData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <HorizontalBar title="Searches" width="550"
                         yLabel="Words" xLabel="Occurences"
                         data={values} filters={filters}
                         color={theme.palette.vis.histogram.one}
            />
        </Box>
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisSearchTerms);