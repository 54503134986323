import React from 'react';
import PropTypes from 'prop-types';
import {getValue} from "AppUtils/objects";

import clsx from 'clsx';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {useSnackbar} from 'notistack';
import {Box, Button, Card, CardContent, Grid, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingScreen from "../../../components/LoadingScreen";

const UserAddForm = ({
                       className,
                       setIsSubmittingState,
                       isSubmittingState,
                       user,
                       errorsServer,
                       setErrorsServer,
                       ...props
                     }) => {
  const {enqueueSnackbar} = useSnackbar();

  let roleList = [
    {'text': 'Content Manager', value: 'contentManager'},
    {'text': 'Admin', value: 'admin'},
    {'text': 'Simple Insights Viewer', value: 'simpleInsightsViewer'},
    {'text': 'Advanced Insights Viewer', value: 'advancedInsightsViewer'}
  ];

  if (props.edit && !user) {
    return <LoadingScreen/>;
  }

  return (
    <Formik
      initialValues={{
        name: getValue(user, 'name', ''),
        email: getValue(user, 'email'),
        role: user.role ? {
          text: user.role.charAt(0).toUpperCase() + user.role.slice(1),
          value: user.role
        } : {'text': 'Simple Insights Viewer', value: 'simpleInsightsViewer'},
        submit: null
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).min(5, 'Must be at least 5 characters').required('Name is required'),
        email: Yup.string().max(255).email('Must be a valid email address').required(user ? false : 'Email is required'),
        role: Yup.string().max(255).required('Role is required').nullable(),
      })}
      onSubmit={(values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        setIsSubmittingState(true);
        if (props.edit) {
          props.updateUser(values);
        } else {
          props.addUser(values);
        }

        // resetForm();
        setSubmitting(false);
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          setTouched,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField fullWidth name="name" onBlur={handleBlur} label="Full name" required value={values.name} variant="outlined"
                             error={Boolean(touched.name && errors.name) || errorsServer.name}
                             helperText={(touched.name && errors.name) || errorsServer.name}
                             onChange={(e) => {
                               setFieldValue("name", e.target.value);
                               setErrorsServer({
                                 ...errorsServer,
                                 name: false
                               })
                             }} />
                </Grid>

                <Grid item xs={12}>
                  <TextField fullWidth label="Email address" name="email" onBlur={handleBlur} required={user ? false : true}
                             value={values.email} variant="outlined" disabled={user ? true : false}
                             error={Boolean(touched.email && errors.email) || errorsServer.email}
                             helperText={(touched.email && errors.email) || errorsServer.email}
                             onChange={(e) => {
                               setFieldValue("email", e.target.value);
                               setErrorsServer({
                                 ...errorsServer,
                                 email: false
                               })
                             }} />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete getOptionLabel={(option) => option.text}
                                options={roleList}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onBlur={() => setTouched({'role': true})}
                                onChange={(e, value) => {
                                  setFieldValue("role", value)
                                  setErrorsServer({
                                    ...errorsServer,
                                    role: false
                                  })
                                }}
                                defaultValue={values.role}
                                includeInputInList
                                value={values.role}
                                renderInput={(params) => (
                      <TextField fullWidth label="Role" name="role" variant="outlined" value={values.role} required {...params}
                                 error={Boolean(touched.role && errors.role) || errorsServer.role}
                                 helperText={(touched.role && errors.role) || errorsServer.role} />
                    )}
                  />
                </Grid>
              </Grid>

              <Box mt={2}>
                <Button variant="contained" color="secondary" type="submit" disabled={isSubmittingState}>
                  {props.edit ? 'Save changes' : 'New User'}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

UserAddForm.propTypes = {
  className: PropTypes.string,
};

export default UserAddForm;
