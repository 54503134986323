// START modules
import React, { useState, useRef, useEffect } from "react";
import connect from "react-redux/es/connect/connect";
// END modules

// START components
import ControlHeader from "./ControlHeader";
import ControlModal from "./ControlModal";
import PublishModal from "./PublishModal";
import Folder from "./Folder";
// END components

// START material
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import {makeStyles} from '@mui/styles';
import {AddCircle} from "@mui/icons-material";
// END material

// START actions
import {
  loadResetContentFolders,
  toggleSelectedFolder,
  loadUpdateContentFolder,
} from "../store/actions";
import AlertDialog from "../../../components/AlertDialogue";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {refreshOrder, reorderByRank} from "../../../utils/reorder";
import {userChannels} from "../../user/store/selectors";
// END actions

const actionsToProps = (dispatch) => ({
  loadResetContentFolders: (data) => dispatch(loadResetContentFolders(data)),
  toggleSelectedFolder: (data) => dispatch(toggleSelectedFolder(data)),
  loadUpdateContentFolder: (data) => dispatch(loadUpdateContentFolder(data)),
});

const stateToProps = (state) => ({
  userChannels: userChannels(state),
});

const Folders = ({ ...props }) => {
  const { packName } = props;
  const createPackFormRef = useRef();
  const [controlModalOpen, setControlModalOpen] = useState(false);
  const [packDeleteDialogOpen, setPackDeleteDialogOpen] = useState(false);
  const [selectedUserChannel, setSelectedUserChannel] = useState();
  const [allUserChannelsSelected, setAllUserChannelsSelected] = useState(true);
  const [concernedFolders, setConcernedFolders] = useState({data: []});
  const [ranksAreInSync, setRanksAreInSync] = useState(false);

  useEffect(() => {
    return () => {
      props.loadResetContentFolders();
    };
  }, []);

  useEffect(() => {
    const localAreRanksInSync = areRanksInSync(props.contentFolders.data);
    setRanksAreInSync(localAreRanksInSync);

    setConcernedFolders({
      ...props.contentFolders,
      data: props.contentFolders.data.filter(contentFolder => {
        return !selectedUserChannel || contentFolder.channels.data.map(channel => channel.kee2_identifier).includes(selectedUserChannel.kee2_identifier);
      }).map(contentFolder => {
        return {
          ...contentFolder,
          rank: localAreRanksInSync ? syncedRankOfFolder(contentFolder) :
                                  (selectedUserChannel ? contentFolder.channels.data.filter(channel => {
                                    return channel.kee2_identifier === selectedUserChannel.kee2_identifier;
                                  }).map(channel => channel.rank)[0] :
                                  0),
        }
      })
    });

  }, [props.contentFolders, selectedUserChannel]);

  const areRanksInSync = (folders) => {
    for( let i = 0; i < folders.length; i++ ) {
      if( folders[i].channels.data.length === 0 ) {
        return false;
      }

      let firstRank = folders[i].channels.data[0].rank;

      for( let j = 0; j < folders[i].channels.data.length; j++ ) {
        if( folders[i].channels.data[j].rank !== firstRank ) {
          return false;
        }
      }
    }

    return folders.length > 0;
  };

  const syncedRankOfFolder = (folder) => {
    return folder.channels.data[0].rank;
  }

  const handleSelectAllUserChannels = (selected) => {
    setAllUserChannelsSelected(selected);
  };

  const handleControlModalOpen = (uid) => {
    setControlModalOpen(!controlModalOpen);
    props.controlModalIsOpened(!controlModalOpen, uid);
  };

  const handlePackDeletionDialogueOpen = () => {
    setPackDeleteDialogOpen(!packDeleteDialogOpen);
  };

  const handlePackDeletionAgree = () => {
    const reorderedRemainingFolders = refreshOrder(concernedFolders.data.filter(folder => !folder.selected));

    console.log(reorderedRemainingFolders);

    reorderedRemainingFolders.forEach((folder) => {
      if( folder.isDirty ) {
        props.loadUpdateContentFolder({
          tenantId: props.tenantId,
          contentTypeUid: props.contentType.uid,
          folderUid: folder.uid,
          params: toContentFolderDTO(folder),
          reloadFolders: false,
        });

        folder.isDirty = false;
      }
    });

    props.handleSelectedPacksDeletion();

    setPackDeleteDialogOpen(false);
  };

  const handlePackDeletionDisagree = () => {
    setPackDeleteDialogOpen(false);
  };

  const handlePackEnabledSwitchChange = (contentFolder, enabled) => {
    props.loadUpdateContentFolder({
      tenantId: props.tenantId,
      contentTypeUid: props.contentType.uid,
      folderUid: contentFolder.uid,
      params: toContentFolderDTO({
        ...contentFolder,
        enabled: enabled,
      }),
      reloadFolders: false,
    });
  };

  const handleFolderSelectionChange = (id, selected) => {
    setTimeout(() => {
      props.toggleSelectedFolder({id, selected});
    }, 350);
  }

  const getFolderStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle
  })

  const handleMoveUp = (index) => {
    reorderFolders(
      props.contentFolders.data,
      index,
      index - 1
    );
  };

  const handleMoveDown = (index) => {
    reorderFolders(
      props.contentFolders.data,
      index,
      index + 1
    );
  };

  const reorderFolders = (folders, sourceIndex, destinationIndex) => {
    const reorderedFolders = reorderByRank(
      concernedFolders.data,
      sourceIndex,
      destinationIndex
    );

    reorderedFolders.forEach((folder) => {
      if( folder.isDirty ) {
        props.loadUpdateContentFolder({
          tenantId: props.tenantId,
          contentTypeUid: props.contentType.uid,
          folderUid: folder.uid,
          params: toContentFolderDTO(folder),
          reloadFolders: false,
        });

        folder.isDirty = false;
      }
    });
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    reorderFolders(
      props.contentFolders.data,
      result.source.index,
      result.destination.index
    );
  };

  const toContentFolderDTO = (contentFolder) => {
    const keywords = [];
    if(!Array.isArray(contentFolder.keywords)) {
      for(const key in contentFolder.keywords) {
        keywords.push(key);
      }
    }

    const revenueChannels = contentFolder.channels.data.map(channel => {
      return {
        id: channel.id,
        rank: (((selectedUserChannel && channel.id === selectedUserChannel.id) || (ranksAreInSync && allUserChannelsSelected)) && contentFolder.rank != null) ?
          contentFolder.rank :
          channel.rank,
      };
    });

    return {...contentFolder, keywords, revenueChannels};
  }

  return (
    <Box>
      <Box sx={{ mt: 12, mb: 8 }}>
        <ControlHeader handleControlModalOpen={() => handleControlModalOpen()}
                       handlePackDeletionDialogueOpen={() => handlePackDeletionDialogueOpen()}
                       contentFolders={props.contentFolders}
                       userChannels={props.userChannels}
                       selectedUserChannel={selectedUserChannel}
                       setSelectedUserChannel={(channel) => setSelectedUserChannel(channel)}
                       allUserChannelsSelected={allUserChannelsSelected}
                       handleSelectAllUserChannels={(selected) => handleSelectAllUserChannels(selected)} />
      </Box>

      {props.contentFolders.isLoading === true ? (
        <Box sx={{ width: 1, display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <CircularProgress id="spinner_folders" size="100px" color="primary" />
        </Box>
      ) : (
        <Box>
          {(ranksAreInSync || selectedUserChannel) ?
            (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <Box {...provided.droppableProps}
                         ref={provided.innerRef}
                         sx={{ display: 'flex', flexDirection: 'column' }}>
                      {concernedFolders.data.sort((a, b) => {
                        if( a.rank > b.rank ) {
                          return 1;
                        }
                        if( a.rank < b.rank ) {
                          return -1;
                        }
                        return 0;
                      }).map((contentFolder, index) => {
                        return (
                          <Draggable key={`folder_${contentFolder.uid}`} draggableId={`draggable_${contentFolder.uid}`} index={index}>
                            {(provided, snapshot) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                   style={getFolderStyle(
                                     snapshot.isDragging,
                                     provided.draggableProps.style
                                   )}>
                                <Folder index={index}
                                        rank={contentFolder.rank}
                                        isDraggable={true}
                                        id={`folder_${index}`}
                                        contentFolder={contentFolder}
                                        handleControlModalOpen={(uid) => handleControlModalOpen(uid)}
                                        handlePackEnabledSwitchChange={(id, checked) => handlePackEnabledSwitchChange(id, checked)}
                                        handleFolderSelectionChange={(id, selected) => handleFolderSelectionChange(id, selected)}
                                        contentFolders={props.contentFolders}
                                        handleMoveUp={handleMoveUp}
                                        handleMoveDown={handleMoveDown} />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}

                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            )
            :
            (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {concernedFolders.data.sort((a, b) => {
                  if( a.rank > b.rank ) {
                    return 1;
                  }
                  if( a.rank < b.rank ) {
                    return -1;
                  }
                  return 0;
                }).map((contentFolder, index) => {
                  return (
                    <Folder index={index}
                            key={index}
                            rank={contentFolder.rank}
                            isDraggable={false}
                            id={`folder_${index}`}
                            contentFolder={contentFolder}
                            handleControlModalOpen={(uid) => handleControlModalOpen(uid)}
                            handlePackEnabledSwitchChange={(id, enabled) => handlePackEnabledSwitchChange(id, enabled)}
                            handleFolderSelectionChange={(id, selected) => handleFolderSelectionChange(id, selected)}
                            contentFolders={props.contentFolders}
                            handleMoveUp={handleMoveUp}
                            handleMoveDown={handleMoveDown} />
                  );
                })}
              </Box>
            )
          }
        </Box>
      )}

      {!props.contentFolders.isLoading && props.contentFolders.data.length === 0 &&
        <Card id="card_add_first_pack" sx={{ mt: 8, display: 'flex', flexDirection: 'column', width: 1, p: 0 }}>
          <CardHeader titleTypographyProps={{sx: { width: 1,
                                                   color: 'text.primary',
                                                   fontWeight: 'regular',
                                                   pb: 3,
                                                   pl: 4,
                                                   borderBottom: 2,
                                                   borderColor: 'background.dark'}}}
                      title={`Add your first ${packName} pack.`}
                      sx={{ px: 0  }} />

          <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button id="button_add_first_pack" variant="contained" color="primary"
                    onClick={() => handleControlModalOpen()}>
              New {packName} Pack <AddCircle sx={{ ml: 4 }} />
            </Button>
          </CardContent>
        </Card>
      }

      <ControlModal id={`modal_control`}
                    packName={packName}
                    open={controlModalOpen}
                    handleControlModalOpen={() => handleControlModalOpen()}
                    createPackFormRef={createPackFormRef}
                    contentFolder={props.contentFolder}
                    loadUpdateContentFolder={props.loadUpdateContentFolder}
                    toContentFolderDTO={toContentFolderDTO}
                    tenantId={props.tenantId}
                    contentType={props.contentType} />

      <AlertDialog id={`dialog_delete_packs`}
                   open={packDeleteDialogOpen}
                   handleAgree={() => handlePackDeletionAgree()}
                   handleDisagree={() => handlePackDeletionDisagree()}
                   title="Are you sure you want to delete the selected packs?"
                   body="Deleting these packs will only detach the assets within them, not remove them completely." />
    </Box>
  );
};

export default connect(stateToProps, actionsToProps)(Folders);
