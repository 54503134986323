import connect from "react-redux/es/connect/connect";
import {Box, Checkbox, Divider, FormControlLabel, FormGroup, Typography} from "@mui/material";
import React, {useState} from "react";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import Timeline from '@mui/icons-material/Timeline';
import {StackedBarChart, StackedLineChart} from "@mui/icons-material";
import {BarChart} from "react-feather";

const RetentionDashboardDaysAxisControl = ({ axis, setAxisItems, ...props }) => {
  const [selectedItems, setSelectedItems] = useState(axis.items);

  const handleItemsSelectionChanged = item => {
    const newItems = selectedItems.includes(item) ?
      selectedItems.filter(i => i !== item) :
      [item, ...selectedItems];

    setSelectedItems(newItems);
    setAxisItems({
      items: newItems,
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
      <FormGroup>
        <Typography variant="overline" sx={{ color: 'text.secondary' }}>App retention</Typography>

        <Divider />

        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControlLabel label="Day 1" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('app_retention_day_1')} checked={selectedItems.includes('app_retention_day_1')} />
          } />

          <FormControlLabel label="Day 2" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('app_retention_day_2')} checked={selectedItems.includes('app_retention_day_2')} />
          } />

          <FormControlLabel label="Day 3" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('app_retention_day_3')} checked={selectedItems.includes('app_retention_day_3')} />
          } />

          <FormControlLabel label="Day 4" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('app_retention_day_4')} checked={selectedItems.includes('app_retention_day_4')} />
          } />

          <FormControlLabel label="Day 5" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('app_retention_day_5')} checked={selectedItems.includes('app_retention_day_5')} />
          } />

          <FormControlLabel label="Day 6" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('app_retention_day_6')} checked={selectedItems.includes('app_retention_day_6')} />
          } />

          <FormControlLabel label="Day 7" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('app_retention_day_7')} checked={selectedItems.includes('app_retention_day_7')} />
          } />
        </Box>
      </FormGroup>

      <FormGroup sx={{ mt: 4 }}>
        <Typography variant="overline" sx={{ color: 'text.secondary' }}>Keyboard retention</Typography>

        <Divider />

        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControlLabel label="Day 1" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('keyboard_retention_day_1')} checked={selectedItems.includes('keyboard_retention_day_1')} />
          } />

          <FormControlLabel label="Day 2" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('keyboard_retention_day_2')} checked={selectedItems.includes('keyboard_retention_day_2')} />
          } />

          <FormControlLabel label="Day 3" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('keyboard_retention_day_3')} checked={selectedItems.includes('keyboard_retention_day_3')} />
          } />

          <FormControlLabel label="Day 4" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('keyboard_retention_day_4')} checked={selectedItems.includes('keyboard_retention_day_4')} />
          } />

          <FormControlLabel label="Day 5" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('keyboard_retention_day_5')} checked={selectedItems.includes('keyboard_retention_day_5')} />
          } />

          <FormControlLabel label="Day 6" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('keyboard_retention_day_6')} checked={selectedItems.includes('keyboard_retention_day_6')} />
          } />

          <FormControlLabel label="Day 7" sx={{ color: 'text.primary' }} control={
            <Checkbox onChange={() => handleItemsSelectionChanged('keyboard_retention_day_7')} checked={selectedItems.includes('keyboard_retention_day_7')} />
          } />
        </Box>
      </FormGroup>
    </Box>
  );
}

export default RetentionDashboardDaysAxisControl;