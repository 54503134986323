import connect from "react-redux/es/connect/connect";
import {Box, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import React from "react";
import {Facebook, Twitter, YouTube} from "@mui/icons-material";

const stateToProps = state => ({});

const actionsToProps = dispatch => ({});

const SocialSetup = ({socialSetupFormik, errors, ...props}) => {
  return (
    <form noValidate onSubmit={socialSetupFormik.handleSubmit}>
      <Grid container={true} spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ mb: 6 }}>
            <Typography variant="body2">Twitter account name without "@".</Typography>

            <TextField error={Boolean(socialSetupFormik.touched.twitterId && socialSetupFormik.errors.twitterId)}
                       fullWidth
                       autoFocus
                       helperText={socialSetupFormik.touched.twitterId && socialSetupFormik.errors.twitterId}
                       label="Twitter account name"
                       margin="normal"
                       name="twitterId"
                       onBlur={socialSetupFormik.handleBlur}
                       onChange={socialSetupFormik.handleChange}
                       type="text"
                       value={socialSetupFormik.values.twitterId}
                       variant="outlined"
                       InputProps={{
                         startAdornment: (
                           <InputAdornment position="start">
                             <Twitter />
                           </InputAdornment>
                         ),
                       }}/>
          </Box>

          <Box sx={{ mb: 6 }}>
            <Typography variant="body2">YouTube channel ID.</Typography>

            <TextField error={Boolean(socialSetupFormik.touched.youtubeId && socialSetupFormik.errors.youtubeId)}
                       fullWidth
                       helperText={socialSetupFormik.touched.youtubeId && socialSetupFormik.errors.youtubeId}
                       label="YouTube channel ID"
                       margin="normal"
                       name="youtubeId"
                       onBlur={socialSetupFormik.handleBlur}
                       onChange={socialSetupFormik.handleChange}
                       type="text"
                       value={socialSetupFormik.values.youtubeId}
                       variant="outlined"
                       InputProps={{
                         startAdornment: (
                           <InputAdornment position="start">
                             <YouTube />
                           </InputAdornment>
                         ),
                       }}/>
          </Box>

          <Box>
            <Typography variant="body2">You can use an online scraper to get a Facebook RSS Feed URL, like rss.app, and add the URL.</Typography>

            <TextField error={Boolean(socialSetupFormik.touched.facebookId && socialSetupFormik.errors.facebookId)}
                       fullWidth
                       helperText={socialSetupFormik.touched.facebookId && socialSetupFormik.errors.facebookId}
                       label="Facebook RSS feed URL"
                       margin="normal"
                       name="facebookId"
                       onBlur={socialSetupFormik.handleBlur}
                       onChange={socialSetupFormik.handleChange}
                       type="text"
                       value={socialSetupFormik.values.facebookId}
                       variant="outlined"
                       InputProps={{
                         startAdornment: (
                           <InputAdornment position="start">
                             <Facebook />
                           </InputAdornment>
                         ),
                       }}/>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default connect(stateToProps, actionsToProps)(SocialSetup);