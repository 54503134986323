export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

export const COOKIE_NAMES = {
  SESSION: 'tappa_cms_session',
  XSRF_TOKEN: 'XSRF-TOKEN',
};

export const LOCAL_STORAGE_NAMES = {
  TWO_FACTOR_SET: 'twoFactorSet',
  TWO_FACTOR_VALIDATED: 'twoFactorValidated',
  LOGIN_AUTHENTICATED: 'loginAuthenticated',
  LOGIN_EMAIL_VERIFIED: 'loginEmailVerified',
};

export const RESEND_EMAIL_CONFIRMATION_THROTTLE_INTERVAL = 10;