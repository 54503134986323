import {createAction} from 'redux-actions';
import * as COMMON from './types';

const appInitialize = createAction(COMMON.APP_INITIALIZE);
const appLoadSetStatusCode = createAction(COMMON.APP_LOAD_SET_STATUS_CODE, payload => payload);
const appLoadTranslations = createAction(COMMON.APP_LOAD_TRANSLATIONS, payload => payload);

const uiToggleMenu = createAction(COMMON.UI_MENU_TOGGLE, (target = null, options = {}, content = null) => ({
  target,
  options,
  content
}));
const uiToggleModal = createAction(COMMON.UI_MODAL_TOGGLE, (target = null, options = {}, content = null) => ({
  target,
  options,
  content
}));
const uiUpdatePreference = createAction(COMMON.UI_PREFERENCE_UPDATE, (type, value) => ({type, value}));
const uiRedirect = createAction(COMMON.UI_REDIRECT, (pathname, options = {keephash: true, keepsearch: true}) => {
  if (typeof pathname === 'string') {
    return {pathname, options};
  }
  if (typeof pathname === 'object') {
    return {...pathname, options};
  }

  throw new Error('Invalid payload data');
});
const uiReplace = createAction(COMMON.UI_REPLACE, (pathname, options = {keephash: true, keepsearch: false}) => {
  if (typeof pathname === 'string') {
    return {pathname, options};
  }
  if (typeof pathname === 'object') {
    return {...pathname, options};
  }

  throw new Error('Invalid payload data');
});
const uiAddErrorMessage = createAction(COMMON.UI_MESSAGE_ADD, (msg, ttl = 6000, id = null) => {
  id = id || Math.random();
  return {
    type: 'error', msg, ttl, id,
  };
});
const uiToggleFooter = createAction(COMMON.UI_FOOTER_TOGGLE, (target = null, options = {}, content = null) => ({
  target,
  options,
  content
}));

export {
  appInitialize,
  appLoadSetStatusCode,
  appLoadTranslations,
  uiAddErrorMessage,
  uiRedirect,
  uiReplace,
  uiToggleMenu,
  uiToggleModal,
  uiUpdatePreference,
  uiToggleFooter,
};
