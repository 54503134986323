import {COOKIE_NAMES} from "../constants";

export const getCookie = (name, def = null) => {
  const cs = document.cookie.split('; ');
  const cookies = {};

  for (let i = cs.length - 1; i >= 0; i--) {
    const C = cs[i].split('=');
    cookies[C[0]] = C[1];
  }

  return cookies[name] || def;
};

const defaultCookieExpiration = 7 * (1000 * 60 * 60 * 24);// 7 days

export const setCookie = (name, value, ttl = defaultCookieExpiration) => {
  const now = new Date();
  const time = now.getTime();
  const expireTime = time + ttl;

  document.cookie = `${encodeURIComponent(name)}=${value};` + `expires=${new Date(expireTime).toGMTString()}; domain=.${window.location.hostname}; path=/`;
};


export const deleteCookie = (name) => {
  document.cookie = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT` + `; domain=.${window.location.hostname}; path=/`;
};

export const getXsrfValueFromCookie = () => {
  const rawXsrfFromCookie = getCookie(COOKIE_NAMES.XSRF_TOKEN);

  return rawXsrfFromCookie ? decodeURIComponent(rawXsrfFromCookie) : null;
}
