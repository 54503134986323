import * as React from 'react';
import {Box, Typography} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import Pie from "../../../../components/vis/Pie";
import {Insights} from "@mui/icons-material";
import MultiDimensionalRoundedChartSkeleton from "../../../../components/vis/MultiDimensionalRoundedChartSkeleton";

function mapData(data, key) {
  return data.map(d => ({ label: d[key], value: Number(d.percentage) }));
}
function VisGenericPie({
                      sx = {},
                      filters,
                      visConfig
                    }) {
  const hasData = visConfig.data && visConfig.data.length > 0;
  const data = visConfig.data && mapData(visConfig.data, 'result');

  return (
    <Box sx={{ bgcolor: "background.paper", position: 'relative', ...sx }}>
      <Tooltip title={
        <React.Fragment>
          <Typography variant="body2">
            {visConfig.insight_display_prompt}
          </Typography>
        </React.Fragment>
      }>
        <Insights sx={{ position: 'absolute', bottom: '10px', right: '10px', 'z-index': 100, color: 'primary.main' }} />
      </Tooltip>

      {visConfig.isLoading && (
        <MultiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                              animation="wave"
                                              title={visConfig.isLoadingForALongTime ? `${visConfig.insight_name} - working` : visConfig.insight_name} />
      )}

      {!visConfig.isLoading && !hasData && (
        <MultiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                              animation={false}
                                               title={`${visConfig.insight_name} - no data`} />
      )}

      {!visConfig.isLoading && hasData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Pie title={visConfig.insight_name} data={data} height={250} width={250} filters={filters} />
        </Box>
      )}
    </Box>
  );
}

export default VisGenericPie;
