import {Box, CircularProgress, IconButton, InputBase, Typography} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import {CheckCircle, Delete} from "@mui/icons-material";
import React from "react";

const stateToProps = (state) => ({});

const GiphyChannel = (props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ width: 1/2, display: 'flex', flexDirection: 'column' }}>
        <Typography id={`label_giphy_channel_${props.index}`} sx={{ fontWeight: 'medium', mb: 3 }}>Channel {props.index + 1}</Typography>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InputBase id={`input_giphy_channel_${props.index}`}
                     type="text"
                     variant="outlined"
                     size={"small"}
                     value={props.giphyChannel.name}
                     disabled={true}
                     sx={{ bgcolor: 'background.default',
                           border: 1,
                           borderRadius: 0.5,
                           borderColor: props.giphyChannel.validationPassed || props.giphyChannel.validationPassed == null ? 'secondary.dark' : 'danger.main',
                           color: 'text.secondary',
                           px: 4,
                           py: 1.5,
                           width: 1,
                           '&:focus': {
                             borderColor: 'secondary.main',
                     }}}
                     onChange={(event) => {props.setName(event.target.value)}}
                     onBlur={(event) => {props.checkGiphyChannel(event.target.value)}} />

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ bgcolor: 'background.default', mx: 1.5, p: 2.5, borderRadius: '3px', whiteSpace: 'nowrap' }}>
              <Typography id={`label_giphy_channel_gifs_count_${props.index}`} sx={{ textAlign: 'center', fontSize: '0.8rem', color: 'text.secondary' }}>
                {`${props.giphyChannel.gifs_count} gifs`}
              </Typography>
            </Box>

            <Box sx={{bgcolor: 'background.default', mx: 1.5, p: 1.8, borderRadius: '3px'}}>
              <CheckCircle sx={{color: 'success.main', fontSize: 20}}/>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {props.giphyChannel.isLoading ?
          <Box>
            <CircularProgress id={`spinner_giphy_channel_${props.index}`} size="56px" color="primary"/>
          </Box> :
          <Box sx={{ width: 144, height: 99, display: 'flex', justifyContent: 'center' }}>
            <img id={`img_preview_giphy_channel_${props.index}`}
                 src={props.giphyChannel.preview_url}
                 style={{ width: 144, height: 99, objectFit: 'cover' }} />
          </Box>
        }

        <Box sx={{ width: 68  , ml: 8 }}>
          <IconButton id={`button_delete_giphy_channel_${props.index}`} aria-label="store"
                      onClick={() => props.handleDeleteGiphyChannel(props.index, props.index)}>
            <Delete sx={{ fontSize: 28 }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default connect(stateToProps)(GiphyChannel)