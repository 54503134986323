import connect from "react-redux/es/connect/connect";
import {Box, Button, Card, CardContent, CircularProgress, Grid, Link, TextField, Typography} from "@mui/material";
import GuestPage from "../../../components/GuestPage";
import {Link as RouterLink} from "react-router-dom";
import Logo from "../../../components/vectors/Logo";
import React, {useEffect} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {registrationErrors, registrationIsLoading} from "../store/selectors";
import {loadRegister} from "../store/actions";

const stateToProps = state => ({
  registrationIsLoading: registrationIsLoading(state),
  registrationErrors: registrationErrors(state),
});

const actionsToProps = dispatch => ({
  loadRegister: data => dispatch(loadRegister(data)),
});

const Register = (props) => {
  useEffect(() => {
    for (const field in props.registrationErrors) {
      if (Object.prototype.hasOwnProperty.call(props.registrationErrors, field)) {
        registrationFormik.setFieldError(field, props.registrationErrors[field][0]);
      }
    }
  }, [props.registrationErrors]);
  
  const registrationFormik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      submit: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().max(255).required('Name is required'),
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      password: Yup.string().min(8).max(255).required('Password is required'),
      password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: async (values) => {
      props.loadRegister({
        name: values.name,
        email: values.email,
        password: values.password,
        password_confirmation: values.password_confirmation,
      });
    },
  });

  return (
    <GuestPage title="register">
      <Box sx={{ width: 1, height: 1, minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ maxWidth:'sm' }}>
          <Box mb={8} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo/>
            </RouterLink>
          </Box>

          <Card variant="outlined">
            <CardContent>
              <Box mb={3}>
                <Typography variant="h2" sx={{ fontWeight: 'regular', color: 'text.primary' }}>
                  Register
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  Create an account for your Tappa Content Management System.
                </Typography>
              </Box>

              <Box flexGrow={1} mt={3}>
                <form noValidate onSubmit={registrationFormik.handleSubmit}>
                  <Grid container={true} spacing={2}>
                    <Grid item md={12} xs={12}>
                      <TextField error={Boolean(registrationFormik.touched.name && registrationFormik.errors.name)}
                                 fullWidth
                                 autoFocus
                                 helperText={registrationFormik.touched.name && registrationFormik.errors.name}
                                 label="Name"
                                 margin="normal"
                                 name="name"
                                 onBlur={registrationFormik.handleBlur}
                                 onChange={registrationFormik.handleChange}
                                 type="text"
                                 value={registrationFormik.values.name}
                                 variant="outlined" />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextField error={Boolean(registrationFormik.touched.email && registrationFormik.errors.email)}
                                 fullWidth
                                 helperText={registrationFormik.touched.email && registrationFormik.errors.email}
                                 label="Email Address"
                                 margin="normal"
                                 name="email"
                                 onBlur={registrationFormik.handleBlur}
                                 onChange={registrationFormik.handleChange}
                                 type="email"
                                 value={registrationFormik.values.email}
                                 variant="outlined" />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextField error={Boolean(registrationFormik.touched.password && registrationFormik.errors.password)}
                                 fullWidth
                                 helperText={registrationFormik.touched.password && registrationFormik.errors.password}
                                 label="Password"
                                 margin="normal"
                                 name="password"
                                 onBlur={registrationFormik.handleBlur}
                                 onChange={registrationFormik.handleChange}
                                 type="password"
                                 value={registrationFormik.values.password}
                                 variant="outlined" />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextField error={Boolean(registrationFormik.touched.password_confirmation && registrationFormik.errors.password_confirmation)}
                                 fullWidth
                                 helperText={registrationFormik.touched.password_confirmation && registrationFormik.errors.password_confirmation}
                                 label="Password confirmation"
                                 margin="normal"
                                 name="password_confirmation"
                                 onBlur={registrationFormik.handleBlur}
                                 onChange={registrationFormik.handleChange}
                                 type="password"
                                 value={registrationFormik.values.password_confirmation}
                                 variant="outlined" />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        <Link variant="body1" color="textPrimary" to="/login" component={RouterLink} sx={{ mr: 4 }}>
                          Already have an account? You can login here
                        </Link>

                        <Button color="primary" disabled={registrationFormik.isSubmitting || props.loginIsLoading} size="large" type="submit" variant="contained">
                          Register

                          {props.registrationIsLoading && (
                            <CircularProgress sx={{ ml: 2 }} size="26px" color="secondary" />
                          )}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </GuestPage>
  );
}

export default connect(stateToProps, actionsToProps)(Register);