import connect from "react-redux/es/connect/connect";
import {Box, Breadcrumbs, Link, Tabs, Tab, CircularProgress} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Page from "../../../components/Page";
import {
  loadReportDescriptors,
  setReportFilters,
  setReportGroupings,
  setReportOrderings,
  setReportRowsLimit
} from "../store/actions";
import {reportDescriptors, reportFilters, reportGroupings, reportOrderings, reportRowsLimit} from "../store/selectors";
import TabPanel from "../../../components/TabPanel";
import ReportSettings from "../subcomponents/ReportSettings";
import ReportGrid from "../subcomponents/ReportGrid";
import {
  clientsLoadOrganization,
  clientsLoadOrganizations,
  clientsLoadResetOrganization
} from "../../clients/store/actions";

const stateToProps = (state) => ({
  reportDescriptors: reportDescriptors(state),
  reportFilters: reportFilters(state),
  reportGroupings: reportGroupings(state),
  reportOrderings: reportOrderings(state),
  reportRowsLimit: reportRowsLimit(state),
});

const actionToProps = (dispatch) => ({
  clientsLoadOrganization: (payload) => dispatch(clientsLoadOrganization(payload)),
  clientsLoadOrganizations: (payload) => dispatch(clientsLoadOrganizations(payload)),
  clientsLoadResetOrganization: (payload) => dispatch(clientsLoadResetOrganization(payload)),
  loadReportDescriptors: (data) => dispatch(loadReportDescriptors(data)),
  setReportFilters: (data) => dispatch(setReportFilters(data)),
  setReportGroupings: (data) => dispatch(setReportGroupings(data)),
  setReportOrderings: (data) => dispatch(setReportOrderings(data)),
  setReportRowsLimit: (data) => dispatch(setReportRowsLimit(data)),
});

const ContentUsage = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tabSelection, setTabSelection] = useState('');

  useEffect(() => {
    props.loadReportDescriptors({
      tenantId: props.match.params.clientId
    });
  }, []);

  const handleTabChange = (e, value) => {
    props.setReportGroupings({
      reportGroupings: props.reportGroupings.map(reportGrouping => {
        return {
          ...reportGrouping,
          groupings: [],
        }
      })
    });
    props.setReportOrderings({
      reportOrderings: props.reportOrderings.map(reportOrdering => {
        return {
          ...reportOrdering,
          orderings: [],
        }
      })
    });
    props.setReportFilters({
      reportFilters: props.reportFilters.map(reportFilter => {
        return {
          ...reportFilter,
          filters: reportFilter.filters.map(filter => {
            return {
              ...filter,
              value: null,
            };
          })
        };
      })
    });
    props.setReportRowsLimit({
      reportRowsLimit: props.reportRowsLimit.map(reportRowsLimit => {
        return {
          key: reportRowsLimit.key,
        }
      })
    });

    setTabIndex(value);
    setTabSelection(e.target.textContent);
  };

  const breadCrumbs =
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to="#" component={RouterLink}>
        Reporting
      </Link>

      <Link variant="body1" to="#" component={RouterLink}>
        Content Usage
      </Link>
    </Breadcrumbs>;

  return (
    <Page title="Reporting" breadCrumbs={breadCrumbs}>
      {!props.reportDescriptors.isLoading && (
        <Box mt={3}>
          <Tabs selectionFollowsFocus
                value={tabIndex}
                onChange={handleTabChange}
                style={{
                  borderBottom: "1px solid #ddd",
                  minHeight: 40,
                }}>
            {props.reportDescriptors.data.length > 0 && props.reportDescriptors.data.map((reportDescriptor, reportDescriptorIndex) => (
              <Tab id={`tab_button_${reportDescriptorIndex}`}
                   index={reportDescriptorIndex}
                   key={`tab_button_${reportDescriptorIndex}`}
                   label={reportDescriptor.name}
                   sx={{ px: 12, color: 'text.primary', textTransform: 'capitalize' }}>
              </Tab>
            ))}
          </Tabs>

          {props.reportDescriptors.data.length > 0 && props.reportDescriptors.data.map((reportDescriptor, reportDescriptorIndex) => (
            <TabPanel value={tabIndex} index={reportDescriptorIndex} name={reportDescriptor.name} key={`tab_panel_${reportDescriptorIndex}`}>
              <Box sx={{ display: 'flex', pt: 8 }}>
                <Box sx={{ width: '300px' }}>
                  <ReportSettings reportDescriptor={reportDescriptor}
                                  reportFilters={props.reportFilters}
                                  reportGroupings={props.reportGroupings}
                                  reportOrderings={props.reportOrderings}
                                  reportRowsLimit={props.reportRowsLimit}
                                  setReportFilters={props.setReportFilters}
                                  setReportGroupings={props.setReportGroupings}
                                  setReportOrderings={props.setReportOrderings}
                                  setReportRowsLimit={props.setReportRowsLimit}
                                  tenantId={props.match.params.clientId} >
                  </ReportSettings>
                </Box>

                <Box sx={{ flex: '1 1 0%' }}>
                  <ReportGrid reportDescriptor={reportDescriptor}
                              reportFilters={props.reportFilters}
                              reportGroupings={props.reportGroupings}>
                  </ReportGrid>
                </Box>
              </Box>
            </TabPanel>
          ))}
        </Box>
      )}

      {props.reportDescriptors.isLoading && (
        <Box sx={{ mt: 3, width: 1, display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <CircularProgress id="spinner_folders" size="100px" color="primary" />
        </Box>
      )}
    </Page>
  );
};

export default connect(stateToProps, actionToProps)(ContentUsage);