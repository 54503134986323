if (!Array.prototype.shuffle) {
  Array.prototype.shuffle = function () {
    var elms, len, idx, tmp;

    if (this === null || this === undefined) {
      throw new TypeError('this is null or undefined');
    }

    // convert this to object
    elms = Object(this);

    // convert length to unsigned 32-bit integer
    len = elms.length >>> 0;

    // fisher-yates shuffle
    while (len) {
      idx = Math.random() * len-- | 0;
      tmp = elms[len];
      elms[len] = elms[idx];
      elms[idx] = tmp;
    }

    return elms;
  }
}

if (!Array.prototype.first) {
  Array.prototype.first = function () {
    if (this === null || this === undefined) {
      throw new TypeError('this is null or undefined');
    }

    if (this.length) return this[0]
    else return null;
  }
}

if (!Array.prototype.sortBy) {
  Array.prototype.propComparator = function (propName, direction) {
    if (direction == 'ASC' || direction == 'asc')
      return (a, b) => {
        return (a[propName] == b[propName]) ? 0 : (a[propName] < b[propName] ? -1 : 1);
      }
    else
      return (a, b) => {
        return (a[propName] == b[propName]) ? 0 : (a[propName] < b[propName] ? 1 : -1);
      }

  }
  Array.prototype.sortBy = function (column, direction = 'ASC') {
    return this.sort(Array.prototype.propComparator(column, direction))
  }
}

if (!Array.prototype.appendArray) {
  Array.prototype.appendArray = function (nextArray, unique = false) {
    if (unique === false)
      return [...this, ...nextArray];
    else
      return [...this, ...nextArray.filter(item => this.indexOf(item) == -1)]
  }
}
