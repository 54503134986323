import {all, fork, put, call, select, takeEvery} from 'redux-saga/effects';
import * as REPORTING from "./types";
import {apiGet, apiPostJson} from "../../../utils/api";
import Observable from 'Observable';

function* onLoadReportDescriptors (action) {
  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiGet(action.payload.tenantId ? `/${action.payload.tenantId}/reports` : '/reports', {})
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then((response) => {
      if (response && !response.errors) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  yield put({
    type: REPORTING.SET_REPORT_DESCRIPTORS,
    payload: { data, message, statusCode },
  });

  yield put({
    type: REPORTING.SET_REPORT_FILTERS,
    payload: {
      reportFilters: data.map((reportDescriptor) => {
        return {
          key: reportDescriptor.key,
          filters: reportDescriptor.availableFilters.map((availableFilter) => {
            return {
              name: availableFilter.name,
              operator: availableFilter.possibleOperators[0],
              value: null,
            };
          }),
        };
      })
    },
  });

  yield put({
    type: REPORTING.SET_REPORT_GROUPINGS,
    payload: {
      reportGroupings: data.map((reportDescriptor) => {
        return {
          key: reportDescriptor.key,
          groupings: [],
        };
      }),
    }
  });

  yield put({
    type: REPORTING.SET_REPORT_ORDERINGS,
    payload: {
      reportOrderings: data.map((reportDescriptor) => {
        return {
          key: reportDescriptor.key,
          orderings: [],
        }
      }),
    }
  });

  yield put({
    type: REPORTING.SET_REPORT_ROWS_LIMIT,
    payload: {
      reportRowsLimit: data.map((reportDescriptor) => {
        return {
          key: reportDescriptor.key,
          limit: undefined,
        }
      }),
    }
  });
}

function* onLoadReport (action) {
  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  const url = action.payload.tenantId ? `/${action.payload.tenantId}/reports/${action.payload.reportKey}` : `/reports/${action.payload.reportKey}`;

  yield apiPostJson(url, {
    filters: action.payload.filters,
    groupings: action.payload.groupings,
    orderings: action.payload.orderings,
    limit: action.payload.limit,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then((response) => {
      if (response && !response.errors) {
        data = response;
      } else {
        message = response.message;
      }
    });

  yield put({
    type: REPORTING.SET_REPORT,
    payload: {data, message, statusCode},
  });
}

function* onLoadReportExcelDownload (action) {
  const retry = 3;
  let statusCode = 0;
  let message = "";

  yield apiPostJson(`/reports/${action.payload.reportKey}`, {
    filters: action.payload.filters,
    groupings: action.payload.groupings,
    orderings: action.payload.orderings,
    limit: action.payload.limit,
  }, {
    emptyHeaders: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/xls',
    }
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .toPromise()
    .then((response) => {
      statusCode = response.status;

      if (response && !response.errors) {
        response.blob().then((blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');

          const now = new Date();
          const day = String(now.getDate()).padStart(2, '0');
          const month = String(now.getMonth() + 1).padStart(2, '0'); //January is 0!
          const year = now.getFullYear();
          const hour = now.getHours();
          const minute = now.getMinutes();
          const second = now.getSeconds();

          link.href = blobUrl;
          link.setAttribute('download', `content_report_${action.payload.reportKey}_${year}_${month}_${day}_${hour}_${minute}_${second}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);

          // clean up Url
          window.URL.revokeObjectURL(blobUrl);
        });
      } else {
        message = response.message;
      }
    });

  yield put({
    type: REPORTING.SET_REPORT_EXCEL_DOWNLOAD,
    payload: {message, statusCode},
  });
}

function* userWatchInitialize () {
  yield takeEvery(REPORTING.LOAD_REPORT_DESCRIPTORS, onLoadReportDescriptors);
  yield takeEvery(REPORTING.LOAD_REPORT, onLoadReport);
  yield takeEvery(REPORTING.LOAD_REPORT_EXCEL_DOWNLOAD, onLoadReportExcelDownload);
}

export default function* sagas() {
  yield fork(userWatchInitialize);
}