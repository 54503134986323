import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";
import PropTypes from "prop-types";

const AlertDialog = ({
                       open,
                       handleAgree,
                       handleDisagree,
                       title,
                       body,
                       ...rest
                     }) => {

  return (
    <Box>
      <Dialog open={open}
              onClose={handleDisagree}
              aria-labelledby="alert_dialog_title"
              aria-describedby="alert_dialog_description">
        <DialogTitle id="alert_dialog_title">
          {title}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert_dialog_description">
            {body}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button id="alert_dialog_disagree" color="tertiary" variant="text" onClick={handleDisagree} sx={{ mr: 4 }}>Disagree</Button>
          <Button id="alert_dialog_agree" color="danger" variant="contained" onClick={handleAgree} autoFocus sx={{ color: 'text.white' }}>Agree</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleAgree: PropTypes.func.isRequired,
  handleDisagree: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
};

export default AlertDialog;