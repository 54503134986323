import {Box} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import SelectionFilter from "./filters/SelectionFilter";
import moment from "moment";
import DateFilter from "./filters/DateFilter";
import MultipleSelectionFilter from "./filters/MultipleSelectionFilter";

const stateToProps = (state) => ({});

const actionToProps = (dispatch) => ({});

const monthPossibleValues = () => {
  const distance = 20;
  let values = [];
  let monthsIterator = moment().add(0 - distance, 'M');

  for( let step = 1; step <= distance + 1; step ++ ) {
    values.push(monthsIterator.format('YYYY-MM'));
    monthsIterator.add(1, 'M');
  }

  return values;
}

const yearPossibleValues = () => {
  const distance = 2;
  let values = [];
  let yearsIterator = moment().add(0 - distance, 'Y');

  for( let step = 0; step <= distance; step ++ ) {
    values.push(yearsIterator.format('YYYY'));
    yearsIterator.add(1, 'Y');
  }

  return values;
};

const possibleValuesOf = (filterDescriptor) => {
  switch (filterDescriptor.name) {
    case 'month':
      return monthPossibleValues();
    case 'year':
      return yearPossibleValues();
    default:
      return filterDescriptor.possibleValues ?? [];
  }
};

const ReportFilters = ({filterDescriptors, handleFilterValueChange, handleFilterOperatorChange}) => {
  const filterComponent = (filterDescriptor) => {
    switch( filterDescriptor.name ) {
      case "date_form":
      case "date_to":
        return (
          <DateFilter filterName={filterDescriptor.name}
                      possibleOperators={filterDescriptor.possibleOperators}
                      handleValueChange={(newValue) => handleFilterValueChange(filterDescriptor.name, newValue)}
                      handleOperatorChange={(newOperator) => handleFilterOperatorChange(filterDescriptor.name, newOperator)}>
          </DateFilter>
        );
      case "asset_type":
        return (
          <MultipleSelectionFilter filterName={filterDescriptor.name}
                                   options={filterDescriptor.possibleValues}
                                   handleValueChange={(newValue) => handleFilterValueChange(filterDescriptor.name, newValue)}>
          </MultipleSelectionFilter>
        )
      default:
        return (
          <SelectionFilter filterName={filterDescriptor.name}
                           possibleOperators={filterDescriptor.possibleOperators}
                           possibleValues={possibleValuesOf(filterDescriptor)}
                           handleValueChange={(newValue) => handleFilterValueChange(filterDescriptor.name, newValue)}
                           handleOperatorChange={(newOperator) => handleFilterOperatorChange(filterDescriptor.name, newOperator)}>
          </SelectionFilter>
        );
    }
  };

  return (
    <Box sx={{ mt: 8 }}>
      {filterDescriptors.map(filterDescriptor => (
        <Box key={`filter-${filterDescriptor.name}`} sx={{ pb: 6 }}>
          {filterComponent(filterDescriptor)}
        </Box>
      ))}
    </Box>
  );
};

export default connect(stateToProps, actionToProps)(ReportFilters);

