import {Box, TextField} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import {useState} from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import OperatorSelector from "../OperatorSelector";
import {Clear} from "@mui/icons-material";
import {capitalizeSnakeCase} from "../../../../utils/string";

const stateToProps = (state) => ({});

const actionToProps = (dispatch) => ({});

const DateFilter = ({filterName, possibleOperators, handleValueChange, handleOperatorChange}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (newValue) => {
    setSelectedValue(newValue);
    handleValueChange(newValue);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', left: -40, top: 16 }}>
        <Clear onClick={() => handleChange(null)} sx={{ cursor: 'pointer', color: 'text.secondary', '&:hover': { color: 'text.primary' } }} />
      </Box>

      <Box sx={{ display: 'flex' }}>
        {possibleOperators.length > 1 && (
          <Box sx={{ width: '25%', mr: 2 }}>
            <OperatorSelector filterName={filterName}
                              availableOperators={possibleOperators}
                              handleOperatorChange={handleOperatorChange}>
            </OperatorSelector>
          </Box>
        )}

        <Box sx={{ flex: '1 1 0%' }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={capitalizeSnakeCase(filterName)}
              value={selectedValue}
              onChange={(newValue) => {
                handleChange(newValue.format('YYYY-MM-DD'));
              }}
              renderInput={(params) => <TextField sx={{ width: 1 }} {...params} />} />
          </LocalizationProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default connect(stateToProps, actionToProps)(DateFilter);

