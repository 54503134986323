const convertJSONtoCSV = (jsonData, separator = ',') => {
  if (!jsonData) {
    console.error('Invalid JSON data');
    return null;
  }

  const isArray = Array.isArray(jsonData);
  const data = isArray ? jsonData : [jsonData];

  if (!data.length) {
    console.error('Invalid JSON data');
    return null;
  }

  const flattenObject = (obj, prefix = '') => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const propName = prefix ? `${prefix}.${key}` : key;

      if (Array.isArray(value)) {
        // Join array elements with a comma within the same cell
        acc[propName] = value.join('; ');
      } else if (typeof value === 'object') {
        return { ...acc, ...flattenObject(value, propName) };
      } else {
        // Replace undefined values with an empty string
        const cleanedValue = value !== undefined ? value : '';

        // Replace non-breaking space characters with regular spaces
        acc[propName] = cleanedValue.replace(/\s/g, ' ');
      }

      return acc;
    }, {});
  };

  const flattenData = data.map((row) => flattenObject(row));

  const csvContent =
    'Key' + separator + 'Value\n' +
    flattenData
      .map((row) => Object.entries(row).map(([key, value]) => `${key}${separator}${value}`).join('\n'))
      .join('\n');

  return csvContent;
};


const downloadCSV = (jsonData, fileName) => {
  const csvContent = convertJSONtoCSV(jsonData);

  if (!csvContent) {
    return;
  }

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const downloadLink = document.createElement('a');

  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `${fileName}.csv`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export { convertJSONtoCSV, downloadCSV };