import connect from "react-redux/es/connect/connect";
import { Box } from "@mui/material";

import BiDimensionalRoundedChartSkeleton from "../../../../components/vis/BiDimensionalRoundedChartSkeleton";
import Pie from "../../../../components/vis/Pie";

const stateToProps = ({ insights: {sections: { genders } }}) => {
  return {
    isLoading: genders.isLoading,
    isLoadingForALongTime: genders.isLoadingForALongTime,
    data: genders.data,
  };
};

function mapData(data, key) {
  return data.map(d => ({ label: d[key], value: Number(d.percentage) }));
}

function VisGenders({
                      sx = {},
                      isLoading,
                      isLoadingForALongTime,
                      data,
                      filters,
 }) {
  const hasData = data?.genders && data.genders.value.length > 0;
  const gendersData = data && data.genders && mapData(data.genders.value, 'gender');

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && (
        <BiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                           animation="wave"
                                           title={isLoadingForALongTime ? "Gender - working" : "Gender"} />
      )}

      {!isLoading && !hasData && (
        <BiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                           animation={false}
                                           title="Genders - no data" />
      )}

      {!isLoading && hasData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Pie title="Genders" data={gendersData} height={250} width={250} filters={filters} />
        </Box>
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisGenders);
