import React, {useState} from 'react';

import {Box, Chip, SvgIcon, TextField, Tooltip, Typography} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import ChipList from "./ChipList";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import _ from 'lodash';
import { slugify } from '../utils/converter';
import GiphyLogo from "./vectors/GiphyLogo";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ChipWithInput = ({
	name,
	loading,
	loadItems,
	isSelect,
	options,
	label,
	error,
	helperText,
	hasMore,
	selectedItems,
	setSelectedItems,
	handleOnClickSeeMore,
	showAllKeywords,
	idForChipList,
	createKeyword,
	handleIsTrim,
	fixedChipText,
	disabled,
}) => {
	const [inputValue, setInputValue] = useState('');
	const [isFocused, setIsFocused] = useState(false);

	const checkOptionSelected = (option) => {
		return _.some(selectedItems, v => _.isEqual(v.id, option.id) || _.isEqual(v.label, option.label));
	}

	const removeOption = (option) => {
		const newData = selectedItems.filter(v => !(_.isEqual(v.id, option.id) || _.isEqual(v.label, option.label)));
		setSelectedItems(newData);
	}

	const selectOption = (option) => {
		let newData = [...selectedItems, option];
		setSelectedItems(newData);
	}

	const handleAdd = () => {
		if( inputValue.trim().length > 0 ) {
			let newOption = {
				id: slugify(inputValue),
				label: inputValue,
				custom: true
			};

			if(!checkOptionSelected(newOption)) {
				createKeyword(newOption, selectOption);
				setInputValue('');
			}
		}
	}

	const checkPopupShouldOpen = () => {
		switch (name) {
			case 'keywords':
				return !(inputValue.length < 1);
			default:
				return true;
		}
	}

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Autocomplete disabled={disabled ?? false}
										loading={isFocused && loading}
										freeSolo={!isSelect}
										multiple
										disableClearable
										value={selectedItems}
										options={checkPopupShouldOpen() ? options : []}
										disableCloseOnSelect
										isOptionEqualToValue={(option, value) => option.id === value.id}
										ListboxProps={{
											onScroll: (event) => {
												const listboxNode = event.currentTarget;

												if (hasMore &&
													(Math.ceil(listboxNode.scrollTop + listboxNode.clientHeight) >= listboxNode.scrollHeight)) {
													loadItems(inputValue);
												}
											}
										}}
										inputValue={inputValue}
										renderOption={(option, state) => {
											const isSelected = checkOptionSelected(state);

											return (
												<Box key={option.id} id={option.id} sx={{ display: 'flex', alignItems: 'center' }}>
													<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={isSelected}
																		onClick={() => {
																			if(!isSelected) {
																				selectOption(state);
																			} else {
																				removeOption(state);
																			}
																		}} />
													<Typography>{state.label}</Typography>
												</Box>
											)
										}}
										renderInput={(params) => (
											<Box sx={{ position: 'relative', height: 'auto', my: 2	 }}>
												<TextField {...params} name={name} error={error}
																	 onFocus={() => {setIsFocused(true)}}
																	 onBlur={() => {setIsFocused(false)}}
																	 helperText={helperText}
																	 variant="outlined"
																	 label={label}
																	 size="small"
																	 sx={{ bgcolor: 'background.paper', width: 232 }}
																	 InputProps={{
																		 ...params.InputProps,
																		 endAdornment: (
																			 <React.Fragment>
																				 {isFocused && loading ? <CircularProgress color="inherit" size={20} /> : null}
																				 {params.InputProps.endAdornment}
																			 </React.Fragment>
																		 ),
																	 }} />
												{!isSelect && isFocused && !loading && (inputValue.trim().length > 0) && (
													<Tooltip title='Create new entry'>
														<SvgIcon fontSize="medium" onClick={() => handleAdd()}
																		 sx={{ position: 'absolute',
																			 right: 10,
																			 top: '50%',
																			 transform: 'translateY(-50%)',
																			 cursor: 'pointer',
																			 color: 'text.secondary',
																			 '&:hover': {
																				 color: 'text.primary',
																			 } }}>
															<AddCircleIcon />
														</SvgIcon>
													</Tooltip>
												)}
											</Box>
										)}
										renderTags={() => null}
										onChange={(event, newValue) => {
											if(event.type === 'checkbox') { return; }

											if(event.type === 'keydown') {
												switch( event.key.toLowerCase() ) {
													case 'enter':
														handleAdd();
														return;
														break;
													default:
														return;
												}
											}

											if(event.type === 'click') {
												newValue = options[event.target.dataset.optionIndex];
											}

											if(newValue.id && !checkOptionSelected(newValue)) {
												selectOption(newValue);
											} else {
												removeOption(newValue);
											}

										}}
										onInputChange={(event, value) => {
											if(event?.type === 'change') {
												setInputValue(event.target.value);
												if(loadItems) {
													loadItems(event.target.value, true);
												}
											}
										}}
										sx={{ width: 232 }} />

			{fixedChipText != null &&
				<Chip icon={<Box sx={{ pl: 1 }}><GiphyLogo width='18px' height='18px'/></Box>}
							sx={{ ml: 2, bgcolor: 'text.primary', color: 'text.white', fontWeight: 'medium' }}
							label={fixedChipText} />
			}

			<ChipList chipsData={selectedItems}
								handleDelete={removeOption}
								handleOnClickSeeMore={id => handleOnClickSeeMore(id)}
								showAllKeywords={showAllKeywords}
								id={idForChipList}
								handleIsTrim={(id, value) => handleIsTrim(id, value)} />
		</Box>
	);
};

export default ChipWithInput;
