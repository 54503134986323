import connect from "react-redux/es/connect/connect";
import { Box } from "@mui/material";

import Donut from "@/components/vis/Donut";
import BiDimensionalRoundedChartSkeleton from "../../../../components/vis/BiDimensionalRoundedChartSkeleton";

const stateToProps = ({ insights: {sections: { devices } }}) => {
  return {
    isLoading: devices.isLoading,
    isLoadingForALongTime: devices.isLoadingForALongTime,
    data: devices.data,
  };
};

function mapData(data, key) {
  return data.map(d => ({ label: d[key], value: Number(d.percentage) }));
}

function VisDevices({ sx = {}, isLoading, isLoadingForALongTime, data, filters }) {
  const hasData = data?.device_types && data.device_types.value.length > 0;
  const deviceData = data && data.device_types && mapData(data.device_types.value, 'device_type');

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && (
        <BiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                           animation="wave"
                                           title={isLoadingForALongTime ? "Device Type - working" : "Device Type"} />
      )}

      {!isLoading && !hasData && (
        <BiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                           animation={false}
                                           title="Device Type - no data" />
      )}

      {!isLoading && hasData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Donut title="Device Type" data={deviceData} height={300} width={300} thickness={30} radius={0} filters={filters} />
        </Box>
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisDevices);
