import connect from "react-redux/es/connect/connect";
import {Box, useTheme} from "@mui/material";
import SimpleRectangleVisSkeleton from "../../../../components/vis/SimpleRectangleVisSkeleton";
import React, {useEffect, useState} from "react";
import HorizontalBar from "../../../../components/vis/HorizontalBar";
import XY from "../../../../components/vis/XY";

const stateToProps = ({ insights: {sections: { trendingTypedTerms } }}) => {
  return {
    isLoading: trendingTypedTerms.isLoading,
    isLoadingForALongTime: trendingTypedTerms.isLoadingForALongTime,
    data: trendingTypedTerms.data,
  };
};

function mapValues(data) {
  return data.map((d, i) => ({
    label: d.terms,
    value: d.occurrences,
  }));
}

function VisTrendingTypedTerms({ sx = {}, isLoading, isLoadingForALongTime, data, filters }) {
  const theme = useTheme();
  const [isDrillingDown, setIsDrillingDown] = useState(false);
  const [drillDownTerm, setDrillDownTerm] = useState('');
  const hasTrendingData = data && data.trending && data.trending.length > 0;
  const trendingValues = hasTrendingData && mapValues(data.trending);

  useEffect(() => {
    setIsDrillingDown(false);
  }, [isLoading]);

  const handleBarClicked = (terms) => {
    setIsDrillingDown(true);
    setDrillDownTerm(terms);
  };

  const getDrillDownData = () => {
    if (!trendingValues || !drillDownTerm || !isDrillingDown) {
      return null;
    }

    let targetIndex = -1;
    for (let i = 0; i < trendingValues.length; i++) {
      if (trendingValues[i].label === drillDownTerm) {
        targetIndex = i;
        break;
      }
    }

    if (targetIndex === -1) {
      console.error(`Object with label "${drillDownTerm}" not found in the array.`);
      return null;
    }

    const startIndex = Math.max(0, targetIndex - 2);
    const endIndex = Math.min(trendingValues.length - 1, targetIndex + 2);

    const drillDownTerms = trendingValues.slice(startIndex, endIndex + 1).map(tt => tt.label);

    return drillDownTerms.map(ddt => data.drilldown[ddt]);
  };

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && !isDrillingDown && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "100%" }}
                                    animation="wave"
                                    title={isLoadingForALongTime ? "Trending typing - working" : "Trending typing"} />
      )}

      {!isLoading && !isDrillingDown && !hasTrendingData && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "100%" }}
                                    animation={false}
                                    title="Trending typing - no data" />
      )}

      {!isLoading && !isDrillingDown && hasTrendingData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <HorizontalBar title="Trending typing" width="550" color={theme.palette.vis.histogram.two}
                         yLabel="Words" xLabel="Occurences"
                         data={trendingValues} filters={filters}
                         handleBarClicked={handleBarClicked} />
        </Box>
      )}

      {!isLoading && isDrillingDown && (
        <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
          <XY title={`Trending typing - drilling down`} height={350}
              data={getDrillDownData()}
              handleBackButtonClicked={() => setIsDrillingDown(false)}/>
        </Box>
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisTrendingTypedTerms);