import {createAction} from 'redux-actions';

import * as CLIENTS from './types';

const loadMsg = createAction(CLIENTS.LOAD_MSG);
const setMsg = createAction(CLIENTS.SET_MSG);
const clientsLoadTags = createAction(CLIENTS.LOAD_TAGS);
const clientsLoadCreateTags = createAction(CLIENTS.LOAD_CREATE_TAGS);
const clientsLoadOrganizations = createAction(CLIENTS.LOAD_ORGANIZATIONS, data => data);
const clientsLoadUpdateOrganization = createAction(CLIENTS.LOAD_UPDATE_ORGANIZATION, data => data);
const clientsLoadDeleteOrganization = createAction(CLIENTS.LOAD_DELETE_ORGANIZATION, data => data);
const clientsLoadResetDeleteOrganization = createAction(CLIENTS.LOAD_RESET_DELETE_ORGANIZATION, data => data);
const clientsLoadCreateOrganizations = createAction(CLIENTS.LOAD_CREATE_ORGANIZATION, data => data);
const clientsLoadOrganization = createAction(CLIENTS.LOAD_ORGANIZATION, data => data);
const clientsLoadClients = createAction(CLIENTS.LOAD_CLIENTS, data => data);
const clientsLoadResetOrganization = createAction(CLIENTS.LOAD_RESET_ORGANIZATION);
const clientsLoadUsers = createAction(CLIENTS.LOAD_USERS);
const clientsLoadCreateUsers = createAction(CLIENTS.LOAD_CREATE_USERS);
const clientsLoadTenantUsers = createAction(CLIENTS.LOAD_TENANT_USERS);
const clientsLoadDeleteTenantUsers = createAction(CLIENTS.LOAD_DELETE_TENANT_USERS);
const clientsLoadUpdateTenantUsers = createAction(CLIENTS.LOAD_UPDATE_TENANT_USERS);
const clientsLoadInviteTenantUsers = createAction(CLIENTS.LOAD_INVITE_TENANT_USERS);
const clientsLoadResetTenantUsers = createAction(CLIENTS.LOAD_RESET_TENANT_USERS);
const clientsLoadResetUsers = createAction(CLIENTS.LOAD_RESET_USERS);
const clientsLoadResetMsg = createAction(CLIENTS.LOAD_RESET_MSG);
const clientsLoadStatsReportSeries = createAction(CLIENTS.LOAD_STATS_REPORT_SERIES, data => data);
const clientsLoadResetStatsReportSeries = createAction(CLIENTS.LOAD_RESET_STATS_REPORT_SERIES, data => data);

export {
  loadMsg,
  setMsg,
  clientsLoadTags,
  clientsLoadCreateTags,
  clientsLoadOrganizations,
  clientsLoadUpdateOrganization,
  clientsLoadDeleteOrganization,
  clientsLoadResetDeleteOrganization,
  clientsLoadCreateOrganizations,
  clientsLoadOrganization,
  clientsLoadClients,
  clientsLoadResetOrganization,
  clientsLoadUsers,
  clientsLoadCreateUsers,
  clientsLoadTenantUsers,
  clientsLoadDeleteTenantUsers,
  clientsLoadUpdateTenantUsers,
  clientsLoadInviteTenantUsers,
  clientsLoadResetTenantUsers,
  clientsLoadResetUsers,
  clientsLoadResetMsg,
  clientsLoadStatsReportSeries,
  clientsLoadResetStatsReportSeries,
}
