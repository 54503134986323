import Observable from 'Observable';
import {fork, put, select, take, takeEvery} from 'redux-saga/effects';
import {getValue} from 'AppUtils/objects';
import {apiDelete, apiGet, apiPost, apiPut} from "AppUtils/api";

import * as USER from './types';
import * as AUTH from '../../auth/store/types';
import {userChannelsIds, userInfo} from "./selectors";

import {apiPostJson} from "../../../utils/api";
import {loginSessionId, loginXsrfToken} from "../../auth/store/selectors";

function* onLoadUserInfo(action) {
  const xsrfToken = getValue(action, 'payload.xsrfToken', '') ? action.payload.xsrfToken : yield select(loginXsrfToken);
  const sessionId = getValue(action, 'payload.sessionId', '') ? action.payload.sessionId : yield select(loginSessionId);

  const retry = 3;
  let info = '';
  let statusCode = '';
  let msg = '';

  yield apiGet(`/user`)
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode === 200) {
        info = response.data;
      } else {
        msg = 'Error';
      }
    });

  if (statusCode === 200 && info) {
    yield put({
      type: AUTH.SET_LOGIN,
      payload: {
        sessionId: sessionId,
        xsrfToken: xsrfToken,
        authenticated: true,
        statusCode: statusCode,
      }
    });

    yield put({
      type: USER.USER_SET_INFO,
      payload: {info, statusCode},
    });
  }
}

function* onLoadUpdateUserInfo(action) {
  const settings = getValue(action, 'payload.settings', '');

  const retry = 3;
  let updateInfo = '';
  let statusCode = '';
  let msg = '';

  const response = yield apiPut(`/user`, {settings})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise();

  if (response && !response.message) {
    updateInfo = response;
  } else {
    msg = response.message;
  }

  if (updateInfo) {
    yield put({type: USER.USER_SET_INFO, payload: {info: updateInfo, statusCode}});
  }

  yield put({type: USER.USER_SET_UPDATE_INFO, payload: {updateInfo: msg ? '' : updateInfo, msg, statusCode}});
}

function* onLoadNotifications() {
  const retry = 3;
  let list = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiGet(`/user/notifications`)
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && !response.message) {
        list = response.items;
      } else {
        msg = response.message;
      }
    });

  yield put({type: USER.USER_SET_NOTIFICATIONS, payload: {list, msg, statusCode}});
}

function* onLoadNotificationsConsume(action) {
  const id = getValue(action, 'payload.notificationId', '');
  const callback = getValue(action, 'payload.callback', '') ? action.payload.callback : '';

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiPost(`/user/notifications/consume`, {id})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && !response.message) {
        data = response;
      } else {
        msg = response.message;
      }
    });

  if (!msg) {
    yield put({type: USER.USER_LOAD_NOTIFICATIONS});
    if (callback) {
      yield take(USER.USER_SET_NOTIFICATIONS);
      callback();
    }
  }

  yield put({type: USER.USER_SET_NOTIFICATIONS_CONSUME, payload: {data, msg, statusCode}});
}

function* onLoadProfile(action) {
  const nickname = getValue(action, 'payload.nickname', '');
  const avatarUrl = getValue(action, 'payload.avatarUrl', '');
  const avatarFile = getValue(action, 'payload.avatarFile', '');

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiPost(`/user/profile`, {nickname, avatarUrl, avatarFile})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && !response.message) {
        data = response;
      } else {
        msg = response.message;
      }
    });

  yield put({type: USER.USER_SET_UPDATE_PROFILE, payload: {data, msg, statusCode}});
}

function* onLoadChannels(action) {
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));
  const page = getValue(action, 'payload.page', 1);
  const rows_per_page = getValue(action, 'payload.rows_per_page', 1000000);

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiGet(`/${tenantId}/channels`, {page, rows_per_page})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode == 200) {
        data = response.data;
      } else {
        msg = response.message;
      }
    });

  yield put({type: USER.USER_SET_CHANNELS, payload: {data, msg, statusCode}});
}

function* onLoadOtherChannels(action) {
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));
  const page = getValue(action, 'payload.page', 1);
  const rows_per_page = getValue(action, 'payload.rows_per_page', 1000000);

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiGet(`/${tenantId}/channels`, {page, rows_per_page})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode == 200) {
        data = response.data;
      } else {
        msg = response.message;
      }
    });

  yield put({type: USER.USER_SET_OTHER_CHANNELS, payload: {data, msg, statusCode}});
}

function* onLoadCreateChannels(action) {
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));
  const channel = getValue(action, 'payload.channel', {});

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiPostJson(`/${tenantId}/channels`, {...channel})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode === 201) {
        data = response.data;
      } else {
        msg = {
          msg: response.message,
          errors: response.errors || {}
        };
      }
    });

  yield put({type: USER.USER_SET_CREATE_CHANNELS, payload: {data, msg, statusCode}});
}

function* onLoadChannelsDetails(action) {
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));
  const channelId = getValue(action, 'payload.channelId', '');

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiGet(`/${tenantId}/channels/${channelId}`)
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode == 200) {
        data = response.data;
      } else {
        msg = response.message;
      }
    });

  yield put({type: USER.USER_SET_CHANNELS_DETAILS, payload: {data, msg, statusCode}});
}

function* onLoadUpdateChannels(action) {
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));
  const channelId = getValue(action, 'payload.channelId', '');
  const channel = getValue(action, 'payload.channel', {});

  if (!channel.bucket) {
    delete channel['bucket'];
  }

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiPut(`/${tenantId}/channels/${channelId}`, {...channel})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode == 200) {
        data = 'updated';
      } else {
        msg = {
          msg: response.message,
          errors: response.errors || {}
        };
      }
    });

  yield put({type: USER.USER_SET_UPDATE_CHANNELS, payload: {data, msg, statusCode}});
}

function* onLoadDeleteChannels(action) {
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));
  const channelId = getValue(action, 'payload.channelId', '');

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiDelete(`/${tenantId}/channels/${channelId}`)
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && (statusCode == 200 || statusCode == 201)) {
        data = 'deleted';
      } else {
        msg = response.message;
      }
    });

  yield put({type: USER.USER_SET_DELETE_CHANNELS, payload: {data, msg, statusCode}});
}

function* onLoadUnmappedChannels(action) {
  const tenantId = yield parseTenantId(getValue(action, 'payload.tenantId'));

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiGet(`/${tenantId}/channels-unmapped`)
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode == 200) {
        data = response;
      } else {
        msg = response.message;
      }
    });

  yield put({type: USER.USER_SET_UNMAPPED_CHANNELS, payload: {data, msg, statusCode}});
}

function* parseChannelsIds(payloadChannels) {
  const channelsIds = yield select(userChannelsIds);
  const channels = {};

  if (payloadChannels.length == 0 && channelsIds.length > 0) {
    channelsIds.map((item, idx) => {
      channels[`channels[${idx}]`] = item;
    });
  } else if (payloadChannels.length > 0) {
    payloadChannels.map((item, idx) => {
      channels[`channels[${idx}]`] = item;
    });
  }

  return channels;
}

function* parseChannels(channels) {
  const channelsObj = {};

  if (channels.length > 0) {
    channels.map((item, idx) => {
      if (item != 'all') {
        channelsObj[`channels[${idx}]`] = item;
      }
    });
  }

  return channelsObj;
}

function* parseTenantId(tenantId) {
  const info = yield select(userInfo);
  const url = new URL(window.location);
  const str = url.pathname;
  const res = str.match(/\/clients\/([0-9]*)\//);
  const clientId = res && res[1] ? parseInt(res[1]) : '';
  let selectedTenantId = tenantId || clientId;

  if (!selectedTenantId) {
    selectedTenantId = getValue(info, 'organizations.0.id');
  }

  return selectedTenantId;
}

function getProvider(platform) {
  platform = platform.toLowerCase();
  let provider = '';

  switch (platform) {
    case 'android':
      provider = 'google';
      break;
    case 'ios':
      provider = 'appstore';
      break;
  }

  return provider;
}

function* userWatchInitialize() {
  yield takeEvery(USER.USER_LOAD_UPDATE_INFO, onLoadUpdateUserInfo);
  yield takeEvery(USER.USER_LOAD_INFO, onLoadUserInfo);
  yield takeEvery(USER.USER_LOAD_NOTIFICATIONS, onLoadNotifications);
  yield takeEvery(USER.USER_LOAD_NOTIFICATIONS_CONSUME, onLoadNotificationsConsume);
  yield takeEvery(USER.USER_LOAD_UPDATE_PROFILE, onLoadProfile);
  yield takeEvery(USER.USER_LOAD_CHANNELS, onLoadChannels);
  yield takeEvery(USER.USER_LOAD_OTHER_CHANNELS, onLoadOtherChannels);
  yield takeEvery(USER.USER_LOAD_CREATE_CHANNELS, onLoadCreateChannels);
  yield takeEvery(USER.USER_LOAD_CHANNELS_DETAILS, onLoadChannelsDetails);
  yield takeEvery(USER.USER_LOAD_UPDATE_CHANNELS, onLoadUpdateChannels);
  yield takeEvery(USER.USER_LOAD_DELETE_CHANNELS, onLoadDeleteChannels);
  yield takeEvery(USER.USER_LOAD_UNMAPPED_CHANNELS, onLoadUnmappedChannels);
}


export default function* sagas() {
  yield fork(userWatchInitialize);
}
