import {LOCAL_STORAGE_NAMES} from "../constants";

const localStorage = window.localStorage;

export const getTwoFactorSet = () => {
  return (localStorage.getItem(LOCAL_STORAGE_NAMES.TWO_FACTOR_SET) || false) === 'true';
};

export const setTwoFactorSet = (twoFactorSet) => {
  return localStorage.setItem(LOCAL_STORAGE_NAMES.TWO_FACTOR_SET, twoFactorSet);
};

export const getTwoFactorValidated = () => {
  return (localStorage.getItem(LOCAL_STORAGE_NAMES.TWO_FACTOR_VALIDATED) || false) === 'true';
};

export const setTwoFactorValidated = (twoFactorValidated) => {
  return localStorage.setItem(LOCAL_STORAGE_NAMES.TWO_FACTOR_VALIDATED, twoFactorValidated);
};

export const getLoginAuthenticated = () => {
  return (localStorage.getItem(LOCAL_STORAGE_NAMES.LOGIN_AUTHENTICATED) || false) === 'true';
};

export const setLoginAuthenticated = (loginAuthenticated) => {
  return localStorage.setItem(LOCAL_STORAGE_NAMES.LOGIN_AUTHENTICATED, loginAuthenticated);
};

export const getLoginEmailVerified = () => {
  return (localStorage.getItem(LOCAL_STORAGE_NAMES.LOGIN_EMAIL_VERIFIED) || false) === 'true';
}

export const setLoginEmailVerified = (loginEmailVerified) => {
  return localStorage.setItem(LOCAL_STORAGE_NAMES.LOGIN_EMAIL_VERIFIED, loginEmailVerified);
};