import connect from "react-redux/es/connect/connect";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  LinearProgress
} from "@mui/material";
import {Android, Apple, Cancel, CheckCircle, Facebook, Inbox, Twitter, YouTube} from "@mui/icons-material";

const stateToProps = state => ({});

const actionsToProps = dispatch => ({});

const Review = ({clientCreationData, campaignSetupData, socialSetupData, quicklinks, errors, ...props}) => {
  return (
    <Box sx={{ width: 1 }}>
      <Box sx={{ mb: 8 }}>
        <Typography variant="h5">Client data</Typography>

        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {errors.clientName && (
              <Cancel color="danger" sx={{ width: '20px', height: '20px', mr: 2 }}/>
            )}

            {!errors.clientName && (
              <CheckCircle color="success" sx={{ width: '20px', height: '20px', mr: 2 }}/>
            )}
            <Typography variant="overline" sx={{ color: 'text.secondary' }}>The name of your app</Typography>
          </Box>

          <Box sx={{ px: 6 }}>
            {errors.clientName && (
              <Typography variant="body2" sx={{ color: 'danger.main' }}>{errors.clientName}</Typography>
            )}

            <Typography variant="body1" sx={{ py: 2 }}>{clientCreationData.name}</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ mb: 8 }}>
        <Typography variant="h5">Campaign setup data</Typography>

        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircle color="success" sx={{ width: '20px', height: '20px', mr: 2 }}/>
            <Typography variant="overline" sx={{ color: 'text.secondary' }}>Platform that your app runs on</Typography>
          </Box>

          <List>
            {campaignSetupData.iosCampaign.checked && (
              <ListItem disablePadding sx={{ py: 2, px: 6 }}>
                <ListItemIcon>
                  <Apple />
                </ListItemIcon>
                <ListItemText primary="iOS" />
              </ListItem>
            )}

            {campaignSetupData.androidCampaign.checked && (
              <ListItem disablePadding sx={{ py: 2, px: 6 }}>
                <ListItemIcon>
                  <Android />
                </ListItemIcon>
                <ListItemText primary="Android" />
              </ListItem>
            )}
          </List>
        </Box>

        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircle color="success" sx={{ width: '20px', height: '20px', mr: 2 }}/>
            <Typography variant="overline" sx={{ color: 'text.secondary' }}>Countries that you're more interested in</Typography>
          </Box>

          <List>
            {campaignSetupData.locales.map(locale => {
              const quicklink = quicklinks.find(ql => ql.country_code === locale);

              return (
                <ListItem disablePadding key={locale} sx={{ py: 2, px: 6 }}>
                  <ListItemText primary={quicklink.country_name} />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>

      <Box sx={{ mb: 8 }}>
        <Typography variant="h5">Social media setup</Typography>

        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircle color="success" sx={{ width: '20px', height: '20px', mr: 2 }}/>
            <Typography variant="overline" sx={{ color: 'text.secondary' }}>Your social media platform connections</Typography>
          </Box>

          <List>
            {socialSetupData.twitterId && (
              <ListItem disablePadding sx={{ py: 2, px: 6 }}>
                <ListItemIcon>
                  <Twitter />
                </ListItemIcon>
                <ListItemText primary={socialSetupData.twitterId} />
              </ListItem>
            )}

            {socialSetupData.youtubeId && (
              <ListItem disablePadding sx={{ py: 2, px: 6 }}>
                <ListItemIcon>
                  <YouTube />
                </ListItemIcon>
                <ListItemText primary={socialSetupData.youtubeId} />
              </ListItem>
            )}

            {socialSetupData.facebookId && (
              <ListItem disablePadding sx={{ py: 2, px: 6 }}>
                <ListItemIcon>
                  <Facebook />
                </ListItemIcon>
                <ListItemText primary={socialSetupData.facebookId} />
              </ListItem>
            )}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default connect(stateToProps, actionsToProps)(Review);