import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import './utils/polyfill/';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import configureAppStore from 'AppStore/configureStore';
import App from './App';
import {SettingsProvider} from './contexts/SettingsContext';

import 'react-perfect-scrollbar/dist/css/styles.css';

const appStore = configureAppStore();

ReactDOM.render(
  <Provider store={appStore.store}>
    <SettingsProvider>
      <ConnectedRouter history={appStore.history}>
        <App/>
      </ConnectedRouter>
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);
