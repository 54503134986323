const reportDescriptors = (state) => state.reporting.reportDescriptors;
const reportFilters = (state) => state.reporting.reportFilters;
const reportGroupings = (state) => state.reporting.reportGroupings;
const reportOrderings = (state) => state.reporting.reportOrderings;
const reportRowsLimit = (state) => state.reporting.reportRowsLimit;
const report = (state) => state.reporting.report;
const reportExcel = (state) => state.reporting.reportExcel;

export {
  reportDescriptors,
  reportFilters,
  reportGroupings,
  reportOrderings,
  reportRowsLimit,
  report,
  reportExcel,
};