import connect from "react-redux/es/connect/connect";
import {Box, CircularProgress, IconButton, InputBase, Typography} from "@mui/material";
import {giphyChannel} from "../store/selectors";
import React from "react";
import GifBoxIcon from "@mui/icons-material/GifBox";
import {setGiphyChannelName} from "../store/actions";
import {Check, CheckCircle, Warning} from "@mui/icons-material";

const stateToProps = (state) => ({
  giphyChannel: giphyChannel(state),
});

const actionToProps = (dispatch) => ({
  setName: (name) => dispatch(setGiphyChannelName({name})),
});

const GiphyChannelUploadBox = (props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ width: 1/2, display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontWeight: 'medium', mb: 3 }}>New channel's name</Typography>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InputBase id="input_new_giphy_channel"
                     type="text"
                     variant="outlined"
                     size={"small"}
                     value={props.giphyChannel.data.name}
                     sx={{ bgcolor: 'background.default',
                       border: 1,
                       borderRadius: 0.5,
                       borderColor: props.giphyChannel.validationPassed || props.giphyChannel.validationPassed == null ? 'secondary.dark' : 'danger.main',
                       color: 'text.secondary',
                       px: 4,
                       py: 1.5,
                       width: 1,
                       '&:focus': {
                         borderColor: 'secondary.main',
                       }}}
                     onChange={(event) => {props.setName(event.target.value)}}
                     onBlur={(event) => {props.checkGiphyChannel(event.target.value)}} />

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ bgcolor: 'background.default', mx: 1.5, p: 2.5, borderRadius: '3px', whiteSpace: 'nowrap' }}>
              <Typography sx={{ textAlign: 'center', fontSize: '0.8rem', color: 'text.secondary' }}>
                {props.giphyChannel.isLoading ? (
                  '0 gifs'
                ) : !props.giphyChannel.validationPassed ? (
                  '0 gifs'
                ) : (
                  `${props.giphyChannel.data.gifsCount} gifs`
                )}
              </Typography>
            </Box>
            <Box>
              {(props.giphyChannel.isLoading || props.giphyChannel.validationPassed == null) ?
                (
                  <Box sx={{bgcolor: 'background.default', mx: 1.5, p: 1.8, borderRadius: '3px'}}>
                    <Warning id="icon_warning_neutral_new_giphy_channel" sx={{color: "secondary.main", fontSize: 20}}/>
                  </Box>
                ) :
                (
                  <Box sx={{bgcolor: 'background.default', mx: 1.5, p: 1.8, borderRadius: '3px'}}>
                    {!props.giphyChannel.validationPassed ? (
                      <Warning id="icon_warning_new_giphy_channel" sx={{color: "danger.main", fontSize: 20}}/>
                    ) : (
                      <CheckCircle id="icon_check_new_giphy_channel" sx={{color: 'success.main', fontSize: 20}}/>
                    )}
                  </Box>
                )
              }
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {props.giphyChannel.isLoading ?
          <Box>
            <CircularProgress id="spinner_new_giphy_channel_preview" size="56px" color="primary"/>
          </Box> :
          <Box sx={{ width: 144, height: 99, display: 'flex', justifyContent: 'center' }}>
            {props.giphyChannel.validationPassed ?
              <img id="img_preview_new_giphy_channel" src={props.giphyChannel.data.previewUrl} style={{ width: 144, height: 99, objectFit: 'cover' }} /> :
              <GifBoxIcon id="icon_gif_new_giphy_channel" sx={{width: 99, height: 99, color: 'tertiary.main'}}/>
            }
          </Box>
        }

        <Box sx={{ width: 68  , ml: 8 }}>
          {props.giphyChannel.isUploading ?
            <CircularProgress id="spinner_new_giphy_channel_confirm" size="28px" color="primary"/> :
            <IconButton id="button_new_giphy_channel_confirm" color="primary" aria-label="store"
                        disabled={props.giphyChannel.validationPassed !== true}
                        onClick={() => props.addGiphyChannel(props.giphyChannel.data)}>
              <Check sx={{ fontSize: 28 }} />
            </IconButton>}
        </Box>
      </Box>
    </Box>
  );
}

export default connect(stateToProps, actionToProps)(GiphyChannelUploadBox);