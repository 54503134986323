import connect from "react-redux/es/connect/connect";
import {Box, Grid} from "@mui/material";

import NumberStat from "@/components/vis/NumberStat";
import SingleTextVisSkeleton from "@/components/vis/SingleTextVisSkeleton";
import {FORMAT_DURATION, FORMAT_LONG} from "@/components/vis/NumberStat";

const stateToProps = ({ insights: {sections: { user, keyboardEngagement } }}) => {
  return {
    usersDataIsLoading: user.isLoading,
    usersDataIsLoadingForALongTime: user.isLoadingForALongTime,
    usersData: user.data,
    keyboardEngagementIsLoading: keyboardEngagement.isLoading,
    keyboardEngagementIsLoadingForALongTime: keyboardEngagement.isLoadingForALongTime,
    keyboardEngagementData: keyboardEngagement.data,
  };
};

const usersVis = [
  { label: "Active Users", key: "active" },
  { label: "Returning Users", key: "returning" },
];

const keyboardEngagementVis = [
  { label: "Time Spent in Keyboard", key: "avg_time_spent_in_keyboard", formatter: FORMAT_DURATION },
  { label: "No. CTA Presses", key: "avg_cta_presses_count", formatter: FORMAT_LONG },
];

function VisUsers({
                    usersDataIsLoading,
                    usersDataIsLoadingForALongTime,
                    usersData,
                    keyboardEngagementIsLoading,
                    keyboardEngagementIsLoadingForALongTime,
                    keyboardEngagementData,
                    filters,
                    sx,
                  }) {
  return (
    <Box sx={{ position: "relative", minHeight: "50px" }}>
      <Grid container spacing={4}>
        {usersDataIsLoading && usersVis.map(({ label, key }) => (
          <Grid item xs={6} lg={3} key={'skeleton_' + key}>
            <SingleTextVisSkeleton sx={{ flex: "1 1 0%", bgcolor: "background.paper" }}
                                   animation="wave"
                                   title={usersDataIsLoadingForALongTime ? label + ' - working' : label} />
          </Grid>
        ))}

        {!usersDataIsLoading && usersVis.map(
          ({ label, key }) =>
            usersData &&
            usersData[key] && (
              <Grid item xs={6} lg={3} key={key}>
                <NumberStat
                  value={usersData[key].value}
                  label={label}
                  delta={usersData[key].delta}
                  filters={filters}
                />
              </Grid>
            )
        )}

        {keyboardEngagementIsLoading && keyboardEngagementVis.map(({ label, key, formatter }) => (
          <Grid item xs={6} lg={3} key={'skeleton_' + key}>
            <SingleTextVisSkeleton key={'skeleton_' + key}
                                   sx={{ flex: "1 1 0%", bgcolor: "background.paper" }}
                                   animation="wave"
                                   title={keyboardEngagementIsLoadingForALongTime ? label + ' - working' : label} />
          </Grid>
        ))}

        {!keyboardEngagementIsLoading && keyboardEngagementVis.map(
          ({ label, key, formatter }) =>
            keyboardEngagementData &&
            keyboardEngagementData[key] && (
              <Grid item xs={6} lg={3} key={key}>
                <NumberStat
                  key={key}
                  value={parseFloat(keyboardEngagementData[key].value)}
                  label={label}
                  delta={keyboardEngagementData[key].delta}
                  formatter={formatter}
                  filters={filters}
                />
              </Grid>
            )
        )}
      </Grid>
    </Box>
  );
}

export default connect(stateToProps)(VisUsers);
