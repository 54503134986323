import React, {useRef, useState, useLayoutEffect} from 'react';

import {Box, Chip, SvgIcon} from "@mui/material";
import {makeStyles} from '@mui/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
	gradient: {
		position: "absolute",
		right: 0,
		top: 0,
		height: "100%",
		width: 132,
		background: `linear-gradient(270deg, ${theme.palette.background.dark} 40%, rgba(249,249,249,0) 100%)`,
	},
	seeMoreIcon: {
		zIndex: 2,
		position: "relative",
		padding: "8px 22px",
		boxSizing: "content-box",
		borderRadius: 20,
		backgroundColor: theme.palette.background.default,
		cursor: "pointer",
		color: theme.palette.primary.main
	},
	closeIcon: {
		zIndex: 2,
		position: "relative",
		padding: "8px 22px",
		boxSizing: "content-box",
		borderRadius: 20,
		backgroundColor: theme.palette.primary.main,
		cursor: "pointer",
		color: theme.palette.background.default
		
	},
}));

const ChipList = ({
	chipsData,
	handleDelete,
	handleOnClickSeeMore,
	showAllKeywords,
	id,
}) => {
	const classes = useStyles();
	const listRef = useRef(null);
	const [isTrim, setIsTrim] = useState({});

	useLayoutEffect(() => {
		if (listRef.current !== null) {
			const checkWidth = listRef.current.scrollWidth > listRef.current.offsetWidth;

			setIsTrim({...isTrim, [id]: checkWidth});
		}
	}, [chipsData]);

	const handleSeeMore = (event) => {
		event.preventDefault();
		handleOnClickSeeMore(id);
	}

	const renderChips = () => {
		return chipsData?.map((data) => {
			return (
			  <div key={data.id}>
				  <Chip label={data.label}
								disabled={showAllKeywords === true}
								onDelete={() => handleDelete(data)}
								sx={{ my: 3, mx: 4, bgcolor: 'background.darker', color: 'text.primary',
											'& .MuiChip-deleteIcon': {
												color: 'text.secondary',
												'&:hover': {
													color: 'text.primary',
												}
											} }} />
			  </div>
			);
		})
	}

	return (
	  <Box ref={listRef} sx={{ position: 'relative', display: 'flex', overflow: 'hidden' }}>
			<Box sx={{ display: 'flex' }}>
				{renderChips()}
			</Box>

		  <Box sx={{ position: 'absolute', top: 0, right: 0, height: '100%', display: 'flex', alignItems: 'center' }}>
			  {
				  isTrim[id] === true && (
					  showAllKeywords === true ? (
						  <>
							  <div className={classes.gradient} />
							  <SvgIcon fontSize="medium" className={classes.closeIcon} onClick={handleSeeMore}>
								  <CloseIcon/>
							  </SvgIcon>
						  </>
					  ) : (
						  <>
							  <div className={classes.gradient} />
							  <SvgIcon fontSize="medium" className={classes.seeMoreIcon} onClick={handleSeeMore}>
								  <MoreHorizIcon/>
							  </SvgIcon>
						  </>
					  )
				  )
			  }
		  </Box>
	  </Box>
	);
};

export default ChipList;
