import {Box, Typography} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import {report} from "../store/selectors";
import { DataGrid } from '@mui/x-data-grid';

const generateRandom = () => {
  let length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

const resolveExtraColumns = (availableGrouping) => {
  let finalColumns = availableGrouping.columns;

  switch (availableGrouping.name) {
    case 'asset':
      if( !finalColumns.includes('asset') ) {
        finalColumns.unshift('asset');
      }
      break;
    case 'theme':
      if( !finalColumns.includes('theme') ) {
        finalColumns.unshift('theme');
      }
    default:
      return finalColumns;
  }

  return finalColumns;
};

const cellRendition = (columnName) => {
  switch (columnName) {
    case 'clicks_count':
      return {
        width: 150,
        type: 'number',
      };
    case 'keyboard_opens':
      return {
        width: 150,
        type: 'number',
      };
    case 'asset_id':
      return {
        width: 100,
      }
    case 'kb_theme_id':
      return {
        width: 300,
      }
    case 'asset':
      return {
        width: 100,
        renderCell: (params) => (
          <Box sx={{ display: 'flex', justifyContent: 'center', minWidth: '100px' }}>
            <Box sx={{ height: 50, minWidth: 50,
                       backgroundImage: `url(${params.row['link_url']})`,
                       backgroundSize: 'auto 100%',
                       backgroundRepeat: 'no-repeat',
                       backgroundPosition: 'center' }}>
            </Box>
          </Box>
        )
      };
    case 'theme':
      return {
        height: 100,
        width: 200,
        renderCell: (params) => (
          <Box sx={{ display: 'flex', justifyContent: 'center', minWidth: '100px' }}>
            <Box sx={{ height: 100, minWidth: 200,
              backgroundImage: `url(${params.row['preview_url']})`,
              backgroundSize: 'auto 100%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center' }}>
            </Box>
          </Box>
        )
      }
    default:
      return {
        width: 200,
      };
  }
};

const stateToProps = (state) => ({
  report: report(state),
});

const actionToProps = (dispatch) => ({});

const ReportGrid = ({reportDescriptor, reportFilters, reportGroupings, ...props}) => {
  const columns = () => {
    const grouping = reportGroupings.filter(reportGrouping => reportGrouping.key === reportDescriptor.key)[0];

    if( grouping ) {
      let columns = [...reportDescriptor.availableGroupings]
        .filter(availableGrouping => grouping.groupings.includes(availableGrouping.name))
        .map(availableGrouping => resolveExtraColumns(availableGrouping));
      columns.push(reportDescriptor.coreColumn);

      return columns.flat().map(columnName => {
        return {
          ...cellRendition(columnName),
          field: columnName,
          headerName: columnName,
        };
      });
    }

    return [];
  }

  return (
    <Box sx={{ height: 1, display: 'flex', flexDirection: 'column', pl: 8 }}>
      <Typography variant="h4" sx={{ color: 'text.primary' }}>Results</Typography>

      <Box sx={{ flex: '1 1 0%', mt: 8 }}>
        {props.report.data != null && (
          <DataGrid rows={props.report.data}
                    getRowHeight={() => {
                      const grouping = reportGroupings.filter(reportGrouping => reportGrouping.key === reportDescriptor.key)[0];

                      return grouping.groupings.includes('theme') ? 'auto' : 52;
                    }}
                    columns={columns()}
                    loading={props.report.isLoading}
                    getRowId={(row) => generateRandom()}
                    sx={{ bgColor: 'paper' }}>
          </DataGrid>
        )}
      </Box>
    </Box>
  );
};

export default connect(stateToProps, actionToProps)(ReportGrid);

