import {createSelector} from 'reselect';

const userMsg = state => state.user.msg;
const userStatusCode = state => state.user.statusCode;
const userInfo = state => state.user.info;
const userPermissions = state => {
  let currentOrganization = state.user.info.organizations.filter(
    (org) => org.id === (state.clients.organization.data !== undefined ? state.clients.organization.data.id : 0)
  )[0];

  if( currentOrganization !== undefined ) {
    return currentOrganization.userPermissions;
  }

  return state.user.info.globalPermissions;
};
const userUpdateInfo = state => state.user.updateInfo;
const userNotifications = state => state.user.notifications;
const userNotificationsConsume = state => state.user.notificationsConsume;
const userProfileUpdate = state => state.user.profileUpdate;
const userOrganizations = state => state.user.organizations;
const userRoles = state => state.user.roles;
const userChannels = state => state.user.channels;
const userOtherChannels = state => state.user.otherChannels;
const userCreateChannels = state => state.user.createChannels;
const userChannelsDetails = state => state.user.channelsDetails;
const userUpdateChannels = state => state.user.updateChannels;
const userDeleteChannels = state => state.user.deleteChannels;
const userUnmappedChannels = state => state.user.unmappedChannels;
const userConnectChannels = state => state.user.connectChannels;
const userConnectChannelsDetails = state => state.user.connectChannelsDetails;
const userSeries = state => state.user.series;
const userDataSeries = state => state.user.dataSeries;
const userStatsLeaderboard = state => state.user.statsLeaderboard;
const userCountrySeries = state => state.user.countrySeries;
const userChannelsIds = createSelector(
  [userChannels], (userChannels) => {
    const ids = [];

    if (userChannels.data) {
      userChannels.data.map((item) => {
        ids.push(item.id);
      });
    }

    return ids;
  },
);
const userStatsLast7days = state => state.user.statsLast7days;
const userStatsLast28days = state => state.user.statsLast28days;
const userStatsLastMonth = state => state.user.statsLastMonth;

export {
  userMsg,
  userStatusCode,
  userInfo,
  userPermissions,
  userUpdateInfo,
  userNotifications,
  userNotificationsConsume,
  userProfileUpdate,
  userOrganizations,
  userRoles,
  userChannels,
  userOtherChannels,
  userCreateChannels,
  userChannelsDetails,
  userUpdateChannels,
  userDeleteChannels,
  userUnmappedChannels,
  userConnectChannels,
  userConnectChannelsDetails,
  userSeries,
  userDataSeries,
  userStatsLeaderboard,
  userCountrySeries,
  userStatsLast7days,
  userStatsLast28days,
  userStatsLastMonth,
  userChannelsIds,
};
