import React from 'react';

const GiphyLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.66667 240" id="svg2" width={props.width} height={props.height} version="1.1">
      <defs id="defs6" />
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,240)" id="g10">
        <g transform="scale(0.1)" id="g12">
          <path id="path14" style={{fill: '#00ff99', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="M 0,1730 V 130 H 200 V 1730 H 0" />
          <path id="path16" style={{fill: '#9933ff', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="M 1200,1330 V 130 h 200 v 1200 h -200" />
          <path id="path18" style={{fill: '#fff35c', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="M 675,1800 H 0 v -200 h 870 v 200 H 675" />
          <path id="path20" style={{fill: '#999999', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} d="M 900,1600 H 600 870 v 60 l 30,-60" />
          <path id="path22" style={{fill: '#999237', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} d="M 870,1600 H 600 l 200,200 70,-140 v -60" />
          <path id="path24" style={{fill: '#5c1f99', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} d="m 1200,1000 v 300 l 200,-100 -200,-200" />
          <path id="path26" style={{fill: '#00ccff', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="M 1200,200 H 0 V 0 h 1400 v 200 h -200" />
          <path id="path28" style={{fill: '#ff6666', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="m 1200,1400 v 200 h -200 v 200 H 800 v -163.33 -200 V 1200 h 200 200 200 v 200 h -200" />
        </g>
      </g>
    </svg>
  );
};

export default GiphyLogo;
