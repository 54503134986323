import {Box, Skeleton, Typography, useTheme} from "@mui/material";
import React from "react";

function SingleTextVisSkeleton ({sx, animation = false, title, ...props}) {
  const theme = useTheme();

  return (
    <Box sx={sx}>
      <Box
        borderRadius={1}
        border={1}
        sx={{ width: "100%", height: "100%", borderColor: theme.palette.background.darkest, p: 4, gap: 4 }}
      >
        {!title && (
          <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "30%", color: 'text.primary' }} />
        )}

        {title && (
          <Typography color="textPrimary" variant="body2" component="div">
            {title}
          </Typography>
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "30%" }} />

          <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "20%" }} />
        </Box>
      </Box>
    </Box>
  );
}

export default SingleTextVisSkeleton;