import {Box, Breadcrumbs, Fab, Grid, LinearProgress, Link, Typography} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import { Link as RouterLink } from "react-router-dom";
import React, {useEffect, useState} from "react";

import Page from "@/components/Page";

import {
  getAudiences,
  getCustomInsightResults,
  getFilters,
  getTenantId,
  getDevices,
  getActiveUsersCount
} from "../store/selectors";
import {loadAudiences, loadInsights} from "../store/actions";

import VisUsers from "../subcomponents/vis/VisUsers";
import VisKeyboardUsage from "../subcomponents/vis/VisKeyboardUsage";
import VisKeyboardOpens from "../subcomponents/vis/VisKeyboardOpens";
import VisDevices from "../subcomponents/vis/VisDevices";
import VisActiveTime from "../subcomponents/vis/VisActiveTime";
import Filters from "../subcomponents/filters/Filters";
import VisOperatingSystems from "../subcomponents/vis/VisOperatingSystems";
import VisApps from "../subcomponents/vis/VisApps";
import VisGenders from "../subcomponents/vis/VisGenders";
import VisAgeGroups from "../subcomponents/vis/VisAgeGroups";
import VisInterests from "../subcomponents/vis/VisInterests";
import CustomInsightsDrawer from "../subcomponents/custom-insights/CustomInsightsDrawer";
import {Insights as InsightsIcon} from "@mui/icons-material";
import VisGenericPie from "../subcomponents/vis/VisGenericPie";
import VisTypedTerms from "../subcomponents/vis/VisTypedTerms";
import VisSearchTerms from "../subcomponents/vis/VisSearchTerms";
import VisTrendingTypedTerms from "../subcomponents/vis/VisTrendingTypedTerms";
import VisTrendingSearchTerms from "../subcomponents/vis/VisTrendingSearchTerms";
import VisKbLanguages from "../subcomponents/vis/VisKbLanguages";
import VisCountries from "../subcomponents/vis/VisCountries";
import VisConnection from "../subcomponents/vis/VisConnection";

const stateToProps = (state) => ({
  tenantId: getTenantId(state),
  audiences: getAudiences(state),
  filters: getFilters(state),
  customInsightsResults: getCustomInsightResults(state),
  devices: getDevices(state),
  activeUsersCount: getActiveUsersCount(state),
});
const actionToProps = (dispatch) => ({
  loadInsights: payload => dispatch(loadInsights(payload)),
  loadAudiences: payload => dispatch(loadAudiences(payload)),
});

const Insights = ({
  loadInsights,
  loadAudiences,
  activeUsersCount,
  tenantId,
  audiences,
  filters,
  customInsightsResults,
  devices
}) => {
  const [customInsightsDrawerOpen, setCustomInsightsDrawerOpen] = useState(false);

  useEffect(() => {
    if (tenantId) {
      loadInsights({ dashboard: 'old-dashboard' });
    }
  }, [loadInsights, tenantId]);

  useEffect(() => {
    if( audiences.data.length === 0 && !audiences.isLoading && tenantId) {
      loadAudiences({
        tenantId: tenantId
      });
    }
  }, [tenantId]);

  const osData = devices?.data?.operating_systems?.value?.map(d => ({ label: d['operating_systems'], value: Number(d.percentage) }));

  const customInsightVis = customInsightResult => {
    const visConfig = {
      ...customInsightResult,
      isLoading: customInsightResult.isLoading,
      isLoadingForALongTime: customInsightResult.isLoadingForALongTime,
    };

    return (
      <VisGenericPie visConfig={visConfig} sx={{ width: "100%", height: "100%" }} filters={filters} />
    );
  };

  const breadCrumbs = (
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to="#" component={RouterLink}>
        Reporting
      </Link>

      <Link variant="body1" to="#" component={RouterLink}>
        Insights
      </Link>
    </Breadcrumbs>
  );

  return (
    <Page title="Insights" breadCrumbs={breadCrumbs}>
      <Box mb={8}>
        <Filters audiences={audiences} dashboard="old-dashboard" />
      </Box>

      {activeUsersCount !== null && activeUsersCount < 100 && (
        <Box sx={{ w: 1, minHeight: '300px', bgcolor: 'background.darkest', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1">There is not enough data. Please expand your filters or target audience.</Typography>
        </Box>
      )}

        {(activeUsersCount === null || activeUsersCount >= 100) && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <VisUsers filters={filters} />
          </Grid>

          <Grid item xs={12}>
            <VisActiveTime filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={6}>
            <VisConnection filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={6}>
            <VisOperatingSystems
              data={osData}
              isLoading={devices.isLoading}
              isLoadingForALongTime={devices.isLoadingForALongTime}
              filters={filters}
              sx={{ width: "100%", height: "100%" }}
            />
          </Grid>

          <Grid item xs={5} xl={3}>
            <VisKeyboardUsage filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={7} xl={5}>
            <VisKeyboardOpens filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={6} xl={4}>
            <VisInterests filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={6} xl={5}>
            <VisGenders filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={8} xl={4}>
            <VisApps filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={4} xl={3}>
            <VisDevices filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={6} xl={4}>
            <VisKbLanguages filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={6} xl={8}>
            <VisCountries filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={6} xl={6}>
            <VisTypedTerms filters={filters} sx={{ width: "100%", height: "350px" }} />
          </Grid>

          <Grid item xs={6} xl={6}>
            <VisSearchTerms filters={filters} sx={{ width: "100%", height: "350px" }} />
          </Grid>

          <Grid item xs={6} xl={6}>
            <VisTrendingTypedTerms filters={filters} sx={{ width: "100%", height: "400px" }} />
          </Grid>

          <Grid item xs={6} xl={6}>
            <VisTrendingSearchTerms filters={filters} sx={{ width: "100%", height: "400px" }} />
          </Grid>

          <Grid item xs={6} xl={4}>
            <VisAgeGroups filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          {!customInsightsResults.isLoading && customInsightsResults.data && customInsightsResults.data.map(customInsightResult => (
            <Grid item xs={6} xl={4} key={`custom_insight_vis_${customInsightResult.insight_uid}`}>
              {customInsightVis(customInsightResult)}
            </Grid>
          ))}
        </Grid>
      )}

      {customInsightsResults.isLoading && (
        <Box sx={{ mt: 12 }}>
          <LinearProgress sx={{ borderRadius: 5, height: '7px' }} />
        </Box>
      )}

      <CustomInsightsDrawer tenantId={tenantId} isOpen={customInsightsDrawerOpen} onClose={() => setCustomInsightsDrawerOpen(false)} />

      <Fab variant="extended" color="primary" sx={{ position: 'fixed', bottom: 12, right: 12 }} onClick={() => setCustomInsightsDrawerOpen(true)}>
        <InsightsIcon sx={{ mr: 1 }} />
        Custom Insights
      </Fab>
    </Page>
  );
};

export default connect(stateToProps, actionToProps)(Insights);
