import React from 'react';

import {Redirect, Route} from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {loginAuthenticated} from "../containers/auth/store/selectors";
import LoadingScreen from 'AppComponents/LoadingScreen';
import {appGetStatus} from "../store/selectors";

const stateToProps = (state, props) => ({
  isAuthenticated: loginAuthenticated(state),
  appGetStatus: appGetStatus(state),
});

class PrivateRoute extends React.PureComponent {

  state = {
    appStatus: '',
  }

  componentDidUpdate(prevProps) {
    if (this.props.appGetStatus != this.state.appStatus) {
      this.setState({
        appStatus: this.props.appGetStatus
      });
    }
  }

  componentDidMount() {
    this.setState({
      appStatus: this.props.appGetStatus
    });
  }

  render() {
    const {appStatus} = this.state;
    const {isAuthenticated, ...props} = this.props;

    if (!appStatus || appStatus == 'loading') {
      return (<LoadingScreen/>);
    }

    if (isAuthenticated) return <Route {...props} />;

    return (<Redirect
      to={{pathname: '/login', state: {from: props.location}}}
    />);
  }
}

export default connect(stateToProps, null)(PrivateRoute);
