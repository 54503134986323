import React from "react";
import { create } from "jss";
import rtl from "jss-rtl";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import GlobalStyles from "./components/GlobalStyles";
import ScrollReset from "./components/ScrollReset";
import SettingsNotification from "./components/SettingsNotification";
import useSettings from "./hooks/useSettings";
import { createTheme } from "./theme";
import RoutesCollection from "./routes";
import { jssPreset, StylesProvider } from "@mui/styles";
import { StyledEngineProvider } from "@mui/material/styles";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const DismissAction = ({ id }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <React.Fragment>
      <Button color="secondary" onClick={() => closeSnackbar(id)}>
        Close
      </Button>
    </React.Fragment>
  );
};

const Wrapper = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <StylesProvider jss={jss}>
          <SnackbarProvider
            maxSnack={3}
            action={(key) => <DismissAction id={key} />}
            hideIconVariant={false}
          >
            <GlobalStyles />
            <ScrollReset />
            <SettingsNotification />
            <RoutesCollection />
          </SnackbarProvider>
        </StylesProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default Wrapper;
