if (!String.prototype.capitalize) {
  String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };
}

if (!String.prototype.ucwords) {
  String.prototype.ucwords = function () {
    return this.toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  };
}

if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (search, replacement) {
    const target = this;
    return target.replace(new RegExp(search, 'gi'), replacement);
  };
}

if (!String.prototype.replaceArray) {
  String.prototype.replaceArray = function (find, replace) {
    var replaceString = this;
    var regex;
    for (var i = 0; i < find.length; i++) {
      //escape special chars
      regex = new RegExp(find[i].replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), "g");
      replaceString = replaceString.replace(regex, replace[i]);
    }
    return replaceString;
  };
}

if (!String.prototype.crc32) {
  String.prototype.crc32 = function () {
    return crc32(this);
  };
}

if (!String.prototype.padTime) {
  String.prototype.padTime = function () {
    return (`0${this}`).slice(-2);
  };
}

if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength >>= 0; //truncate if number or convert non-number to 0;
    padString = String((typeof padString !== 'undefined' ? padString : ' '));
    if (this.length > targetLength) {
      return String(this);
    }

    targetLength -= this.length;
    if (targetLength > padString.length) {
      padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
    }
    return padString.slice(0, targetLength) + String(this);
  };
}

/**
 * String.prototype.random()
 * String().random()
 */
if (!String.prototype.random) {
  const __randomCharsLower = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const __randomChars = `ABCDEFGHIJKLMNOPQRSTUVWXYZ${__randomCharsLower}`;
  String.prototype.random = function (length = 10, lower = true) {
    const chars = [];
    for (let i = 0; i < length; i++) {
      if (lower) {
        chars.push(__randomCharsLower.charAt(Math.floor(Math.random() * __randomCharsLower.length)));
      } else {
        chars.push(__randomChars.charAt(Math.floor(Math.random() * __randomChars.length)));
      }
    }
    return chars.join('');
  };
}


const makeCRCTable = function () {
  let c;
  const crcTable = [];
  for (let n = 0; n < 256; n++) {
    c = n;
    for (let k = 0; k < 8; k++) {
      c = ((c & 1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
    }
    crcTable[n] = c;
  }
  return crcTable;
};

const crc32 = function (str) {
  const crcTable = window.crcTable || (window.crcTable = makeCRCTable());
  let crc = 0 ^ (-1);

  for (let i = 0; i < str.length; i++) {
    crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xFF];
  }

  return (crc ^ (-1)) >>> 0;
};


/**
 * Formats a string using {0}{1} ... placholders .
 * 'Welcome to {0} {1}'.strFormat('website','user')
 * If the arguments are missing, the {x} is displayed
 * @param str
 * @param args
 */
String.prototype.strFormat = function (...args) {
  return this.replace(this._strFormatRegexp, (match, number) => {
    return typeof args[number] !== 'undefined' ? args[number] : match;
  });
};
String.prototype._strFormatRegexp = /{(\d+)}/g;

/**
 * Formats a string for plural/singular . It is a more advanced version of the strFormat
 * "{0} {0,minute|minutes} and {1} {1,second|seconds} left".strFormatPlurals(1,23)
 * '1 minute and 23 seconds left'
 * '{0} '
 * @param {string} str - pattern to be parsed
 * @param {...string|array} args - value to replaces the matches
 */
String.prototype.strFormatPlurals = function (...args) {
  //First replace the standard digits
  return this.strFormat(...args).replace(this._strFormatPluralsRegexp, (match, i, left, right) => {
    const value = typeof args[i] === 'undefined' ? 0 : args[i];
    if (value == 1) return left;
    return right;
  });
};
String.prototype._strFormatPluralsRegexp = /{(\d+),([^|]+)\|([^}]+)}/g;

String.prototype.indexOfPosition = function (searchElement, n, fromElement) {
  n = n || 0;
  fromElement = fromElement || 0;
  while (n > 0) {
    fromElement = this.indexOf(searchElement, fromElement);
    if (fromElement < 0) {
      return -1;
    }
    --n;
    ++fromElement;
  }
  return fromElement - 1;
};
