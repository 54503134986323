import connect from "react-redux/es/connect/connect";
import {Box} from "@mui/material";
import SimpleRectangleVisSkeleton from "../../../../components/vis/SimpleRectangleVisSkeleton";
import WorldMap from "../../../../components/vis/WorldMap";

const stateToProps = ({ insights: {sections: { countries } }}) => {
  return {
    isLoading: countries.isLoading,
    isLoadingForALongTime: countries.isLoadingForALongTime,
    data: countries.data,
  };
};

function mapData(data, key) {
  return data.map(d => ({ label: d['country_code'], percentage: Number(d.percentage), value: d.total_users }));
}

const VisCountries = ({ sx = {}, isLoading, isLoadingForALongTime, data, filters }) => {
  const hasData = data?.countries && data.countries.value.length > 0;
  const countriesData = data && data.countries && mapData(data.countries.value, 'country_code');

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "100%" }}
                                    animation="wave"
                                    title={isLoadingForALongTime ? "Active users by country - working" : "Active users by country"} />
      )}

      {!isLoading && !hasData && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: "100%" }}
                                    animation={false}
                                    title={"Active users by country - no data"} />
      )}

      {!isLoading && hasData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <WorldMap title="Active users by country" data={countriesData} filters={filters} />
        </Box>
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisCountries);