const LOAD_LOGIN_OBTAIN_XSRF_TOKEN = 'LOAD_LOGIN_OBTAIN_XSRF_TOKEN';
const SET_LOGIN_OBTAIN_XSRF_TOKEN = 'SET_LOGIN_OBTAIN_XSRF_TOKEN';

const LOAD_LOGIN_CHECK = 'LOAD_LOGIN_CHECK';
const SET_LOGIN_CHECK = 'SET_LOGIN_CHECK';

const LOAD_LOGIN = 'LOAD_LOGIN';
const SET_LOGIN = 'LOGIN_SET';
const RESET_LOGIN = 'RESET_LOGIN';

const LOAD_LOGOUT = 'LOAD_LOGOUT';
const SET_LOGOUT  = 'SET_LOGOUT';


const LOAD_CHECK_PASSWORD_CONFIRMED = 'LOAD_CHECK_PASSWORD_CONFIRMED';
const SET_CHECK_PASSWORD_CONFIRMED = 'SET_CHECK_PASSWORD_CONFIRMED';
const LOAD_CONFIRM_PASSWORD = 'LOAD_CONFIRM_PASSWORD';
const SET_CONFIRM_PASSWORD = 'SET_CONFIRM_PASSWORD';

const LOAD_INITIALIZE_TWO_FACTOR = 'LOAD_INITIALIZE_TWO_FACTOR';
const SET_INITIALIZE_TWO_FACTOR = 'SET_INITIALIZE_TWO_FACTOR';
const LOAD_TWO_FACTOR_QR_CODE = 'LOAD_TWO_FACTOR_QR_CODE';
const SET_TWO_FACTOR_QR_CODE = 'SET_TWO_FACTOR_QR_CODE';
const LOAD_CONFIRM_TWO_FACTOR = 'LOAD_CONFIRM_TWO_FACTOR';
const SET_CONFIRM_TWO_FACTOR = 'SET_CONFIRM_TWO_FACTOR';
const LOAD_VALIDATE_TWO_FACTOR_CHALLENGE = 'LOAD_VALIDATE_TWO_FACTOR_CHALLENGE';
const SET_VALIDATE_TWO_FACTOR_CHALLENGE = 'SET_VALIDATE_TWO_FACTOR_CHALLENGE';

const LOAD_REGISTER = 'LOAD_REGISTER';
const SET_REGISTER = 'SET_REGISTER';

const LOAD_RESEND_EMAIL_CONFIRMATION = 'LOAD_RESEND_EMAIL_CONFIRMATION';
const SET_RESEND_EMAIL_CONFIRMATION = 'SET_RESEND_EMAIL_CONFIRMATION';
const LOAD_VERIFY_EMAIL = 'LOAD_VERIFY_EMAIL';
const SET_VERIFY_EMAIL = 'SET_VERIFY_EMAIL';

export {
  LOAD_LOGIN_OBTAIN_XSRF_TOKEN,
  SET_LOGIN_OBTAIN_XSRF_TOKEN,
  LOAD_LOGIN_CHECK,
  SET_LOGIN_CHECK,
  LOAD_LOGIN,
  SET_LOGIN,
  RESET_LOGIN,
  LOAD_LOGOUT,
  SET_LOGOUT,
  LOAD_CHECK_PASSWORD_CONFIRMED,
  SET_CHECK_PASSWORD_CONFIRMED,
  LOAD_CONFIRM_PASSWORD,
  SET_CONFIRM_PASSWORD,
  LOAD_INITIALIZE_TWO_FACTOR,
  SET_INITIALIZE_TWO_FACTOR,
  LOAD_TWO_FACTOR_QR_CODE,
  SET_TWO_FACTOR_QR_CODE,
  LOAD_CONFIRM_TWO_FACTOR,
  SET_CONFIRM_TWO_FACTOR,
  LOAD_VALIDATE_TWO_FACTOR_CHALLENGE,
  SET_VALIDATE_TWO_FACTOR_CHALLENGE,
  LOAD_REGISTER,
  SET_REGISTER,
  LOAD_RESEND_EMAIL_CONFIRMATION,
  SET_RESEND_EMAIL_CONFIRMATION,
  LOAD_VERIFY_EMAIL,
  SET_VERIFY_EMAIL,
};
