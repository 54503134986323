import {Box, CircularProgress, Fab, Typography} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import ReportFilters from "./ReportFilters";
import {reportExcel} from "../store/selectors";
import {
  loadExcelDownload,
  loadReport,
} from "../store/actions";
import ReportGroupings from "./ReportGroupings";
import {Download, Summarize} from "@mui/icons-material";
import ReportOrderings from "./ReportOrderings";
import OrderingDirection from "./OrderingDirection";
import ReportRowsLimit from "./ReportRowsLimit";
import React from "react";

const stateToProps = (state) => ({
  reportExcel: reportExcel(state),
});

const actionToProps = (dispatch) => ({
  loadReport: (data) => dispatch(loadReport(data)),
  loadExcelDownload: (data) => dispatch(loadExcelDownload(data)),
});

const ReportSettings = ({
  reportDescriptor,
  reportFilters,
  reportGroupings,
  reportOrderings,
  reportRowsLimit,
  setReportFilters,
  setReportGroupings,
  setReportOrderings,
  setReportRowsLimit,
  tenantId,
  ...props
}) => {
  const availableOrderings = () => {
    const foundGroupings = reportGroupings.filter(grouping => grouping.key === reportDescriptor.key);

    return [
      reportDescriptor.coreColumn,
      ...(foundGroupings.length === 1 ? foundGroupings[0].groupings : []),
    ];
  }

  const handleOrderingsChange = (selectedOrderings) => {
    setReportOrderings({
      reportOrderings: reportOrderings.map(ordering => {
        if (ordering.key === reportDescriptor.key) {
          return {
            ...ordering,
            orderings: [selectedOrderings].map(selectedOrdering => {
              return {
                name: selectedOrdering,
                mode: 'desc',
              };
            }),
          };
        }

        return ordering;
      }),
    })
  };

  const handleOrderingsDirectionChange = (newDirection) => {
    setReportOrderings({
      reportOrderings: reportOrderings.map(ordering => {
        if (ordering.key === reportDescriptor.key) {
          return {
            ...ordering,
            orderings: ordering.orderings.map(column => {
              return {
                name: column.name,
                mode: newDirection,
              };
            })
          };
        }
      }),
    });
  };

  const handleFilterChange = (filterName, newValue, changedProperty) => {
    setReportFilters({
      reportFilters: reportFilters.map(reportFilter => {
        if( reportFilter.key === reportDescriptor.key ) {
          return {
            ...reportFilter,
            filters: reportFilter.filters.map(filter => {
              if( filter.name === filterName ) {
                if( changedProperty === 'value' ) {
                  filter.value = newValue;
                }

                if( changedProperty === 'operator' ) {
                  filter.operator = newValue;
                }
              }

              return filter;
            }),
          };
        } else {
          return reportFilter;
        }
      }),
    });
  };

  const handleGroupingChange = (selectedGroupings) => {
    setReportGroupings({
      reportGroupings: reportGroupings.map((grouping) => {
        if( grouping.key === reportDescriptor.key) {
          return {
            ...grouping,
            groupings: selectedGroupings,
          };
        }

        return grouping;
      }),
    });

    setReportOrderings({
      reportOrderings: reportOrderings.map((ordering) => {
        if( ordering.key === reportDescriptor.key ) {
          return {
            ...ordering,
            orderings: ordering.orderings.filter(column => selectedGroupings.includes(column.name) || column.name === reportDescriptor.coreColumn),
          };
        }

        return ordering;
      }),
    });
  }

  const handleReportRowsLimitChange = (newLimit) => {
    setReportRowsLimit({
      reportRowsLimit: reportRowsLimit.map(reportRowLimit => {
        if( reportRowLimit.key === reportDescriptor.key ) {
          return {
            ...reportRowLimit,
            limit: newLimit,
          };
        } else {
          return reportRowLimit;
        }
      }),
    });
  };

  const reportParams = () => {
    return {
      reportKey: reportDescriptor.key,
      filters: reportFilters.filter(filtersCollection => filtersCollection.key === reportDescriptor.key)[0].filters.filter(
        filterRecord => filterRecord.value != null && filterRecord.value !== ''
      ),
      groupings: reportGroupings.filter(groupingsCollection => groupingsCollection.key === reportDescriptor.key)[0].groupings,
      orderings: reportOrderings.filter(groupingsCollection => groupingsCollection.key === reportDescriptor.key)[0].orderings,
      limit: reportRowsLimit.filter(reportRowLimit => reportRowLimit.key === reportDescriptor.key)[0].limit,
    }
  };

  const handleReportGeneration = () => {
    props.loadReport({
      ...reportParams(),
      tenantId: tenantId,
    });
  };

  const handleReportExcelGeneration = () => {
    if( !props.reportExcel.isLoading ) {
      props.loadExcelDownload(reportParams());
    }
  }

  return (
    <Box>
      <Box>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>Filters</Typography>

        <ReportFilters filterDescriptors={reportDescriptor.availableFilters}
                       handleFilterValueChange={(filterName, newValue) => handleFilterChange(filterName, newValue, 'value')}
                       handleFilterOperatorChange={(filterName, newValue) => handleFilterChange(filterName, newValue, 'operator')}>
        </ReportFilters>
      </Box>

      <Box sx={{ mt: 8 }}>
        <Typography variant="h4" sx={{ mb: 4, color: 'text.primary' }}>Breakdown</Typography>

        <ReportGroupings availableGroupings={reportDescriptor.availableGroupings}
                         setReportGroupings={handleGroupingChange}>
        </ReportGroupings>
      </Box>

      <Box sx={{ mt: 8 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 1, mb: 4 }}>
          <Typography variant="h4" sx={{ color: 'text.primary' }}>Order by</Typography>

          <OrderingDirection handleOrderingsDirectionChange={handleOrderingsDirectionChange} />
        </Box>

        <ReportOrderings availableOrderings={availableOrderings()}
                         setReportOrderings={handleOrderingsChange}>
        </ReportOrderings>
      </Box>

      <Box sx={{ mt: 8 }}>
        <Typography variant="h4" sx={{ mb: 4, color: 'text.primary' }}>Limit</Typography>

        <ReportRowsLimit setReportRowsLimit={handleReportRowsLimitChange} />
      </Box>

      <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 10 }}>
        <Fab sx={{ mr: 4 }} variant="extended" color="primary" aria-label="add" onClick={handleReportExcelGeneration}>
          {props.reportExcel.isLoading ? (
            <CircularProgress sx={{ mr: 1 }} size="24px" color="secondary"/>
          ) : (
            <Download sx={{ mr: 1 }} />
          )}
          Download report
        </Fab>

        <Fab variant="extended" color="primary" aria-label="add" onClick={handleReportGeneration}>
          <Summarize sx={{ mr: 1 }} />
          Generate report
        </Fab>
      </Box>
    </Box>
  );
};

export default connect(stateToProps, actionToProps)(ReportSettings);

