import Observable from 'Observable';
import {all, fork, put, call, select, takeEvery} from 'redux-saga/effects';
import { getValue } from 'AppUtils/objects';
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPostJson,
  apiPutJson,
} from "AppUtils/api";

import * as CONTENT from "./types";
import { userInfo } from "../../user/store/selectors";
import mashValidationMessage from "../../../utils/laravelValidationMessagesMasher";
import {apiPatch} from "../../../utils/api";

// Content Types
function* onLoadContentTypes(action) {
  const tenantId = yield parseTenantId(getValue(action, "payload.tenantId"));
  const page = getValue(action, "payload.page", 1);
  const rows_per_page = getValue(action, "payload.rows_per_page", 1000000);
  const filter = getValue(action, "payload.filter", "");

  const retry = 3;
  let data = "";
  let msg = "";
  let statusCode = "";

  const response = yield apiGet(`/${tenantId}/content/types`, {
    page,
    rows_per_page,
    filter,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(function(response) {
      if (response && !response.errors) {
        data = response.data;
      } else {
        msg = response.message;
      }
    });

  yield put({
    type: CONTENT.SET_CONTENT_TYPES,
    payload: { data, msg, statusCode },
  });
}

function* onLoadResetContentTypes(action) {
  yield put({type: CONTENT.SET_RESET_CONTENT_TYPES, payload: {data: [], msg: ''}});
}

// Content Keywords
function* onLoadContentKeywords(action) {
  const tenantId = yield parseTenantId(getValue(action, "payload.tenantId"));
  const page = getValue(action, "payload.page", 1);
  const rows_per_page = getValue(action, "payload.rows_per_page", 1000000);
  const filter = getValue(action, "payload.filter", "");

  const retry = 3;
  let data = "";
  let msg = "";
  let statusCode = "";
  let meta = {};

  const response = yield apiGet(`/${tenantId}/content/keywords`, {
    page,
    rows_per_page,
    filter,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(function(response) {
      if (response && !response.errors) {
        data = response.data;
        meta.current_page = response.meta.current_page;
        meta.last_page = response.meta.last_page;
      } else {
        msg = response.message;
      }
    });

  yield put({
    type: CONTENT.SET_CONTENT_KEYWORDS,
    payload: { data, msg, statusCode, meta },
  });
}

function* onLoadContentCreateKeywords(action) {
  const tenantId = yield parseTenantId(getValue(action, "payload.tenantId"));
  const keyword = getValue(action, "payload.keyword");
  const callback = getValue(action, "payload.callback");

  const retry = 3;
  let data = "";
  let msg = "";
  let statusCode = "";

  yield apiPost(`/${tenantId}/content/keywords`, { name: keyword.label })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => {Observable.of([])})
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(function(response) {
      if (response && !response.errors) {
        data = response.data;
        callback(keyword);
      } else {
        msg = mashValidationMessage(response.message, response.errors);
      }
    });

  yield put({
    type: CONTENT.SET_CONTENT_CREATE_KEYWORDS,
    payload: { data, msg, statusCode },
  });
}

// Content Folders
function* onLoadContentFolders(action) {
  const tenantId = getValue(action, "payload.tenantId");
  const contentTypeUid = getValue(action, "payload.contentTypeUid");

  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiGet(`/${tenantId}/content/types/${contentTypeUid}/folders/`, {})
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      statusCode = res.status;

      return res.json();
    })
    .toPromise()
    .then((response) => {
      if (statusCode === 200) {
        data = response.data.map((folder) => {
            folder.selected = false;
            folder.assets.data = folder.assets.data.map((asset) => {
              asset.mainResource = asset.items.filter((item) => item.placeholder === 'mainResource')[0];
              asset.previewResource = asset.items.filter((item) => item.placeholder === 'previewResource')[0];

              return asset;
            });

            return folder;
          });
      } else {
        message = response.message;
      }
    });

  yield put({
    type: CONTENT.SET_CONTENT_FOLDERS,
    payload: { data: data, message: message },
  });
}

function* onLoadCreateContentFolder(action) {
  const tenantId = getValue(action, "payload.tenantId");
  const contentTypeUid = getValue(action, "payload.contentTypeUid");
  const name = getValue(action, "payload.queryParams.name");
  const type = getValue(action, "payload.queryParams.type");
  const rank = getValue(action, "payload.queryParams.rank");

  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiPost(`/${tenantId}/content/types/${contentTypeUid}/folders/`, {
    name: name,
    type: type,
    rank: rank,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      statusCode = res.status;
      return res.json();
    })
    .toPromise()
    .then((response) => {
      if (statusCode === 201) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  yield put({
    type: CONTENT.SET_CREATE_CONTENT_FOLDER,
    payload: { data: data, message: message },
  });
  yield put({
    type: CONTENT.LOAD_CONTENT_FOLDERS,
    payload: { tenantId: tenantId, contentTypeUid: contentTypeUid },
  });
}

function* onLoadReadContentFolder(action) {
  const tenantId = getValue(action, "payload.tenantId");
  const contentTypeUid = getValue(action, "payload.contentTypeUid");
  const folderUid = getValue(action, "payload.folderUid");

  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiGet(
    `/${tenantId}/content/types/${contentTypeUid}/folders/${folderUid}/`,
    {}
  )
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      statusCode = res.status;

      return res.json();
    })
    .toPromise()
    .then((response) => {
      if (statusCode === 200) {
        data = response.data;
      } else {
        message = response.message;
      }
    });
  
  yield put({
    type: CONTENT.SET_READ_CONTENT_FOLDER,
    payload: { data: data, message: message },
  });
}

function* onLoadUpdateContentFolder(action) {
  const tenantId = getValue(action, "payload.tenantId");
  const contentTypeUid = getValue(action, "payload.contentTypeUid");
  const folderUid = getValue(action, "payload.folderUid");
  const params = getValue(action, "payload.params");
  const reloadFolders = getValue(action, "payload.reloadFolders", true);

  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiPutJson(
    `/${tenantId}/content/types/${contentTypeUid}/folders/${folderUid}/`,
    params
  )
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      statusCode = res.status;

      return res.json();
    })
    .toPromise()
    .then((response) => {
      if (statusCode === 200) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  yield put({
    type: CONTENT.SET_UPDATE_CONTENT_FOLDER,
    payload: { data: data, message: message },
  });

  if( reloadFolders ) {
    yield put({
      type: CONTENT.LOAD_CONTENT_FOLDERS,
      payload: { tenantId: tenantId, contentTypeUid: contentTypeUid },
    });
  }
}

function* onLoadDeleteContentFolder(action) {
  const tenantId = getValue(action, "payload.tenantId");
  const contentTypeUid = getValue(action, "payload.contentTypeUid");
  const folderUid = getValue(action, "payload.folderUid");

  const retry = 3;
  let data = [];

  yield apiDelete(
    `/${tenantId}/content/types/${contentTypeUid}/folders/${folderUid}/`,
    {}
  )
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      return res.json();
    })
    .toPromise()
    .then((response) => {});

  yield put({
    type: CONTENT.SET_DELETE_CONTENT_FOLDER,
    payload: { data: data },
  });

  yield put({
    type: CONTENT.LOAD_CONTENT_FOLDERS,
    payload: { tenantId: tenantId, contentTypeUid: contentTypeUid },
  });
}

function* onLoadModifyContentFolder(action) {
  yield put({
    type: CONTENT.SET_MODIFY_CONTENT_FOLDER,
    payload: { data: action.payload.data, message: action.payload.message },
  });
}

// Content Assets
function* onLoadDeleteContentFolderAsset(action) {
  const tenantId = getValue(action, 'payload.tenantId');
  const contentTypeUid = getValue(action, 'payload.contentTypeUid');
  const folderUid = getValue(action, 'payload.folderUid');
  const contentAssetUid = getValue(action, 'payload.contentAssetUid');
  const giphyChannelUid = getValue(action, 'payload.giphyChannelUid');

  const retry = 3;

  yield apiDelete(`/${tenantId}/content/types/${contentTypeUid}/folders/${folderUid}/assets/${contentAssetUid}`, {})
      .retryWhen((errors) => errors.delay(1000).take(retry))
      .catch(e => Observable.of([]))
      .mergeMap((res) => {
        return res.json();
      })
      .toPromise()
      .then(response => {});

  yield put({
    type: CONTENT.SET_DELETE_CONTENT_FOLDER_ASSET,
    payload: { contentAssetUid: contentAssetUid, giphyChannelUid: giphyChannelUid }
  });
  yield put({
    type: CONTENT.LOAD_READ_CONTENT_ASSETS,
    payload: { tenantId: tenantId, contentTypeUid: contentTypeUid, folderUid: folderUid },
  });
}

function* onLoadResetContentFolders(action) {
  yield put({type: CONTENT.SET_RESET_CONTENT_FOLDERS, payload: {data: [], message: ''}});
}

function* onLoadCreateContentAssets(action) {
  const tenantId = getValue(action, "payload.tenantId");
  const contentTypeUid = getValue(action, "payload.contentTypeUid");
  const params = getValue(action, "payload.params");

  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiPostJson(
    `/${tenantId}/content/types/${contentTypeUid}/assets/`,
    params
  )
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      statusCode = res.status;

      return res.json();
    })
    .toPromise()
    .then((response) => {
      if (statusCode === 200) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  data = data.map(asset => ({
    ...asset, 
    showAllKeywords: false,
    rank: asset.folders[0].rank
  }));

  yield put({
    type: CONTENT.SET_CREATE_CONTENT_ASSETS,
    payload: { data: data, message: message },
  });
}

function* onLoadReadContentAssets(action) {
  const tenantId = getValue(action, "payload.tenantId");
  const contentTypeUid = getValue(action, "payload.contentTypeUid");
  const folderUid = getValue(action, "payload.folderUid");

  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiGet(
    `/${tenantId}/content/types/${contentTypeUid}/folders/${folderUid}/assets/`,
    {}
  )
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      statusCode = res.status;
      return res.json();
    })
    .toPromise()
    .then((response) => {
      if (statusCode === 200) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  let newData = [];
  for (let i = 0; i < data.length; i++) {
    newData.push({
      ...data[i].asset,
      enabled: data[i].enabled,
      featured: data[i].featured,
      rank: data[i].rank,
      clicks: data[i].clicks,
      showAllKeywords: false,
      showReplaceAsset: false,
      isLoading: false,
    });
  }

  yield put({type: CONTENT.SET_READ_CONTENT_ASSETS, payload: {data: newData, message: message}});
}

function* onLoadUpdateContentAsset(action) {
  const tenantId = getValue(action, 'payload.tenantId');
  const contentTypeUid = getValue(action, 'payload.contentTypeUid');
  const contentAssetUid = getValue(action, 'payload.contentAssetUid');
  const params = getValue(action, 'payload.params');

  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiPutJson(
      `/${tenantId}/content/types/${contentTypeUid}/assets/${contentAssetUid}/`,
      params
  )
      .retryWhen((errors) => errors.delay(1000).take(retry))
      .catch((e) => Observable.of([]))
      .mergeMap((res) => {
        statusCode = res.status;

        return res.json();
      })
      .toPromise()
      .then((response) => {
        if (statusCode === 200) {
          data = response.data;
        } else {
          message = response.message;
        }
      });

  yield put({
    'type': CONTENT.SET_UPDATE_CONTENT_ASSET,
    'payload': {
      asset: data,
      message
    }
  });
}

function* onLoadUpdateContentAssetVersion(action) {
  const tenantId = getValue(action, 'payload.tenantId');
  const contentTypeUid = getValue(action, 'payload.contentTypeUid');
  const contentAssetUid = getValue(action, 'payload.contentAssetUid');
  const version = getValue(action, 'payload.version');

  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiPatch(
    `/${tenantId}/content/types/${contentTypeUid}/assets/${contentAssetUid}/version`,
    {version: version}
  ).retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      statusCode = res.status;

      return res.json();
    })
    .toPromise()
    .then(response => {
      console.log('updated baby!');
    });
}

function* onLoadDeleteContentAsset(action) {
  const tenantId = getValue(action, 'payload.tenantId');
  const contentTypeUid = getValue(action, 'payload.contentTypeUid');
  const folderUid = getValue(action, 'payload.folderUid');
  const contentAssetUid = getValue(action, 'payload.contentAssetUid');

  const retry = 3;
  let data = [];

  yield apiDelete(`/${tenantId}/content/types/${contentTypeUid}/folders/${folderUid}/assets/${contentAssetUid}/`, {})
      .retryWhen((errors) => errors.delay(1000).take(retry))
      .catch(e => Observable.of([]))
      .mergeMap((res) => {
        return res.json();
      })
      .toPromise()
      .then(response => {});

  yield put({type: CONTENT.SET_DELETE_CONTENT_ASSET, payload: {data: data}});
  yield put({
    type: CONTENT.LOAD_READ_CONTENT_ASSETS,
    payload: { tenantId: tenantId, contentTypeUid: contentTypeUid, folderUid: folderUid },
  });
}

// Content Items
function* onUploadContentItems(action) {
  const tenantId = yield parseTenantId(getValue(action, "payload.tenantId"));
  const contentTypeUid = yield getValue(action, "payload.contentTypeUid");
  const form = getValue(action, "payload.form");
  const file = getValue(action, "payload.localFile");

  const retry = 3;
  let data = file;
  let msg = "";
  let statusCode = "";

  yield put({
    type: CONTENT.LOAD_CONTENT_ITEMS,
    payload: { data, msg, statusCode },
  });

  yield apiPost(`/${tenantId}/content/types/${contentTypeUid}/items/`, form, { postFile: true })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(function(response) {
      if (statusCode === 201) {
        data = response.data;
        data.preview = file.preview;
        data.index = file.index;
      } else {
        msg = mashValidationMessage(response.message, response.errors)
        data = file;
      }
    });
  yield put({
    type: CONTENT.SET_CONTENT_ITEMS,
    payload: { data, msg, statusCode },
  });
}

function* onUploadLinkContentItem(action) {
  const tenantId = yield parseTenantId(getValue(action, "payload.tenantId"));
  const contentTypeUid = getValue(action, "payload.contentTypeUid");
  const localItem = getValue(action, "payload.localItem");

  const retry = 3;
  let data = {...localItem};
  let msg = "";
  let statusCode = "";

  yield put({
    type: CONTENT.LOAD_UPLOAD_LINKED_ITEM,
    payload: {data}
  });

  yield apiPost(
    `/${tenantId}/content/types/${contentTypeUid}/items/`, {
      name: 'gif',
      url: localItem.url,
      storageProviderIdentifier: 'linked',
    })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .mergeMap((response) => {
      const jsonResponse = response.json();
      statusCode = response.status;
      return jsonResponse;
    })
    .toPromise()
    .then(function(response) {
      if (statusCode === 201) {
        data = {
          ...data,
          uid: response.data.uid,
          name: response.data.name,
        };
      } else {
        msg = response.message;
      }
    });

  yield put({
    type: CONTENT.SET_UPLOAD_LINKED_ITEM,
    payload: { data, msg },
  });
}

function* onLoadDeleteContentItem(action) {
  const tenantId = getValue(action, 'payload.tenantId');
  const contentTypeUid = getValue(action, 'payload.contentTypeUid');
  const contentItemUid = getValue(action, 'payload.contentItemUid');

  const retry = 3;

  yield apiDelete(`/${tenantId}/content/types/${contentTypeUid}/items/${contentItemUid}/`, {})
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap((res) => {
      return res.json();
    })
    .toPromise()
    .then(response => {});

  yield put({type: CONTENT.SET_DELETE_CONTENT_ITEM, payload: {data: {contentItemUid: contentItemUid}}});
}

function* onLoadUpdateContentItem(action) {
  const tenantId = getValue(action, 'payload.tenantId');
  const contentTypeUid = getValue(action, 'payload.contentTypeUid');
  const contentItemUid = getValue(action, 'payload.contentItemUid');
  const form = getValue(action, 'payload.form');

  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = '';

  yield apiPost(
    `/${tenantId}/content/types/${contentTypeUid}/items/${contentItemUid}/`,
    form,
    { postFile: true }
  )
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      statusCode = res.status;
      return res.json();
    })
    .toPromise()
    .then(function(response) {
      if (statusCode === 200) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  yield put({type: CONTENT.SET_UPDATE_CONTENT_ITEM, payload: {data: data, message: message}});
}

function* onLoadUpdateLinkedContentItem(action) {
  const tenantId = getValue(action, 'payload.tenantId');
  const contentTypeUid = getValue(action, 'payload.contentTypeUid');
  const contentFolderUid = getValue(action, 'payload.contentFolderUid');
  const contentAssetUid = getValue(action, 'payload.contentAssetUid');
  const contentItemUid = getValue(action, 'payload.contentItemUid');
  const form = getValue(action, 'payload.form');

  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = '';

  yield apiPost(
    `/${tenantId}/content/types/${contentTypeUid}/items/${contentItemUid}/`,
    form
  )
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      statusCode = res.status;
      return res.json();
    })
    .toPromise()
    .then(function(response) {
      if (statusCode === 200) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  yield put({type: CONTENT.SET_UPDATE_CONTENT_ITEM, payload: {data: data, message: message}});
  yield put({type: CONTENT.LOAD_READ_CONTENT_ASSETS, payload: {
    tenantId: tenantId,
    contentTypeUid: contentTypeUid,
    folderUid: contentFolderUid}});
}

function* onLoadCheckLinkedItem(action) {
  const url = getValue(action, 'payload.url');
  let passes = false;
  let validatedUrl = url;

  yield apiGet(url, {}, {doNotTouchUrl: true})
    .catch((e) => Observable.of([]))
    .toPromise()
    .then((response) => {
      passes = response.status === 200 && response.headers.get('content-type') === 'image/gif';
    });

  yield put({type: CONTENT.SET_CHECK_LINKED_ITEM, payload: { validatedUrl: validatedUrl, passes: passes }});
}

function *onLoadReplaceAdditionalItems(action) {
  const tenantId = yield parseTenantId(getValue(action, "payload.tenantId"));
  const contentTypeUid = getValue(action, "payload.contentTypeUid");
  const contentAsset = getValue(action, "payload.contentAsset");
  const originalItems = getValue(action, "payload.originalItems");
  const newItems = getValue(action, "payload.newItems");
  const mainItem = getValue(action, "payload.mainItem");
  const previewItem = getValue(action, "payload.previewItem");

  for( let index = 0; index < originalItems.length; index ++ ) {
    yield put({
      type: CONTENT.LOAD_DELETE_CONTENT_ITEM,
      payload: {
        tenantId: tenantId,
        contentTypeUid: contentTypeUid,
        contentItemUid: originalItems[index].uid
      }
    });
  }

  let createdItems = [];

  for( let index = 0; index < newItems.length; index ++ ) {
    const formData = new FormData();
    formData.append("file", newItems[index]);
    formData.append("name", newItems[index].name);
    formData.append("url", newItems[index].preview);
    formData.append("storageProviderIdentifier", 's3');

    let createdItem = yield call(() => {
      const retry = 3;
      let statusCode = "";
      let data = newItems[index];
      let msg = '';

      return apiPost(`/${tenantId}/content/types/${contentTypeUid}/items/`, formData, { postFile: true })
        .retryWhen((errors) => errors.delay(1000).take(retry))
        .catch((e) => Observable.of([]))
        .mergeMap((res) => {
          const resp = res.json();
          statusCode = res.status;
          return resp;
        })
        .toPromise()
        .then(response => {
          if (statusCode === 201) {
            data = response.data;
          } else {
            msg = response.message;
            data = newItems[index];
          }

          return {data, msg};
        });
    });

    createdItems.push(createdItem);
  }

  yield put({
    type: CONTENT.LOAD_UPDATE_CONTENT_ASSET,
    payload: {
      tenantId,
      contentTypeUid,
      contentAssetUid: contentAsset.uid,
      params: {
        ...contentAsset,
        items: [
          {uid: mainItem.uid, placeholder: 'mainResource'},
          {uid: previewItem.uid, placeholder: 'previewResource'},
          ...createdItems.map(item => {return {uid: item.data.uid, placeholder: 'additionalResource'}})
        ]
      }
    }
  });

  yield put({
    type: CONTENT.SET_REPLACE_ADDITIONAL_ITEMS,
    payload: {
      contentAsset
    }
  });
}

// Compound items (themes - zip + preview(s))
function* onAddCompoundItem(action) {
  const tenantId = yield parseTenantId(getValue(action, "payload.tenantId"));
  const contentTypeUid = getValue(action, "payload.contentTypeUid");
  const localZip = getValue(action, "payload.zip");
  const localPreview = getValue(action, "payload.preview");
  const localAdditionalItems = getValue(action, "payload.additional");
  const index = getValue(action, "payload.index");

  const retry = 3;
  let msg = "";
  let statusCode = "";
  let items = {
    zip: localZip,
    preview: localPreview,
    ...localAdditionalItems,
    index: index,
  };
  let itemUploadPromises = [];

  yield put({
    type: CONTENT.LOAD_CONTENT_ITEMS,
    payload: { data: items, msg, statusCode },
  });

  for( const itemKey in items ) {
    if( itemKey === 'index' ) {
      break;
    }

    let itemForm = new FormData();
    itemForm.append("file", items[itemKey].file);
    itemForm.append("name", items[itemKey].name);
    itemForm.append("url", items[itemKey].url);
    itemForm.append("storageProviderIdentifier", "s3");

    itemUploadPromises.push(apiPost(`/${tenantId}/content/types/${contentTypeUid}/items/`, itemForm, { postFile: true })
      .retryWhen((errors) => errors.delay(1000).take(retry))
      .catch((e) => Observable.of([]))
      .mergeMap((res) => {
        const resp = res.json();
        statusCode = res.status;
        return resp;
      })
      .toPromise()
      .then(function(response) {
        if (statusCode === 201) {
          items[itemKey] = {
            ...items[itemKey],
            ...response.data,
            preview: localZip.preview,
            index: localZip.index,
          }
        } else {
          msg = response.message;
        }
      }));
  }

  yield put({
    type: CONTENT.RESET_CONTENT_ITEM,
    payload: {}
  });

  yield all(itemUploadPromises);

  yield put({
    type: CONTENT.SET_CONTENT_ITEMS,
    payload: {data: items, msg, statusCode},
  });
}

// Giphy Channels
function* onLoadCheckGiphyChannel(action) {
  const tenantId = getValue(action, 'payload.tenantId');
  const contentTypeUid = getValue(action, 'payload.contentTypeUid');
  const contentFolderUid = getValue(action, 'payload.contentFolderUid');
  const name = getValue(action, 'payload.name');

  const retry = 3;
  let statusCode = '';
  let passes = false;
  let msg = "";
  let gifsCount = 0;
  let previewUrl = '';

  yield apiPost(`/${tenantId}/content/types/${contentTypeUid}/folders/${contentFolderUid}/giphy-channels/${name}`, {}, {})
    .catch((e) => Observable.of([]))
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .mergeMap((response) => {
      const jsonResponse = response.json();
      statusCode = response.status;
      return jsonResponse;
    })
    .toPromise()
    .then((response) => {
      if( statusCode === 200 ) {
        passes = response.gifsCount > 0;
        gifsCount = response.gifsCount;
        previewUrl = response.previewUrl;
      } else {
        passes = false;
        msg = response.message;
      }
    });

  yield put({
    type: CONTENT.SET_CHECK_GIPHY_CHANNEL,
    payload: {
      gifsCount: gifsCount,
      giphyChannelValidationPasses: passes,
      previewUrl: previewUrl,
    }
  });
}

function* onAddGiphyChannel(action) {
  const tenantId = getValue(action, 'payload.tenantId');
  const contentTypeUid = getValue(action, 'payload.contentTypeUid');
  const contentFolderUid = getValue(action, 'payload.contentFolderUid');
  const localGiphyChannel = getValue(action, 'payload.localGiphyChannel');

  const retry = 3;
  let items = [];
  let giphyChannel = {};
  let msg = "";
  let statusCode = "";

  yield put({
    type: CONTENT.LOAD_ADD_GIPHY_CHANNEL,
    payload: {}
  });

  yield apiPost(`/${tenantId}/content/types/${contentTypeUid}/folders/${contentFolderUid}/giphy-channels/${localGiphyChannel.name}`, {}, {
    emptyHeaders: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
      'X-DoStore': true
    }
  })
    .catch((e) => Observable.of([]))
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .mergeMap((response) => {
      const jsonResponse = response.json();
      statusCode = response.status;
      return jsonResponse;
    })
    .toPromise()
    .then((response) => {
      if( statusCode === 201 ) {
        items = response.items.data;
        giphyChannel = response.giphyChannel;
      } else {
        msg = response.message;
      }
    });

  yield put({
    type: CONTENT.SET_ADD_GIPHY_CHANNEL,
    payload: { contentFolderUid, giphyChannel, items, statusCode, msg },
  });
}

function* onLoadDeleteGiphyChannel(action) {
  const tenantId = getValue(action, 'payload.tenantId');
  const contentTypeUid = getValue(action, 'payload.contentTypeUid');
  const contentFolderUid = getValue(action, 'payload.contentFolderUid');
  const giphyChannelUid = getValue(action, 'payload.giphyChannelUid');

  const retry = 3;

  yield apiDelete(`/${tenantId}/content/types/${contentTypeUid}/folders/${contentFolderUid}/giphy-channels/${giphyChannelUid}`, {})
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap((res) => {
      return res.json();
    })
    .toPromise()
    .then(response => {});

  yield put({type: CONTENT.SET_DELETE_GIPHY_CHANNEL, payload: {data: {giphyChannelUid: giphyChannelUid}}});
}

// Publish
function* onLoadPublishContent(action) {
  const tenantId = getValue(action, 'payload.tenantId');

  const retry = 3;
  let statusCode = 0;
  let message = "";
  let success = false;
  let diffs = [];

  yield apiGet(`/${tenantId}/content/mocha-publish`)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      statusCode = res.status;
      message = res.message;
      diffs = res;

      return res.json();
    })
    .toPromise()
    .then(response => {
      if( statusCode === 200 ) {
        diffs = response;
        success = true;
      }
    });

  yield put({
    type: CONTENT.SET_DIFFS,
    payload: {
      diffs: diffs,
      success: success,
      message: message,
    },
  });
}

function* onPublishToMocha(action) {
  const tenantId = getValue(action, 'payload.tenantId');

  const retry = 3;
  let statusCode = 0;
  let message = "";

  yield apiPostJson(`/${tenantId}/content/mocha-publish`, {})
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      statusCode = res.status;

      return res.json();
    })
    .toPromise()
    .then((response) => {
      message = response.message;
    });

  yield put({
    type: CONTENT.PUBLISH_TO_MOCHA_FINISHED,
    payload: {
      success: statusCode === 200,
      message: message,
    }
  });

  // @TODO Refresh the packs if we're on the content assets view.
  // yield put({
  //   type: CONTENT.LOAD_CONTENT_FOLDERS,
  //   payload: {
  //     tenantId: tenantId
  //   }
  // });
}

// Utils
function* parseTenantId(tenantId) {
  const info = yield select(userInfo);
  const url = new URL(window.location);
  const str = url.pathname;
  const res = str.match(/\/clients\/([0-9]*)\//);
  const clientId = res && res[1] ? parseInt(res[1]) : "";
  let selectedTenantId = tenantId || clientId;

  if (!selectedTenantId) {
    selectedTenantId = getValue(info, "organizations.0.id");
  }

  return selectedTenantId;
}

function* userWatchInitialize() {
  yield takeEvery(CONTENT.UPLOAD_CONTENT_ITEMS, onUploadContentItems);
  yield takeEvery(CONTENT.UPLOAD_LINKED_ITEM, onUploadLinkContentItem);
  yield takeEvery(CONTENT.LOAD_DELETE_CONTENT_ITEM, onLoadDeleteContentItem);
  yield takeEvery(CONTENT.LOAD_UPDATE_CONTENT_ITEM, onLoadUpdateContentItem);
  yield takeEvery(CONTENT.LOAD_UPDATE_LINKED_CONTENT_ITEM, onLoadUpdateLinkedContentItem);
  yield takeEvery(CONTENT.LOAD_CHECK_LINKED_ITEM, onLoadCheckLinkedItem);
  yield takeEvery(CONTENT.LOAD_REPLACE_ADDITIONAL_ITEMS, onLoadReplaceAdditionalItems);
  yield takeEvery(CONTENT.ADD_COMPOUND_ITEM, onAddCompoundItem);
  yield takeEvery(CONTENT.LOAD_CHECK_GIPHY_CHANNEL, onLoadCheckGiphyChannel);
  yield takeEvery(CONTENT.ADD_GIPHY_CHANNEL, onAddGiphyChannel);
  yield takeEvery(CONTENT.LOAD_DELETE_GIPHY_CHANNEL, onLoadDeleteGiphyChannel);
  yield takeEvery(CONTENT.LOAD_CONTENT_TYPES, onLoadContentTypes);
  yield takeEvery(CONTENT.LOAD_RESET_CONTENT_TYPES, onLoadResetContentTypes);
  yield takeEvery(CONTENT.LOAD_CONTENT_KEYWORDS, onLoadContentKeywords);
  yield takeEvery(CONTENT.LOAD_CONTENT_CREATE_KEYWORDS, onLoadContentCreateKeywords);
  yield takeEvery(CONTENT.LOAD_CONTENT_FOLDERS, onLoadContentFolders);
  yield takeEvery(CONTENT.LOAD_CREATE_CONTENT_FOLDER, onLoadCreateContentFolder);
  yield takeEvery(CONTENT.LOAD_READ_CONTENT_FOLDER, onLoadReadContentFolder);
  yield takeEvery(CONTENT.LOAD_UPDATE_CONTENT_FOLDER, onLoadUpdateContentFolder);
  yield takeEvery(CONTENT.LOAD_DELETE_CONTENT_FOLDER, onLoadDeleteContentFolder);
  yield takeEvery(CONTENT.LOAD_MODIFY_CONTENT_FOLDER, onLoadModifyContentFolder);
  yield takeEvery(CONTENT.LOAD_DELETE_CONTENT_FOLDER_ASSET, onLoadDeleteContentFolderAsset);
  yield takeEvery(CONTENT.LOAD_RESET_CONTENT_FOLDERS, onLoadResetContentFolders);
  yield takeEvery(CONTENT.LOAD_CREATE_CONTENT_ASSETS, onLoadCreateContentAssets);
  yield takeEvery(CONTENT.LOAD_READ_CONTENT_ASSETS, onLoadReadContentAssets);
  yield takeEvery(CONTENT.LOAD_UPDATE_CONTENT_ASSET, onLoadUpdateContentAsset);
  yield takeEvery(CONTENT.LOAD_UPDATE_CONTENT_ASSET_VERSION, onLoadUpdateContentAssetVersion);
  yield takeEvery(CONTENT.LOAD_DELETE_CONTENT_ASSET, onLoadDeleteContentAsset);
  yield takeEvery(CONTENT.LOAD_PUBLISH_CONTENT, onLoadPublishContent);
  yield takeEvery(CONTENT.PUBLISH_TO_MOCHA, onPublishToMocha);
}

export default function* sagas() {
  yield fork(userWatchInitialize);
}
